import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {deleteDataBase, getModal, getModalRow, getOrder} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import {useNavigate, useParams} from "react-router-dom";
import Modal from "react-modal";

const {REACT_APP_ASSETS_PATH} = process.env;
const MainScreen = (() => {

    const navigate = useNavigate();

    const lang = useSelector(LangSlices);

    const [modal, setModal] = useState(false);
    const [openRow, setOpenRow] = useState({});


    const [reset, setReset] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const [modalName, setModalName] = useState('Pack');

    const [searchOb, setSearchOb] = useState({});


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);

    const [colSklad, setSklad] = useState([]);


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setSearchOb(searchObS);
        setReset(!reset);
    }

    function addFilter(e) {
        setSearchOb({...searchOb, ...{search: e.target.value}});
        setReset(!reset);
    }


    async function getOrderSend() {

        let row = (await getModalRow(modalName)).data;
        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });


        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }

        setRowTable(row);
        const col = (await getModal(modalName, '?' + serialize(searchOb))).data;
        setColTable(col);


        // setOrder(orderReq);
    }

    async function getPreSklad() {
        const coSklad = (await getModal('Sklad', '?hidden_info=true&v=2')).data;
        setSklad(coSklad);
    }


    useEffect(() => {
        getOrderSend().then();
        getPreSklad().then();
    }, [reset]);

    return (<Layout>

        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
                Готовые чек-листы
            </p>

            <div className="table-btn">

                <Btn className={'btn-def-table text text-s14'} onClick={() => {
                    navigate('/Pack/edit/0');
                }} label={'Добавить'}/>
            </div>
        </div>

        <div className="table-filter">
            <CustomInput onChange={addFilter} value={searchOb?.search}
                         img={require('../../assets/images/search-normal.png')} label={'Поиск по комплектам'}/>

        </div>


        {
            !calendar && (
                <Table th={
                    rowTable
                } tr={
                    colTable
                }
                       option={(id, row) => {
                           return (
                               <div className={'table-options'}>
                                   <BtnIcon onClick={() => {
                                       setOpenRow(row);
                                       setModal(true);
                                   }} value={'список'}/>
                                   <BtnIcon title={'edit'} onClick={() => {
                                       navigate('/Pack/edit/' + id);
                                   }} img={require('../../assets/images/edit.png')}/>
                                   <BtnIcon onClick={async () => {
                                       if (window.confirm("Точно удалить?")) {
                                           await deleteDataBase({
                                               id: id
                                           }, modalName)
                                           setReset(!reset);
                                       }
                                   }} title={'delete'} img={require('../../assets/images/trash.png')}/>
                               </div>
                           )
                       }}
                />
            )
        }


        <Modal
            isOpen={modal}
            onRequestClose={() => {
                setModal(false);
            }}
            contentLabel="Example Modal"
            className="model-add-box-comp-list"
        >
            <div className="pack-center">
                <div className="pack-box">
                    <div className="pack-box__img">
                        <img
                            src={openRow?.photo ? REACT_APP_ASSETS_PATH + openRow?.photo : 'https://static.tildacdn.com/stor3066-6637-4635-b235-306432393236/28942791.jpg'}/>
                    </div>
                    <div className={'pack-box__list'}>
                        <div className="complect-list-head">
                            <span className={'text text-capitalize text-bold text-s26'}>{openRow?.name}</span>
                        </div>
                        <div className="complect-list-body">
                            {
                                typeof openRow?.product_list_product != "undefined" ?
                                    Object.entries(openRow?.product_list_array).map((product_list_controls) => {


                                        let nameBox = '';


                                        let contBox = [...colSklad].filter((bControl) => {
                                            return bControl.id == product_list_controls[0];
                                        });

                                        if (contBox.length > 0) {
                                            nameBox = contBox[0].name;
                                        }


                                        return (
                                            <div style={{marginBottom: "4rem"}}>
                                                <p className={'text text-s16'}>
                                                    <b>{nameBox}</b>
                                                </p>
                                                {
                                                    product_list_controls[1].map((li) => {


                                                        const string = li['photo'];
                                                        let type = 'text';


                                                        return (
                                                            <div className="item-complit"
                                                                 style={{display: "flex", alignItems: 'center'}}>

                                                                {
                                                                    string ? (
                                                                        <img onClick={() => {

                                                                        }} style={{
                                                                            marginRight: "1.5rem",
                                                                            position: "relative",
                                                                            zIndex: 100000,
                                                                            pointerEvents: 'all',
                                                                            objectFit: 'contain',
                                                                            objectPosition: "center center",
                                                                            backgroundColor: '#ececec',
                                                                            width: '7.5rem',
                                                                            height: '7.5rem',
                                                                        }}
                                                                             src={(string ? (string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') : '') + string}/>
                                                                    ) : (
                                                                        <img style={{
                                                                            marginRight: "1.5rem",
                                                                            position: "relative",
                                                                            zIndex: 100000,
                                                                            pointerEvents: 'all',
                                                                            objectPosition: "center center",
                                                                            width: '7.5rem',
                                                                            height: '7.5rem',
                                                                            backgroundColor: '#ececec'
                                                                        }}
                                                                        />
                                                                    )
                                                                }
                                                                <div className={'pack-detalis text text-s14'}>

                                                <span className={'pack-detalis__name text-capitalize'}>
                                                    <b>{li['name']}</b> <br/>
                                                    {li['num']}
                                                </span>

                                                                    <span
                                                                        className={'pack-detalis__count'}> {li['count']}</span>

                                                                    <span
                                                                        className={'pack-detalis__price'}> {parseInt(li['price_arenda']) > 0 ? parseInt(li['price_arenda']).toLocaleString() : '0'} тнг</span>

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        );


                                    })

                                    : ''
                            }
                        </div>

                        <div className={'pack-box__footer'} style={{marginTop: "-4rem"}}>
                            <p className={'text text-s18'}>
                                <b>Сумма: {
                                    typeof openRow?.product_list_product != "undefined" ? Object.entries(openRow?.product_list_product).reduce((ass, rowers) => {


                                        return ass + rowers[1].reduce((ass2, li) => {

                                            return ass2 + (parseInt(li['price_arenda']) > 0 ? parseInt(li['price_arenda']) : 0) * (parseInt(li['count']) > 0 ? parseInt(li['count']) : 0);


                                        }, 0);


                                    }, 0).toLocaleString() : '0'
                                } {' '} тг.</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>


    </Layout>);
});
export default MainScreen;
