import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

import Table from "../../components/Table";
import {
    getModal,
    getModalRow,
    getModalRowEdit,
    getOrder,
    getSingle,
    sendImportFile,
    updateDB
} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";
import CustomFile from "../../components/CustomFile";
import CustomSelect from "../../components/CustomSelect";

const MainScreen = (() => {


    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);
    const [excelFile, setExcelFile] = useState('');

    const [modalName, setModalName] = useState('Pack');
    const params = useParams();


    const [search, setSearch] = useState("");

    const [reset, setReset] = useState(false);
    const [rowTable, setRowTable] = useState({});

    const [page, setPage] = useState(0);


    const [openProduct, setOpenProduct] = useState(false);

    const [colTable, setColTable] = useState([]);


    const [modalProduct, setModalProduct] = useState(false);

    const [colTableProduct, setColTableProduct] = useState([]);

    const [orderSingle, setOrderSingle] = useState({});

    const [productList, setProductList] = useState({});
    const [selectSklad, setSelectSklad] = useState('1');
    const [colSklad, setSklad] = useState([]);

    async function getProductModal(page2) {
        setColTableProduct((await getModal('Product', '?page=' + (page2 + 1) + '&browse=1&filter_sklad_id=' + selectSklad + '&search=' + (search) + '&catalog_id=')).data);
    }

    async function getOrderSend() {

        let row = (await getModalRowEdit(modalName, params.id)).data;
        let orderSingle = (await getSingle(modalName, params.id, '?v=2')).data;

        if (orderSingle?.product_list) {
            setProductList(JSON.parse(orderSingle.product_list));
        }

        setOrderSingle(orderSingle);


        try {
            let modalBrowse = require('./components/Model/edit.json');
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        row[index] = {...row[index], ...modalBrowse[key]}
                    }
                });
            });


            let sortArray = [];
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        sortArray.push(row[index]);
                    }
                });
            });

            row.forEach((val, index) => {
                if (sortArray.filter((filItem) => {
                    return filItem['name'] == val['name']
                }).length == 0) {
                    sortArray.push(row[index]);
                }
            });


            row = sortArray;

        } catch (err) {

        }

        if (orderSingle) {
            row = row.map((pr) => {
                return {...pr, ...{value: orderSingle[pr.name]}}
            })
        }
        setInputs(row);


    }

    async function tableRow() {


        let row = (await getModalRow('Product',)).data;


        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88",
                value: "",
            }
        });
        try {
            let modalBrowse = require('../ProductScreen/components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }
        delete row['store_sklad'];
        delete row['store_arenda'];
        delete row['store'];
        setRowTable(row);

    }

    async function getPreSklad() {
        const coSklad = (await getModal('Sklad', '?hidden_info=true&v=2')).data;
        setSklad(coSklad);
    }

    useEffect(() => {
        getPreSklad().then();

        getProductModal(page).then();

    }, [reset]);

    useEffect(() => {
        getOrderSend().then();
        tableRow().then();
    }, [params.id]);

    return (<Layout>

        <div className="edit-model-head">
            <p className={'text text-bold text-s26'}>
                Добавить Комплекты
            </p>

            <Btn className={'btn-def-table text text-s14'} onClick={async () => {

                const updateDataOrder = (await updateDB({
                    'save': {
                        ...inputs.reduce((ass, val) => {
                            return {...ass, ...{[val.name]: val.value}};
                        }, {}), ...{product_list: JSON.stringify(productList)}
                    },
                    'id': params.id, 'model_name': 'Pack',
                })).data[0];

                if (params.id == 0) {
                    navigate('/Pack/edit/' + updateDataOrder.id);
                }

                setProductList(JSON.parse(updateDataOrder.product_list));
                setOrderSingle(updateDataOrder);

            }} label={'Сохранить'}/>
        </div>

        <InputsEdit inputs={inputs} onChange={(e, name) => {
            setInputs(inputs.map((inp) => {
                if (inp["name"] == name) {
                    return {...inp, ...{value: e.target.value}};
                }
                return inp;
            }));

        }}/>


        <Modal
            isOpen={modalProduct}
            onRequestClose={() => {
                setModalProduct(false);
            }}
            contentLabel="Example Modal"
            className="model-add-box"
        >
            <div className="edit-table edit-table-model">
                <div className="edit-table__head">
                    <span className="text text-s22">Выбрать товары из каталога</span>
                    <div className="modal-add-btns">
                        <Btn className={'btn-def-table active text text-s14'} label={'Каталог'}/>
                    </div>
                    <div className="modal-add-btns">

                        <Btn className={'btn-def-table btn-def-table-clear text text-s14'} onClick={() => {
                            setModalProduct(false);
                        }} label={'закрыть'}/>
                        <Btn onClick={async () => {

                            const updateDataOrder = (await updateDB({
                                'save': {
                                    product_list: JSON.stringify(productList)
                                }, 'id': params.id, 'model_name': 'Pack',
                            })).data[0];
                            setProductList(JSON.parse(updateDataOrder.product_list));
                            setOrderSingle(updateDataOrder);
                            NotificationManager.success('можете закрыть, форму с продуктами', 'Товары добавлены');
                            getOrderSend().then();

                        }} className={'btn-def-table text text-s14'} load={false} label={(<>Добавить товар</>)}/>
                    </div>
                </div>
                <div className={'pagination-box text text-s18'}>

                    <div style={{marginRight: "auto", display: "flex", alignItems: "center"}}>
                        <CustomInput value={search} onChange={(e) => {
                            setSearch(e.target.value);
                            setPage(0);
                            setReset(!reset);
                        }} name={'search'} img={require('../../assets/images/search-normal.png')} label={'Поиск'}/>

                        <div style={{width: "30rem", marginLeft: "4rem"}}>
                            <CustomSelect onChange={(e) => {
                                setSelectSklad(e.target.value);
                                setReset(!reset);
                            }} options={colSklad.reduce((acc, item) => {
                                return {...acc, ...{[item['id']]: item["name"]}};
                            }, {})} value={selectSklad} label={'Склады'}/>
                        </div>
                    </div>

                    {colTableProduct.last_page > 1 && (
                        <ReactPaginate
                            nextLabel=">"
                            previousLabel="<"
                            onPageChange={(e) => {
                                setPage(e.selected);
                                setReset(!reset);
                            }}
                            forcePage={page}
                            pageCount={colTableProduct.last_page}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    )}

                </div>
                <div className="edit-table__body">

                    <Table th={
                        rowTable
                    } tr={
                        colTableProduct.data
                    }
                           option={(id, row) => {
                               return (
                                   <div className={'table-options'}>
                                       <CustomInput className={'edit-product-order'} onChange={(e) => {
                                           setProductList({...productList, ...{[selectSklad]: {...productList[selectSklad], ...{[id]: parseInt(e.target.value)}}}});
                                       }}
                                                    value={selectSklad in productList && id in productList[selectSklad] ? productList[selectSklad][id] : ''}
                                                    type={'text'}
                                                    label={'Количество'}/>
                                   </div>
                               )
                           }}
                    />

                    <div className={'pagination-box text text-s18'}>
                        {colTableProduct.last_page > 1 && (
                            <ReactPaginate
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={(e) => {
                                    setPage(e.selected);
                                    setReset(!reset);
                                }}
                                forcePage={page}
                                pageCount={colTableProduct.last_page}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        )}

                    </div>
                </div>
            </div>
        </Modal>


        {/*<Modal*/}
        {/*    isOpen={modalProduct}*/}
        {/*    onRequestClose={() => {*/}
        {/*        setModalProduct(false);*/}
        {/*    }}*/}
        {/*    contentLabel="Example Modal"*/}
        {/*    className="model-add-box"*/}
        {/*>*/}
        {/*    <div className="edit-table edit-table-model">*/}


        {/*    </div>*/}
        {/*</Modal>*/}


        {
            params.id == 0 ? (
                <div className={'info_table text text-s18'}>
                    Чтобы добавить товары, заполните информацию о заказе выше и нажмине кнопку сохранить
                </div>
            ) : <div className="edit-table">
                <div className="edit-table__head">
                    <span className="text text-s22">Выбрать товары из каталога</span>

                    <form style={{marginLeft: "auto", marginRight: "2rem", display: "flex", alignItems: "center"}}>

                        <p style={{marginRight: "1.5rem"}}></p>

                    </form>
                    <span style={{display: "flex", marginLeft: "4rem"}}></span>


                    <span style={{display: "flex", marginLeft: "4rem"}}></span>
                    <Btn onClick={async () => {
                        setOpenProduct(true);

                        await getProductModal(page);
                        setModalProduct(true);

                        setOpenProduct(false);
                    }} className={'btn-def-table text text-s14'} load={openProduct} label={'Добавить товар вручную'}/>
                </div>
                <div className="edit-table__body">
                    {
                        orderSingle?.product_list_array != null ? Object.entries(orderSingle?.product_list_array).map((product_list_controls) => {

                            let nameBox = '';

                            let contBox = [...colSklad].filter((bControl) => {
                                return bControl.id == product_list_controls[0];
                            });

                            if (contBox.length > 0) {
                                nameBox = contBox[0].name;
                            }

                            return (
                                <div>
                                    <p className={'text text-s20'}>
                                        <b>{nameBox}</b>
                                    </p>
                                    <Table th={
                                        {...rowTable}
                                    } tr={
                                        product_list_controls[1]
                                    }
                                           option={(id, row) => {
                                               return (
                                                   <div className={'table-options'}>
                                                       <div className="edit-box text text-s14"
                                                            style={{marginRight: "3rem", whiteSpace: "nowrap"}}>
                                                           <CustomInput className={'edit-product-order'}
                                                                        onChange={(e) => {

                                                                            let preProduct = {...productList};


                                                                            setProductList({...productList, ...{[product_list_controls[0]]: {...productList[product_list_controls[0]], ...{[id]: parseInt(e.target.value)}}}});

                                                                        }}
                                                                        value={product_list_controls[0] in productList && id in productList[product_list_controls[0]] ? productList[product_list_controls[0]][id] : ''}
                                                                        type={'text'}
                                                                        label={'Количество'}/>
                                                       </div>

                                                       <BtnIcon
                                                           onClick={async () => {
                                                               // Создаем копию массива
                                                               let updatedProducts = {...productList};

                                                               // Получаем ID склада
                                                               const warehouseId = product_list_controls[0];

                                                               // Проверяем наличие объекта товаров для данного склада
                                                               if (updatedProducts[warehouseId]) {

                                                                   // Удаляем нужный элемент товара
                                                                   delete updatedProducts[warehouseId][id];

                                                                   // Если у склада нет товаров, удаляем сам склад
                                                                   if (Object.keys(updatedProducts[warehouseId]).length === 0) {
                                                                       delete updatedProducts[warehouseId];
                                                                   }

                                                                   // Обновляем клиентский массив

                                                                   // Обновляем базу данных с полным списком товаров
                                                                   const updateResponse = await updateDB({
                                                                       'save': {
                                                                           product_list: JSON.stringify(updatedProducts)
                                                                       },
                                                                       'id': params.id,
                                                                       'model_name': 'Pack',
                                                                   });

                                                                   await getOrderSend().then();


                                                               } else {
                                                                   console.error('The warehouse with the ID ' + warehouseId + ' does not exist in the product list.');
                                                               }
                                                           }}
                                                           img={require('../../assets/images/trash.png')}/>
                                                   </div>
                                               )
                                           }}
                                    />
                                </div>
                            )
                        }) : ''
                    }

                </div>
            </div>
        }


    </Layout>);
});
export default MainScreen;
