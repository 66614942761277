import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

import Table from "../../components/Table";
import {getModal, getModalRow, getModalRowEdit, getOrder, getSingle, updateDB} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";

const MainScreen = (() => {


    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);

    const [modalName, setModalName] = useState('User');
    const params = useParams();


    const [rowTable, setRowTable] = useState({});


    const [colTableProduct, setColTableProduct] = useState([]);

    const [orderSingle, setOrderSingle] = useState({});

    const [productList, setProductList] = useState({});


    async function getOrderSend() {

        let row = ((await getModalRowEdit(modalName, params.id)).data);
        let orderSingle = (await getSingle(modalName, params.id)).data;


        setOrderSingle(orderSingle);


        if (orderSingle) {
            row = row.map((pr) => {
                if (typeof orderSingle[pr.name] != "undefined" && orderSingle[pr.name]!= null) {
                    return {...pr, ...{value: orderSingle[pr.name]}};
                }
                return {...pr, ...{value: ""}};
            })
        }
        setInputs(row);


    }


    useEffect(() => {
        getOrderSend().then();
    }, [params.id]);

    return (<Layout>

        <div className="edit-model-head">
            <p className={'text text-bold text-s26'}>
                Добавить пользоватея
            </p>

            <Btn className={'btn-def-table text text-s14'} onClick={async () => {

                const updateDataOrder = (await updateDB({
                    'save': {
                        ...inputs.reduce((ass, val) => {
                            return {...ass, ...{[val.name]: val.value}};
                        }, {}),
                        ...{product_list: JSON.stringify(productList)}
                    }, 'id': params.id, 'model_name': 'User',
                })).data[0];

                if (params.id == 0) {
                    navigate('/User/edit/' + updateDataOrder.id);
                }

                setProductList(JSON.parse(updateDataOrder.product_list));
                setOrderSingle(updateDataOrder);

                NotificationManager.success('все хорошо, данные получили', 'Инвентаризация обновлена');

            }} label={'Сохранить'}/>
        </div>

        <InputsEdit inputs={inputs} onChange={(e, name) => {
            setInputs(inputs.map((inp) => {
                if (inp["name"] == name) {
                    return {...inp, ...{value: e.target.value}};
                }
                return inp;
            }));

        }}/>


    </Layout>);
});
export default MainScreen;
