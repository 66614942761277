import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

import Table from "../../components/Table";
import {getModal, getModalRow, getModalRowEdit, getOrder, getSingle, updateDB} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";
import CustomSelect from "../../components/CustomSelect";
import CustomFile from "../../components/CustomFile";

const MainScreen = (() => {


    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);

    const [modalName, setModalName] = useState('ProductDownManage');
    const params = useParams();


    const [productCh, setProductCh] = useState({});


    const [groupTable, setGroupTable] = useState([]);


    const [search, setSearch] = useState("");

    const [reset, setReset] = useState(false);
    const [timeOut, setTimeOut] = useState(false);


    const [rowTable, setRowTable] = useState({});

    const [page, setPage] = useState(0);


    const [openProduct, setOpenProduct] = useState(false);

    const [colTable, setColTable] = useState([]);


    const [modalProduct, setModalProduct] = useState(false);
    const [resetUpdate, setResetUpdate] = useState(false);
    const [resetUpdateStart, setResetUpdateStart] = useState(false);

    const [colTableProduct, setColTableProduct] = useState([]);

    const [catalogId, setCatalogId] = useState('');

    const [orderSingle, setOrderSingle] = useState({});

    const [productList, setProductList] = useState({});
    const [skladId, setSkladId] = useState(0);

    async function getProductModal() {

        let sklad_save = '';
        if (orderSingle?.sklad_id) {
            sklad_save = orderSingle?.sklad_id;
        }
        setColTableProduct((await getModal('Product', '?page=' + (page + 1) + '&browse=1&filter_sklad_id=' + sklad_save + '&search=' + (search) + '&catalog_id=' + catalogId)).data);
    }

    async function getOrderSend() {

        let row = (await getModalRowEdit(modalName, params.id + '?hidden=true')).data;


        let orderSingle = (await getSingle(modalName, params.id, '?hidden=true')).data;

        if (orderSingle?.sklad_id) {
            setSkladId(orderSingle?.sklad_id);
        }

        if (orderSingle?.product_list) {
            setProductList(JSON.parse(orderSingle.product_list));
        }
        if (orderSingle?.product_ch) {
            setProductCh(JSON.parse(orderSingle.product_ch));
        }
        setOrderSingle(orderSingle);


        try {
            let modalBrowse = require('./components/Model/edit.json');
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        row[index] = {...row[index], ...modalBrowse[key]}
                    }
                });
            });


            let sortArray = [];
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        sortArray.push(row[index]);
                    }
                });
            });

            row.forEach((val, index) => {
                if (sortArray.filter((filItem) => {
                    return filItem['name'] == val['name']
                }).length == 0) {
                    sortArray.push(row[index]);
                }
            });


            row = sortArray;

        } catch (err) {

        }

        if (orderSingle) {
            row = row.map((pr) => {
                return {...pr, ...{value: orderSingle[pr.name]}}
            })
        }


        const colGroup = (await getModal('Group', '')).data;
        setGroupTable(colGroup);

        setInputs(row);

        getProductModal();

    }

    async function tableRow() {


        let row = (await getModalRow('Product')).data;


        delete row['store'];
        delete row['store_arenda'];
        delete row['store_sklad'];
        delete row['price_arenda'];
        delete row['catalog_id'];
        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key], className: "ci-table-th-88", value: "",
            }
        });
        try {
            let modalBrowse = require('../ProductScreen/components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }
        setRowTable(row);

    }


    useEffect(() => {

        clearTimeout(timeOut);
        setTimeOut(setTimeout(() => {
            getProductModal();
        }, 500));

    }, [reset]);

    useEffect(() => {
        getOrderSend().then();
        tableRow().then();
    }, [params.id]);


    useEffect(() => {

        if (resetUpdateStart) {
            updateInfo();
        }

    }, [resetUpdate]);

    let type_val = 0;
    let select_type_item = [...inputs].filter((orIts) => {
        return orIts.name == 'type_down';
    });
    if (select_type_item.length > 0) {
        select_type_item = select_type_item[0];
        type_val = select_type_item.value;
    }

    async function updateInfo() {
        const updateDataOrder = (await updateDB({
            'save': {
                ...inputs.reduce((ass, val) => {
                    return {...ass, ...{[val.name]: val.value}};
                }, {}), ...{product_list: JSON.stringify(productList), product_ch: JSON.stringify(productCh)}
            }
            , 'id': params.id, 'model_name': 'ProductDownManage',
        })).data[0];

        if (params.id == 0) {
            navigate('/ProductDownManage/edit/' + updateDataOrder.id);
        }

        setProductList(JSON.parse(updateDataOrder.product_list));
        setOrderSingle(updateDataOrder);
    }

    return (<Layout>

        <div className="edit-model-head">
            <p className={'text text-bold text-s26'}>
                Товары на списание
            </p>

            <div>
                {
                    params.id > 0 && (
                        <Btn className={'btn-def-table text text-s14'} onClick={async () => {


                            const updateDataOrder = (await updateDB({
                                'save': {
                                    ...inputs.reduce((ass, val) => {
                                        return {...ass, ...{[val.name]: val.value}};
                                    }, {}), ...{
                                        product_list: JSON.stringify(productList),
                                        product_ch: JSON.stringify(productCh)
                                    }
                                }
                                , 'id': params.id, 'model_name': 'ProductDownManage',
                            })).data[0];

                            navigate('/ProductDownManage/browse');

                        }} label={'Завершить'}/>
                    )
                }

                <Btn className={'btn-def-table text text-s14'} onClick={async () => {


                    const updateDataOrder = (await updateDB({
                        'save': {
                            ...inputs.reduce((ass, val) => {
                                return {...ass, ...{[val.name]: val.value}};
                            }, {}), ...{
                                product_list: JSON.stringify(productList),
                                product_ch: JSON.stringify(productCh)
                            }
                        }
                        , 'id': params.id, 'model_name': 'ProductDownManage',
                    })).data[0];

                    if (params.id == 0) {
                        navigate('/ProductDownManage/edit/' + updateDataOrder.id);
                    }

                    setProductList(JSON.parse(updateDataOrder.product_list));
                    setOrderSingle(updateDataOrder);

                }} label={'Сохранить'}/>
            </div>
        </div>

        <InputsEdit openSelectCity={true} inputs={[...inputs].filter((inputControl) => {

            if (type_val == 1 && inputControl.name == "order_id") {
                return false;
            }
            return true;
        })} onChange={(e, name) => {


            setInputs(inputs.map((inp) => {
                if (inp["name"] == 'sklad_id') {
                    setSkladId(e.target.value);
                    setReset(!reset);
                }
                if (inp["name"] == name) {
                    return {...inp, ...{value: e.target.value}};
                }
                return inp;
            }));

            setResetUpdateStart(true);
            setResetUpdate(!resetUpdate);

        }}/>


        <Modal
            isOpen={modalProduct}
            onRequestClose={() => {
                setModalProduct(false);
            }}
            contentLabel="Example Modal"
            className="model-add-box"
        >
            <div className="edit-table edit-table-model">
                <div className="edit-table__head">
                    <span className="text text-s22">Выбрать товары из каталога</span>
                    <div className="modal-add-btns">
                        <Btn className={'btn-def-table active text text-s14'} label={'Каталог'}/>
                    </div>
                    <div className="modal-add-btns">
                        <Btn className={'btn-def-table btn-def-table-clear text text-s14'} onClick={() => {
                            setModalProduct(false);
                        }} label={'закрыть'}/>

                        <Btn onClick={async () => {


                            const updateDataOrder = (await updateDB({
                                'save': {
                                    product_list: JSON.stringify(productList)
                                }, 'id': params.id, 'model_name': 'ProductDownManage',
                            })).data[0];

                            setProductList(JSON.parse(updateDataOrder.product_list));
                            setOrderSingle(updateDataOrder);
                            NotificationManager.success('можете закрыть, форму с продуктами', 'Товары добавлены');
                            getOrderSend().then();
                            setModalProduct(false);
                        }} className={'btn-def-table text text-s14'} load={false}
                             label={(<>Добавить товар</>)}/>
                    </div>
                </div>
                <div className={'pagination-box text text-s18'}>

                    <div className="table-filter" style={{marginTop: "1rem", width: "100%"}}>

                        <CustomInput value={search} onChange={(e) => {
                            setSearch(e.target.value);
                            setPage(0);
                            setReset(!reset);
                        }} name={'search'}
                                     img={require('../../assets/images/search-normal.png')} label={'Поиск'}/>

                        <CustomSelect onChange={(e) => {
                            setCatalogId(e.target.value)

                            setPage(0);
                            setReset(!reset);
                        }} options={groupTable.reduce((acc, item) => {
                            return {...acc, ...{[item['id']]: item["name"]}};
                        }, {})} value={catalogId} label={'Все разделы'}/>

                    </div>

                    {colTableProduct.last_page > 1 && (
                        <ReactPaginate
                            nextLabel=">"
                            previousLabel="<"
                            onPageChange={(e) => {
                                setPage(e.selected);
                                setReset(!reset);
                            }}
                            forcePage={page}
                            pageCount={colTableProduct.last_page}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    )}

                </div>
                <div className="edit-table__body">

                    <Table th={rowTable} tr={colTableProduct.data}
                           option={(id, row) => {
                               return (<div className={'table-options'}>
                                   <CustomInput className={'edit-product-order'} onChange={(e) => {

                                       let preProduct = {...productList};
                                       if (parseInt(e.target.value) > 0) {
                                           preProduct[id] = parseInt(e.target.value);
                                       } else {
                                           delete preProduct[id];
                                       }
                                       setProductList(preProduct);

                                   }} value={id in productList ? productList[id] : ''} type={'text'}
                                                label={'Количество'}/>
                               </div>)
                           }}
                    />

                    <div className={'pagination-box text text-s18'}>
                        {colTableProduct.last_page > 1 && (
                            <ReactPaginate
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={(e) => {
                                    setPage(e.selected);
                                    setReset(!reset);
                                }}
                                forcePage={page}
                                pageCount={colTableProduct.last_page}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        )}

                    </div>
                </div>
            </div>
        </Modal>


        {params.id == 0 ? (<div className={'info_table text text-s18'}>
            Чтобы добавить товары, заполните информацию о заказе выше и нажмине кнопку сохранить
        </div>) : <div className="edit-table">
            <div className="edit-table__head">
                <span className="text text-s22">Выбрать товары из каталога</span>

                {
                    type_val == 1 && (
                        <Btn onClick={async () => {
                            setOpenProduct(true);

                            await getProductModal(page);
                            setModalProduct(true);

                            setOpenProduct(false);
                        }} className={'btn-def-table text text-s14'} load={openProduct} label={'Добавить товар'}/>
                    )
                }
            </div>
            <div className="edit-table__body">
                <Table th={{...rowTable}} tr={orderSingle?.product_list_array}
                       option={(id, row) => {
                           return (<div className={'table-options'}>
                               <CustomInput className={'edit-product-order edit-product-order-right'} onChange={(e) => {

                                   let preProduct = {...productList};
                                   if (parseInt(e.target.value) > 0) {
                                       preProduct[id] = parseInt(e.target.value);
                                   } else {
                                       delete preProduct[id];
                                   }
                                   setProductList(preProduct);

                               }} value={id in productList ? productList[id] : ''} type={'text'}
                                            label={'Количество'}/>


                               <CustomInput className={'edit-product-order edit-product-order-right'}
                                            onChange={(e) => {
                                                setProductCh({...productCh, ...{[id]: {...productCh[id], ...{'comment': e.target.value}}}});
                                            }}
                                            value={(id in productCh && 'comment' in productCh[id]) ? productCh[id]['comment'] : ''}
                                            type={'text'}
                                            label={'Примечание'}/>


                               <BtnIcon onClick={() => {
                                   let preProduct = {...productList};
                                   delete preProduct[id];
                                   setProductList(preProduct);

                                   let control_box = {...orderSingle};
                                   control_box.product_list_array = control_box?.product_list_array.filter((proControls) => {
                                       return proControls['id'] != id;
                                   });


                                   setOrderSingle(control_box);
                               }} img={require('../../assets/images/trash.png')}/>
                           </div>)
                       }}
                />
            </div>
        </div>}


    </Layout>);
});
export default MainScreen;
