import React, {useState} from 'react';

import './style.scss';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";

import ru from 'date-fns/locale/ru';
import moment from "moment/moment";

registerLocale('ru', ru);

const Article = (({
                      className,
                      onChangeVal1,
                      onChangeVal2,
                      val1 = false,
                      val2 = false,
                      showHolder = true,
                      onClick,
                      type = 'button',
                      name = '',
                      disabled = false,
                      finger = false,
                      label = '',
                      value,
                      onChange,
                      load = false,
                  }) => {


    let stateStart = val1;

    function setStateStart(e) {
        onChangeVal1(e);
    }


    let stateEnd = val2;

    function setStateEnd(e) {
        onChangeVal2(e);
    }

    const handleDateChangeStart = (dates) => {
        const dateSa = new Date(dates);
        const hours = dateSa.getHours();
        const minutes = dateSa.getMinutes();
        dateSa.setHours(hours);
        dateSa.setMinutes(minutes);
        setStateStart(dateSa);
    };


    const handleDateChangeEnd = (dates) => {
        const dateSa = new Date(dates);
        const hours = dateSa.getHours();
        const minutes = dateSa.getMinutes();
        dateSa.setHours(hours);
        dateSa.setMinutes(minutes);
        setStateEnd(dateSa);
    };


    let currentTime = false;
    let currentTimeEnd = false;


    let maxTimeStartDate = false;

    let minTimeEndDate = false;


    let isSameDay = false;

    if (stateStart != false && stateEnd != false) {
        isSameDay = (
            stateStart.getFullYear() === stateEnd.getFullYear() &&
            stateStart.getMonth() === stateEnd.getMonth() &&
            stateStart.getDate() === stateEnd.getDate()
        );
    }

    if (isSameDay && stateStart != false && stateEnd != false) {

        currentTime = new Date(stateStart);
        currentTime.setHours(0);
        currentTime.setMinutes(0);

        maxTimeStartDate = new Date();
        maxTimeStartDate.setHours(stateEnd.getHours());
        maxTimeStartDate.setMinutes(stateEnd.getMinutes());
        maxTimeStartDate.setSeconds(0);


        minTimeEndDate = new Date();
        minTimeEndDate.setHours(stateStart.getHours());
        minTimeEndDate.setMinutes(stateStart.getMinutes());
        minTimeEndDate.setSeconds(0);

        currentTimeEnd = new Date(stateEnd);
        currentTimeEnd.setHours(23);
        currentTimeEnd.setMinutes(30);
    }

    return (
        <div className={'box-input-controls'}>
            <div
                className={`ci-input-custom-box `}>

                {
                    showHolder && (
                        <div
                            className={'text  text-s10  ci-placeholder-edit '}> {finger ? 'Начало' : 'Монтаж'}
                        </div>
                    )
                }
                <DatePicker
                    selected={stateStart}
                    onChange={handleDateChangeStart}
                    startDate={stateStart}
                    endDate={stateEnd}
                    placeholderText={!showHolder ? (finger ? 'Начало' : 'Монтаж') : ''}
                    locale="ru"
                    timeIntervals={30}
                    dateFormat="dd.MM.yyyy"
                    maxDate={stateEnd}
                    minDate={false}
                    minTime={currentTime}
                    maxTime={maxTimeStartDate}
                />
            </div>

            <div
                className={`ci-input-custom-box `}>
                {
                    showHolder && (
                        <div
                            className={'text  text-s10  ci-placeholder-edit '}>{finger ? 'Конец' : 'Демонтаж'}
                        </div>
                    )
                }
                <DatePicker
                    selected={stateEnd}
                    onChange={handleDateChangeEnd}
                    startDate={stateStart}
                    endDate={stateEnd}
                    locale="ru"
                    placeholderText={!showHolder ? (finger ? 'Конец' : 'Демонтаж') : ''}
                    timeIntervals={30}
                    dateFormat="dd.MM.yyyy"
                    minDate={stateStart}
                    minTime={minTimeEndDate}
                    maxTime={currentTimeEnd}
                />
            </div>
        </div>
    );
});


export default Article;
