import React, {useState} from 'react';

import './style.scss';
import CustomFile from "../CustomFile";
import {updateDB} from "../../services";

const {REACT_APP_ASSETS_PATH} = process.env;

const Article = (({
                      className = "",
                      fileImg = "",
                      file = false,
                      ahe = false,
                      file_controls = false,
                      title = '',
                      onClick,
                      type = 'button',
                      img = '',
                      value,
                      productValue = false,
                      onChange
                  }) => {

    let titleName = {
        'delete': 'Удалить',
        'download': 'Скачать',
        'edit': 'Редактировать',
    };

    const [sendFile, setSendFile] = useState(className.includes('active'));
    const [sendFileImg, setSendFileImg] = useState(fileImg);

    const [age, setAge] = useState(productValue ? (productValue['pre']['agn'] == null ? true : false) : false);

    if (file) {
        if (sendFile) {
            className += ' active';
            file_controls = true;
        } else {
            className = className.split('active').join("");
            file_controls = false;

        }
    }

    if (file && !file_controls) {
        return (
            <div className={'box_conts-boxs1 '}>

                <button type={'button'} title={title in titleName && titleName[title]} onClick={onClick}
                        style={img ? {backgroundImage: 'url("' + (img) + '")'} : {}}
                        className={` ci-btn-icon ${className}`}>
                    <span className={'text text-s16'}>
                        {value}
                    </span>
                </button>

                <CustomFile capture={true} onChange={(e) => {

                    onClick(e.target.value);
                    setSendFileImg(e.target.value);
                    setSendFile(true);


                }} value={''} name={''}
                            labelName={'Загрузить файл'}/>


            </div>
        );
    }

    return (
        <div style={{display: "flex", alignItems: "center"}} className={'box_conts-boxs1'}>

            {
                ahe && (
                    <button title={title in titleName && titleName[title]} onClick={async (e) => {


                        const updateDataOrder = (await updateDB({
                            'save': {
                                "agn": !age ? null : 1,
                            },
                            'id': productValue['update_id'],
                            'model_name': 'OrderProduct',
                        }, false));

                        setAge(!age);


                    }} type={type}
                            className={` ci-btn-icon ci-btn-icon-age ` + (age && ('active'))}>
                        <span className={'text text-s16'}>
                           %
                        </span>
                    </button>
                )
            }

            {
                !ahe && (
                    <button title={title in titleName && titleName[title]} onClick={(e) => {
                        setSendFile(false);
                        setSendFileImg('');
                        onClick(e);
                    }} type={type}
                            style={img ? {backgroundImage: 'url("' + (img) + '")'} : {}}
                            className={` ci-btn-icon ${className}`}>
                        <span className={'text text-s16'}>
                            {value}
                        </span>
                    </button>
                )
            }


        </div>
    );
});


export default Article;
