import axios from 'axios';
import {NotificationManager} from "react-notifications";
import {store} from "../redux/index";
import StateActions from "../redux/actions/StateActions";

const {REACT_APP_API_PATH} = process.env;


window.update_controle = false;

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}


async function request(request, token, nativ = true) {

    let codeSave = getRandomArbitrary(0, 9000000);
    window.requestSendCode = codeSave;

    let status_code = false;
    if (window.update_controle) {
        status_code = true;
        window.update_controle = false;
    } else {
        status_code = false;
    }

    if (status_code && nativ) {
        NotificationManager.warning('запрос отправлен', 'Ожидайте идет обновление');
    }

    request = Object.assign({
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + store.getState().rootReducer.state.token_,
        },
        method: 'GET',
        url: '',
    }, request);

    let preUrl = request.url;
    request.url = REACT_APP_API_PATH + request.url;
    return await axios(request).then((response) => {
        if (status_code && nativ) {
            NotificationManager.success('все ок', 'Запись обновлена');
        }
        if (codeSave == window.requestSendCode) {
            window.requestSendCode = false;
        }
        return response.data;
    }).catch(function (error) {

        if (request.method == 'POST') {
            StateActions({
                order_add_product: [...store.getState().rootReducer.state.order_add_product, {
                    ...request, ...{
                        create_send: new Date(),
                        message: error.message
                    }
                }]
            });
        }
        if (error.message == 'Network Error') {
            error.message = 'Нет доступа к интернету';
        }


        NotificationManager.error(error.message, 'Ошибка');
        return [];
    });
}


export const getAllDistantionAdress = async (modal, add) => {

    const req = (await request({
        url: '/order/maps/list',
        method: 'GET',
    }));

    return req;
}

export const getModalRow = async (modal, add) => {

    const req = (await request({
        url: '/database/' + modal + '/browse_column?hidden=true',
        method: 'GET',
    }));

    return req;
}
export const getModalRowEdit = async (modal, id) => {

    const req = (await request({
        url: '/database/' + modal + '/edit_column/' + id,
        method: 'GET',
    }));

    return req;
}

export const getCatalogStorage = async (id) => {

    const req = (await request({
        url: '/store/catalog/' + id,
        method: 'GET',
    }));

    return req;
}


export const getUserInfo = async (token) => {

    const req = (await request({
        url: '/auth/user',
        method: 'GET',
    }, token));

    return req;
}


export const tableListGet = async () => {

    const req = (await request({
        url: '/table/list',
        method: 'GET',
    }));

    return req;
}
export const getModal = async (modal, search) => {

    const req = (await request({
        url: '/database/get/' + modal + search,
        method: 'GET',
    }));

    return req;
}
export const getCatalogMenu = async () => {

    const req = (await request({
        url: '/get_catalog_name',
        method: 'GET',
    }));

    return req;
}
export const getOrderProduct = async (id) => {

    const req = (await request({
        url: '/order/' + id + '/product',
        method: 'GET',
    }));

    return req;
}

export const copyOrder = async (id) => {

    const req = (await request({
        url: '/order/copy/' + id,
        method: 'GET',
    }));

    return req;
}
export const getOrderMenu = async (id) => {

    const req = (await request({
        url: '/order/' + id + '/menu',
        method: 'GET',
    }));

    return req;
}

export const getOrderPersonal = async (id) => {

    const req = (await request({
        url: '/order/' + id + '/person',
        method: 'GET',
    }));

    return req;
}

export const getOrderMenuPos = async (id) => {

    const req = (await request({
        url: '/order/' + id + '/newmenu',
        method: 'GET',
    }));

    return req;
}


export const getMessagePush = async (user_id) => {

    const req = (await request({
        url: '/get_messages/' + user_id,
        method: 'GET',
    }));
    return req;

}

export const getOrderCalculation = async (id) => {

    const req = (await request({
        url: '/order/get/' + id + '/calculation',
        method: 'GET',
    }));

    return req;
}

export const getOrderServ = async (id) => {

    const req = (await request({
        url: '/order/' + id + '/serv',
        method: 'GET',
    }));

    return req;
}

export const getRowArenda = async (id, date_select) => {

    const req = (await request({
        url: '/get/order/product/' + id + '?day=' + date_select,
        method: 'GET',
    }));

    return req;
}

export const getOrder = async (search) => {

    const req = (await request({
        url: '/database/get/Order' + search,
        method: 'GET',
    }));

    return req;
}

export const deleteDataBase = async (form, model) => {

    const req = (await request({
        url: '/database/' + model + '/delete',
        method: 'POST',
        data: form
    }));

    return req;
}


export const getCalendar = async (controls = true) => {

    const req = (await request({
        url: '/calendar_get' + (controls ? '' : '?hidden=true'),
        method: 'GET',
    }));

    return req;
}


export const getText = async () => {

    const req = (await request({
        url: '/text/get?lang=' + store.getState().rootReducer.state.lang,
        method: 'GET',
    }));

    return req;
}

export const getGaller = async (id) => {

    const req = (await request({
        url: '/text/get/img?key=' + id,
        method: 'GET',
    }));

    return req;
}

export const setForm = async (form) => {

    const req = (await request({
        url: '/text/form/save',
        method: 'POST',
        data: form
    }));
    return req;
}


export const setText = async (name_key, content, type) => {

    const req = (await request({
        url: '/text/save',
        method: 'POST',
        data: {
            name_key: name_key,
            content: content,
            type: type,
        }
    }));
    return req;
}


export const getAuth = async (query) => {

    const req = (await request({
        url: '/auth/login',
        method: 'POST',
        data: query
    }));
    return req;
}

export const sendFile = async (query) => {

    const req = (await request({
        url: '/update/files',
        method: 'POST',
        data: query
    }, '', 'file'));
    return req;
}

export const sendForm = async (query) => {

    const req = (await request({
        url: '/send/form',
        method: 'POST',
        data: query
    }));
    return req;
}

export const getSingle = async (modal, id, params = '') => {

    const req = (await request({
        url: `/database/get/${modal}/${id}` + params,
        method: 'GET',
    }));
    return req;
}

export const updateDBOrderProduct = async (data, nativ = true) => {
    window.update_controle = true;
    let info = await request({
        method: 'POST',
        url: `/database/orderproduct?v=2`,
        data: data,
    }, false, nativ);
    return info;
}

export const updateDBOrderMenu = async (data, nativ = true) => {
    window.update_controle = true;
    let info = await request({
        method: 'POST',
        url: `/database/ordermenu?v=2`,
        data: data,
    }, false, nativ);
    return info;
}

export const updateDBOrderPersonal = async (data, nativ = true) => {
    window.update_controle = true;
    let info = await request({
        method: 'POST',
        url: `/database/orderpersonal?v=2`,
        data: data,
    }, false, nativ);
    return info;
}

export const updateDB = async (data, nativ = true) => {
    window.update_controle = true;

    let info = await request({
        method: 'POST',
        url: `/database/update?v=2`,
        data: data,
    }, false, nativ);
    return info;
}

export const saveJson = async (data, nativ = true) => {
    window.update_controle = true;
    let info = await request({
        method: 'POST',
        url: `/pdf/save_json`,
        data: data,
    }, false, nativ);


    return info;
}
export const povarGen = async (fileName, nativ = true) => {
    window.update_controle = true;
    let info = await request({
        method: 'GET',
        url: `/pdf/save_json?filename=` + fileName,
    }, false, nativ);


    return info;
}
export const sendImportFile = async (data) => {
    window.update_controle = true;
    let info = await request({
        method: 'POST',
        url: `/import/pack/` + data['id'],
        data: {
            file: data['file']
        },
    }, false);
    return info;
}
