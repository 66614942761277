import React, {useState} from 'react';

import './style.scss';
import CustomInput from "../CustomInput";
import CustomSelect from "../CustomSelect";
import CustomFile from "../CustomFile";
import CustomText from "../CustomText";
import DateRange from "../DateRange";


const Article = (({inputs, onChange,adresGroup, timeShow = true,openSelectCity=false, nameDropClick, nameDrop = false}) => {

    const [search, setSearch] = useState(false);
    const [drop, setDrop] = useState(false);

    return (
        <>
            <div className="model-edit">
                {
                    Object.keys(inputs).map((index) => {
                        const input = inputs[index];

                        if (input?.name == 'mon') {

                            let inputMon = [...inputs.filter((inBoxin) => {
                                return inBoxin.name == 'mon';
                            })];
                            inputMon=inputMon[0];
                            let inputDemon = [...inputs.filter((inBoxin) => {
                                return inBoxin.name == 'demon';
                            })];
                            inputDemon=inputDemon[0];
                            inputMon.value = new Date(inputMon.value);
                            inputDemon.value = new Date(inputDemon.value);

                            if (!(new Date(inputMon.value) instanceof Date)) {
                                inputMon.value = false;
                            }
                            if (!(inputDemon.value instanceof Date)) {
                                inputDemon.value = false;
                            }

                            return (
                                <DateRange onChange={onChange} val1={inputMon.value} val2={inputDemon.value}/>
                            )
                        }
                        if (input?.name == 'demon') {
                            return null;
                        }

                        if (input?.type == 'hasOne') {
                            return (
                                <CustomSelect openSelectCity={openSelectCity} required={input?.required} onChange={onChange}
                                              options={input.hasOne.reduce((ass, val) => {
                                                  return {...ass, ...{[val.id]: val.name}};
                                              }, {})} name={input?.name} value={input.value}
                                              label={input?.placeholder}/>
                            );
                        }
                        if (input?.type == 'file') {
                            return (
                                <CustomFile onChange={onChange} value={input.value} className={input?.className}
                                            name={input?.name}
                                            type={input?.type}
                                            label={input?.placeholder}/>
                            );
                        }
                        if (input?.type == 'Textarea') {
                            return (
                                <CustomText required={input?.required} onChange={onChange} value={input.value}
                                            className={input?.className}
                                            name={input?.name}
                                            type={input?.type}
                                            label={input?.placeholder}/>
                            );
                        }


                        if (input.name == 'name' && nameDrop) {
                            return (
                                <div className={'select-product '}>
                                    <CustomInput required={input?.required} onChange={(e, name) => {
                                        onChange(e, name);
                                        setSearch(true);
                                        setDrop(true);

                                    }} value={input.value} className={input?.className}
                                                 name={input?.name}
                                                 type={input?.type}
                                                 label={input?.placeholder}/>


                                    {
                                        search && drop && nameDrop && typeof nameDrop == 'object' && (
                                            <div className="select-product__drop">
                                                {
                                                    [...nameDrop].filter((item) => {
                                                        if (item?.name) {
                                                            return item.name.toLowerCase().includes(input.value.toLowerCase());
                                                        }
                                                        return false;
                                                    }).map((item) => {
                                                        return (
                                                            <div onClick={() => {
                                                                nameDropClick(item);
                                                                setDrop(false);
                                                            }} className={'prod_item text text-s16'}>
                                                                {item.name}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }


                        return (
                            <CustomInput adresGroup={adresGroup} required={input?.required} timeShow={timeShow} onChange={onChange}
                                         value={input.value}
                                         className={input?.className}
                                         name={input?.name}
                                         type={input?.type}
                                         label={input?.placeholder}/>

                        )

                    })
                }

            </div>
        </>
    )
});


export default Article;
