import React, {useState} from 'react';
import './style.scss';
import Btn from "../Btn";

import {sendFile} from "../../services/index";
import Modal from "react-modal";

const {REACT_APP_ASSETS_PATH} = process.env;

const More = (({
                   clasName,
                   onChange,
                   name,
                   labelName = 'Загрузить фото',
                   typeFile = '',
                   value = false,
                   photoControl = true,
                   noFixFox = false,
                   capture = false,
                   fileName = false,
                   orderId = false,
                   saveNameCurrent = false,
                   onClick,
                   disable = false
               }) => {


    if (value == null || !value) {
        value = '';
    }


    const [image, setImage] = useState(value);
    const [modalShow, setModalShow] = useState(false);

    function showModal() {
        setModalShow(false);
    }

    return (
        <div
            className={`ci-input-custom-box ci-input-custom-box-edit ` + clasName}
            style={{flexDirection: 'row', alignItems: "center"}}>

            <Modal
                isOpen={modalShow}
                onRequestClose={showModal}
                contentLabel="Example Modal"
                className="model-add-box model-add-box-center"
            >
                <div className="box_conteol box_conteol-img">
                    <img onClick={() => {
                        showModal();
                    }} className={'mole_img'}
                         src={(image.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + image}/>
                    <div className="box_conteol_nav">
                        <p onClick={() => {
                            showModal();
                        }} className={'text text-s20'} style={{color: "#fff", marginTop: "2rem"}}>
                            закрыть
                        </p>
                        <p onClick={() => {
                            setImage('');
                            showModal();
                            onChange({
                                target: {
                                    name: name,
                                    value: '',
                                }
                            }, name);
                        }} className={'text text-s20'} style={{color: "#fff", marginTop: "2rem"}}>
                            удалить
                        </p>
                    </div>
                </div>
            </Modal>

            {
                (image && (/\.(jpe?g|png|gif|bmp)$/i.test(image))) && (
                    <img onClick={() => {
                        setModalShow(true);
                    }} className={'img-limit-fix'}
                         src={(image.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + image}/>
                )
            }
            {
                (image && !(/\.(jpe?g|png|gif|bmp)$/i.test(image))) && (
                    <a target={'_blank'} href={(image.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + image}
                       style={{
                           height: "5rem",
                           lineHeight: "100%",
                           backgroundColor: "#fff",
                           fontSize: "1.4rem",
                           border: "1px solid #000",
                           marginRight: "1rem",
                           color: "#000",
                           textTransform: "uppercase",
                           display: "flex",
                           alignItems: "center",
                           position: "relative",
                           justifyContent: "center"
                       }} className={'isd-text'}>
                        <p style={{position: "absolute", left: '50%', top: "50%", transform: "translate(-50%, -50%)"}}>
                            {(image.split('.')[image.split('.').length - 1])}
                        </p>
                    </a>
                )
            }
            {
                fileName && image && (
                    <a target={'_blank'} href={(image.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + image}
                       className={'text text-s16'} style={{marginRight: "2rem", color: "black", fontWeight: 'bold'}}>
                        {(image.split('/')[image.split('/').length - 1])}
                    </a>
                )
            }
            {
                !fileName && image && !(/\.(jpe?g|png|gif|bmp)$/i.test(image)) && (
                    <p className={'text text-s16'} style={{marginRight: "1rem"}}>
                        Файл загрузили
                    </p>
                )
            }
            <label
                className={`ci-input-file  `} style={{position: "relative"}}>

                {
                    !capture && (
                        <input type={'file'} accept="image/*"
                               onChange={(event) => {
                                   if (!disable) {
                                       let fileName = event.target.files[0].name.split('.');
                                       let fileNameFirst = fileName[0];
                                       fileName = fileName[fileName.length - 1];
                                       if (typeFile == '') {
                                           typeFile = fileName;
                                       }
                                       var reader = new FileReader();
                                       reader.readAsBinaryString(event.target.files[0]);
                                       reader.onload = async () => {

                                           let file = (await sendFile({
                                               "files": btoa(reader.result),
                                               "typeFile": typeFile,
                                               "currentName": saveNameCurrent ? fileNameFirst : false,
                                               "saveName": event.target.files[0].name,
                                               "orderId": orderId,
                                           })).data

                                           if (noFixFox) {
                                               setImage(file);
                                           }

                                           onChange({
                                               target: {
                                                   name: name,
                                                   value: file,
                                               }
                                           }, name);

                                       };
                                       reader.onerror = function (error) {
                                           console.log('Error: ', error);
                                       };
                                   }
                               }}
                               style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: "100%", height: "100%"}}/>
                    )
                }
                {
                    capture && (
                        <input type={'file'} accept="image/*"
                               onChange={(event) => {
                                   if (!disable) {
                                       let fileName = event.target.files[0].name.split('.');
                                       let fileNameFirst = fileName[0];
                                       fileName = fileName[fileName.length - 1];
                                       if (typeFile == '') {
                                           typeFile = fileName;
                                       }
                                       var reader = new FileReader();
                                       reader.readAsBinaryString(event.target.files[0]);
                                       reader.onload = async () => {

                                           let file = (await sendFile({
                                               "files": btoa(reader.result),
                                               "typeFile": typeFile,
                                               "currentName": saveNameCurrent ? fileNameFirst : false,
                                               "saveName": event.target.files[0].name,
                                               "orderId": orderId,
                                           })).data

                                           if (noFixFox) {
                                               setImage(file);
                                           }

                                           onChange({
                                               target: {
                                                   name: name,
                                                   value: file,
                                               }
                                           }, name);

                                       };
                                       reader.onerror = function (error) {
                                           console.log('Error: ', error);
                                       };
                                   }
                               }}
                               style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: "100%", height: "100%"}}/>
                    )
                }


                {/*<input onChange={(e) => {*/}

                {/*}} name={name}*/}
                {/*       className="text text-s14  ci-input-edit_in"/>*/}

                <span className="text text-s14  ">
                     {labelName + '  '}
                </span>
            </label>
        </div>
    )
});


export default More;
