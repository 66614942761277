import React, {useEffect, useState} from 'react';

import './style.scss';

import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

const Article = (({
                      className,
                      img,
                      key,
                      id,
                      adresGroup,
                      required = false,
                      timeShow = true,
                      showHolder = true,
                      name,
                      type = 'text',
                      disabled = false,
                      placeholder_active = false,
                      label,
                      value,
                      onChange,
                      onBlur,
                  }) => {


    if (type == 'datetime-local') {
        type = 'Datetime-local';
        if (value) {
            value = decodeURIComponent((value + '').replace(/\+/g, '%20'))
        }
    }

    if (typeof ((value)) == "undefined") {
        value = '';
    }
    if (typeof value == 'number' && '' + (value) == 'NaN') {
        value = '';
    }

    if (type == 'number' || type == 'Number') {
        if (!(parseInt(value) > 0)) {
            value = 0;
        } else {
            value = parseInt(value);
        }
        type = 'text';
    }

    return (
        <>
            <div
                className={`ci-input-custom-box ${className} ` + (type == 'Datetime-local' && 'ci-input-custom-box-date')}>
                {
                    type == 'Datetime-local' && showHolder && (
                        <div
                            className={'text  text-s10   ci-input-custom-box_label'}>{label}</div>
                    )
                }
                {
                    type != 'Datetime-local' && showHolder && (
                        <div
                            className={'text  text-s10  ci-placeholder-edit '}>{label}</div>
                    )
                }
                <label
                    className={`ci-input-custom ci-input-edit  ` + (img && 'ci-input-custom-img ')}>

                    {
                        showHolder && (typeof value == 'string') && value[0] == "#" && (
                            <p style={{width: 10, height: 10, marginTop: "3rem", backgroundColor: value}}>

                            </p>
                        )
                    }
                    {
                        showHolder && img && (
                            <span className={'ci-input-img'} style={{backgroundImage: 'url("' + img + '")'}}></span>
                        )
                    }


                    {
                        type == 'Datetime-local' && timeShow && (
                            <DateTimePicker
                                amPmAriaLabel="Select AM/PM"
                                format="dd.MM.yyyy HH:mm"
                                returnValue="start"
                                clearIcon={null}
                                calendarIcon={null}
                                dayPlaceholder="--"
                                monthPlaceholder="--"
                                yearPlaceholder="----"
                                hourPlaceholder="--"
                                minutePlaceholder="--"
                                shouldOpenWidgets={({widget}) => widget !== 'clock'}
                                required={required} placeholder="Выберите дату"
                                value={value ? new Date(moment(value)) : ''}
                                showTimeSelect={true} onChange={(date) => {
                                if (date) {
                                    let dateSave = moment(date).format("YYYY-MM-DD HH:mm:ss");


                                    // saveTime=saveTime.split('.');
                                    // saveTime=saveTime[0];

                                    onChange({
                                        target: {
                                            value: dateSave,
                                            name: name
                                        }
                                    }, name);
                                } else {
                                    onChange({
                                        target: {
                                            value: '',
                                            name: name
                                        }
                                    }, name);
                                }

                            }}/>
                        )
                    }

                    {
                        type == 'Datetime-local' && !timeShow && (
                            <DatePicker
                                amPmAriaLabel="Select AM/PM"
                                format="dd.MM.yyyy"
                                returnValue="start"
                                clearIcon={null}
                                calendarIcon={null}
                                dayPlaceholder="--"
                                monthPlaceholder="--"
                                yearPlaceholder="----"
                                hourPlaceholder="--"
                                minutePlaceholder="--"
                                shouldOpenWidgets={({widget}) => widget !== 'clock'}
                                required={required} placeholder="Выберите дату" value={value ? new Date(moment(value)) : ''}
                                showTimeSelect={true} onChange={(date) => {
                                if (date) {
                                    let dateSave = moment(date).format("YYYY-MM-DD HH:mm:ss");

                                    onChange({
                                        target: {
                                            value: dateSave,
                                            name: name
                                        }
                                    }, name);
                                } else {
                                    onChange({
                                        target: {
                                            value: '',
                                            name: name
                                        }
                                    }, name);
                                }

                            }}/>
                        )
                    }

                    {
                        type != 'Datetime-local' && (
                            <input onBlur={(e) => onBlur(e, name)} placeholder={!showHolder ? label : ''}
                                   required={required} key={key} type={type} value={value}
                                   disabled={disabled}
                                   onKeyUp={(e) => onChange(e, name)} onChange={(e) => onChange(e, name)} name={name}
                                   className="text text-s14  ci-input-edit_in"/>
                        )
                    }

                </label>

                {
                    adresGroup && adresGroup.length > 0 && name == 'adress_full' && (
                        <div className={'input-down-list'}>
                            {
                                adresGroup.map((userBox) => {

                                    return (
                                        <div  className={'input-down-list-el text text-s16'}>
                                            <span>{userBox.display_name}</span>
                                            <span className={'el_button_el'} onClick={()=>{
                                                onChange({
                                                    target: {
                                                        value: userBox.display_name + ': https://2gis.kz/almaty/geo/9430021605163016/' + userBox.lon + ',' + userBox.lat + '?m=' + userBox.lon + ',' + userBox.lat + '%2F19',
                                                        name: name
                                                    }
                                                }, name);
                                            }}>Выбрать</span>
                                            <a href={'https://2gis.kz/almaty/geo/9430021605163016/' + userBox.lon + ',' + userBox.lat + '?m=' + userBox.lon + ',' + userBox.lat + '%2F19'}
                                               target={'_blank'} className={'input-down-list-el-open-map'}>показать на
                                                карте</a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        </>
    )
});


export default Article;
