import './style.scss';
import "react-datepicker/dist/react-datepicker.min.css";

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {deleteDataBase, getModal, getModalRow, getOrder, getRowArenda} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";


import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import modalBrowse from "../OrderScreen/components/Model/browse.json";

import DateNewRange from '../../components/DateNewRange/index';

import moment from "moment"; // theme css file

const MainScreen = (() => {

    const navigate = useNavigate();
    const lang = useSelector(LangSlices);

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);

    const [searchParams, setSearchParams] = useState();

    const [order, setOrder] = useState([]);
    const [page, setPage] = useState(0);

    const [reset, setReset] = useState(false);


    const [timeStart, setTimeStart] = useState(false);
    const [timeEnd, setTimeEnd] = useState(false);


    const [calendar, setCalendar] = useState(false);


    const [openArendaModel, setOpenArendaModel] = useState(false);

    const [openArendaModelRow, setOpenArendaModelRow] = useState([]);
    const [openArendaModelRowCol, setOpenArendaModelRowCol] = useState([]);

    const [catalogId, setCatalogId] = useState(false);

    const [modalName, setModalName] = useState('Product');

    const [selectSklad, setSelectSklad] = useState('');

    // const [searchOb, setSearchOb] = useState({});
    const [searchOb, setSearchOb] = useState(location.search.length > 0 ? JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {filter_sklad_id: 1});


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);
    const [colTableStatus, setColTableStatus] = useState([]);

    const [colSklad, setSklad] = useState([]);

    const [sendTime, setSendTime] = useState(false);


    const [groupTable, setGroupTable] = useState([]);


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setSearchOb(searchObS);
        setReset(!reset);
    }


    function addFilter(e) {
        setPage(0);
        setSearchOb({...searchOb, ...{[e.target.name]: e.target.value, page: 1}});

        if (e.target.name == 'search' && e.keyCode == 13) {
            setReset(!reset);

        } else if (e.target.name != 'search') {
            setReset(!reset);

        }
    }


    async function getOrderSend() {

        const coSklad = (await getModal('Sklad', '?hidden_info=true')).data;

        setSklad(coSklad);
        let select_id = '';
        if (selectSklad == '') {
            setSelectSklad(coSklad[0]['id']);
            select_id = coSklad[0]['id'];
        } else {
            select_id = selectSklad;
        }


        let row = {};
        row['store_arenda'] = 'Мероприятие';
        row['bob'] = 'Менеджер ';
        row['bob8'] = 'Оборудование';
        row['bob1'] = 'К-во';
        row['bob2'] = 'Сумма';
        row['bob3'] = 'Клиент';
        row['bob4'] = 'Компания';
        row['bob5'] = 'Примечание';




        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88", // Your existing class name
                style: { // Add the style object
                    fontSize: "20px", // Set the font size to your desired value
                    // Add other CSS styles as needed
                }
            }
        });



        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }

        delete row['num'];
        delete row['store_sklad'];
        delete row['store'];
        setRowTable(row);


        const colGroup = (await getModal('Group', '')).data;


        let start_time = false;
        if (timeStart) {
            start_time = moment(timeStart).format("YYYY-MM-DD");
        }

        let end_time = false;

        if (timeEnd) {
            end_time = moment(timeEnd).format("YYYY-MM-DD");
        }

        const orderStatus = (await getModal('OrderType', '')).data;
        setColTableStatus(orderStatus);


        const col = (await getModal(modalName, '?' + serialize(searchOb) + "&top=1&browse=1" + (start_time && end_time ? ((start_time ? '&start_time=' + start_time : '') + (end_time ? '&end_time=' + end_time : '')) : ''))).data;

        setColTable(col);
        setGroupTable(colGroup);
        setSendTime(false);

        // setOrder(orderReq);
    }

    useEffect(() => {

        clearTimeout(sendTime);
        setSendTime(
            setTimeout(() => {

                getOrderSend().then();
            }, 500)
        )

    }, [reset]);


    useEffect(() => {
        navigate('/ProductTopDown/browse?' + querystring(searchOb));
    }, [searchOb]);


    function querystring(obj) {

        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    async function setSearch(e) {
        setSelectSklad(e.target.value);
        const col = (await getModal(modalName, '?' + serialize(searchOb) + "&browse=1&filter_sklad_id=" + e.target.value)).data;
        setColTable(col);


    }

    async function arendaOpen(id) {


        let start_time = false;
        if (timeStart) {
            start_time = moment(timeStart).format("YYYY-MM-DD");
        }

        let end_time = false;

        if (timeEnd) {
            end_time = moment(timeEnd).format("YYYY-MM-DD");
        }


        let info_arenda = await getRowArenda(id, '&top=1&' + serialize(searchOb) + (start_time && end_time ? ((start_time ? '&start_time=' + start_time : '') + (end_time ? '&end_time=' + end_time : '')) : ''));
        // const coSklad = (await getModal('Order', '?hidden_info=true')).data;

        let row = (await getModalRow('Order')).data;

        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });
        try {
            let modalBrowse = require('../OrderScreen/components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }


        setOpenArendaModel(true);
        setOpenArendaModelRowCol(row);
        setOpenArendaModelRow(info_arenda);

    }

    return (<Layout>
        <div style={{display :"flex",justifyContent:"flex-end"}}>

            <Btn className={'btn-def-table active text text-s14'} onClick={() =>{

            }} style={{color: "#fff", backgroundColor: "#ffbd3e", width: "20rem", cursor: "pointer"}}
                 label={'Скачать Эксель'} />
        </div>
        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
                Аналитика списания
            </p>


        </div>


        <div className="table-filter table-filter-configs">




            <div className="ci-input-custom-box bonx_control_linger">
                <DateNewRange showHolder={false} finger={true}
                              onChangeVal1={(e) => {
                                  setTimeStart(e);
                                  setReset(!reset);

                              }}
                              onChangeVal2={(e) => {
                                  setTimeEnd(e);
                                  setReset(!reset);

                              }}
                              val1={timeStart}
                              val2={timeEnd}
                />
            </div>




            <CustomSelect showHolder={false}  onChange={(e) => {

                setCatalogId(e.target.value)
                setPage(0);
                setSearchOb({...searchOb, ...{catalog_id: e.target.value, page: 1}});
                setReset(!reset);

            }} options={groupTable.reduce((acc, item) => {
                return {...acc, ...{[item['id']]: item["name"]}};
            }, {})} value={searchOb ? searchOb?.catalog_id : ''} label={'Ответсвенный менеджер'}/>
            <CustomSelect showHolder={false} onChange={(e) => {

                setCatalogId(e.target.value)
                setPage(0);
                setSearchOb({...searchOb, ...{catalog_id: e.target.value, page: 1}});
                setReset(!reset);

            }} options={groupTable.reduce((acc, item) => {
                return {...acc, ...{[item['id']]: item["name"]}};
            }, {})} value={searchOb ? searchOb?.catalog_id : ''} label={'Раздел оборудования'}/>


            <div className="table-btn" style={{marginBottom:0,marginLeft:"3rem"}}>

                <Btn className={'btn-def-table radius-box text text-s14'} onClick={() => {

                }}  label={'Текущий месяц'}/>
                <Btn className={'btn-def-table radius-box text text-s14'} onClick={() => {

                }} label={'Предыдущий месяц'}/>

            </div>


        </div>

        <div className="table-text-control">
            {
                !calendar && (
                    <Table modalName={modalName} th={
                        rowTable
                    } tr={
                        []
                    }
                           loading={sendTime}
                           openArenda={arendaOpen}
                           option={(id) => {
                               return (
                                   <div className={'table-options text text-s18'}>
                                   </div>
                               )
                           }}
                    />
                )
            }
        </div>

        <Modal
            isOpen={openArendaModel}
            contentLabel="Example Modal"
            className="modelOpenOrder-row"
            onRequestClose={() => {
                setOpenArendaModel(false);
            }}
        >
            <div className={'complect-list'} style={{height: "80vh"}}>

                <div className="complect-list-body">

                    <Table modalName={modalName} th={
                        openArendaModelRowCol
                    } tr={
                        openArendaModelRow
                    }
                           option={(id) => {
                               return (
                                   <div className={'table-options'}>
                                       <BtnIcon title={'download'} onClick={() => {
                                           location.href = REACT_APP_ASSETS_PATH + '/api/excel/order/' + id;
                                       }} img={require('../../assets/images/document-download.png')}/>
                                       <BtnIcon title={'edit'} onClick={() => {
                                           navigate('/Order/edit/' + id);
                                       }} img={require('../../assets/images/edit.png')}/>
                                   </div>
                               )
                           }}
                    />


                </div>
            </div>
        </Modal>


    </Layout>);
});
export default MainScreen;