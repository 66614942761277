import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

const {REACT_APP_ASSETS_PATH} = process.env;
import Table from "../../components/Table";
import {
    deleteDataBase,
    getModal,
    getModalRow,
    getModalRowEdit,
    getOrder,
    getSingle,
    updateDB
} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";
import BtnCustom from "../../components/BtnCustom";
import modalBrowse from "../ProductScreen/components/Model/browse.json";
import CustomSelect from "../../components/CustomSelect";
import StoreSettingProduct from "../../components/StoreSettingProduct";

const MainScreen = (() => {


    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);
    const [timeCode, setTimerCode] = useState(false);

    const [productListCh, setProductListCh] = useState({});

    const [modalName, setModalName] = useState('Sklad');
    const params = useParams();

    const [roles, setRoles] = useState([]);


    const [rowTable, setRowTable] = useState({});

    const [OpenRa, setOpenRa] = useState(false);


    const [colTableProduct, setColTableProduct] = useState([]);


    const [productListReturn, setProductListReturn] = useState([]);

    const [orderSingle, setOrderSingle] = useState({});

    const [productList, setProductList] = useState({});


    const [productListInfo, setProductListInfo] = useState([]);


    const [settingOpen, setSettingOpen] = useState(false);
    const [catalogsOpen, setCatalogsOpen] = useState(false);


    const [showError, setShowError] = useState(false);

    const [resetResult, setResetResult] = useState(false);


    const [catalogSelect, setCatalogSelect] = useState('');
    const [search, setSearch] = useState('');


    useEffect(() => {

        if (productListInfo) {
            setProductListReturn([]);
            if (window?.resetTomer) {
                clearTimeout(window.resetTomer);
            }
            window.resetTomer = setTimeout(() => {
                setProductListReturn([...productListInfo].filter((items) => {
                    if (items?.name) {
                        let controle = true;
                        let error = true;

                        if (showError) {


                            let countSave = items.store;
                            countSave = parseInt(countSave);
                            if (countSave > 0) {
                            } else {
                                countSave = 0;
                            }

                            let priceSave = items.price;
                            priceSave = parseInt(priceSave);
                            if (priceSave > 0) {
                            } else {
                                priceSave = 0;
                            }
                            if (priceSave == 0 || countSave == 0) {
                                error = true;
                            } else {
                                error = false;
                            }

                        }

                        if (catalogSelect) {
                            controle = items?.catalog_id.includes(catalogSelect);
                        }
                        return items?.name.toLowerCase().includes(search.toLowerCase()) && controle && error;
                    }
                    return false;
                }));
            }, 1000);
        }
    }, [resetResult]);

    async function getOrderSend() {

        function isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        let row = ((await getModalRowEdit(modalName, params.id)).data);
        let orderSingle = (await getSingle(modalName, params.id, "?filter_sklad_id=" + params.id)).data;

        let ProductList = (await getModal('Product', '?detaishidden=true&pagitanion=1&browse=1&filter_sklad_id='+params.id)).data;




        setOrderSingle(orderSingle);
        setProductListReturn(ProductList);
        setProductListInfo(ProductList);

        if (isJson(orderSingle?.group_ch)) {
            let saveScr = JSON.parse(orderSingle.group_ch);
            if (saveScr == null) {
                saveScr = {};
            }
            setProductListCh(saveScr);
        }

        let rowRoles = (await getModal('Product', '?detaishidden=true&pagitanion=1&sklad_no_hidden=1&browse=1&filter_sklad_id='+params.id)).data;


        rowRoles = rowRoles.reduce((ass, item) => {
            return {...ass, ...{[item['catalog_id']]: [...(item['catalog_id'] in ass ? ass[item['catalog_id']] : []), item]}}
        }, {});
        setRoles(Object.entries(rowRoles));

        if (orderSingle) {
            row = row.map((pr) => {
                if (typeof orderSingle[pr.name] != "undefined" && orderSingle[pr.name] != null) {
                    return {...pr, ...{value: orderSingle[pr.name]}};
                }
                return {...pr, ...{value: ""}};
            })
        }
        setInputs(row);


        let rowSave = (await getModalRow('Product')).data;

        delete rowSave["store"];
        delete rowSave["store_arenda"];
        delete rowSave["price_arenda"];
        rowSave["option"]="Итоговая сумма";

        Object.keys(rowSave).forEach((key) => {
            rowSave[key] = {
                name: rowSave[key],
                className: "ci-table-th-88"
            }
        });


        try {
            let modalBrowse = require('../ProductScreen/components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof rowSave[key] != "undefined") {
                    rowSave[key] = {...rowSave[key], ...modalBrowse[key]};
                } else {
                    rowSave[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }

        setRowTable(rowSave);


    }


    useEffect(() => {
        getOrderSend().then();
    }, [params.id]);

    return (<Layout>

        <div className="edit-model-head">
            <p className={'text text-bold text-s26'}>
                Добавить склад
            </p>

            <Btn className={'btn-def-table text text-s14'} onClick={async () => {

                const updateDataOrder = (await updateDB({
                    'save': {
                        ...inputs.reduce((ass, val) => {
                            return {...ass, ...{[val.name]: val.value}};
                        }, {}),
                        ...{product_list: JSON.stringify(productList), group_ch: JSON.stringify(productListCh)}
                    }, 'id': params.id, 'model_name': 'Sklad',
                })).data[0];

                let ProductList = (await getModalRow('Product', '?detaishidden=true&pagitanion=1&browse=1&filter_sklad_id='+params.id)).data;



                if (params.id == 0) {
                    navigate('/Sklad/edit/' + updateDataOrder.id);
                }

                setProductList(ProductList);
                setOrderSingle(updateDataOrder);

                NotificationManager.success('все хорошо, данные получили', 'Инвентаризация обновлена');

            }} label={'Сохранить'}/>
        </div>

        <InputsEdit inputs={inputs} onChange={(e, name) => {
            setInputs(inputs.map((inp) => {
                if (inp["name"] == name) {
                    return {...inp, ...{value: e.target.value}};
                }
                return inp;
            }));

        }}/>


        <Btn className={'btn-def-table text text-s14 ' + (settingOpen && 'active')} onClick={async () => {
            setCatalogsOpen(false);
            setSettingOpen(true);
        }} label={'Настройка разделов'}/>

        <Btn className={'btn-def-table text text-s14 ' + (catalogsOpen && 'active')} onClick={async () => {
            setSettingOpen(false);
            setCatalogsOpen(true);

        }} label={'Каталог'}/>


        {settingOpen && (
            <div className="box-list-eid">
                {
                    roles ? roles.map(([key, items]) => {
                        return (
                            <StoreSettingProduct
                                onChange={(itemsCh) => {
                                    let productChListSave = {...productListCh};

                                    itemsCh.forEach((name) => {
                                        if (typeof productChListSave[name.id] == "undefined") {
                                            productChListSave[name.id] = 1;
                                        } else {
                                            if (productChListSave[name.id] == 0) {
                                                productChListSave[name.id] = 1;
                                            } else {
                                                delete productChListSave[name.id];
                                            }
                                        }
                                    })

                                    setProductListCh(productChListSave);

                                }} checked={productListCh}
                                label={key} items={items}/>
                        )
                    }) : ''
                }
            </div>
        )}


        {catalogsOpen && (
            <div>
                <br/>
                <p className={'text text-s24'}>
                    Суммарная цена: {' '}
                    {
                        productListInfo ? (productListInfo.filter((items) => {
                            if (items?.name) {
                                let controle = true;
                                let error = true;

                                if (showError) {


                                    let countSave = items.store;
                                    countSave = parseInt(countSave);
                                    if (countSave > 0) {
                                    } else {
                                        countSave = 0;
                                    }

                                    let priceSave = items.price;
                                    priceSave = parseInt(priceSave);
                                    if (priceSave > 0) {
                                    } else {
                                        priceSave = 0;
                                    }
                                    if (priceSave == 0 || countSave == 0) {
                                        error = true;
                                    } else {
                                        error = false;
                                    }

                                }

                                if (catalogSelect) {
                                    controle = items?.catalog_id.includes(catalogSelect);
                                }
                                return items?.name.toLowerCase().includes(search.toLowerCase()) && controle && error;
                            }
                            return false;
                        })).reduce((ass, item) => {
                            let countSave = item.store_sklad;
                            countSave = parseInt(countSave);
                            if (countSave > 0) {
                            } else {
                                countSave = 0;
                            }

                            let priceSave = item.price;
                            priceSave = parseInt(priceSave);
                            if (priceSave > 0) {
                            } else {
                                priceSave = 0;
                            }
                            return ass + (priceSave * countSave)
                        }, 0).toLocaleString() : ''
                    } тнг

                    <a
                        style={{color: "#000", display: "flex"}}
                        href={REACT_APP_ASSETS_PATH+'/api/store/' + orderSingle?.id}>(скачать excel)</a>
                </p>


                <div style={{marginRight: "auto", marginTop: "4rem", display: "flex"}}>
                    <div style={{width: "30%"}}>
                        <CustomInput value={search} onChange={(e) => {
                            setSearch(e.target.value);

                            setResetResult(!resetResult);

                        }} name={'search'}
                                     img={require('../../assets/images/search-normal.png')}
                                     label={'Поиск'}/>
                    </div>
                    <div style={{width: "25%", marginLeft: "3%"}}>
                        <CustomSelect onChange={(e) => {
                            setCatalogSelect(e.target.value);
                            setResetResult(!resetResult);
                        }}
                                      options={productListInfo ? productListInfo.reduce((acc, item) => {
                                          return {...acc, ...{[item['catalog_id']]: item["catalog_id"]}};
                                      }, {}) : {}} value={catalogSelect} label={'Все разделы'}/>
                    </div>
                    <label style={{width: "35%", marginLeft: "3%", display: "flex", alignItems: "center"}}>
                        <input onChange={(e) => {
                            let returnS = !showError;
                            setShowError(returnS)
                            setResetResult(!resetResult);

                        }} type="checkbox" checked={showError}/>
                        <span className={'text text-s12'} style={{marginLeft: "1rem"}}>
                        показать {' '}
                            {
                                productListInfo ? productListInfo.reduce((ass, item) => {
                                    let countSave = parseInt(item.store);
                                    if (countSave > 0) {
                                    } else {
                                        countSave = 0;
                                    }


                                    let priceSave = item.price;
                                    priceSave = parseInt(priceSave);
                                    if (priceSave > 0) {
                                    } else {
                                        priceSave = 0;
                                    }
                                    if (priceSave == 0 || countSave == 0) {
                                        return ass + (1)
                                    }

                                    return ass + (0);
                                }, 0).toLocaleString() : ''
                            }
                            {' '} позиций у кого нет цены или количества
                            </span>
                    </label>
                </div>


                <div className="box-list-eid" style={{width: "100%", marginTop: "3rem"}}>


                    <Table number={true} th={
                        rowTable
                    } tr={
                        productListReturn
                    }
                           option={(id, row, index) => {

                               let about=((parseInt(row['price']) > 0 ? parseInt(row['price']) : 0) * (parseInt(row['store_sklad']) > 0 ? parseInt(row['store_sklad']) : 0)).toLocaleString();

                               return (
                                   <div className={'table-options'} style={{display:"flex",alignItems:"center"}}>
                                       <p className={"text text-s18"} style={{marginRight:14}}>
                                           {about}
                                       </p>

                                       <BtnIcon onClick={() => {
                                           navigate('/Product/edit/' + id);
                                       }} img={require('../../assets/images/edit.png')}/>
                                   </div>
                               )
                           }}
                    />
                </div>
            </div>
        )}

    </Layout>);
});
export default MainScreen;
