import React, {useState} from 'react';

import './style.scss';
import Loader from "react-js-loader";
import {CarouselProvider, Slide, Slider} from "pure-react-carousel";
import Modal from "react-modal";
import BtnIcon from "../BtnIcon";
import {updateDB} from "../../services";

const {REACT_APP_ASSETS_PATH} = process.env;


const Article = (({
                      className,
                      img,
                      item,
                      onClick,
                      type = 'button',
                      name = '',
                      disabled = false,
                      label = '',
                      value,
                      onChange,
                      load = false
                  }) => {

    const [string2, setString2] = useState('');
    let imgPost = false;

    const [modalShow, setModalShow] = useState(false);

    const [isComplit, setIsComplit] = useState(item?.is_complit ? item?.is_complit : false);
    const [isPost, setIsPost] = useState(item?.is_post ? item?.is_post : false);
    const [postImg, setPostImg] = useState(item?.post_img ? item?.post_img : '');

    const showModal = () => {
        setModalShow(false);
        setModalEditShow(false);
    }

    let string = item?.images;
    return (
        <>
            <div className={'product-sm'}>

                <CarouselProvider
                    naturalSlideWidth={200}
                    naturalSlideHeight={200}
                    totalSlides={isPost ? 2 : 1}
                >
                    <Slider>
                        <Slide index={0}>
                            <div onClick={() => {
                                setString2(string);
                                setModalShow(true);
                            }} className="product-sm-box">
                                <img
                                    src={(string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + (string ? string : 'https://rus-traktor.ru/upload/iblock/f74/f74f39dbc9b60954c926d72401adf1cc.jpg')}
                                    className="product-sm-img"/>
                            </div>
                        </Slide>

                        {
                            isPost && (
                                <Slide index={1}>
                                    <div onClick={() => {
                                        setString(postImg);
                                        setModalShow(true);
                                    }} className="product-sm-box">
                                        <img
                                            src={REACT_APP_ASSETS_PATH + postImg}
                                            className="product-sm-img"/>
                                    </div>
                                </Slide>
                            )
                        }
                    </Slider>
                </CarouselProvider>
                <div className="product-sm-detalis">
                    <div className="product-sm-name text text-s18">
                        {item['name']}
                    </div>
                </div>


                <div className={'row-option-flex'}>
                    <BtnIcon onClick={async () => {
                        onChange({
                            is_complit: !isComplit
                        });
                        setIsComplit(!isComplit);

                    }}
                             className={'icon-box-sas ' + (isComplit && 'active')}
                             value={'Собран'}/>
                </div>
                <div className={'row-option-flex'}>


                    <BtnIcon onClick={async (e) => {



                        onChange({
                            is_post: isPost ? '' : true,
                            post_img: isPost ? '' : e
                        });

                        setPostImg(isPost ? '' : e);
                        setIsPost(!isPost);

                    }}
                             file={true}
                             file_controls={isPost}
                             className={'icon-box-sas ' + (isPost && 'active')}
                             value={'Выдан'}/>
                </div>

                <Modal
                    isOpen={modalShow}
                    onRequestClose={showModal}
                    contentLabel="Example Modal"
                    className="model-add-box  model-add-box-center"
                >
                    <div className="box_conteol box_conteol-img">
                        <img onClick={() => {
                            showModal();
                        }} className={'mole_img'}
                             src={(string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + string2}/>
                        <br/>
                        <div className="box_conteol_nav">
                            <p onClick={() => {
                                showModal();
                            }} className={'text text-s20'} style={{color: "#fff", marginTop: "2rem"}}>
                                закрыть
                            </p>
                        </div>
                    </div>
                </Modal>

            </div>
        </>
    )
});


export default Article;
