import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {deleteDataBase, getModal, getModalRow, getOrder} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import {useNavigate, useParams} from "react-router-dom";
import Modal from "react-modal";
import DateNewRange from "../../components/DateNewRange";
import moment from "moment";

const {REACT_APP_ASSETS_PATH} = process.env;
const MainScreen = (() => {

    const navigate = useNavigate();

    const lang = useSelector(LangSlices);

    const [modal, setModal] = useState(false);
    const [sortProduct, setSortProduct] = useState(false);
    const [openRow, setOpenRow] = useState({});


    const [timeStart, setTimeStart] = useState(false);
    const [timeEnd, setTimeEnd] = useState(false);

    const [reset, setReset] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const [modalName, setModalName] = useState('ProductDownManage');

    const [typePage, setTypePage] = useState(0);

    const [searchOb, setSearchOb] = useState({});


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setSearchOb(searchObS);
        setReset(!reset);
    }

    function addFilter(e) {
        setSearchOb({...searchOb, ...{search: e.target.value}});
        setReset(!reset);
    }


    async function getOrderSend() {

        let row = (await getModalRow(modalName)).data;
        row['option'] = 'На Сумму';
        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });

        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }
        setRowTable(row);
        const col = (await getModal(modalName, '?browse=1&' + serialize(searchOb))).data;
        setColTable(col);

        // setOrder(orderReq);
    }

    useEffect(() => {
        getOrderSend().then();
    }, [reset]);


    let rowTime = colTable.filter((item) => {


        let moTime = moment(item.created_at, 'DD.MM.YYYY').unix();
        let timeEndControl = true;

        if (timeStart && timeEnd) {
            let timeStartFix = moment(timeStart).unix();
            let timeEndFix = moment(timeEnd).unix();


            timeEndControl = timeEndFix >= moTime && timeStartFix <= moTime;


        }

        return timeEndControl;
    })

    let productAll = ((rowTime.reduce((acc, item) => {
        return [...acc, ...item.product_list_product];
    }, [])));


// Create a Map to store the sums
    let idSums = new Map();

// Process the data
    productAll.forEach(item => {
        // Use a unique key based on the properties you want to consider
        let key = JSON.stringify({
            id: item.id,
            name: item.name,
            num: item.num,
            art_id: item.art_id,
            catalog_id: item.catalog_id,
            price: item.price,
            photo: item.photo,
            // ... (other properties)
        });

        // Update the sum in the Map
        idSums.set(key, (idSums.get(key) || 0) + item.count);
    });

// Convert the Map back to an array of objects
    let filteredData = Array.from(idSums, ([key, count]) => {
        let properties = JSON.parse(key);
        return {...properties, count};
    });




    return (<Layout>

        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
                Списание группами
            </p>

            <div className="table-btn">

                <Btn className={'btn-def-table text text-s14'} onClick={() => {
                    navigate('/ProductDownManage/edit/0');
                }} label={'Добавить'}/>
            </div>
        </div>


        <>

            <div className="table-filter">
                <Btn showHolder={false} className={'btn-def-table text text-s14 ' + (!sortProduct && 'active')}
                     onClick={() => {
                         setSortProduct(false);
                     }} label={'По списанию'}/>
                <Btn showHolder={false} className={'btn-def-table text text-s14 ' + (sortProduct && 'active')}
                     onClick={() => {
                         setSortProduct(true);
                     }} label={'По товарам'}/>

            </div>

            <div className="table-filter" style={{alignItems: "flex-start"}}>
                <CustomInput showHolder={false} onChange={addFilter} value={searchOb?.search}
                             label={'Поиск'}/>

                <p style={{padding: '0 1rem'}}>

                </p>
                <DateNewRange showHolder={false} finger={true}
                              onChangeVal1={(e) => {
                                  setTimeStart(e);
                              }}
                              onChangeVal2={(e) => {
                                  setTimeEnd(e);
                              }}
                              val1={timeStart}
                              val2={timeEnd}
                />
            </div>


            <p className={'text text-s20'}>
                <b className={''}>
                    в общем: {
                    (
                        rowTime.reduce((ass, val) => {

                            return ass + val.product_list_array.reduce((ass2, val2) => {
                                let countOut = (parseInt(val2.count) > 0 ? parseInt(val2.count) : 0);
                                let priceOut = (parseInt(val2.price) > 0 ? parseInt(val2.price) : 0);
                                return ass2 + (countOut * priceOut);
                            }, 0)

                        }, 0).toLocaleString()
                    )
                } тнг
                </b>
            </p>

            {
                sortProduct && (
                    <Table th={
                        {
                            'photo': {
                                'className': "ci-table-th-88",
                                'name': "Фото"
                            },
                            'name': {
                                'className': "ci-table-th-140",
                                'name': "Название"
                            },
                            'num': {
                                'className': "ci-table-th-140",
                                'name': "Артикул"
                            },
                            'price': {
                                'className': "ci-table-th-140",
                                'name': "Закуп цена"
                            },
                            'count': {
                                'className': "ci-table-th-88",
                                'name': "Списано"
                            },
                            'option': {
                                'className': "ci-table-th-88",
                                'name': "Списано на сумму"
                            }
                        }

                    } tr={
                        filteredData
                    }
                           option={(id, row) => {

                               let countOut = (parseInt(row['count']) > 0 ? parseInt(row['count']) : 0);
                               let priceOut = (parseInt(row['price']) > 0 ? parseInt(row['price']) : 0);

                               return (
                                   <div className={'text text-s14'} style={{display: "flex", alignItems: "center"}}>
                                       {
                                           ( countOut * priceOut).toLocaleString()
                                       }
                                   </div>
                               )
                           }}
                    />
                )
            }

            {
                !sortProduct && (
                    <Table th={
                        rowTable
                    } tr={
                        rowTime
                    }
                           option={(id, row) => {


                               return (
                                   <div style={{display: "flex", alignItems: "center"}}>
                                       <div style={{marginRight: "auto"}} className={'text text-s18'}>
                                           {
                                               row.product_list_array.reduce((ass, val) => {
                                                   let countOut = (parseInt(val.count) > 0 ? parseInt(val.count) : 0);
                                                   let priceOut = (parseInt(val.price) > 0 ? parseInt(val.price) : 0);
                                                   return ass + (countOut * priceOut);
                                               }, 0).toLocaleString()
                                           } тнг
                                       </div>
                                       <div className={'table-options'}>

                                           <BtnIcon onClick={() => {
                                               setOpenRow(row);
                                               setModal(true);
                                           }} value={'список'}/>
                                           <BtnIcon title={'edit'} onClick={() => {
                                               navigate('/ProductDownManage/edit/' + id);
                                           }} img={require('../../assets/images/edit.png')}/>
                                           <BtnIcon onClick={async () => {
                                               if (window.confirm("Точно удалить?")) {
                                                   await deleteDataBase({
                                                       id: id
                                                   }, modalName)
                                                   setReset(!reset);
                                               }
                                           }} title={'delete'} img={require('../../assets/images/trash.png')}/>
                                       </div>
                                   </div>
                               )
                           }}
                    />
                )
            }

        </>


        <Modal
            isOpen={modal}
            onRequestClose={() => {
                setModal(false);
            }}
            contentLabel="Example Modal"
            className="model-add-box-comp-list"
        >
            <div className={'complect-list'}>
                <div className="complect-list-head">
                    <span className={'text text-bold text-s18'}>комплект: {openRow?.name}</span>
                </div>
                <div className="complect-list-body">

                    <p className={'text text-s18'}>
                        Цена комплекта <b>{
                        typeof openRow?.product_list_product != "undefined" ? openRow?.product_list_product.reduce((ass, li) => {

                            return ass + (parseInt(li['price']) > 0 ? parseInt(li['price']) : 0) * (parseInt(li['count']) > 0 ? parseInt(li['count']) : 0)
                        }, 0).toLocaleString() : '0'
                    } {' '} тнг</b>
                        <br/>
                        у <b>{
                        typeof openRow?.product_list_product != "undefined" ? openRow?.product_list_product.reduce((ass, li) => {

                            if ((parseInt(li['price']) > 0 ? parseInt(li['price']) : 0) == 0 || (parseInt(li['count']) > 0 ? parseInt(li['count']) : 0) == 0) {
                                return ass + 1
                            }
                            return ass + 0;
                        }, 0) : '0'
                    }</b>
                        {' '} позиций нет цены или количества
                    </p>
                    {
                        typeof openRow?.product_list_product != "undefined" ? openRow?.product_list_product.filter((icontr) => {
                            let asd = parseInt(icontr['count']);
                            return asd > 0;
                        }).map((li) => {
                            const string = li['photo'];
                            let type = 'text';


                            return (
                                <div className="item-complit" style={{display: "flex"}}>

                                    {
                                        string ? (
                                            <img onClick={() => {

                                            }} style={{
                                                marginRight: "1.5rem",
                                                position: "relative",
                                                zIndex: 100000,
                                                pointerEvents: 'all',
                                                objectFit: 'contain',
                                                backgroundColor: '#ececec'
                                            }} width={75} height={75}
                                                 src={(string ? (string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') : '') + string}/>
                                        ) : (
                                            <img style={{
                                                marginRight: "1.5rem",
                                                position: "relative",
                                                zIndex: 100000,
                                                pointerEvents: 'all',
                                                objectFit: 'contain',
                                                backgroundColor: '#ececec'
                                            }} width={75} height={75}
                                            />
                                        )
                                    }
                                    <span className={'text text-s16'}>Название: {li['name']}
                                        <br/> кол-во: {li['count']}
                                        <br/> цена: {parseInt(li['price']) > 0 ? parseInt(li['price']).toLocaleString() : '0'} тнг
                                        <br/> цена за все: {((parseInt(li['price']) > 0 ? parseInt(li['price']) : 0) * (parseInt(li['count']) > 0 ? parseInt(li['count']) : 0)).toLocaleString()} тнг</span>
                                </div>
                            )
                        }) : ''
                    }


                </div>

            </div>
        </Modal>


    </Layout>);
});
export default MainScreen;
