import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useRef, useState} from "react";

import Table from "../../components/Table";
import {
    copyOrder,
    deleteDataBase,
    getCatalogMenu,
    getCatalogStorage,
    getModal,
    getModalRow,
    getModalRowEdit,
    getOrderCalculation,
    getOrderMenu,
    getOrderMenuPos,
    getOrderProduct,
    getSingle,
    saveJson,
    updateDB,
    updateDBOrderMenu,
    updateDBOrderPersonal,
    updateDBOrderProduct
} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";
import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";
import {useSelector} from "react-redux";
import State from "../../redux/slices/State";
import CustomFile from "../../components/CustomFile";
import CustomSelect from "../../components/CustomSelect";

import SelectSearch from 'react-select-search';

import 'react-select-search/style.css';
import moment from "moment";
import ExtraExpen from "../../components/ExtraExpen";
import axios from "axios";
import StateActions from "../../redux/actions/StateActions";
import {store} from "../../redux";


const {REACT_APP_ASSETS_PATH, REACT_APP_API_PATH} = process.env;

const MainScreen = (() => {

        function isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        const timerId = useRef(null);
        const state_in = useSelector(State);
        const navigate = useNavigate();

        const [colSklad, setSklad] = useState([]);

        const [selectSklad, setSelectSklad] = useState('');

        const [searchPage, setSearchPage] = useState('');

        const [messageItem, sedMessageItem] = useState(false);

        const [sendTelegramInfo, setSendTelegramInfo] = useState(false);

        const [selectHouseProduct, setSelectHouseProduct] = useState(false);


        const [inputs, setInputs] = useState([]);

        const [dopMenu, setDopMenu] = useState([]);

        const [openEditControls, setOpenEditControls] = useState([]);


        let [dopMenuList, setDopMenuList] = useState({});
        const [decorList, setDecorList] = useState({});

        const [dopMenuPlusList, setDopMenuPlusList] = useState({});
        const [decorPlusList, setDecorPlusList] = useState({});

        const [productListCh, setProductListCh] = useState({});

        const [customFile, setCustomFile] = useState([]);

        const [openModalControl, setOpenModalControl] = useState(false);

        const [openMenuModalControl, setOpenMenuModalControl] = useState(false);

        const [updateIdMenu, setUpdateIdMenu] = useState(false);
        const [updateIdMenuValue, setUpdateIdMenuValue] = useState(false);

        const [servingId, setServingId] = useState(false);

        const [menuId, setMenuId] = useState(false);

        const [mapsList, setMapsList] = useState([]);

        const [clickOrder, setClickOrder] = useState({});

        const [modalNode, setModalNode] = useState(false);

        const [modalPdf, setModalPdf] = useState(false);

        const [catalogSelectItem, setCatalogSelectItem] = useState({});
        const [catalogSelect, setCatalogSelect] = useState(false);

        const [pageOpen, setPageOpen] = useState(false);
        const [pageOpenProductHoz, setPageOpenProductHoz] = useState(false);
        const [pageOpen2, setPageOpen2] = useState(false);
        const [pageOpen3, setPageOpen3] = useState(false);

        const [pageOpen4, setPageOpen4] = useState(false);
        const [pageOpen5, setPageOpen5] = useState(false);
        const [pageOpen6, setPageOpen6] = useState(false);
        const [pageOpen8, setPageOpen8] = useState(false);
        const [pageOpen10, setPageOpen10] = useState(false);

        const [createModal, setCreateModal] = useState(false);

        const [createModalMenu, setCreateModalMenu] = useState(false);
        const [createModalMenuInfo, setCreateModalMenuInfo] = useState({});


        const [openEditId, setOpenEditId] = useState(false);

        const [modalShow, setModalShow] = useState(false);

        const [modelOpenId, setModelOpenId] = useState(false);

        const [countProductOpen, setCountProductOpen] = useState(0);

        const [modal, setModal] = useState(false);
        const [modalPackMenu, setModalPackMenu] = useState(false);
        const [modalMenu, setModalMenu] = useState(false);
        const [modalMenuPack, setModalMenuPack] = useState(false);


        const [modalMenuComplect, setModalMenuComplect] = useState(false);

        const [openEditMenu, setOpenEditMenu] = useState(-10);

        const [modalPersonal, setModalPersonal] = useState(false);
        const [openRow, setOpenRow] = useState({});

        const [openRowMenu, setOpenRowMenu] = useState([]);

        const [sendTime, setSendTime] = useState(false);

        const [storeNameSave, setStoreNameSave] = useState('');
        const [typeSelect, setTypeSelect] = useState('');

        const [allInfoProduct, setAllInfoProduct] = useState({});


        const [modalName, setModalName] = useState('Order');

        const [orderProduct, setOrderProduct] = useState({});

        const [orderProductServ, setOrderProductServ] = useState({});

        const [orderMenuServ, setOrderMenuServ] = useState({});

        const [thSelectInfo, setThSelectInfo] = useState([]);

        const [orderMenu, setOrderMenu] = useState({'': {}});
        const [orderPersonal, setOrderPersonal] = useState({'': {}});
        const params = useParams();

        const [catalogId, setCatalogId] = useState(false);
        const [catalogIdMenu, setCatalogIdMenu] = useState(false);

        const [rowTable, setRowTable] = useState({});

        const [rowPersonal, setRowPersonal] = useState({});

        const [rowTableComplit, setRowTableComplit] = useState({});

        const [page, setPage] = useState(0);

        const [pageModel, setPageModel] = useState(0);


        const [openProduct, setOpenProduct] = useState(false);

        const [colTable, setColTable] = useState([]);

        const [rowsDb, setRowsDb] = useState([['Списание', 'down'], ['Ремонт', 'repit'], ['Утеря', 'broken'], ['Демонтаж', 'return']]);


        const [productIn, setProductIn] = useState({});
        const [errorList, setErrorList] = useState({});

        const [sendNode, setSendNode] = useState(false);


        const [productId, setProductId] = useState(false);
        const [messageNode, setMessageNode] = useState("");

        const [modalProduct, setModalProduct] = useState(false);


        const [modalProductServ, setModalProductServ] = useState(false);

        const [modalNote, setModalNote] = useState(false);

        const [reset, setReset] = useState(false);

        const [nameComplect, setNameComplect] = useState('');

        const [colTableProduct, setColTableProduct] = useState([]);
        const [listPersonal, setListPersonal] = useState([]);

        const [productMenu, setProductMenu] = useState([]);
        const [productMenuAll, setProductMenuAll] = useState([]);
        const [productMenuRow, setProductMenuRow] = useState({});


        const [menuSelectEditCatalog, setMenuSelectEditCatalog] = useState('');
        const [menuSelectEditMenu, setMenuSelectEditMenu] = useState('');


        const [colTableProductComplit, setColTableProductComplit] = useState([]);
        const [colTableProductComplitMenu, setColTableProductComplitMenu] = useState([]);

        const [catalogMenuList, setCatalogMenuList] = useState([]);


        const [orderSingle, setOrderSingle] = useState({});

        const [productList, setProductList] = useState({});

        const [productListPre, setProductListPre] = useState({});

        const [productListPreCommint, setProductListPreCommint] = useState({});

        const [productListPreServ, setProductListPreServ] = useState({});

        const [menuListPreServ, setMenuListPreServ] = useState({});

        const [menuListPre, setMenuListPre] = useState({'': {}});
        const [personalListPre, setPersonalListPre] = useState({'': {}});


        const [search, setSearch] = useState("");
        const [stringImg, setStringImg] = useState("");

        const [groupTable, setGroupTable] = useState([]);
        const [groupTableMenu, setGroupTableMenu] = useState([]);

        const [firstFile, setFirstFile] = useState(true);

        const [completeGet, setCompleteGet] = useState(false);


        const [updateReset, setUpdateReset] = useState(false);
        const [updateReset2, setUpdateReset2] = useState(false);


        const [serving, setServing] = useState([]);

        const [menuType, setMenuType] = useState([]);


        const [groupVal, setGroupVal] = useState([]);

        const [adresGroup, setAdresGroup] = useState([]);


        const [allPriceProduct, setAllPriceProduct] = useState(0);
        const [allPriceProductNoDiscont, setAllPriceProductNoDiscont] = useState(0);
        const [allPriceMenuFood, setAllPriceMenuFood] = useState(0);

        const [allGramMenuFood, setAllGramMenuFood] = useState(0);
        const [allGramMenuDrink, setAllGramMenuDrink] = useState(0);

        const [allPriceMenuDrinks, setAllPriceMenuDrinks] = useState(0);
        const [allPriceMenu, setAllPriceMenu] = useState(0);
        const [allPricePersonal, setAllPricePersonal] = useState(0);


        const [loading, setLoading] = useState(false);

        const [loadingCreate, setLoadingCreate] = useState(false);


        async function updateInfoInput(e) {

            setOrderSingle({...orderSingle, ...{[e.target.name]: e.target.value}});
            await updateDB({
                'save': {
                    ...{[e.target.name]: e.target.value}
                }, 'id': params.id, 'model_name': 'Order',
            }, false);
            setOrderSingle({...orderSingle, ...{[e.target.name]: e.target.value}});
            calcultationSet();
        }


        async function getProductSenPre(pg = '', noSearch = false) {
            let product_menu_all = (await getModal('ProductMenu', '?browse=1&catalog_name=' + catalogIdMenu + '&search=' + (noSearch ? '' : search))).data;
            setProductMenu(product_menu_all);
            setSendTime(false);
        }


        async function getProductAllLink(page, mon_, demon, houseBox) {
            let product_all = (await getModal('Product', '?page=' + (page + 1) + '&browse=1&daymon=' + mon_ + '&daydemon=' + demon + '&search=' + (search) + '&catalog_id=' + (houseBox ? '78' : (!catalogId ? '' : catalogId)) + '&filter_sklad_id=' + selectSklad)).data;
            setColTableProduct(product_all);
            setSendTime(false);
        }

        async function getPesonalAllLink(page, mon_, demon) {

            let personal_all = (await getModal('Personal', '?browse=1' + '&search=' + (search))).data;
            setListPersonal(personal_all);
        }

        async function getMenuAllLink(page, mon_, demon) {
            let product_menu_all = (await getModal('ProductMenu', '?page=' + (page + 1) + '&browse=1&catalog_name=' + catalogIdMenu + '&search=' + (search))).data;
            setProductMenu(product_menu_all);
        }

        async function getPackAllLink(page, mon_, demon) {
            setColTableProductComplit((await getModal('Pack', '')).data);

        }

        async function getProductModal(page, houseProduct) {
            if (inputs[2]?.value && inputs[3]?.value) {
                let mon_ = moment(inputs[2].value).format("YYYY-MM-DD HH:mm:ss");
                let demon = moment(inputs[3].value).format("YYYY-MM-DD HH:mm:ss");

                getProductAllLink(page, mon_, demon, houseProduct);
                getPackAllLink(page, mon_, demon);
                getPesonalAllLink(page, mon_, demon);
                getMenuAllLink(page, mon_, demon);
            }
        }


        async function getPreSklad() {
            const coSklad = (await getModal('Sklad', '?hidden_info=true&v=2')).data;
            setSklad(coSklad);
            if (firstFile) {
                getStorage(coSklad[0]['id']);
                setSelectSklad(coSklad[0]['id']);
            }
        }

        
        async function getPreMenu() {

            let orderMenuSave = (await getOrderMenu(params.id)).data;
            if (orderMenuSave.length == 0) {
                orderMenuSave = {'': {}};
            }
            setOrderMenu(orderMenuSave);
        }


        async function getStorage(id) {

            let orderMenuSave = (await getCatalogStorage(id));
            setGroupVal(orderMenuSave);

        }


        async function getPreServant() {

            let servingBox = (await getModal('OrderServing', '?order_id=' + params.id)).data;
            setServing(servingBox);


            let servingBoxMenu = (await getModal('OrderMenuType', '?order_id=' + params.id)).data;
            setMenuType(servingBoxMenu);

        }

        async function calcultationSet() {

            let allInfo = (await getOrderCalculation(params.id)).data;
            setAllInfoProduct(allInfo);

        }

        async function getOrderSend() {


            setCompleteGet(false);

            let row = (await getModalRowEdit(modalName, params.id)).data;
            let orderSingle = (await getSingle(modalName, params.id + '?filter_sklad_id=1&v=2')).data;


            if (orderSingle?.file_update) {
                if (firstFile) {
                    if (isJson(orderSingle?.file_update)) {
                        setCustomFile(JSON.parse(orderSingle.file_update));
                        setFirstFile(false);
                    }
                }
            }


            if (orderSingle?.adress_full) {
                let mapsSplit = orderSingle?.adress_full.match(/[^ ]+/g)
                let mapsListSave = [];
                mapsSplit.forEach((stringExp) => {
                    if (stringExp.includes('2gis.kz')) {
                        mapsListSave.push(stringExp);
                    }
                });
                setMapsList(mapsListSave);
            }


            setOrderSingle(orderSingle);
            setColTableProductComplitMenu((await getModal('PackMenu', '')).data);

            if (parseInt(params.id) > 0) {

                let allInfo = (await getOrderCalculation(params.id)).data;


                setOrderProduct(allInfo.product.products);
                setOrderProductServ(allInfo.product.servs);


                await getPreServant();


                const colGroup = (await getModal('Group', '')).data;
                setGroupTable(colGroup);


                let rowListCatalog = [...colGroup].reduce((acc, item) => {
                    return {...acc, ...{[item['id']]: item["name"]}};
                }, {});
                let prohoslGos = Object.entries(allInfo.product.products).reduce((acc, item) => {
                    let listCatalog = Object.entries(item[1]).filter((controlItems) => {
                        return controlItems[1][0]?.product?.catalog_id != 78;
                    }).map((controlItems) => {
                        return controlItems[1][0]?.product?.catalog_id;
                    });


                    let returnCatalogList = {};
                    listCatalog.forEach((controlListAll) => {
                        returnCatalogList[controlListAll] = rowListCatalog[controlListAll];
                    });
                    return {...acc, ...returnCatalogList};
                }, {});


                setCatalogSelectItem(prohoslGos);
                setOrderMenuServ(allInfo.product.menu);


                let persona = allInfo.product?.personals;
                if (!('' in persona)) {
                    persona[''] = [];
                }

                setOrderPersonal(persona);


                setDopMenuList((allInfo.product.dop_html));
                setDecorList((allInfo.product.decor_html));
                setDopMenu((allInfo.product.dop_menu_html));
                setDopMenuPlusList((allInfo.product.dop_plus_html));
                setDecorPlusList((allInfo.product.decor_plus_html));


                setAllInfoProduct(allInfo);

                const colGroupMenu = (await getModal('ProductMenu', '')).data;
                setGroupTableMenu(colGroupMenu);
            }

            try {
                let modalBrowse = require('./components/Model/edit.json');
                Object.keys(modalBrowse).forEach((key) => {
                    row.forEach((val, index) => {
                        if (val.name == key) {
                            row[index] = {...row[index], ...modalBrowse[key]}
                        }
                    });
                });


                if (orderSingle) {
                    row = row.map((pr) => {
                        return {...pr, ...{value: orderSingle[pr.name]}}
                    })
                }

                let sortArray = [];
                Object.keys(modalBrowse).forEach((key) => {
                    row.forEach((val, index) => {
                        if (val.name == key) {
                            sortArray.push(row[index]);
                        }
                    });
                });

                row.forEach((val, index) => {
                    if (sortArray.filter((filItem) => {
                        return filItem['name'] == val['name']
                    }).length == 0) {
                        sortArray.push(row[index]);
                    }
                });


                row = sortArray;

            } catch (err) {

            }


            row[9].hasOne = row[9].hasOne.filter((box) => {
                return box.role_id == 3;
            });


            setInputs(row);


            await getPreSklad();
            await getPreMenu();

            setCompleteGet(true);
        }

        async function searchSklad(e) {
            setCatalogId('');
            setPage(0);
            getStorage(e.target.value);

            setSelectSklad(e.target.value);

            setReset(!reset);
        }

        async function tableRow() {


            let row = (await getModalRow('Product')).data;
            let rowPack = ((await getModalRow('Pack')).data);
            let rowPersonalGet = ((await getModalRow('Personal')).data);
            let rowMenuGet = ((await getModalRow('ProductMenu')).data);


            Object.keys(rowMenuGet).forEach((key) => {
                rowMenuGet[key] = {
                    name: rowMenuGet[key], className: "ci-table-th-88", value: "",
                }
            });
            rowMenuGet['option'] = {
                name: "", className: "ci-table-th-88", value: "",
            };
            setProductMenuRow(rowMenuGet);

            Object.keys(rowPersonalGet).forEach((key) => {
                rowPersonalGet[key] = {
                    name: rowPersonalGet[key], className: "ci-table-th-88", value: "",
                }
            });
            rowPersonalGet['option'] = {
                name: "", className: "ci-table-th-88", value: "",
            };


            setRowPersonal(rowPersonalGet);

            Object.keys(rowPack).forEach((key) => {
                rowPack[key] = {
                    name: row[key], className: "ci-table-th-88", value: "",
                }
            });
            rowPack['option'] = {
                name: "", className: "ci-table-th-88", value: "",
            };


            setRowTableComplit(rowPack)
            Object.keys(row).forEach((key) => {
                row[key] = {
                    name: row[key], className: "ci-table-th-88", value: "",
                }
            });
            try {
                let modalBrowse = require('../ProductScreen/components/Model/browse.json');
                Object.keys(modalBrowse).forEach((key) => {
                    if (typeof row[key] != "undefined") {
                        row[key] = {...row[key], ...modalBrowse[key]};
                    } else {
                        row[key] = modalBrowse[key]
                    }
                });
            } catch (err) {

            }

            if (window.innerWidth < 1030) {
                delete (row['catalog_id']);
                delete (row['num']);
                delete (row['price']);
                delete (row['store_sklad']);
                delete (row['store']);
            }

            setRowTable(row);

        }


        async function getMenuAllOrder() {
            let product_menu_all_all = (await getModal('ProductMenu', '')).data;
            setProductMenuAll(product_menu_all_all);


            let catalogMenu = (await getCatalogMenu('', ''));
            setCatalogMenuList(catalogMenu);

        }

        useEffect(() => {
            getMenuAllOrder();
        }, []);


        useEffect(() => {
            if (updateReset2) {
                submitFormStore();
            }
        }, [updateReset]);


        useEffect(() => {


            setColTableProduct([]);
            setProductMenu([]);


            clearTimeout(sendTime);
            setSendTime(
                setTimeout(() => {
                    getProductModal(page, selectHouseProduct);
                }, 500)
            );


        }, [reset]);
        useEffect(() => {
            getOrderSend().then();
            tableRow().then();
        }, [params.id]);


        let sklad_list = {};

        if (colSklad) {
            sklad_list = colSklad.reduce((acc, item) => {
                return {...acc, ...{[item['id']]: item["name"]}};
            }, {});
        }


        async function sendFunctionSave() {

        }


        let typeMenu = false;
        let select_type_item = [...inputs].filter((orIts) => {
            return orIts.name == 'order_type_id';
        });

        let type_val = 0;
        if (select_type_item.length > 0) {
            select_type_item = select_type_item[0];
            type_val = select_type_item.value;
        }


        let is_decor_s = true;
        let is_prsonal = true;
        let is_obrv = true;
        let is_servirov = true;
        let is_logistica = true;


        if (type_val == 1) {
            is_servirov = false;
            is_obrv = false;
            is_decor_s = false;
            is_prsonal = false;
            is_logistica = false;
        }

        let countPrice = 0;
        let countInputName = inputs.filter((iinputs) => {
            return iinputs['name'] == 'player_count'
        });
        if (countInputName.length > 0) {
            if (countInputName[0]?.value) {
                countPrice = countInputName[0].value
            }
        }


        let cityRowList = '';
        let cityRowListName = inputs.filter((iinputs) => {
            return iinputs['name'] == 'adres_row'
        });
        if (cityRowListName.length > 0) {
            if (cityRowListName[0]?.value) {
                cityRowList = cityRowListName[0].value;
            }
        }

        let cityListModal = {
            "131": "Алматы",
            "132": "Астана",
        };

        let cityModalControls = "";

        if (typeof cityListModal[cityRowList] != "undefined") {
            cityModalControls = cityListModal[cityRowList];
        }

        console.log(cityModalControls);


        // console.log(cityModalControls,cityListModal,cityRowList,cityRowListName);

        let countSteps = 0;

        if (orderSingle?.step && parseInt(orderSingle?.step) > 0) {
            countSteps = parseInt(orderSingle?.step);
        }

        async function searchAdresApi(text) {

            clearTimeout(timerId.current);

            timerId.current = (setTimeout(async () => {
                let apiGet = 'https://nominatim.openstreetmap.org/search?q=' + ('Казахстан ' + cityModalControls) +' '+ text + '&format=json';


                let request = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    method: 'GET',
                    url: apiGet,
                };
                await axios(request).then((response) => {

                    setAdresGroup(response.data);

                }).catch(function (error) {

                    return [];
                });
            }, 1000))
        }

        useEffect(() => {
            thSelectInfoEvent();
        }, [productMenuAll, setOpenEditMenu, menuSelectEditMenu, menuSelectEditCatalog]);
        const thSelectInfoEvent = function (itemOp) {


            setThSelectInfo([
                ...[
                    (<div className={'text'}>

                        <SelectSearch onChange={(e) => {
                            setPage(0);
                            setMenuSelectEditCatalog(e);
                        }} options={
                            productMenuAll.reduce((acc, item) => {

                                const category = item.category_name;

                                if (acc.filter((itemBox) => {
                                    return itemBox['name'] == category;
                                }).length == 0) {
                                    return [...acc, {name: category, value: category}];
                                }
                                return [...acc];

                            }, [])} value={menuSelectEditCatalog} search={true} name="language"
                                      placeholder="Выбор раздела"/>


                        {/*{*/}
                        {/*    type: 'group',*/}
                        {/*    name: 'Group name',*/}
                        {/*    items: [*/}
                        {/*{name: 'Spanish', value: 'es'},*/}
                        {/*    ]*/}
                        {/*}*/}
                    </div>)
                    ,
                    (<SelectSearch onChange={async (e) => {

                        setMenuSelectEditMenu(e);

                        const updateDataOrder = (await updateDB({
                            'save': {
                                "menu_id": e,
                                "sort": openEditControls['sort'],
                                "count": openEditControls['count'],
                                "status": openEditControls['pre']['status'],
                                "table": openEditControls['pre']['table'],
                                "type": openEditControls['pre']['type'],
                                "menu_type_id": openEditControls['pre']['menu_type_id'],
                                "order_id": openEditControls['pre']['order_id'],
                            },
                            'id': openEditControls['pre']['id'],
                            'model_name': 'OrderProduct',
                        }, false));


                        const orderProductMenuSavePre = (await getOrderMenuPos(params.id)).data;
                        setOrderMenuServ(orderProductMenuSavePre);

                        setOpenEditMenu(-10);

                    }} options={
                        productMenuAll.filter((bfilter) => {
                            return bfilter.category_name == menuSelectEditCatalog;
                        }).reduce((acc, item) => {
                            const category = item.category_name;
                            const existingGroup = acc.find((group) => group.name === category);

                            if (existingGroup) {
                                existingGroup.items.push({name: item.name, value: String(item.id)});
                            } else {
                                acc.push({
                                    type: "group",
                                    name: category,
                                    items: [{name: item.name, value: (item.id)}],
                                });
                            }
                            return acc;
                        }, [])} value={menuSelectEditMenu} search={true} name="language"
                                   placeholder="Выбор товара"/>)
                ]
            ]);

        };


        const openOrder = function (itemOp) {

            if (openEditMenu != itemOp['id']) {
                setMenuSelectEditMenu(itemOp['id']);
                setOpenEditControls(itemOp);
                setMenuSelectEditCatalog(itemOp['category_name']);
                setOpenEditMenu(itemOp['id']);
            }

        };


        let agentSbor = inputs.filter((org) => {
            return org['name'] == 'agency';
        });

        let daySborVal = 1;

        let daySbor = inputs.filter((org) => {
            return org['name'] == 'count_day';
        });

        let status_id = inputs.filter((org) => {
            return org['name'] == 'order_type_id';
        });

        let status = 0;
        if (status_id.length > 0) {
            status = parseInt(status_id[0].value);
        }

        if (daySbor.length > 0 && status == 5) {
            daySborVal = parseInt(daySbor[0].value);
        }

        let agentSborVal = 1;

        if (agentSbor.length > 0) {
            agentSborVal = parseInt(agentSbor[0].value);
        }

        let listTypeBron = {
            "1": "Бронь",
            "2": "Выполненные",
            "5": "Неподтвержденный",
            "3": "Отмененные"
        };


        async function updatePdf() {

            let fileSave = [...customFile];
            fileSave = fileSave.filter((controlFIlet) => {
                return !(controlFIlet.indexOf('Menu_' + params.id + '_') >= 0);
            });
            setCustomFile(fileSave)

            if (params.id != 0) {


                function getRandomInt(max) {
                    return Math.floor(Math.random() * max);
                }

                let fileNameSave = 'Menu_' + params.id + '_' + getRandomInt(10000);


                let elms = (menuType.map((serItems) => {
                    if (serItems.id in orderMenuServ) {
                        return {
                            name: serItems.name,
                            menu: Object.entries(orderMenuServ[serItems.id]).map((controlBMenu) => {
                                return [...controlBMenu[1].map((menuControl) => {

                                    let elementCount = parseInt(menuControl['count']);
                                    let elementDopCount = parseInt(menuControl['count_dop']);
                                    if (elementDopCount > 0) {

                                    } else {
                                        elementDopCount = 0;
                                    }
                                    if (elementCount > 0) {

                                    } else {
                                        elementCount = 0;
                                    }
                                    return {...menuControl['menu'], ...{'count': elementCount + elementDopCount}};
                                })];
                            }).map((controlBMenu) => {
                                return controlBMenu[0];
                            })
                        }
                    }
                    return false;
                }).filter((cofilter) => {
                    return cofilter;
                }));


                let bodyPost = {
                    'filename': fileNameSave,
                    'order': orderSingle,
                    'menu': elms
                };

                const visited = new Set();

// Custom replacer function
                const replacer = (key, value) => {
                    // Check if the value is an object and it has been visited before
                    if (typeof value === "object" && value !== null) {
                        if (visited.has(value)) {
                            // If it's a circular reference, return a placeholder value or omit the property
                            return "[Circular Reference]";
                        }

                        // Mark the object as visited before recursively processing its properties
                        visited.add(value);
                    }

                    return value;
                };

                await saveJson(
                    {
                        body: JSON.stringify(bodyPost, replacer, 2)
                    }
                );

                // await povarGen(fileNameSave);

                await fetch(REACT_APP_ASSETS_PATH + '/public/pdf/povar.php?filename=' + fileNameSave)
                    .then(data => {
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });

                setCustomFile([...fileSave, "/storage/uploads/" + fileNameSave + ".pdf"])


            }

        }

        async function submitForm(messageItemSend = false) {


            if (completeGet) {


                setLoading(true);

                let sendFiles = customFile;
                let AddFileSave = '';


                const updateDataOrder = (await updateDB({
                    'save': {
                        ...inputs.reduce((ass, val) => {

                            if (val.name == 'mon' || val.name == 'demon') {
                                return {...ass, ...{[val.name]: moment(val.value).format("YYYY-MM-DD HH:mm:ss")}};
                            }
                            return {...ass, ...{[val.name]: val.value}};
                        }, {}), ...{
                            "product_list_ch": productListCh,
                            "sendMessage": messageItem,
                            product_list: JSON.stringify(productList),
                            // product_in: JSON.stringify(productIn),
                            file_update: JSON.stringify(sendFiles),
                            decor_html: JSON.stringify(decorList),

                            dop_plus_html: JSON.stringify(dopMenuPlusList),
                            decor_plus_html: JSON.stringify(decorPlusList),

                            dop_menu_html: JSON.stringify(dopMenu),

                            fileNameAddList: AddFileSave,
                            dop_html: JSON.stringify(dopMenuList),
                        }
                    }, 'id': params.id, 'model_name': 'Order',
                })).data[0];

                if (params.id == 0 && updateDataOrder?.id) {
                    navigate('/Order/edit/' + updateDataOrder.id);
                } else {

                }

                setProductList(JSON.parse(updateDataOrder.product_list));
                // setOrderSingle(updateDataOrder);

                setLoading(false);

                await getOrderSend().then();


            } else {
                alert('Ждите, идет загрузка данные 3-5 секунд')
            }
        }

        async function submitFormStore(messageItemSend = false) {


            const updateDataOrder = (await updateDB({
                'save': {
                    dop_plus_html: JSON.stringify(dopMenuPlusList),
                    dop_html: JSON.stringify(dopMenuList),
                }, 'id': params.id, 'model_name': 'Order',
            })).data[0];

        }

//  "adress_full": {
//    "className": "ci-table-th-140"
//  },

        return (<Layout>

            <form
                onSubmit={(e) => {

                    e.preventDefault();
                    submitForm();

                }} method={'post'}
            >

                {
                    window.isRole("Order", 'edit') ? (
                        <>
                            <p className={'text text-bold text-s26'} style={{marginTop: "3rem"}}>
                                Этапы создания заказа
                            </p>

                            <div className="nav-steps" style={{marginBottom: "3rem"}}>


                                {
                                    parseInt(params.id) > 0 && (
                                        <>
                                            <div onClick={async () => {
                                                if (params.id != 0) {
                                                    setOrderSingle({...orderSingle, ...{step: 0, status: 5}});

                                                    setInputs(inputs.map((inp) => {
                                                        if (inp["name"] == 'status') {
                                                            return {...inp, ...{value: 5}};
                                                        }
                                                        return inp;
                                                    }));

                                                    await updateDB({
                                                        'save': {
                                                            "step": "0",
                                                            "status": "5",
                                                        }, 'id': params.id, 'model_name': 'Order',
                                                    }, false);
                                                }
                                            }} className={"step-item " + (countSteps == 0 && 'active')}>
                                                <p className={'text text-bold text-s16'}>
                                                    Создание КП
                                                </p>
                                                <p className={'text text-s16'}>
                                                    {
                                                        countSteps == 0 && ('В процессе')
                                                    }

                                                    {
                                                        countSteps != 0 && ('Сделка в процессе')
                                                    }
                                                </p>
                                            </div>
                                            <div onClick={async () => {

                                                if (confirm('Вы подтверждаете заказ, он будет создан в календаре, вы уверенны?')) {

                                                    if (params.id != 0) {
                                                        setOrderSingle({
                                                            ...orderSingle, ...{
                                                                step: 2,
                                                                status: (orderSingle.status == 5 || !(orderSingle.status)) ? 1 : orderSingle.status
                                                            }
                                                        });
                                                        setInputs(inputs.map((inp) => {
                                                            if (inp["name"] == 'status') {
                                                                return {...inp, ...{value: (orderSingle.status == 5 || !(orderSingle.status)) ? 1 : orderSingle.status}};
                                                            }
                                                            return inp;
                                                        }));
                                                        await updateDB({
                                                            'save': {
                                                                "step": "2",
                                                                "status": (orderSingle.status == 5 || !(orderSingle.status)) ? 1 : orderSingle.status,
                                                            }, 'id': params.id, 'model_name': 'Order',
                                                        }, false);

                                                        // getOrderSend().then();
                                                    }
                                                }
                                            }} className={"step-item " + (countSteps == 2 && 'active')}>
                                                <p className={'text text-bold text-s16 '}>
                                                    Подтвердить заказ
                                                </p>
                                                <p className={'text text-s16'}>
                                                    {
                                                        (orderSingle?.status && orderSingle?.status in listTypeBron) ? listTypeBron[orderSingle?.status] : 'Непотвержденный'
                                                    }
                                                </p>
                                            </div>
                                        </>
                                    )
                                }

                                <div style={{marginLeft: "auto"}} className={'sm-nav-order'}>

                                    {
                                        countSteps != 0 && (<Btn type={'submit'} onClick={() => {
                                            // submitForm(true);
                                            sedMessageItem(true);
                                        }} className={'btn-def-table text text-s14'} name={'sendMessage'} disabled={loading}
                                                                 load={loading}
                                                                 label={'Уведомить'}/>)
                                    }

                                    <Btn type={'submit'} onClick={() => {
                                        // submitForm(false);
                                        sedMessageItem(false);

                                    }} className={'btn-def-table text text-s14'} disabled={loading}
                                         load={loading} label={params.id == 0 ? 'Создать' : 'Сохранить'}/>


                                </div>
                            </div>
                        </>
                    ) : (

                        <>
                            <p className={'text text-bold text-s26'} style={{marginTop: "3rem"}}>
                            </p>

                            <div className="nav-steps" style={{marginBottom: "3rem"}}>

                                <div style={{marginLeft: "auto"}} className={'sm-nav-order'}>
                                    <Btn onClick={() => {

                                        let contbox = [...(Object.entries({...orderProduct}).map(([storeName, productArray]) => {

                                                let rowTableSave = {...rowTable};
                                                delete (rowTableSave['catalog_id']);

                                                let serius = Object.values(productArray).sort((a, b) => {
                                                    let catalogIdA = a[0]?.product?.catalog_id ? a[0]?.product?.catalog_id : 0;
                                                    let catalogIdB = b[0]?.product?.catalog_id?.catalog_id ? b[0]?.product?.catalog_id : 0;
                                                    if (catalogIdA < catalogIdB) {
                                                        return -1;
                                                    }
                                                    if (catalogIdA > catalogIdB) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                })

                                                return (Object.entries(serius).map(([productId, productArraySingle]) => {
                                                    let productReturn = productArraySingle[0]['product'];
                                                    if (productReturn == null) {
                                                        return false;
                                                    }
                                                    productReturn['count'] = productArraySingle[0]['count'];
                                                    productReturn['count_dop'] = productArraySingle[0]['count_dop'];
                                                    productReturn['discont'] = productArraySingle[0]['discont'];
                                                    productReturn['product_commit'] = productArraySingle[0]['product_commit'];
                                                    productReturn['update_id'] = productArraySingle[0]['id'];
                                                    productReturn['pre'] = productArraySingle[0];


                                                    productReturn['count_result'] = (productReturn['count'] * productReturn['price_arenda']);

                                                    if (parseInt(productReturn['discont']) > 0) {
                                                        productReturn['count_result'] = productReturn['count_result'] * (1 - (parseInt(productReturn['discont']) / 100));
                                                    }
                                                    return productReturn;
                                                }).filter((itsrx) => {
                                                    if (catalogSelect) {
                                                        return itsrx.catalog_id == catalogSelect && itsrx;
                                                    }

                                                    return itsrx;
                                                }))
                                            }).reduce((ass, itemVal) => {
                                                return [...ass, ...itemVal];
                                            }, []).map((itemVal) => {

                                                let groupList = ([...groupTable].reduce((ass, val) => {
                                                    return {...ass, ...{[val.id]: val.name}};
                                                }, {}));

                                                let pre_post_count = isNaN(parseInt(itemVal['count'])) ? 0 : parseInt(itemVal['count']);
                                                let pre_post_count_dop = isNaN(parseInt(itemVal['count_dop'])) ? 0 : parseInt(itemVal['count_dop']);
                                                let pre_product_store_post = isNaN(parseInt(itemVal['pre']['product_store_post'])) ? 0 : parseInt(itemVal['pre']['product_store_post']);

                                                return {
                                                    category_name: itemVal.catalog_id in groupList ? groupList[itemVal.catalog_id] : "Оборудование",
                                                    category_name_big: "Оборудование",
                                                    name: itemVal['name'],
                                                    price_arenda: parseInt(itemVal['price_arenda']).toLocaleString(),
                                                    photo: itemVal['photo'],
                                                    photo_mini: itemVal['photo_mini'],
                                                    post_count: pre_product_store_post,
                                                    count: pre_post_count + pre_post_count_dop
                                                };
                                            })
                                        ),
                                            ...(serving.map((servingItem) => {


                                                if (servingItem.id in orderProductServ) {
                                                    let controlBoxRest = [...(Object.entries({...orderProductServ[servingItem.id]}))].map(([storeName, productArray]) => {

                                                        let rowTableSave = {...rowTable};
                                                        delete (rowTableSave['catalog_id']);

                                                        let serius = Object.values(productArray).sort((a, b) => {
                                                            let catalogIdA = a[0]?.product?.catalog_id ? a[0]?.product?.catalog_id : 0;
                                                            let catalogIdB = b[0]?.product?.catalog_id?.catalog_id ? b[0]?.product?.catalog_id : 0;
                                                            if (catalogIdA < catalogIdB) {
                                                                return -1;
                                                            }
                                                            if (catalogIdA > catalogIdB) {
                                                                return 1;
                                                            }

                                                            return 0;
                                                        })
                                                        return (Object.entries(serius).map(([productId, productArraySingle]) => {
                                                            let productReturn = productArraySingle[0]['product'];
                                                            if (productReturn == null) {
                                                                return false;
                                                            }
                                                            productReturn['count'] = productArraySingle[0]['count'];
                                                            productReturn['count_dop'] = productArraySingle[0]['count_dop'];
                                                            productReturn['discont'] = productArraySingle[0]['discont'];
                                                            productReturn['product_commit'] = productArraySingle[0]['product_commit'];
                                                            productReturn['update_id'] = productArraySingle[0]['id'];
                                                            productReturn['pre'] = productArraySingle[0];
                                                            productReturn['count_result'] = (productReturn['count'] * productReturn['price_arenda']);

                                                            if (parseInt(productReturn['discont']) > 0) {
                                                                productReturn['count_result'] = productReturn['count_result'] * (1 - (parseInt(productReturn['discont']) / 100));
                                                            }
                                                            return productReturn;
                                                        }).filter((itsrx) => {
                                                            if (catalogSelect) {
                                                                return itsrx.catalog_id == catalogSelect && itsrx;
                                                            }
                                                            return itsrx;
                                                        }))
                                                    }).reduce((ass, itemVal) => {
                                                        return [...ass, ...itemVal];
                                                    }, []).map((itemVal) => {
                                                        let groupList = ([...groupTable].reduce((ass, val) => {
                                                            return {...ass, ...{[val.id]: val.name}};
                                                        }, {}));

                                                        let pre_post_count = isNaN(parseInt(itemVal['count'])) ? 0 : parseInt(itemVal['count']);
                                                        let pre_post_count_dop = isNaN(parseInt(itemVal['count_dop'])) ? 0 : parseInt(itemVal['count_dop']);
                                                        let pre_product_store_post = isNaN(parseInt(itemVal['pre']['product_store_post'])) ? 0 : parseInt(itemVal['pre']['product_store_post']);


                                                        return {
                                                            category_name: itemVal.catalog_id in groupList ? groupList[itemVal.catalog_id] : "Оборудование",
                                                            category_name_big: 'Сервировка: ' + servingItem.name,
                                                            name: itemVal['name'],
                                                            photo: itemVal['photo'],
                                                            photo_mini: itemVal['photo_mini'],
                                                            price_arenda: parseInt(itemVal['price_arenda']).toLocaleString(),
                                                            post_count: pre_product_store_post,
                                                            count: pre_post_count + pre_post_count_dop

                                                        }
                                                    });
                                                    return controlBoxRest;

                                                } else {
                                                    return [];
                                                }

                                            }).reduce((ass, object) => {


                                                return [...ass, ...object];

                                            }, []))].filter((product_pre_send) => {

                                            return product_pre_send.count > product_pre_send.post_count;

                                        });

                                        function send_message(message) {

                                            const data = {
                                                // ваш JSON-объект с данными
                                                'message': message,
                                                'order_id': params.id,
                                            };
                                            const options = {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                    // Здесь могут быть другие необходимые заголовки
                                                },
                                                body: JSON.stringify(data)
                                            };

                                            const url = REACT_APP_API_PATH + '/get/order/message';

                                            fetch(url, options)
                                                .then(response => {
                                                    if (!response.ok) {
                                                        throw new Error('Network response was not ok');
                                                    }
                                                    return response.json(); // Если ожидается JSON в ответе
                                                })
                                                .then(data => {
                                                    console.log('Успешный ответ:', data);
                                                })
                                                .catch(error => {
                                                    console.error('Произошла ошибка:', error);
                                                });


                                        }


                                        if (contbox.length > 0) {

                                            if (confirm('Вы не выдали ' + contbox.length + ' позиций, вы уверены что хотите завершить выдачу?')) {
                                                let tr_box = '';
                                                contbox.forEach((ibox) => {
                                                    tr_box += "\n<b>" + ibox.name + "</b>: к выдачи: " + ibox.count + ", Выдали:" + ibox.post_count + "";
                                                });

                                                send_message("Заказ:<b>" + orderSingle.name_ords + "</b> \n<b>Не все товары были выданы</b>" + tr_box);
                                            }
                                        } else if (confirm('Вы все выдали, вы уверены что хотите завершить выдачу?')) {
                                            send_message("Заказ:<b>" + orderSingle.name_ords + "</b> \n <b>Все товары выдали</b>");
                                        }

                                        setSendTelegramInfo(true);

                                    }} className={'btn-def-table text text-s14 green-btn ' + (sendTelegramInfo && 'active')}
                                         disabled={loading}

                                         load={loading} label={
                                        sendTelegramInfo ? 'Уведомление отправлено' : 'Завершить выдачу'
                                    }/>


                                </div>
                            </div>
                        </>
                    )
                }


                {
                    window.isRole("Order", 'edit') && (
                        <InputsEdit adresGroup={adresGroup} inputs={[...[...inputs].filter((elementInput) => {
                            if (elementInput.name == 'count_day') {
                                return inputs.filter((inpbox) => {
                                    return inpbox.name == 'order_type_id' && inpbox.value == '5';
                                }).length > 0;
                            }


                            return true;
                        }).map((itm) => {
                            let itmSave = itm;
                            if (itmSave.name == "lift" || itmSave.name == "samo") {
                                itmSave.type = 'hasOne';
                                itmSave.hasOne = [{'id': 'да', 'name': 'да'}, {'id': 'нет', 'name': 'нет'},];
                            }
                            return itmSave;
                        })]} onChange={(e, name) => {

                            setInputs(inputs.map((inp) => {


                                if (inp["name"] == name) {
                                    return {...inp, ...{value: e.target.value}};
                                }

                                if (name == 'adress_full') {
                                    searchAdresApi(e.target.value);
                                }
                                return inp;
                            }));


                        }}/>
                    )
                }


                {
                    !window.isRole("Order", 'edit') && (
                        <div>
                            {
                                [...inputs].filter((itemBs) => {
                                    if (itemBs.name == 'phone' || itemBs.name == 'order_type_id' || itemBs.name == 'status'
                                        || itemBs.name == 'personal'
                                        || itemBs.name == 'agency'
                                        || itemBs.name == 'taiming'
                                        || itemBs.name == 'player_count'
                                        || itemBs.name == 'samo'
                                        || itemBs.name == 'name') {
                                        return false;
                                    }
                                    // hasOne
                                    //

                                    return true;
                                }).map((itm) => {
                                    let itmSave = itm;
                                    if (itmSave.name == "lift" || itmSave.name == "samo") {
                                        itmSave.type = 'hasOne';
                                        itmSave.hasOne = [{'id': 'да', 'name': 'да'}, {'id': 'нет', 'name': 'нет'},];
                                    }

                                    if ('hasOne' in itm) {
                                        let valueRet = itm.hasOne.filter((control_list) => {
                                            return control_list.id == itm.value;
                                        });
                                        if (valueRet.length > 0) {
                                            itmSave.value = valueRet[0].name;
                                        }
                                    }

                                    return (
                                        <p className={'text text-s18'} style={{marginBottom: "0rem"}}>
                                            <b>{itmSave.placeholder} :</b>
                                            {' ' + itmSave.value}
                                        </p>
                                    )
                                })
                            }
                        </div>
                    )
                }

                {
                    window.isRole("Order", 'edit') && (
                        <div style={{margin: "4rem 0"}}>

                            {
                                countSteps == 2 && (
                                    <div>
                                        <p className={'text text-s22'}>
                                            Добавить файл
                                        </p>

                                        <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
                                            {[...customFile].map((item, indexOld) => {
                                                return (
                                                    <div key={indexOld}
                                                         style={{display: "flex", alignItems: "center", marginTop: "1rem"}}>
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                            {item}
                                                        </div>

                                                        <p onClick={() => {
                                                            const updatedCustomFile = customFile.filter((_, i) => i !== indexOld);
                                                            setCustomFile([]);
                                                            setTimeout(() => {
                                                                setCustomFile([...updatedCustomFile]);
                                                            }, 400);
                                                        }} style={{
                                                            color: "red",
                                                            cursor: 'pointer',
                                                            marginLeft: "2rem",
                                                            marginBottom: "0"
                                                        }}
                                                           className={'text text-s18'}>
                                                            <div className="delete-button">&#10006;</div>
                                                        </p>
                                                        <br/>
                                                    </div>)
                                            })}

                                            <div className="save_file" style={{display: "flex", marginTop: "2rem"}}>

                                                {/*<p onClick={() => {*/}
                                                {/*    setCustomFile([...customFile, ...['']])*/}
                                                {/*}} className={'ci-btn  ci-btn-def btn-def-table text text-s16'}>*/}
                                                {/*    Добавить новый файл*/}
                                                {/*</p>*/}

                                                <p onClick={async () => {

                                                    await updatePdf();


                                                }} className={'ci-btn  ci-btn-def btn-def-table text text-s16'}>
                                                    Обновить pdf для повара
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                        </div>
                    )
                }

                <Modal
                    isOpen={modalProduct}
                    onRequestClose={() => {
                        setModalProduct(false);
                    }}
                    contentLabel="Example Modal"
                    className="model-add-box"
                >
                    <div className="edit-table edit-table-model">

                        <div className="edit-table__head">
                            <span className="text text-s22">Выбрать товары из каталога</span>
                            <div className="modal-add-btns">
                                <Btn onClick={() => {
                                    setPageModel(0)
                                }} className={'btn-def-table text text-s14 ' + (pageModel == 0 && 'active')}
                                     label={'Каталог'}/>
                                <Btn onClick={() => {
                                    setPageModel(1)
                                }} className={'btn-def-table text text-s14 ' + (pageModel == 1 && 'active')}
                                     label={'Готовые чек-листы'}/>
                            </div>


                            <div className="modal-add-btns">
                                <Btn className={'btn-def-table btn-def-table-clear text text-s14'} onClick={() => {
                                    if (!loadingCreate) {
                                        setProductListPre({});
                                        setCatalogId('')
                                        setSelectSklad('1');
                                        setProductListPreCommint({});
                                        setModalProduct(false);
                                    }
                                }} load={loadingCreate} label={'закрыть'}/>
                                <Btn onClick={async () => {
                                    NotificationManager.info('Ожидайте', 'добавление товаров');

                                    if (!loadingCreate) {
                                        setLoadingCreate(true);

                                        if (pageModel == 0) {

                                            if (Object.keys(errorList).length > 0 ? confirm('Вы пытаетесь добавить больше чем есть в наличии, вы уверенны?') : true) {


                                                const preList = Object.entries(productListPre);
                                                const preListComment = Object.entries(productListPreCommint);


                                                let saveUpdate = {'products': []};

                                                for (let iFor = 0; iFor < preList.length; iFor++) {

                                                    const preListProduct = Object.entries(preList[iFor][1]);
                                                    for (let iForProduct = 0; iForProduct < preListProduct.length; iForProduct++) {


                                                        if (((preListProduct[iForProduct][1][2]['store'] - preListProduct[iForProduct][1][1]) < 0) ?
                                                            confirm('Вы пытаетесь добавить больше чем есть в наличии, вы уверены? ' + preListProduct[iForProduct][1][3] + "\nТовар : " + preListProduct[iForProduct][1][2]['name']) : true
                                                        ) {


                                                            let addInfoProduct = {
                                                                "sklad_id": preList[iFor][0],
                                                                "order_id": params.id,
                                                                "status": 'new',
                                                                "type": 'rent',
                                                                "table": 'order',
                                                                "product_id": preListProduct[iForProduct][0],
                                                            };
                                                            if (countSteps == 0) {
                                                                addInfoProduct['count'] = preListProduct[iForProduct][1][1];
                                                            } else {
                                                                if (preListProduct[iForProduct][1][0] == 0) {
                                                                    addInfoProduct['count'] = 0;
                                                                }
                                                                addInfoProduct['count_dop'] = preListProduct[iForProduct][1][1];
                                                            }

                                                            saveUpdate['products'].push({
                                                                'save': addInfoProduct,
                                                                'id': preListProduct[iForProduct][1][0],
                                                                'model_name': 'OrderProduct',
                                                            });
                                                        }


                                                    }
                                                }

                                                for (let iFor = 0; iFor < preListComment.length; iFor++) {

                                                    const preListProduct = Object.entries(preListComment[iFor][1]);
                                                    for (let iForProduct = 0; iForProduct < preListProduct.length; iForProduct++) {


                                                        let addInfoProduct = {
                                                            "sklad_id": preListComment[iFor][0],
                                                            "order_id": params.id,
                                                            "status": 'new',
                                                            "type": 'rent',
                                                            "table": 'order',
                                                            "product_id": preListProduct[iForProduct][0],
                                                        };
                                                        addInfoProduct['product_commit'] = preListProduct[iForProduct][1][1];

                                                        let pushSaveBox = {
                                                            'save': addInfoProduct,
                                                            'id': preListProduct[iForProduct][1][0],
                                                            'model_name': 'OrderProduct',
                                                        };

                                                        let updateSave = -1;
                                                        saveUpdate['products'].forEach((itemControl, indexSabox) => {
                                                            if (
                                                                pushSaveBox['id'] == itemControl['id'] &&
                                                                pushSaveBox['save']['product_id'] == itemControl['save']['product_id'] &&
                                                                pushSaveBox['save']['sklad_id'] == itemControl['save']['sklad_id']
                                                            ) {
                                                                updateSave = indexSabox;
                                                            }
                                                        });

                                                        if (updateSave === -1) {
                                                            saveUpdate['products'].push(pushSaveBox);
                                                        } else {
                                                            saveUpdate['products'][updateSave]['save']['product_commit'] = preListProduct[iForProduct][1][1];
                                                        }


                                                    }
                                                }

                                                const updateDataOrder = (await updateDBOrderProduct(saveUpdate, false));
                                            }

                                        } else {

                                            let productPack = [...colTableProductComplit].filter((packList) => {
                                                return packList['id'] in productListPre;
                                            });

                                            // orderProduct['Склад Алматы']

                                            let save_new = {};

                                            let sklad_select_sl = {};

                                            productPack.forEach((packPro) => {


                                                // colSklad
                                                if (isJson(packPro.product_list) && packPro.product_list != null) {

                                                    let sklad_all = JSON.parse(packPro.product_list);

                                                    Object.entries(sklad_all).forEach((valm, index) => {

                                                        let productListPreProduct = valm[1];


                                                        Object.keys(productListPreProduct).forEach((productjsonKey, index) => {

                                                            const val = parseInt(productListPreProduct[productjsonKey]);
                                                            const key = productjsonKey;

                                                            if (val > 0) {
                                                                sklad_select_sl[valm[0]] = {...sklad_select_sl[valm[0]], ...{[key]: ((val) * productListPre[packPro['id']])}};
                                                            }

                                                        });
                                                    });
                                                }

                                            });


                                            let listUpdateSend = [];


                                            Object.entries(sklad_select_sl).forEach(([skladInfoControl, countSaa]) => {

                                                let nameBoxSklad = '';
                                                let contBox = [...colSklad].filter((bControl) => {
                                                    return bControl.id == skladInfoControl;
                                                });
                                                if (contBox.length > 0) {
                                                    nameBoxSklad = contBox[0].name;
                                                }

                                                if (!(nameBoxSklad in orderProduct)) {
                                                    orderProduct[nameBoxSklad] = {};
                                                }

                                                Object.entries(countSaa).forEach(([idSaa, countSaa]) => {


                                                    if (idSaa in orderProduct[nameBoxSklad]) {

                                                        let controlBox = {};

                                                        if (countSteps == 0) {
                                                            controlBox['count'] = countSaa;
                                                        } else {
                                                            controlBox['count_dop'] = countSaa;
                                                        }

                                                        const updateDataOrder = (updateDB({
                                                            'save': controlBox,
                                                            'id': orderProduct[nameBoxSklad][idSaa][0]['id'],
                                                            'model_name': 'OrderProduct',
                                                        }, false));

                                                    } else {

                                                        let controlBox = {
                                                            "sklad_id": skladInfoControl,
                                                            "order_id": params.id,
                                                            "status": 'new',
                                                            "type": 'rent',
                                                            "table": 'order',
                                                            "product_id": idSaa,
                                                        };

                                                        if (countSteps == 0) {
                                                            controlBox['count'] = countSaa;
                                                        } else {
                                                            controlBox['count_dop'] = countSaa;
                                                        }


                                                        listUpdateSend.push({
                                                            'save': controlBox,
                                                            'id': 0,
                                                            'model_name': 'OrderProduct',
                                                        })
                                                    }
                                                });

                                            });


                                            for (let control = 0; control < listUpdateSend.length; control++) {
                                                await (updateDB(listUpdateSend[control], false));
                                            }


                                        }
                                        NotificationManager.success('можете закрыть, форму с продуктами', 'Товары добавлены');


                                        await getOrderSend().then();

                                        setProductListPre({});
                                        setProductListPreCommint({});
                                        setLoadingCreate(false);
                                    }
                                }} className={'btn-def-table text text-s14'} load={loadingCreate}
                                     label={(pageModel == 0 ? 'Добавить товар' : 'Добавить комплекты')}/>
                            </div>
                        </div>


                        {pageModel == 0 && (<div className="catalog_order">
                            <div style={{width: "100%"}} className={'pagination-box text text-s18'}>
                                <div style={{marginRight: "auto", display: "flex"}}>
                                    <div style={{width: "30rem"}}>
                                        <CustomInput value={search} onChange={(e) => {
                                            setSearch(e.target.value);

                                            if (e.keyCode == 13) {
                                                setPage(0);
                                                setPageModel(0);
                                                setReset(!reset);
                                            }

                                        }} name={'search'}
                                                     img={require('../../assets/images/search-normal.png')}
                                                     label={'Поиск'}/>
                                    </div>

                                    {
                                        !selectHouseProduct && (
                                            <div style={{width: "30rem", marginLeft: "4rem"}}>
                                                <CustomSelect onChange={(e) => {
                                                    setCatalogId(e.target.value)
                                                    setPage(0);
                                                    setPageModel(0);
                                                    setReset(!reset);
                                                }} options={groupVal} value={catalogId} label={'Все разделы'}/>
                                            </div>
                                        )
                                    }

                                    <div style={{width: "30rem", marginLeft: "4rem"}}>
                                        <CustomSelect onChange={(e) => {
                                            searchSklad(e);
                                        }} options={colSklad.reduce((acc, item) => {
                                            return {...acc, ...{[item['id']]: item["name"]}};
                                        }, {})} value={selectSklad} label={'Склады'}/>
                                    </div>
                                </div>

                                {colTableProduct.last_page > 1 && (
                                    <ReactPaginate
                                        nextLabel=">"
                                        previousLabel="<"
                                        onPageChange={(e) => {
                                            // setPage(1);
                                            setPage(e.selected);
                                            setReset(!reset);
                                        }}
                                        forcePage={page}
                                        pageCount={colTableProduct.last_page}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                )}
                            </div>
                            <div className="edit-table__body">

                                <Table th={rowTable} loading={sendTime} modalName={'Product'} productOption={true}
                                       tr={colTableProduct.data}
                                       option={(id, row) => {

                                           return (<div className={'table-options'}>

                                               <CustomInput className={'edit-product-order right-text-big'}
                                                            onChange={(e) => {


                                                                let preProductBox = {...productListPreCommint};
                                                                if (!(selectSklad in preProductBox)) {
                                                                    preProductBox[selectSklad] = {};
                                                                }
                                                                let storeObj = colSklad.reduce((acc, item) => {
                                                                    return {...acc, ...{[item['id']]: item["name"]}};
                                                                }, {})
                                                                let idSaveBox = 0;
                                                                if (storeObj[selectSklad] in orderProduct && row['id'] in orderProduct[storeObj[selectSklad]]) {
                                                                    idSaveBox = orderProduct[storeObj[selectSklad]][row['id']][0]['id'];
                                                                }
                                                                preProductBox[selectSklad][id] = [idSaveBox, e.target.value, row];

                                                                setProductListPreCommint(preProductBox);
                                                            }}
                                                            disabled={loadingCreate}
                                                            value={(selectSklad in productListPreCommint && row['id'] in productListPreCommint[selectSklad]) ? (productListPreCommint[selectSklad][row['id']][1]) : ((sklad_list[selectSklad] in orderProduct && row['id'] in orderProduct[sklad_list[selectSklad]]) ? orderProduct[sklad_list[selectSklad]][row['id']][0]['product_commit'] : '')}
                                                            label={'Примечание'}/>

                                               <CustomInput className={'edit-product-order'} onChange={async (e) => {

                                                   let preProduct = {...productListPre};
                                                   if (!(selectSklad in preProduct)) {
                                                       preProduct[selectSklad] = {};
                                                   }
                                                   let storeObj = colSklad.reduce((acc, item) => {
                                                       return {...acc, ...{[item['id']]: item["name"]}};
                                                   }, {})
                                                   let idSaveBox = 0;
                                                   if (storeObj[selectSklad] in orderProduct && row['id'] in orderProduct[storeObj[selectSklad]]) {
                                                       idSaveBox = orderProduct[storeObj[selectSklad]][row['id']][0]['id'];
                                                   }
                                                   if (parseInt(e.target.value) >= 0) {
                                                       let nameList = colSklad.reduce((acc, item) => {
                                                           return {...acc, ...{[item['id']]: item["name"]}};
                                                       }, {});
                                                       let sklad = ((sklad_list[selectSklad] in orderProduct && row['id'] in orderProduct[sklad_list[selectSklad]]) ? orderProduct[sklad_list[selectSklad]][row['id']][0]['count'] : '')
                                                       preProduct[selectSklad][id] = [idSaveBox, parseInt(e.target.value), row, nameList[selectSklad]];
                                                   } else {
                                                       preProduct[selectSklad][id] = 0;
                                                   }
                                                   setProductListPre(preProduct);

                                               }}

                                                            disabled={loadingCreate}
                                                            value={(selectSklad in productListPre && row['id'] in productListPre[selectSklad]) ? (productListPre[selectSklad][row['id']][1] >= 0 ? productListPre[selectSklad][row['id']][1] : '') : ((sklad_list[selectSklad] in orderProduct && row['id'] in orderProduct[sklad_list[selectSklad]]) ? orderProduct[sklad_list[selectSklad]][row['id']][0][countSteps == 0 ? 'count' : 'count_dop'] : '')}
                                                            type={'number'}
                                                            label={'Количество'}/>
                                           </div>)
                                       }}
                                />
                            </div>
                            <div className={'pagination-box text text-s18'}>
                                {colTableProduct.last_page > 1 && (
                                    <ReactPaginate
                                        nextLabel=">"
                                        previousLabel="<"
                                        onPageChange={(e) => {
                                            // setPage(1);
                                            setPage(e.selected);
                                            setReset(!reset);
                                        }}
                                        forcePage={page}
                                        pageCount={colTableProduct.last_page}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                )}
                            </div>
                        </div>)}

                        {pageModel == 1 && (<div className="catalog_order">
                            <div className="edit-table__body">
                                <Table th={rowTableComplit} tr={colTableProductComplit}
                                       option={(id, row) => {
                                           return (<div className={'table-options'}>
                                               <BtnIcon onClick={() => {
                                                   setOpenRow(row);
                                                   setModal(true);
                                               }} value={'список'}/>
                                               <span style={{display: "flex", marginRight: "1rem"}}></span>
                                               <CustomInput className={'edit-product-order'} onChange={(e) => {

                                                   let preProduct = {...productListPre};
                                                   if (parseInt(e.target.value) >= 0) {
                                                       preProduct[id] = parseInt(e.target.value);
                                                   } else {
                                                       delete preProduct[id];
                                                   }
                                                   setProductListPre(preProduct);


                                               }} disabled={loadingCreate}
                                                            value={id in productListPre ? productListPre[id] : ''}
                                                            type={'number'}
                                                            label={'Количество'}/>
                                           </div>)
                                       }}
                                />
                            </div>
                        </div>)}
                    </div>
                </Modal>


                <Modal
                    isOpen={modalProductServ}
                    onRequestClose={() => {

                        setModalProductServ(false);

                    }}
                    contentLabel="Example Modal"
                    className="model-add-box"
                >
                    <div className="edit-table edit-table-model">

                        <div className="edit-table__head">
                            <span className="text text-s22">Выбрать товары из каталога</span>
                            <div className="modal-add-btns">
                                <Btn onClick={() => {
                                    setPageModel(0)
                                }} className={'btn-def-table text text-s14 ' + (pageModel == 0 && 'active')}
                                     label={'Каталог'}/>
                                <Btn onClick={() => {
                                    setPageModel(1)
                                }} className={'btn-def-table text text-s14 ' + (pageModel == 1 && 'active')}
                                     label={'Комплекты'}/>
                            </div>


                            <div className="modal-add-btns">
                                <Btn className={'btn-def-table btn-def-table-clear text text-s14'} onClick={() => {
                                    if (!loadingCreate) {
                                        setProductListPreServ({});
                                        setCatalogId('')
                                        setSelectSklad('1');
                                        setModalProductServ(false);
                                    }
                                }} load={loadingCreate} label={'закрыть'}/>
                                <Btn onClick={async () => {

                                    if (!loadingCreate) {
                                        setLoadingCreate(true);

                                        if (pageModel == 0) {

                                            if (Object.keys(errorList).length > 0 ? confirm('Вы пытаетесь добавить больше чем есть в наличии, вы уверенны?') : true) {

                                                NotificationManager.info('Ожидайте', 'добавление товаров');


                                                const preList = Object.entries(productListPreServ[servingId]);

                                                let saveUpdate = {'products': []};

                                                for (let iFor = 0; iFor < preList.length; iFor++) {

                                                    const preListProduct = Object.entries(preList[iFor][1]);
                                                    for (let iForProduct = 0; iForProduct < preListProduct.length; iForProduct++) {


                                                        if (((preListProduct[iForProduct][1][2]['store'] - preListProduct[iForProduct][1][1]) < 0) ?
                                                            confirm('Вы пытаетесь добавить больше чем есть в наличии, вы уверены? ' + preListProduct[iForProduct][1][3] + "\nТовар : " + preListProduct[iForProduct][1][2]['name']) : true
                                                        ) {

                                                            saveUpdate['products'].push({
                                                                'save': {
                                                                    "count": preListProduct[iForProduct][1][1],
                                                                    "sklad_id": preList[iFor][0],
                                                                    "order_id": params.id,
                                                                    "status": 'new',
                                                                    "type": 'rent',
                                                                    "table": 'order',
                                                                    "order_serving_id": servingId,
                                                                    "product_id": preListProduct[iForProduct][0],
                                                                },
                                                                'id': preListProduct[iForProduct][1][0],
                                                                'model_name': 'OrderProduct',
                                                            });
                                                        }


                                                    }
                                                }


                                                const updateDataOrder = (await updateDBOrderProduct(saveUpdate, false));


                                                await getOrderSend().then();
                                                setProductListPreServ({});

                                                NotificationManager.success('можете закрыть, форму с продуктами', 'Товары добавлены');
                                            }

                                        } else {


                                            let productPack = [...colTableProductComplit].filter((packList) => {
                                                return packList['id'] in productListPreServ;
                                            });

                                            // orderProduct['Склад Алматы']


                                            let save_new = {};


                                            productPack.forEach((packPro) => {

                                                if (isJson(packPro.product_list) && packPro.product_list != null) {

                                                    let productjson = JSON.parse(packPro.product_list);

                                                    Object.keys(productjson).forEach((valm, index) => {
                                                        const val = parseInt(productjson[valm]);
                                                        const key = valm;
                                                        if (val > 0) {
                                                            save_new[key] = ((key in save_new ? val + save_new[key] : val) * productListPreServ[packPro['id']]);
                                                        }
                                                    });

                                                }

                                            });


                                            Object.entries(save_new).forEach(([idSaa, countSaa]) => {


                                                if (servingId in orderProductServ && 'Склад Алматы' in orderProductServ[servingId] && idSaa in orderProductServ[servingId]['Склад Алматы']) {

                                                    const updateDataOrder = (updateDB({
                                                        'save': {
                                                            "count": countSaa,
                                                        },
                                                        'id': orderProductServ[servingId]['Склад Алматы'][idSaa][0]['id'],
                                                        'model_name': 'OrderProduct',
                                                    }, false));

                                                } else {
                                                    const updateDataOrder = (updateDB({
                                                        'save': {
                                                            "count": countSaa,
                                                            "sklad_id": '1',
                                                            "order_id": params.id,
                                                            "status": 'new',
                                                            "type": 'rent',
                                                            "table": 'order',
                                                            "order_serving_id": servingId,
                                                            "product_id": idSaa,
                                                        },
                                                        'id': 0,
                                                        'model_name': 'OrderProduct',
                                                    }, false));
                                                }
                                            });


                                            NotificationManager.success('можете закрыть, форму с продуктами', 'Товары добавлены');
                                            await getOrderSend().then();
                                            setProductListPreServ({});

                                        }
                                        setLoadingCreate(false);
                                    }
                                }} className={'btn-def-table text text-s14'} load={loadingCreate}
                                     label={(pageModel == 0 ? 'Добавить товар' : 'Добавить комплекты')}/>
                            </div>
                        </div>

                        {pageModel == 0 && (<div className="catalog_order">
                            <div style={{width: "100%"}} className={'pagination-box text text-s18'}>
                                <div style={{marginRight: "auto", display: "flex"}}>
                                    <div style={{width: "30rem"}}>
                                        <CustomInput value={search} onChange={(e) => {
                                            setSearch(e.target.value);


                                            if (e.keyCode == 13) {
                                                setPage(0);
                                                setReset(!reset);
                                            }

                                        }} name={'search'}
                                                     img={require('../../assets/images/search-normal.png')}
                                                     label={'Поиск'}/>

                                    </div>
                                    {
                                        !selectHouseProduct && (
                                            <div style={{width: "30rem", marginLeft: "4rem"}}>
                                                <CustomSelect onChange={(e) => {
                                                    setCatalogId(e.target.value)
                                                    setPage(0);
                                                    setReset(!reset);
                                                }} options={groupVal} value={catalogId} label={'Все разделы'}/>
                                            </div>
                                        )
                                    }

                                    <div style={{width: "30rem", marginLeft: "4rem"}}>
                                        <CustomSelect onChange={(e) => {
                                            searchSklad(e);
                                        }} options={colSklad.reduce((acc, item) => {
                                            return {...acc, ...{[item['id']]: item["name"]}};
                                        }, {})} value={selectSklad} label={'Склады'}/>
                                    </div>
                                </div>
                                {colTableProduct.last_page > 1 && (
                                    <ReactPaginate
                                        nextLabel=">"
                                        previousLabel="<"
                                        onPageChange={(e) => {
                                            // setPage(1);
                                            setPage(e.selected);
                                            setReset(!reset);
                                        }}
                                        forcePage={page}
                                        pageCount={colTableProduct.last_page}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                )}
                            </div>
                            <div className="edit-table__body">

                                <Table th={rowTable} loading={sendTime} modalName={'Product'} productOption={true}
                                       tr={colTableProduct.data}
                                       option={(id, row) => {

                                           return (<div className={'table-options'}>
                                               <CustomInput className={'edit-product-order right-text-big'}
                                                            onChange={async (e) => {

                                                            }}
                                                            type={'text'}
                                                            label={'Примечание'}/>

                                               <CustomInput className={'edit-product-order'} onChange={async (e) => {

                                                   let preProduct = {...productListPreServ};

                                                   if (!(servingId in preProduct)) {
                                                       preProduct[servingId] = {};
                                                   }
                                                   if (!(selectSklad in preProduct[servingId])) {
                                                       preProduct[servingId][selectSklad] = {};
                                                   }


                                                   let storeObj = colSklad.reduce((acc, item) => {
                                                       return {...acc, ...{[item['id']]: item["name"]}};
                                                   }, {})


                                                   let idSaveBox = 0;
                                                   if (servingId in orderProductServ && storeObj[selectSklad] in orderProductServ[servingId] && row['id'] in orderProductServ[servingId][storeObj[selectSklad]]) {
                                                       idSaveBox = orderProductServ[servingId][storeObj[selectSklad]][row['id']][0]['id'];
                                                   }


                                                   if (parseInt(e.target.value) >= 0) {
                                                       let nameList = colSklad.reduce((acc, item) => {
                                                           return {...acc, ...{[item['id']]: item["name"]}};
                                                       }, {});


                                                       preProduct[servingId][selectSklad][id] = [idSaveBox, parseInt(e.target.value), row, nameList[selectSklad]];
                                                   } else {
                                                       preProduct[servingId][selectSklad][id] = 0;
                                                   }
                                                   setProductListPreServ(preProduct);


                                               }}
                                                            disabled={loadingCreate}
                                                            value={(servingId in productListPreServ && selectSklad in productListPreServ[servingId] && row['id'] in productListPreServ[servingId][selectSklad]) ? (productListPreServ[servingId][selectSklad][row['id']][1] >= 0 ? productListPreServ[servingId][selectSklad][row['id']][1] : '') : ((servingId in orderProductServ && sklad_list[selectSklad] in orderProductServ[servingId] && row['id'] in orderProductServ[servingId][sklad_list[selectSklad]]) ? orderProductServ[servingId][sklad_list[selectSklad]][row['id']][0]['count'] : '')}
                                                            type={'number'}
                                                            label={'Количество'}/>
                                           </div>)
                                       }}
                                />
                            </div>
                            <div className={'pagination-box text text-s18'}>
                                {colTableProduct.last_page > 1 && (
                                    <ReactPaginate
                                        nextLabel=">"
                                        previousLabel="<"
                                        onPageChange={(e) => {
                                            // setPage(1);
                                            setPage(e.selected);
                                            setReset(!reset);
                                        }}
                                        forcePage={page}
                                        pageCount={colTableProduct.last_page}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                )}
                            </div>
                        </div>)}

                        {pageModel == 1 && (<div className="catalog_order">
                            <div className="edit-table__body">
                                <Table th={rowTableComplit} tr={colTableProductComplit}
                                       option={(id, row) => {
                                           return (<div className={'table-options'}>
                                               <BtnIcon onClick={() => {
                                                   setOpenRow(row);
                                                   setModal(true);
                                               }} value={'список'}/>
                                               <span style={{display: "flex", marginRight: "1rem"}}></span>
                                               <CustomInput className={'edit-product-order'} onChange={(e) => {

                                                   let preProduct = {...productListPreServ};
                                                   if (parseInt(e.target.value) > 0) {
                                                       preProduct[id] = parseInt(e.target.value);
                                                   } else {
                                                       delete preProduct[id];
                                                   }
                                                   setProductListPreServ(preProduct);


                                               }}
                                                            disabled={loadingCreate}
                                                            value={id in productListPreServ ? productListPreServ[id] : ''}
                                                            type={'number'}
                                                            label={'Количество'}/>
                                           </div>)
                                       }}
                                />
                            </div>
                        </div>)}
                    </div>
                </Modal>


                {/*<p>*/}
                {/*    Общий за продукты {allPriceProduct} <br/>*/}
                {/*    Общий за напитаик {allPriceMenuFood} <br/>*/}
                {/*    Общий за еду {allPriceMenuDrinks} <br/>*/}
                {/*    Общий за персону {allPricePersonal} <br/>*/}
                {/*</p>*/}
                {
                    window.isRole("Order", 'edit') && countSteps == 0 && (
                        <div>
                            <div className="icoms-adv-box">
                                <div className="icon_item active">
                                    <div className="icon_item_title  ">
                                        <span className={'text text-s18'}> Общий итог</span>
                                    </div>
                                    <div className="icon_item_content">
                        <span
                            className={'text text-s30'}>{allInfoProduct?.price?.allPrice.toLocaleString()} тг</span>

                                    </div>
                                </div>


                                <div className="icon_item ">
                                    <div className="icon_item_title">
                                        <span className={'text text-s18'}> На персону</span>
                                    </div>
                                    <div className="icon_item_content">

                                      <span
                                          className={'text text-s30'}>{allInfoProduct?.price?.players.toLocaleString()} тг</span>


                                    </div>
                                </div>

                                <div className="icon_item ">
                                    <div className="icon_item_title">
                                        <span className={'text text-s18'}> Еда на персону в тнг</span>
                                    </div>
                                    <div className="icon_item_content">
                                    <span
                                        className={'text text-s30'}> {allInfoProduct?.price?.menuPriceFood.toLocaleString()} тг</span>
                                    </div>
                                </div>

                                <div className="icon_item ">
                                    <div className="icon_item_title">
                                        <span className={'text text-s18'}> Напитки на персону в тнг</span>
                                    </div>
                                    <div className="icon_item_content">
                                   <span
                                       className={'text text-s30'}>   {allInfoProduct?.price?.menuPriceNoFood.toLocaleString()} тг</span>
                                    </div>
                                </div>

                                <div className="icon_item ">
                                    <div className="icon_item_title">
                                        <span className={'text text-s18'}> Напитки на персону в мл</span>
                                    </div>
                                    <div className="icon_item_content">
                                    <span
                                        className={'text text-s30'}>  {allInfoProduct?.price?.menuGramNoFood.toLocaleString()} мл</span>
                                    </div>
                                </div>

                                <div className="icon_item ">
                                    <div className="icon_item_title">
                                        <span className={'text text-s18'}> Еда на персону в гр</span>
                                    </div>
                                    <div className="icon_item_content">
                                    <span
                                        className={'text text-s30'}>  {allInfoProduct?.price?.menuGramFood.toLocaleString()} гр</span>
                                    </div>
                                </div>
                            </div>
                            <div className="icoms-adv-box" style={{marginTop: "2rem"}}>
                                <div className="icon_item ">
                                    <div className="icon_item_title">
                                        <span className={'text text-s18'}>Количество закусок на персону</span>
                                    </div>
                                    <div className="icon_item_content">
                                    <span
                                        className={'text text-s30'}>  {allInfoProduct?.price?.menuCountFood.toLocaleString()} шт</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }


                <Modal
                    isOpen={modalMenu}
                    onRequestClose={() => {
                        setModalMenu(false);
                    }}
                    contentLabel="Example Modal"
                    className="model-add-box"
                >
                    <div className="edit-table edit-table-model">

                        <div className="edit-table__head">
                            <span className="text text-s22">Создать меню</span>

                            <div className="modal-add-btns">
                                <Btn onClick={() => {
                                    setPageModel(0)
                                }} className={'btn-def-table text text-s14 ' + (pageModel == 0 && 'active')}
                                     label={'Каталог'}/>

                            </div>

                            <div className="modal-add-btns">
                                <Btn className={'btn-def-table btn-def-table-clear text text-s14'} onClick={() => {
                                    if (!loadingCreate) {
                                        setModalMenu(false);
                                        setCatalogId('');
                                        setSelectSklad('1');
                                    }
                                }} label={'закрыть'}/>
                                <Btn onClick={async () => {
                                    if (!loadingCreate) {
                                        setLoadingCreate(true);

                                        NotificationManager.info('Ожидайте', 'добавление товаров');

                                        const preList = Object.entries(menuListPreServ);

                                        let saveUpdate = {'products': []};

                                        for (let iFor = 0; iFor < preList.length; iFor++) {

                                            const preListProduct = Object.entries(preList[iFor][1]);
                                            for (let iForProduct = 0; iForProduct < preListProduct.length; iForProduct++) {


                                                let addInfoProduct = {
                                                    "order_id": params.id,
                                                    "menu_type_id": preList[iFor][0],
                                                    "status": 'new',
                                                    "type": 'rent',
                                                    "table": 'order',
                                                    "menu_id": preListProduct[iForProduct][0],
                                                };
                                                if (countSteps == 0) {
                                                    addInfoProduct['count'] = preListProduct[iForProduct][1][1];
                                                } else {
                                                    if (preListProduct[iForProduct][1][0] == 0) {
                                                        addInfoProduct['count'] = 0;
                                                    }
                                                    addInfoProduct['count_dop'] = preListProduct[iForProduct][1][1];
                                                }

                                                saveUpdate['products'].push({
                                                    'save': addInfoProduct,
                                                    'id': preListProduct[iForProduct][1][0],
                                                    'model_name': 'OrderProduct',
                                                });


                                            }
                                        }


                                        const updateDataOrder = (await updateDBOrderMenu(saveUpdate, false));


                                        await getOrderSend().then();
                                        setMenuListPreServ({});

                                        NotificationManager.success('можете закрыть, форму с продуктами', 'Товары добавлены');
                                        setLoadingCreate(false);
                                    }
                                }} className={'btn-def-table text text-s14'} load={loadingCreate}
                                     label={(pageModel == 0 ? 'Добавить позицию' : 'Добавить комплекты')}/>
                            </div>
                        </div>

                        {pageModel == 0 && (<div className="catalog_order">
                            <div style={{width: "100%"}} className={'pagination-box text text-s18'}>
                                <div style={{marginRight: "auto", display: "flex"}}>
                                    <div style={{width: "30rem", marginRight: "2rem"}}>
                                        <CustomInput value={search} onChange={(e) => {
                                            setSearch(e.target.value);
                                            if (e.keyCode == 13) {
                                                setPage(0);
                                                setReset(!reset);
                                            }
                                        }} name={'search'}
                                                     img={require('../../assets/images/search-normal.png')}
                                                     label={'Поиск'}/>
                                    </div>
                                    {' '}
                                    <CustomSelect onChange={async (e) => {
                                        setCatalogIdMenu(e.target.value)
                                        setPage(0);

                                        let product_menu_all = (await getModal('ProductMenu', '?browse=1&catalog_name=' + e.target.value + '&search=' + (search))).data;
                                        setProductMenu(product_menu_all);

                                    }} options={catalogMenuList.reduce((acc, item) => {
                                        return {...acc, ...{[item]: item}};
                                    }, {})} value={catalogIdMenu} label={'Все разделы'}/>
                                </div>
                                {productMenu.last_page > 1 && (
                                    <ReactPaginate
                                        nextLabel=">"
                                        previousLabel="<"
                                        onPageChange={(e) => {
                                            // setPage(1);
                                            setPage(e.selected);
                                            setReset(!reset);
                                        }}
                                        forcePage={page}
                                        pageCount={productMenu.last_page}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                )}
                            </div>
                            <div className="edit-table__body">

                                <Table th={productMenuRow} loading={sendTime} productOption={true}
                                       modalName={'ProductMenu'} tr={productMenu.data}
                                       option={(id, row) => {

                                           return (<div className={'table-options'}>
                                               <CustomInput className={'edit-product-order'} onChange={async (e) => {
                                                   let preProduct = {...menuListPreServ};
                                                   //
                                                   //
                                                   let idSaveBox = 0;
                                                   if (menuId in orderMenuServ && row['id'] in orderMenuServ[menuId]) {
                                                       idSaveBox = orderMenuServ[menuId][row['id']][0]['id'];
                                                   }

                                                   if (!(menuId in preProduct)) {
                                                       preProduct[menuId] = {};
                                                   }

                                                   if (parseInt(e.target.value) > 0) {
                                                       preProduct[menuId][id] = [idSaveBox, parseInt(e.target.value), row, ''];
                                                   } else {
                                                       preProduct[menuId][id] = [idSaveBox, 0, row, ''];
                                                   }
                                                   setMenuListPreServ(preProduct);


                                               }}


                                                            value={(menuId in menuListPreServ && row['id'] in menuListPreServ[menuId]) ? menuListPreServ[menuId][row['id']][1] : ((menuId in orderMenuServ && row['id'] in orderMenuServ[menuId]) ? orderMenuServ[menuId][row['id']][0][countSteps == 0 ? 'count' : 'count_dop'] : '')}
                                                            disabled={loadingCreate}
                                                            type={'number'}
                                                            label={'Количество'}/>
                                           </div>)
                                       }}
                                />
                            </div>
                            <div className={'pagination-box text text-s18'}>
                                {productMenu.last_page > 1 && (
                                    <ReactPaginate
                                        nextLabel=">"
                                        previousLabel="<"
                                        onPageChange={(e) => {
                                            // setPage(1);
                                            setPage(e.selected);
                                            setReset(!reset);
                                        }}
                                        forcePage={page}
                                        pageCount={productMenu.last_page}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                )}
                            </div>
                        </div>)}

                        {pageModel == 1 && (<div className="catalog_order">
                            <div className="edit-table__body">
                                <Table th={rowTableComplit} tr={colTableProductComplitMenu}
                                       option={(id, row) => {
                                           return (<div className={'table-options'}>
                                               <BtnIcon onClick={() => {
                                                   setOpenRow(row);
                                                   setModal(true);
                                               }} value={'список'}/>
                                               <span style={{display: "flex", marginRight: "1rem"}}></span>
                                               <CustomInput className={'edit-product-order'} onChange={(e) => {

                                                   let preProduct = {...productListPre};
                                                   if (parseInt(e.target.value) >= 0) {
                                                       preProduct[id] = parseInt(e.target.value);
                                                   } else {
                                                       delete preProduct[id];
                                                   }
                                                   setProductListPre(preProduct);


                                               }} disabled={loadingCreate}
                                                            value={id in productListPre ? productListPre[id] : ''}
                                                            type={'number'}
                                                            label={'Количество'}/>
                                           </div>)
                                       }}
                                />
                            </div>
                        </div>)}
                    </div>
                </Modal>


                <Modal
                    isOpen={modalMenuComplect}
                    onRequestClose={() => {
                        setModalMenuComplect(false);
                    }}
                    contentLabel="Example Modal"
                    className="model-add-box"
                >
                    <div className="edit-table edit-table-model">

                        <div className="edit-table__head">
                            <span className="text text-s22">Добавить шаблон в меню</span>


                            <div className="modal-add-btns">
                                <Btn className={'btn-def-table btn-def-table-clear text text-s14'} onClick={() => {
                                    if (!loadingCreate) {
                                        setModalMenuComplect(false);
                                        setCatalogId('');
                                        setSelectSklad('1');
                                    }
                                }} label={'закрыть'}/>
                                <Btn onClick={async () => {
                                    if (!loadingCreate) {

                                        setLoadingCreate(true);
                                        NotificationManager.info('Ожидайте', 'добавление товаров');
                                        let pushUpdate = [];


                                        let rowColuReturn = colTableProductComplitMenu.filter((item) => {
                                            return Object.keys(productListPre).includes('' + item.id);
                                        });

                                        let returnUpdateDbBox = [];


                                        rowColuReturn.forEach((rowColuReturnReturn) => {


                                            let returnUpdateDb = [];
                                            rowColuReturnReturn.product_list_array.forEach((rowColuReturnReturnItem) => {


                                                let childProduct = [];
                                                rowColuReturnReturnItem.child.forEach((rowColuReturnReturnItemBox) => {

                                                    childProduct.push({
                                                        id: rowColuReturnReturnItemBox.id,
                                                        count: rowColuReturnReturnItemBox.count,
                                                    });

                                                });


                                                returnUpdateDb.push({
                                                    "name": rowColuReturnReturnItem.name,
                                                    "child": childProduct,
                                                })


                                            });


                                            returnUpdateDbBox = [...returnUpdateDbBox, ...returnUpdateDb];

                                        });


                                        for (let ibox = 0; ibox < returnUpdateDbBox.length; ibox++) {


                                            let saveControls = await (updateDB({
                                                'save': {
                                                    "name": returnUpdateDbBox[ibox].name,
                                                },
                                                'id': 0,
                                                'model_name': 'OrderMenuType',
                                            }, false));


                                            returnUpdateDbBox[ibox].child.forEach((controlBcount) => {

                                                (updateDB({
                                                    'save': {
                                                        "order_id": params.id,
                                                        "menu_type_id": saveControls.data[0]['id'],
                                                        "status": 'new',
                                                        "type": 'rent',
                                                        "table": 'order',
                                                        "count": controlBcount.count,
                                                        "menu_id": controlBcount.id,
                                                    },
                                                    'id': 0,
                                                    'model_name': 'OrderProduct',
                                                }, false));

                                            });


                                        }

                                        NotificationManager.success('можете закрыть, форму с продуктами', 'Товары добавлены');
                                        setLoadingCreate(false);
                                        getOrderSend().then();

                                    }
                                }} className={'btn-def-table text text-s14'} load={loadingCreate}
                                     label={'Добавить комплекты'}/>
                            </div>
                        </div>


                        <div className="catalog_order">
                            <div className="edit-table__body">
                                <Table th={rowTableComplit} tr={colTableProductComplitMenu}
                                       option={(id, row) => {
                                           return (<div className={'table-options'}>
                                               <BtnIcon onClick={() => {

                                                   setOpenRowMenu(row);
                                                   setModalPackMenu(true);

                                               }} value={'список'}/>
                                               <span style={{display: "flex", marginRight: "1rem"}}></span>
                                               <CustomInput className={'edit-product-order'} onChange={(e) => {


                                                   let preProduct = {...productListPre};
                                                   if (parseInt(e.target.value) >= 0) {
                                                       preProduct[id] = parseInt(e.target.value);
                                                   } else {
                                                       delete preProduct[id];
                                                   }
                                                   setProductListPre(preProduct);


                                               }} disabled={loadingCreate}
                                                            value={id in productListPre ? productListPre[id] : ''}
                                                            type={'number'}
                                                            label={'Количество'}/>
                                           </div>)
                                       }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>


                <Modal
                    isOpen={modalPersonal}
                    onRequestClose={() => {
                        setModalPersonal(false);
                    }}
                    contentLabel="Example Modal"
                    className="model-add-box"
                >
                    <div className="edit-table edit-table-model">

                        <div className="edit-table__head">
                            <span className="text text-s22">Выберите персонал</span>


                            <div className="modal-add-btns">
                                <Btn className={'btn-def-table btn-def-table-clear text text-s14'} onClick={() => {
                                    if (!loadingCreate) {
                                        setModalPersonal(false);
                                        setCatalogId('');
                                        setSelectSklad('1');
                                    }

                                }} load={loadingCreate} label={'закрыть'}/>
                                <Btn onClick={async () => {

                                    if (!loadingCreate) {
                                        setLoadingCreate(true);

                                        NotificationManager.info('Ожидайте', 'добавление товаров');

                                        const preList = Object.entries(personalListPre);

                                        let saveUpdate = {'products': []};

                                        for (let iFor = 0; iFor < preList.length; iFor++) {

                                            const preListProduct = Object.entries(preList[iFor][1]);
                                            for (let iForProduct = 0; iForProduct < preListProduct.length; iForProduct++) {


                                                let addInfoProduct = {
                                                    "order_id": params.id,
                                                    "status": 'new',
                                                    "type": 'rent',
                                                    "table": 'order',
                                                    "personal_id": preListProduct[iForProduct][0],
                                                };
                                                if (countSteps == 0) {
                                                    addInfoProduct['count'] = preListProduct[iForProduct][1][1];
                                                } else {
                                                    if (preListProduct[iForProduct][1][0] == 0) {
                                                        addInfoProduct['count'] = 0;
                                                    }
                                                    addInfoProduct['count_dop'] = preListProduct[iForProduct][1][1];
                                                }

                                                saveUpdate['products'].push({
                                                    'save': addInfoProduct,
                                                    'id': preListProduct[iForProduct][1][0],
                                                    'model_name': 'OrderProduct',
                                                });


                                            }
                                        }

                                        const updateDataOrder = (await updateDBOrderPersonal(saveUpdate, false));

                                        await getOrderSend().then();
                                        NotificationManager.success('можете закрыть, форму с продуктами', 'Товары добавлены');
                                        setPersonalListPre({'': {}});

                                        setLoadingCreate(false);
                                    }
                                }} className={'btn-def-table text text-s14'} load={loadingCreate}
                                     label={'Добавить персонал'}/>
                            </div>
                        </div>

                        {(<div className="catalog_order">
                            <div style={{width: "100%"}} className={'pagination-box text text-s18'}>
                                <div style={{marginRight: "auto", display: "flex"}}>
                                    <div style={{width: "30rem"}}>
                                        <CustomInput value={search} onChange={(e) => {
                                            setSearch(e.target.value);

                                            if (e.keyCode == 13) {
                                                setPage(0);
                                                setReset(!reset);
                                            }
                                        }} name={'search'}
                                                     img={require('../../assets/images/search-normal.png')}
                                                     label={'Поиск'}/>
                                    </div>
                                </div>

                            </div>
                            <div className="edit-table__body">

                                <Table th={rowPersonal} loading={sendTime} productOption={true}
                                       modalName={'ProductMenu'} tr={listPersonal.data}
                                       option={(id, row) => {


                                           return (<div className={'table-options'}>
                                               <CustomInput className={'edit-product-order'} onChange={async (e) => {
                                                   let preProduct = {...personalListPre};


                                                   let idSaveBox = 0;
                                                   if (row['id'] in orderPersonal['']) {
                                                       idSaveBox = orderPersonal[''][row['id']][0]['id'];
                                                   }


                                                   if (parseInt(e.target.value) > 0) {
                                                       let nameList = colSklad.reduce((acc, item) => {
                                                           return {...acc, ...{[item['id']]: item["name"]}};
                                                       }, {});


                                                       preProduct[''][id] = [idSaveBox, parseInt(e.target.value), row, ''];
                                                   } else {
                                                       preProduct[''][id] = [idSaveBox, 0, row, ''];
                                                   }
                                                   setPersonalListPre(preProduct);


                                               }}

                                                            value={(row['id'] in personalListPre[''])
                                                                ?
                                                                (personalListPre[''][row['id']][1] > 0 ? personalListPre[''][row['id']][1] : '') :
                                                                ((row['id'] in orderPersonal['']) ? orderPersonal[''][row['id']][0][countSteps == 0 ? 'count' : 'count_dop'] : '')}
                                                            disabled={loadingCreate}
                                                            type={'number'}
                                                            label={'Количество'}/>
                                           </div>)
                                       }}
                                />
                            </div>
                        </div>)}

                        {pageModel == 1 && (<div className="catalog_order">
                            <div className="edit-table__body">
                                <Table th={rowTableComplit} tr={colTableProductComplit}
                                       option={(id, row) => {
                                           return (<div className={'table-options'}>
                                               <BtnIcon onClick={() => {
                                                   setOpenRow(row);
                                                   setModal(true);
                                               }} value={'список'}/>
                                               <span style={{display: "flex", marginRight: "1rem"}}></span>
                                               <CustomInput className={'edit-product-order'} onChange={(e) => {

                                                   let preProduct = {...productListPre};
                                                   if (parseInt(e.target.value) > 0) {
                                                       preProduct[id] = parseInt(e.target.value);
                                                   } else {
                                                       delete preProduct[id];
                                                   }
                                                   setProductListPre(preProduct);


                                               }} disabled={loadingCreate}
                                                            value={id in productListPre ? productListPre[id] : ''}
                                                            type={'number'}
                                                            label={'Количество'}/>
                                           </div>)
                                       }}
                                />
                            </div>
                        </div>)}
                    </div>
                </Modal>

                <Modal
                    isOpen={modalNode}
                    onRequestClose={() => {
                        setModalNode(false);
                    }}
                    contentLabel="Example Modal"
                    className="model-add-box model-add-box-center"
                >
                    <div className="edit-table edit-table-model edit-table-model-center">

                        <div className="edit-table__head edit-table__head-fix">
                            <span className="text text-s18">Оставить примечание</span>

                            <Btn className={'btn-def-table me-left btn-def-table-clear text text-s14'}
                                 onClick={async () => {
                                     setModalNode(false);
                                     setCatalogId('')
                                     setSelectSklad('1');
                                 }} label={'закрыть'}/>

                            <Btn load={sendNode} className={'btn-def-table text text-s14'} onClick={async () => {
                                if (!sendNode) {
                                    setSendNode(true);

                                    const updateDataOrder = (await updateDB({
                                        'save': {
                                            user_id: state_in.authUser.id,
                                            product_id: productId,
                                            order_id: params.id,
                                            message: messageNode + '\n' + storeNameSave,
                                        }, 'id': 0, 'model_name': 'NodeMessage',
                                    })).data[0];
                                    setMessageNode("");
                                    setModalNode(false);
                                    setSendNode(false);
                                }

                            }} label={'Добавить'}/>
                        </div>

                        <div className="edit-table__body edit-table__body-fix">
            <textarea onKeyUp={(e) => {
                setMessageNode(e.target.value);
            }} className={'text text-s18'} placeholder={'Примечание'}>{messageNode}</textarea>
                        </div>
                    </div>
                </Modal>


                <Modal
                    isOpen={openEditId}
                    onRequestClose={() => {
                        setOpenEditId(false);
                    }}
                    contentLabel="Example Modal"
                    className="model-add-box model-add-box-center"
                >
                    <div className="edit-table edit-table-model edit-table-model-center">

                        <div className="edit-table__head edit-table__head-fix">
                            <span className="text text-s18">Оставить примечание</span>

                            <Btn className={'btn-def-table me-left btn-def-table-clear text text-s14'}
                                 onClick={async () => {
                                     setModalNode(false);
                                     setCatalogId('')
                                     setSelectSklad('1');
                                 }} label={'закрыть'}/>

                            <Btn load={sendNode} className={'btn-def-table text text-s14'} onClick={async () => {
                                if (!sendNode) {
                                    setSendNode(true);

                                    const updateDataOrder = (await updateDB({
                                        'save': {
                                            user_id: state_in.authUser.id,
                                            product_id: productId,
                                            order_id: params.id,
                                            message: messageNode + '\n' + storeNameSave,
                                        }, 'id': 0, 'model_name': 'NodeMessage',
                                    })).data[0];
                                    setMessageNode("");
                                    setModalNode(false);
                                    setSendNode(false);
                                }

                            }} label={'Добавить'}/>
                        </div>

                        <div className="edit-table__body edit-table__body-fix">
            <textarea onKeyUp={(e) => {
                setMessageNode(e.target.value);
            }} className={'text text-s18'} placeholder={'Примечание'}>{messageNode}</textarea>
                        </div>
                    </div>
                </Modal>


                <Modal
                    isOpen={modelOpenId}
                    onRequestClose={() => {
                        setModelOpenId(false);
                    }}
                    contentLabel="Example Modal"
                    className="model-add-box model-add-box-vig model-add-box-center"
                >
                    <div className="edit-table edit-table-model edit-table-model-center">

                        <div className="edit-table__head edit-table__head-fix">
                            <div className="row-flex">
                                <span className="text text-s14">В общем было в аренде: {countProductOpen}</span>
                                {/*<span*/}
                                {/*    className="text text-s14">Остаток в аренде: {countProductOpen - (([...rowsDb].map((typpp) => {*/}
                                {/*    let countDb = parseInt(((modelOpenId in productIn && typpp[1] in productIn[modelOpenId]) ? productIn[modelOpenId][typpp[1]]['count'] : 0));*/}
                                {/*    if (countDb > 0) {*/}
                                {/*        return countDb;*/}
                                {/*    }*/}
                                {/*    return 0;*/}
                                {/*})).reduce((ass, item) => {*/}
                                {/*    return ass + item;*/}
                                {/*}, 0))}</span>*/}
                            </div>
                            <Btn className={'btn-def-table me-left btn-def-table-clear text text-s14'}
                                 onClick={async () => {
                                     setProductIn({});
                                     setCatalogId('')
                                     setSelectSklad('1');
                                     setModelOpenId(false);
                                 }} label={'закрыть'}/>

                            <Btn load={sendNode} className={'btn-def-table text text-s14'} onClick={async () => {
                                let saveProductInPre = {...productIn};
                                setProductIn({});
                                const updateDataOrder = (await updateDB({
                                    'save': saveProductInPre,
                                    'id': clickOrder['pre']['id'],
                                    'model_name': 'OrderProduct',
                                }, false));
                                getOrderSend().then();
                                setModelOpenId(false);
                            }} label={'Применить'}/>
                        </div>
                        <div className="edit-table__body edit-table__body-fix">
                            <div className="control_rep control_rep-el-list">
                                {rowsDb.map((typpp) => {

                                    // setStoreNameSave(storeName);
                                    // setClickOrder(row);


                                    if (typeSelect == '') {
                                        return (<></>);
                                    }

                                    let reutnName = '';

                                    if ((typeSelect == 'product_store' && typpp[1] == 'return')) {
                                        reutnName = 'Демонтаж';
                                    }
                                    if ((typeSelect == 'product_manager' && typpp[1] == 'return')) {
                                        reutnName = 'Получен';
                                    }

                                    if ((typeSelect == 'product_received' && typpp[1] == 'return')) {
                                        reutnName = 'Отправлен';
                                    }

                                    return (<div className="item-demontash">

                                        {/*<CustomInput className={'btn-color-orange'} placeholder_active={true} disabled={true} value={''} name={''}*/}
                                        {/*             type={'text'}*/}
                                        {/*             label={reutnName != '' ? reutnName : typpp[0]}/>*/}

                                        <div
                                            className={`ci-input-custom-box btn-color-orange`}>
                                            <div
                                                className={'text  text-s10  ci-placeholder-edit '}>&nbsp;</div>
                                            <div className="ci-input-custom  ci-input-edit element-org"
                                                 style={{color: '#000'}}>
                                                {
                                                    reutnName != '' ? reutnName : typpp[0]
                                                }
                                            </div>
                                        </div>

                                        <CustomInput onChange={(e, name) => {

                                            setProductIn({
                                                ...productIn, ...{
                                                    [typeSelect + '_' + typpp[1]]: e.target.value
                                                }
                                            });

                                        }}
                                                     value={!(typeSelect + '_' + typpp[1] in productIn) ? (clickOrder['pre'][typeSelect + '_' + typpp[1]]) : productIn[typeSelect + '_' + typpp[1]]}
                                                     name={typeSelect + '_' + typpp[1]}
                                                     type={'number'}
                                                     label={'Количество'}/>

                                        <CustomInput onChange={(e, name) => {

                                            setProductIn({
                                                ...productIn, ...{
                                                    [typeSelect + '_' + typpp[1] + '_commit']: e.target.value
                                                }
                                            });

                                        }}
                                                     value={!(typeSelect + '_' + typpp[1] + '_commit' in productIn) ? (clickOrder['pre'][typeSelect + '_' + typpp[1] + '_commit']) : productIn[typeSelect + '_' + typpp[1] + '_commit']}
                                                     name={''}
                                                     type={'text'}
                                                     label={'Примечание'}/>


                                        {(typpp[1] == 'down' || typpp[1] == 'repit') ? (<CustomFile onChange={(e) => {

                                            setProductIn({
                                                ...productIn, ...{
                                                    [typeSelect + '_' + typpp[1] + '_photo']: e.target.value
                                                }
                                            });

                                        }}
                                                                                                    value={!(typeSelect + '_' + typpp[1] + '_photo' in productIn) ? (clickOrder['pre'][typeSelect + '_' + typpp[1] + '_photo']) : productIn[typeSelect + '_' + typpp[1] + '_photo']}

                                                                                                    name={typeSelect + '_' + typpp[1] + '_photo'}
                                                                                                    label={'Картинка'}/>) : (
                                            <div className={'fix-input-img'}></div>)}


                                    </div>)
                                })}
                            </div>
                        </div>


                    </div>
                </Modal>


                {
                    (
                        params.id == 0 ? (<div className={'info_table text text-s18'}>
                                Чтобы добавить товары, заполните информацию о заказе выше и нажмине кнопку
                                сохранить
                            </div>) :
                            <div className={'list-item-control'}>

                                {/*Оборудование Начало*/}
                                {
                                    is_obrv && (window.isRole("Order", 'edit') ? true : (allInfoProduct?.product?.products ? Object.entries(allInfoProduct?.product?.products).length > 0 : false)) && (
                                        <div style={{
                                            marginTop: "8rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                            <span onClick={() => {
                                setPageOpen(!pageOpen);
                            }} style={{display: "flex", alignItems: "center"}}>
                               <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                    src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/> Оборудование:
                            </span>


                                                {
                                                    window.isRole("Order", 'edit') && (
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                            {
                                                                window.isRole("Order", 'edit') && (
                                                                    <Btn onClick={async () => {
                                                                        setNameComplect('');
                                                                        setCreateModal(true);
                                                                    }} className={'btn-def-table btn-def-table-w20  text text-s14'}
                                                                         load={openProduct}
                                                                         label={'Создать комплект'}/>
                                                                )
                                                            }

                                                            <Btn onClick={async () => {
                                                                setPage(0);
                                                                setPageModel(0)
                                                                setOpenProduct(true);
                                                                setSelectHouseProduct(false);
                                                                setSearch('');

                                                                await getProductModal(page, false);
                                                                setModalProduct(true);
                                                                setOpenProduct(false);


                                                            }} className={'btn-def-table btn-def-table-w20 active text text-s14'}

                                                                 load={openProduct}
                                                                 label={'Добавить оборудование'}/>

                                                        </div>
                                                    )
                                                }
                                            </p>

                                            {
                                                (window.isRole("Order", 'edit') ? pageOpen : true) && (
                                                    <div style={{maxWidth: "45rem", marginTop: "2rem"}}>
                                                        <CustomSelect onChange={(e) => {
                                                            // setCatalogSelect(e.target.value);
                                                            // setResetResult(!resetResult);
                                                            setPage(0);
                                                            setCatalogSelect(e.target.value);
                                                        }}
                                                                      options={catalogSelectItem} value={catalogSelect}
                                                                      label={'Все разделы'}/>


                                                        <div style={{width: "45rem"}}>
                                                            <CustomInput value={searchPage} onChange={(e) => {
                                                                setPage(0);
                                                                setSearchPage(e.target.value);
                                                            }} name={'search'}
                                                                         img={require('../../assets/images/search-normal.png')}
                                                                         label={'Поиск'}/>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                (window.isRole("Order", 'edit') ? pageOpen : true) && Object.entries(orderProduct).map(([storeName, productArray]) => {

                                                    let rowTableSave = {...rowTable};
                                                    delete (rowTableSave['catalog_id']);

                                                    let serius = Object.values(productArray).sort((a, b) => {
                                                        let catalogIdA = a[0]?.product?.catalog_id ? a[0]?.product?.catalog_id : 0;
                                                        let catalogIdB = b[0]?.product?.catalog_id?.catalog_id ? b[0]?.product?.catalog_id : 0;
                                                        if (catalogIdA < catalogIdB) {
                                                            return -1;
                                                        }
                                                        if (catalogIdA > catalogIdB) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    })

                                                    if ((storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары')) {
                                                        // if (countSteps == 0) {
                                                        //     return (<></>);
                                                        // }
                                                    }
                                                    delete (rowTableSave['store']);
                                                    delete (rowTableSave['store_sklad']);
                                                    delete (rowTableSave['option']);
                                                    delete (rowTableSave['count']);
                                                    delete (rowTableSave['price']);
                                                    // console.log(rowTableSave);
                                                    rowTableSave['count'] = {
                                                        name: 'Кол-во КП', className: "ci-table-th-88", value: "",
                                                    };


                                                    if (countSteps == 2 && window.isRole("Order", 'edit')) {
                                                        rowTableSave['count_dop'] = {
                                                            name: 'Доп. ед', className: "ci-table-th-88", value: "",
                                                        };
                                                        rowTableSave['count_dop_result'] = {
                                                            name: 'Общее кол-во', className: "ci-table-th-88", value: "",
                                                        };
                                                    } else if (!window.isRole("Order", 'edit')) {
                                                        rowTableSave['count'] = {
                                                            name: 'Кол-во', className: "ci-table-th-88", value: "",
                                                        };
                                                        delete rowTableSave['count_result'];
                                                        delete rowTableSave['price_arenda'];
                                                    }

                                                    if (window.isRole("Order", 'edit')) {

                                                        rowTableSave['count_result'] = {
                                                            name: 'Сумма', className: "ci-table-th-88", value: "",
                                                        };
                                                    }


                                                    rowTableSave['option'] = {
                                                        name: '', className: "ci-table-th-88 text-right-table", value: "",
                                                    };


                                                    return (
                                                        <div
                                                            style={{marginTop: 0}}
                                                            className={"edit-table " + ((storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары') && 'grenn-box')}>

                                        <span style={{marginTop: "2rem", display: "flex"}} className={'text text-s18'}>
                                            {
                                                storeName
                                            }
                                        </span>
                                                            <div className="edit-table__body">
                                                                <Table th={{
                                                                    ...(rowTableSave)
                                                                }}
                                                                       photoHidden={true}
                                                                       productOption={true}
                                                                       modalName={'Product'}

                                                                       tr={(Object.entries(serius).map(([productId, productArraySingle]) => {
                                                                           let productReturn = productArraySingle[0]['product'];
                                                                           if (productReturn == null) {
                                                                               return false;
                                                                           }
                                                                           productReturn['count'] = productArraySingle[0]['count'];
                                                                           productReturn['count_dop'] = productArraySingle[0]['count_dop'];
                                                                           productReturn['discont'] = productArraySingle[0]['discont'];
                                                                           productReturn['product_commit'] = productArraySingle[0]['product_commit'];
                                                                           productReturn['update_id'] = productArraySingle[0]['id'];
                                                                           productReturn['pre'] = productArraySingle[0];


                                                                           let countPlus = parseInt(productArraySingle[0]['count']);
                                                                           if (!(countPlus > 0)) {
                                                                               countPlus = 0;
                                                                           }

                                                                           let countDopPlus = parseInt(productArraySingle[0]['count_dop']);
                                                                           if (!(countDopPlus > 0)) {
                                                                               countDopPlus = 0;
                                                                           }

                                                                           productReturn['count_dop_result'] = countPlus + countDopPlus;


                                                                           productReturn['count_result'] = (productReturn['count'] * productReturn['price_arenda']);

                                                                           if (parseInt(productReturn['discont']) > 0) {
                                                                               productReturn['count_result'] = productReturn['count_result'] * (1 - (parseInt(productReturn['discont']) / 100));
                                                                           }

                                                                           if (!window.isRole("Order", 'edit')) {
                                                                               productReturn['count'] = productReturn['count_dop_result'];
                                                                           }
                                                                           return productReturn;
                                                                       }).filter((itsrx) => {

                                                                           let countBox = true;
                                                                           let searchInfo = true;

                                                                           let houseProduct = itsrx.catalog_id == '78' ? false : true;


                                                                           if (countSteps == 0) {
                                                                               countBox = itsrx?.count > 0
                                                                           }
                                                                           if (searchPage != '') {
                                                                               searchInfo = itsrx.name.toLowerCase().includes(searchPage.toLowerCase()) && houseProduct
                                                                           }


                                                                           if (catalogSelect) {
                                                                               return itsrx.catalog_id == catalogSelect && itsrx && countBox && searchInfo && houseProduct;
                                                                           }


                                                                           return itsrx && countBox && searchInfo && houseProduct;
                                                                       }))
                                                                       }
                                                                       option={(id, row) => {

                                                                           return (<div
                                                                               className={'table-options table-options-sm-flex'}>

                                                                               {
                                                                                   (storeName != 'Суб-аренда Decoretto' && storeName != 'Доп товары') && (
                                                                                       <div className={'fileControlTextBOx'}
                                                                                            style={{alignItems: "flex-start"}}>
                                                                                           {
                                                                                               window.isRole("Order", 'edit') && (
                                                                                                   <>
                                                                                                       <b>Остаток: {row['store']}</b> <br/>
                                                                                                       <b>В
                                                                                                           Аренде: {row['store_arenda']}</b><br/>
                                                                                                   </>
                                                                                               )
                                                                                           }
                                                                                           <b>Кол-во: {row['count']}</b>
                                                                                       </div>
                                                                                   )
                                                                               }
                                                                               {
                                                                                   (storeName == 'Доп товары') && (
                                                                                       <div style={{
                                                                                           width: "100%",
                                                                                           alignItems: "flex-start"
                                                                                       }}>
                                                                                           <b>Кол-во: {row['count']}</b>
                                                                                       </div>
                                                                                   )
                                                                               }

                                                                               {
                                                                                   (storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары') && (
                                                                                       <div style={{width: "7rem"}}>
                                                                                       </div>
                                                                                   )
                                                                               }


                                                                               <div className="edit-box text text-s14"
                                                                                    style={{marginRight: "3rem", width: "10rem"}}>
                                                                                   <b>{row['product_commit']}</b>
                                                                               </div>


                                                                               {
                                                                                   window.isRole("Order", 'post') && (
                                                                                       <>
                                                                                           <div className={'row-option-flex'}>
                                                                                               <BtnIcon onClick={async () => {
                                                                                                   if (window.isRole("Order", 'post')) {
                                                                                                       setStoreNameSave(storeName);
                                                                                                       setClickOrder(row);
                                                                                                       setTypeSelect('product_store');
                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                           'save': {
                                                                                                               "product_store_complit": (row['count'] == row['pre']['product_store_complit']) ? 0 : row['count'],
                                                                                                           },
                                                                                                           'id': row['update_id'],
                                                                                                           'model_name': 'OrderProduct',
                                                                                                       }, false));

                                                                                                       getOrderSend().then();
                                                                                                   }
                                                                                               }}
                                                                                                        className={'icon-box-sas ' + ((row['count'] == row['pre']['product_store_complit']) && 'active')}
                                                                                                        value={'Собран'}/>
                                                                                           </div>
                                                                                           <div className={'row-option-flex'}>


                                                                                               <BtnIcon onClick={async (e) => {
                                                                                                   if (window.isRole("Order", 'post')) {
                                                                                                       setStoreNameSave(storeName);
                                                                                                       setClickOrder(row);
                                                                                                       setTypeSelect('product_store');
                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                           'save': {
                                                                                                               "product_store_post": (row['count'] == row['pre']['product_store_post']) ? 0 : row['count'],
                                                                                                               "product_store_post_img": typeof e == 'string' ? e : '',
                                                                                                           },
                                                                                                           'id': row['update_id'],
                                                                                                           'model_name': 'OrderProduct',
                                                                                                       }, false));


                                                                                                       getOrderSend().then();
                                                                                                   }
                                                                                               }}
                                                                                                        file={true}
                                                                                                        fileImg={row['pre']['product_store_post_img']}
                                                                                                        file_controls={(row['count'] == row['pre']['product_store_post'])}
                                                                                                        className={'icon-box-sas ' + ((row['count'] == row['pre']['product_store_post']) && 'active')}
                                                                                                        value={'Выдан'}/>

                                                                                               {window.isRole("Order", 'note') && (
                                                                                                   <BtnIcon onClick={() => {
                                                                                                       setProductId(id);
                                                                                                       setStoreNameSave(storeName);
                                                                                                       setClickOrder(row);
                                                                                                       setModalNode(true);
                                                                                                   }} img={require('./message.png')}/>)}
                                                                                           </div>
                                                                                       </>
                                                                                   )
                                                                               }


                                                                               {

                                                                                   window.isRole("Order", 'get') && (
                                                                                       <>
                                                                                           <div className={'row-option-flex'}>
                                                                                               <BtnIcon onClick={async () => {
                                                                                                   setStoreNameSave(storeName);
                                                                                                   setClickOrder(row);
                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "product_manager_return": (row['count'] == row['pre']['product_manager_return']) ? 0 : row['count'],
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));

                                                                                                   getOrderSend().then();

                                                                                               }}
                                                                                                        className={'icon-box-sas ' + ((row['count'] == row['pre']['product_manager_return']) && 'active')}
                                                                                                        value={('Получен')}/>
                                                                                               <BtnIcon onClick={() => {
                                                                                                   setClickOrder(row);
                                                                                                   setStoreNameSave(storeName);

                                                                                                   setTypeSelect('product_manager');
                                                                                                   setCountProductOpen(row['count']);
                                                                                                   setModelOpenId(id);

                                                                                               }} img={require('./message.png')}/>
                                                                                           </div>

                                                                                           <div className={'row-option-flex'}>
                                                                                               <BtnIcon onClick={async () => {
                                                                                                   setClickOrder(row);
                                                                                                   setStoreNameSave(storeName);
                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "product_received_return": (row['count'] == row['pre']['product_received_return']) ? 0 : row['count'],
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));

                                                                                                   getOrderSend().then();

                                                                                               }}
                                                                                                        className={'icon-box-sas ' + ((row['count'] == row['pre']['product_received_return']) && 'active')}
                                                                                                        value={('Отправлен')}/>
                                                                                               <BtnIcon onClick={() => {
                                                                                                   setClickOrder(row);
                                                                                                   setStoreNameSave(storeName);
                                                                                                   setTypeSelect('product_received');
                                                                                                   setCountProductOpen(row['count']);
                                                                                                   setModelOpenId(id);

                                                                                               }} img={require('./message.png')}/>
                                                                                           </div>
                                                                                       </>
                                                                                   )
                                                                               }

                                                                               {
                                                                                   window.isRole("Order", 'post') && (
                                                                                       <div className={'row-option-flex'}>
                                                                                           <BtnIcon onClick={async () => {
                                                                                               setStoreNameSave(storeName);
                                                                                               setClickOrder(row);
                                                                                               const updateDataOrder = (await updateDB({
                                                                                                   'save': {
                                                                                                       "product_store_return": (row['count'] == row['pre']['product_store_return']) ? 0 : row['count'],
                                                                                                   },
                                                                                                   'id': row['update_id'],
                                                                                                   'model_name': 'OrderProduct',
                                                                                               }, false));
                                                                                               getOrderSend().then();
                                                                                           }}
                                                                                                    className={'icon-box-sas ' + ((row['count'] == row['pre']['product_store_return']) ? 'active' : (parseInt(row['pre']['product_store_return']) > 0 ? 'no_active_form' : ''))}
                                                                                                    value={("Демонтаж:" + (row['pre']['product_store_return'] == null ? 0 : row['pre']['product_store_return']))}/>

                                                                                           <BtnIcon onClick={() => {
                                                                                               setStoreNameSave(storeName);
                                                                                               setTypeSelect('product_store');
                                                                                               setClickOrder(row);
                                                                                               if (window.isRole("Order", 'post')) {
                                                                                                   setCountProductOpen(row['count']);
                                                                                                   setModelOpenId(id);
                                                                                               }
                                                                                           }} img={require('./message.png')}/>
                                                                                       </div>
                                                                                   )
                                                                               }


                                                                               {
                                                                                   window.isRole("Order", 'edit') && (
                                                                                       <>
                                                                                           <CustomInput
                                                                                               key={row['update_id']}
                                                                                               className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                               onChange={async (e) => {

                                                                                                   let countValue = parseInt(e.target.value);

                                                                                                   if (countValue <= 0 || e.target.value == '') {
                                                                                                       if (countSteps == 0) {
                                                                                                           countValue = 1;
                                                                                                       }
                                                                                                   }

                                                                                                   if (countSteps == 0) {

                                                                                                       async function senUpdateInfo(e, row) {
                                                                                                           const updateDataOrder = (await updateDB({
                                                                                                               'save': {
                                                                                                                   "count": countValue,
                                                                                                               },
                                                                                                               'id': row['update_id'],
                                                                                                               'model_name': 'OrderProduct',
                                                                                                           }, false));
                                                                                                           getOrderSend().then();
                                                                                                           calcultationSet();
                                                                                                       }

                                                                                                       clearTimeout(window.timeUpdateSend)

                                                                                                       window.timeUpdateSend = setTimeout(() => {
                                                                                                           senUpdateInfo(e, row);
                                                                                                       }, 1200);
                                                                                                       let preUpdate = {...orderProduct}
                                                                                                       orderProduct[storeName][row['id']][0]['count'] = countValue;
                                                                                                       setOrderProduct(preUpdate);

                                                                                                   } else {

                                                                                                       async function senUpdateInfo(e, row) {
                                                                                                           const updateDataOrder = (await updateDB({
                                                                                                               'save': {
                                                                                                                   "count_dop": countValue,
                                                                                                               },
                                                                                                               'id': row['update_id'],
                                                                                                               'model_name': 'OrderProduct',
                                                                                                           }, false));

                                                                                                           calcultationSet();
                                                                                                           // getOrderSend().then();
                                                                                                       }

                                                                                                       clearTimeout(window.timeUpdateSend)

                                                                                                       window.timeUpdateSend = setTimeout(() => {
                                                                                                           senUpdateInfo(e, row);
                                                                                                       }, 1200);
                                                                                                       let preUpdate = {...orderProduct}
                                                                                                       orderProduct[storeName][row['id']][0]['count_dop'] = countValue;
                                                                                                       setOrderProduct(preUpdate);

                                                                                                   }


                                                                                               }}
                                                                                               value={countSteps == 2 ? row['count_dop'] : row['count']}
                                                                                               type={'number'}
                                                                                               label={countSteps == 2 ? 'Доп. ед' : 'Кол-во'}/>


                                                                                           {
                                                                                               countSteps == 0 && (
                                                                                                   <CustomInput
                                                                                                       key={row['update_id'] + 1000000}
                                                                                                       className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                                       onChange={async (e) => {


                                                                                                           async function senUpdateInfo(e, row) {
                                                                                                               const updateDataOrder = (await updateDB({
                                                                                                                   'save': {
                                                                                                                       "discont": e.target.value,
                                                                                                                   },
                                                                                                                   'id': row['update_id'],
                                                                                                                   'model_name': 'OrderProduct',
                                                                                                               }, false));
                                                                                                           }

                                                                                                           clearTimeout(window.timeUpdateSend)

                                                                                                           window.timeUpdateSend = setTimeout(() => {
                                                                                                               senUpdateInfo(e, row);
                                                                                                           }, 1200);
                                                                                                           let preUpdate = {...orderProduct}
                                                                                                           orderProduct[storeName][row['id']][0]['discont'] = e.target.value;
                                                                                                           setOrderProduct(preUpdate);


                                                                                                       }} value={row['discont']}
                                                                                                       type={'number'}
                                                                                                       label={'Скидка'}/>
                                                                                               )
                                                                                           }


                                                                                           <CustomInput
                                                                                               key={row['update_id'] + 2000000}
                                                                                               onBlur={async (e) => {

                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "product_commit": e.target.value,
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));

                                                                                                   if (window?.timeSend) {
                                                                                                       clearTimeout(window.timeSend);
                                                                                                   }

                                                                                                   window.timeSend = setTimeout(async () => {
                                                                                                       const orderProductSave = (await getOrderProduct(params.id)).data;
                                                                                                       setOrderProduct(orderProductSave);
                                                                                                   }, 500);

                                                                                               }}
                                                                                               className={'edit-product-order edit-product-order-edit'}
                                                                                               onChange={async (e) => {

                                                                                                   async function senUpdateInfo(e, row) {
                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                           'save': {
                                                                                                               "product_commit": e.target.value,
                                                                                                           },
                                                                                                           'id': row['update_id'],
                                                                                                           'model_name': 'OrderProduct',
                                                                                                       }, false));
                                                                                                   }

                                                                                                   clearTimeout(window.timeUpdateSend)

                                                                                                   window.timeUpdateSend = setTimeout(() => {
                                                                                                       senUpdateInfo(e, row);
                                                                                                   }, 1000);
                                                                                                   let preUpdate = {...orderProduct}
                                                                                                   orderProduct[storeName][row['id']][0]['product_commit'] = e.target.value;
                                                                                                   setOrderProduct(preUpdate);


                                                                                               }} value={row['product_commit']}
                                                                                               type={'text'}
                                                                                               label={'Примечание'}/>


                                                                                           <BtnIcon onClick={async () => {

                                                                                               let preUpdate = {...orderProduct};

                                                                                               getOrderSend().then();
                                                                                           }}
                                                                                                    productValue={row}
                                                                                                    ahe={true}/>


                                                                                           <BtnIcon onClick={async () => {

                                                                                               let preUpdate = {...orderProduct};

                                                                                               if (countSteps == 0) {

                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "count": 0,
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));
                                                                                                   preUpdate[storeName][row['id']][0]['count'] = 0;
                                                                                                   setOrderProduct(preUpdate);

                                                                                               } else {

                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "count_dop": 0,
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));

                                                                                                   preUpdate[storeName][row['id']][0]['count_dop'] = 0;
                                                                                                   setOrderProduct(preUpdate);

                                                                                               }


                                                                                               let dopCountSave = parseInt(preUpdate[storeName][row['id']][0]['count_dop']);
                                                                                               if (!(dopCountSave > 0)) {
                                                                                                   dopCountSave = 0;
                                                                                               }
                                                                                               let countSave = parseInt(preUpdate[storeName][row['id']][0]['count']);
                                                                                               if (!(countSave > 0)) {
                                                                                                   countSave = 0;
                                                                                               }
                                                                                               if (dopCountSave == 0 && countSave == 0) {
                                                                                                   await deleteDataBase({
                                                                                                       id: row['update_id']
                                                                                                   }, 'OrderProduct');

                                                                                               }
                                                                                               calcultationSet();
                                                                                               getOrderSend().then();


                                                                                           }}

                                                                                                    img={require('../../assets/images/trash.png')}
                                                                                           />

                                                                                       </>
                                                                                   )
                                                                               }


                                                                           </div>)
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                }
                                {/*Оборудование конец*/}


                                {/*Хоз-товары Начало*/}
                                {

                                    is_obrv && (window.isRole("Order", 'edit') ? true : (allInfoProduct?.product?.products ? Object.entries(allInfoProduct?.product?.products).length > 0 : false)) && (
                                        <div style={{
                                            marginTop: "2rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                            <span onClick={() => {
                                setPageOpenProductHoz(!pageOpenProductHoz);
                            }} style={{display: "flex", alignItems: "center"}}>
                               <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                    src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/> Хозтовары:
                            </span>


                                                {
                                                    window.isRole("Order", 'edit') && (
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                            <Btn onClick={async () => {
                                                                setPage(0);
                                                                setPageModel(0)
                                                                setOpenProduct(true);
                                                                setSelectHouseProduct(true);
                                                                setSearch('');

                                                                await getProductModal(page, true);
                                                                setModalProduct(true);
                                                                setOpenProduct(false);


                                                            }} className={'btn-def-table btn-def-table-w20 active text text-s14'}

                                                                 load={openProduct}
                                                                 label={'Добавить оборудование'}/>

                                                        </div>
                                                    )
                                                }
                                            </p>

                                            {
                                                (window.isRole("Order", 'edit') ? pageOpenProductHoz : true) && (
                                                    <div style={{maxWidth: "45rem", marginTop: "2rem"}}>
                                                        <div style={{width: "45rem"}}>
                                                            <CustomInput value={searchPage} onChange={(e) => {
                                                                setPage(0);
                                                                setSearchPage(e.target.value);
                                                            }} name={'search'}
                                                                         img={require('../../assets/images/search-normal.png')}
                                                                         label={'Поиск'}/>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                (window.isRole("Order", 'edit') ? pageOpenProductHoz : true) && Object.entries(orderProduct).map(([storeName, productArray]) => {

                                                    let rowTableSave = {...rowTable};
                                                    delete (rowTableSave['catalog_id']);

                                                    let serius = Object.values(productArray).sort((a, b) => {
                                                        let catalogIdA = a[0]?.product?.catalog_id ? a[0]?.product?.catalog_id : 0;
                                                        let catalogIdB = b[0]?.product?.catalog_id?.catalog_id ? b[0]?.product?.catalog_id : 0;
                                                        if (catalogIdA < catalogIdB) {
                                                            return -1;
                                                        }
                                                        if (catalogIdA > catalogIdB) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    })

                                                    // if ((storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары')) {
                                                    //     if (countSteps == 0) {
                                                    //         return (<></>);
                                                    //     }
                                                    // }
                                                    delete (rowTableSave['store']);
                                                    delete (rowTableSave['store_sklad']);
                                                    delete (rowTableSave['option']);
                                                    delete (rowTableSave['count']);
                                                    delete (rowTableSave['price']);
                                                    // console.log(rowTableSave);
                                                    rowTableSave['count'] = {
                                                        name: 'Кол-во КП', className: "ci-table-th-88", value: "",
                                                    };


                                                    if (countSteps == 2 && window.isRole("Order", 'edit')) {
                                                        rowTableSave['count_dop'] = {
                                                            name: 'Доп. ед', className: "ci-table-th-88", value: "",
                                                        };
                                                        rowTableSave['count_dop_result'] = {
                                                            name: 'Общее кол-во', className: "ci-table-th-88", value: "",
                                                        };
                                                    } else if (!window.isRole("Order", 'edit')) {
                                                        rowTableSave['count'] = {
                                                            name: 'Кол-во', className: "ci-table-th-88", value: "",
                                                        };
                                                        delete rowTableSave['count_result'];
                                                        delete rowTableSave['price_arenda'];
                                                    }

                                                    if (window.isRole("Order", 'edit')) {

                                                        rowTableSave['count_result'] = {
                                                            name: 'Сумма', className: "ci-table-th-88", value: "",
                                                        };
                                                    }


                                                    rowTableSave['option'] = {
                                                        name: '', className: "ci-table-th-88 text-right-table", value: "",
                                                    };


                                                    return (
                                                        <div
                                                            style={{marginTop: 0}}
                                                            className={"edit-table " + ((storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары') && 'grenn-box')}>

                                        <span style={{marginTop: "2rem", display: "flex"}} className={'text text-s18'}>
                                            {
                                                storeName
                                            }
                                        </span>
                                                            <div className="edit-table__body">
                                                                <Table th={{
                                                                    ...(rowTableSave)
                                                                }}
                                                                       photoHidden={true}
                                                                       productOption={true}
                                                                       modalName={'Product'}

                                                                       tr={(Object.entries(serius).map(([productId, productArraySingle]) => {
                                                                           let productReturn = productArraySingle[0]['product'];
                                                                           if (productReturn == null) {
                                                                               return false;
                                                                           }
                                                                           productReturn['count'] = productArraySingle[0]['count'];
                                                                           productReturn['count_dop'] = productArraySingle[0]['count_dop'];
                                                                           productReturn['discont'] = productArraySingle[0]['discont'];
                                                                           productReturn['product_commit'] = productArraySingle[0]['product_commit'];
                                                                           productReturn['update_id'] = productArraySingle[0]['id'];
                                                                           productReturn['pre'] = productArraySingle[0];


                                                                           let countPlus = parseInt(productArraySingle[0]['count']);
                                                                           if (!(countPlus > 0)) {
                                                                               countPlus = 0;
                                                                           }

                                                                           let countDopPlus = parseInt(productArraySingle[0]['count_dop']);
                                                                           if (!(countDopPlus > 0)) {
                                                                               countDopPlus = 0;
                                                                           }

                                                                           productReturn['count_dop_result'] = countPlus + countDopPlus;


                                                                           productReturn['count_result'] = (productReturn['count'] * productReturn['price_arenda']);

                                                                           if (parseInt(productReturn['discont']) > 0) {
                                                                               productReturn['count_result'] = productReturn['count_result'] * (1 - (parseInt(productReturn['discont']) / 100));
                                                                           }

                                                                           if (!window.isRole("Order", 'edit')) {
                                                                               productReturn['count'] = productReturn['count_dop_result'];
                                                                           }
                                                                           return productReturn;
                                                                       }).filter((itsrx) => {

                                                                           let countBox = true;
                                                                           let searchInfo = true;

                                                                           let houseProduct = itsrx.catalog_id == 78 ? true : false;

                                                                           if (countSteps == 0) {
                                                                               countBox = itsrx?.count > 0
                                                                           }
                                                                           if (searchPage != '') {
                                                                               searchInfo = itsrx.name.toLowerCase().includes(searchPage.toLowerCase())
                                                                           }


                                                                           return houseProduct && countBox && searchInfo;
                                                                       }))
                                                                       }
                                                                       option={(id, row) => {

                                                                           return (<div
                                                                               className={'table-options table-options-sm-flex'}>

                                                                               {
                                                                                   (storeName != 'Суб-аренда Decoretto' && storeName != 'Доп товары') && (
                                                                                       <div className={'fileControlTextBOx'}
                                                                                            style={{alignItems: "flex-start"}}>
                                                                                           {
                                                                                               window.isRole("Order", 'edit') && (
                                                                                                   <>
                                                                                                       <b>Остаток: {row['store']}</b> <br/>
                                                                                                       <b>В
                                                                                                           Аренде: {row['store_arenda']}</b><br/>
                                                                                                   </>
                                                                                               )
                                                                                           }
                                                                                           <b>Кол-во: {row['count']}</b>
                                                                                       </div>
                                                                                   )
                                                                               }
                                                                               {
                                                                                   (storeName == 'Доп товары') && (
                                                                                       <div style={{
                                                                                           width: "100%",
                                                                                           alignItems: "flex-start"
                                                                                       }}>
                                                                                           <b>Кол-во: {row['count']}</b>
                                                                                       </div>
                                                                                   )
                                                                               }

                                                                               {/*{*/}
                                                                               {/*    (storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары') && (*/}
                                                                               {/*        <div style={{width: "7rem"}}>*/}
                                                                               {/*        </div>*/}
                                                                               {/*    )*/}
                                                                               {/*}*/}


                                                                               <div className="edit-box text text-s14"
                                                                                    style={{marginRight: "3rem", width: "10rem"}}>
                                                                                   <b>{row['product_commit']}</b>
                                                                               </div>


                                                                               {
                                                                                   window.isRole("Order", 'post') && (
                                                                                       <>
                                                                                           <div className={'row-option-flex'}>
                                                                                               <BtnIcon onClick={async () => {
                                                                                                   if (window.isRole("Order", 'post')) {
                                                                                                       setStoreNameSave(storeName);
                                                                                                       setClickOrder(row);
                                                                                                       setTypeSelect('product_store');
                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                           'save': {
                                                                                                               "product_store_complit": (row['count'] == row['pre']['product_store_complit']) ? 0 : row['count'],
                                                                                                           },
                                                                                                           'id': row['update_id'],
                                                                                                           'model_name': 'OrderProduct',
                                                                                                       }, false));

                                                                                                       getOrderSend().then();
                                                                                                   }
                                                                                               }}
                                                                                                        className={'icon-box-sas ' + ((row['count'] == row['pre']['product_store_complit']) && 'active')}
                                                                                                        value={'Собран'}/>
                                                                                           </div>
                                                                                           <div className={'row-option-flex'}>


                                                                                               <BtnIcon onClick={async (e) => {
                                                                                                   if (window.isRole("Order", 'post')) {
                                                                                                       setStoreNameSave(storeName);
                                                                                                       setClickOrder(row);
                                                                                                       setTypeSelect('product_store');
                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                           'save': {
                                                                                                               "product_store_post": (row['count'] == row['pre']['product_store_post']) ? 0 : row['count'],
                                                                                                               "product_store_post_img": typeof e == 'string' ? e : '',
                                                                                                           },
                                                                                                           'id': row['update_id'],
                                                                                                           'model_name': 'OrderProduct',
                                                                                                       }, false));

                                                                                                       getOrderSend().then();
                                                                                                   }
                                                                                               }}
                                                                                                        file={true}
                                                                                                        fileImg={row['pre']['product_store_post_img']}
                                                                                                        file_controls={(row['count'] == row['pre']['product_store_post'])}
                                                                                                        className={'icon-box-sas ' + ((row['count'] == row['pre']['product_store_post']) && 'active')}
                                                                                                        value={'Выдан'}/>

                                                                                               {window.isRole("Order", 'note') && (
                                                                                                   <BtnIcon onClick={() => {
                                                                                                       setProductId(id);
                                                                                                       setStoreNameSave(storeName);
                                                                                                       setClickOrder(row);
                                                                                                       setModalNode(true);
                                                                                                   }} img={require('./message.png')}/>)}
                                                                                           </div>
                                                                                       </>
                                                                                   )
                                                                               }


                                                                               {

                                                                                   window.isRole("Order", 'get') && (
                                                                                       <>
                                                                                           <div className={'row-option-flex'}>
                                                                                               <BtnIcon onClick={async () => {
                                                                                                   setStoreNameSave(storeName);
                                                                                                   setClickOrder(row);
                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "product_manager_return": (row['count'] == row['pre']['product_manager_return']) ? 0 : row['count'],
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));

                                                                                                   getOrderSend().then();

                                                                                               }}
                                                                                                        className={'icon-box-sas ' + ((row['count'] == row['pre']['product_manager_return']) && 'active')}
                                                                                                        value={('Получен')}/>
                                                                                               <BtnIcon onClick={() => {
                                                                                                   setClickOrder(row);
                                                                                                   setStoreNameSave(storeName);

                                                                                                   setTypeSelect('product_manager');
                                                                                                   setCountProductOpen(row['count']);
                                                                                                   setModelOpenId(id);

                                                                                               }} img={require('./message.png')}/>
                                                                                           </div>

                                                                                           <div className={'row-option-flex'}>
                                                                                               <BtnIcon onClick={async () => {
                                                                                                   setClickOrder(row);
                                                                                                   setStoreNameSave(storeName);
                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "product_received_return": (row['count'] == row['pre']['product_received_return']) ? 0 : row['count'],
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));

                                                                                                   getOrderSend().then();

                                                                                               }}
                                                                                                        className={'icon-box-sas ' + ((row['count'] == row['pre']['product_received_return']) && 'active')}
                                                                                                        value={('Отправлен')}/>
                                                                                               <BtnIcon onClick={() => {
                                                                                                   setClickOrder(row);
                                                                                                   setStoreNameSave(storeName);
                                                                                                   setTypeSelect('product_received');
                                                                                                   setCountProductOpen(row['count']);
                                                                                                   setModelOpenId(id);

                                                                                               }} img={require('./message.png')}/>
                                                                                           </div>
                                                                                       </>
                                                                                   )
                                                                               }


                                                                               {
                                                                                   window.isRole("Order", 'edit') && (
                                                                                       <>
                                                                                           <CustomInput
                                                                                               key={row['update_id']}
                                                                                               className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                               onChange={async (e) => {

                                                                                                   let countValue = parseInt(e.target.value);

                                                                                                   if (countValue <= 0 || e.target.value == '') {
                                                                                                       if (countSteps == 0) {
                                                                                                           countValue = 1;
                                                                                                       }
                                                                                                   }

                                                                                                   if (countSteps == 0) {

                                                                                                       async function senUpdateInfo(e, row) {
                                                                                                           const updateDataOrder = (await updateDB({
                                                                                                               'save': {
                                                                                                                   "count": countValue,
                                                                                                               },
                                                                                                               'id': row['update_id'],
                                                                                                               'model_name': 'OrderProduct',
                                                                                                           }, false));

                                                                                                           calcultationSet();

                                                                                                           getOrderSend().then();
                                                                                                       }

                                                                                                       clearTimeout(window.timeUpdateSend)

                                                                                                       window.timeUpdateSend = setTimeout(() => {
                                                                                                           senUpdateInfo(e, row);
                                                                                                       }, 1200);
                                                                                                       let preUpdate = {...orderProduct}
                                                                                                       orderProduct[storeName][row['id']][0]['count'] = countValue;
                                                                                                       setOrderProduct(preUpdate);

                                                                                                   } else {

                                                                                                       async function senUpdateInfo(e, row) {
                                                                                                           const updateDataOrder = (await updateDB({
                                                                                                               'save': {
                                                                                                                   "count_dop": countValue,
                                                                                                               },
                                                                                                               'id': row['update_id'],
                                                                                                               'model_name': 'OrderProduct',
                                                                                                           }, false));

                                                                                                           calcultationSet();
                                                                                                           // getOrderSend().then();
                                                                                                       }

                                                                                                       clearTimeout(window.timeUpdateSend)

                                                                                                       window.timeUpdateSend = setTimeout(() => {
                                                                                                           senUpdateInfo(e, row);
                                                                                                       }, 1200);
                                                                                                       let preUpdate = {...orderProduct}
                                                                                                       orderProduct[storeName][row['id']][0]['count_dop'] = countValue;
                                                                                                       setOrderProduct(preUpdate);

                                                                                                   }


                                                                                               }}
                                                                                               value={countSteps == 2 ? row['count_dop'] : row['count']}
                                                                                               type={'number'}
                                                                                               label={countSteps == 2 ? 'Доп. ед' : 'Кол-во'}/>


                                                                                           {
                                                                                               countSteps == 0 && (
                                                                                                   <CustomInput
                                                                                                       key={row['update_id'] + 1000000}
                                                                                                       className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                                       onChange={async (e) => {


                                                                                                           async function senUpdateInfo(e, row) {
                                                                                                               const updateDataOrder = (await updateDB({
                                                                                                                   'save': {
                                                                                                                       "discont": e.target.value,
                                                                                                                   },
                                                                                                                   'id': row['update_id'],
                                                                                                                   'model_name': 'OrderProduct',
                                                                                                               }, false));
                                                                                                           }

                                                                                                           clearTimeout(window.timeUpdateSend)

                                                                                                           window.timeUpdateSend = setTimeout(() => {
                                                                                                               senUpdateInfo(e, row);
                                                                                                           }, 1200);
                                                                                                           let preUpdate = {...orderProduct}
                                                                                                           orderProduct[storeName][row['id']][0]['discont'] = e.target.value;
                                                                                                           setOrderProduct(preUpdate);


                                                                                                       }} value={row['discont']}
                                                                                                       type={'number'}
                                                                                                       label={'Скидка'}/>
                                                                                               )
                                                                                           }


                                                                                           <CustomInput
                                                                                               key={row['update_id'] + 2000000}
                                                                                               onBlur={async (e) => {

                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "product_commit": e.target.value,
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));

                                                                                                   if (window?.timeSend) {
                                                                                                       clearTimeout(window.timeSend);
                                                                                                   }

                                                                                                   window.timeSend = setTimeout(async () => {
                                                                                                       const orderProductSave = (await getOrderProduct(params.id)).data;
                                                                                                       setOrderProduct(orderProductSave);
                                                                                                   }, 500);

                                                                                               }}
                                                                                               className={'edit-product-order edit-product-order-edit'}
                                                                                               onChange={async (e) => {

                                                                                                   async function senUpdateInfo(e, row) {
                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                           'save': {
                                                                                                               "product_commit": e.target.value,
                                                                                                           },
                                                                                                           'id': row['update_id'],
                                                                                                           'model_name': 'OrderProduct',
                                                                                                       }, false));
                                                                                                   }

                                                                                                   clearTimeout(window.timeUpdateSend)

                                                                                                   window.timeUpdateSend = setTimeout(() => {
                                                                                                       senUpdateInfo(e, row);
                                                                                                   }, 1000);
                                                                                                   let preUpdate = {...orderProduct}
                                                                                                   orderProduct[storeName][row['id']][0]['product_commit'] = e.target.value;
                                                                                                   setOrderProduct(preUpdate);


                                                                                               }} value={row['product_commit']}
                                                                                               type={'text'}
                                                                                               label={'Примечание'}/>


                                                                                           <BtnIcon onClick={async () => {

                                                                                               let preUpdate = {...orderProduct};

                                                                                               getOrderSend().then();
                                                                                           }}
                                                                                                    productValue={row}
                                                                                                    ahe={true}/>

                                                                                           <BtnIcon onClick={async () => {

                                                                                               let preUpdate = {...orderProduct};

                                                                                               if (countSteps == 0) {

                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "count": 0,
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));
                                                                                                   preUpdate[storeName][row['id']][0]['count'] = 0;
                                                                                                   setOrderProduct(preUpdate);

                                                                                               } else {

                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                       'save': {
                                                                                                           "count_dop": 0,
                                                                                                       },
                                                                                                       'id': row['update_id'],
                                                                                                       'model_name': 'OrderProduct',
                                                                                                   }, false));

                                                                                                   preUpdate[storeName][row['id']][0]['count_dop'] = 0;
                                                                                                   setOrderProduct(preUpdate);

                                                                                               }


                                                                                               let dopCountSave = parseInt(preUpdate[storeName][row['id']][0]['count_dop']);
                                                                                               if (!(dopCountSave > 0)) {
                                                                                                   dopCountSave = 0;
                                                                                               }
                                                                                               let countSave = parseInt(preUpdate[storeName][row['id']][0]['count']);
                                                                                               if (!(countSave > 0)) {
                                                                                                   countSave = 0;
                                                                                               }
                                                                                               if (dopCountSave == 0 && countSave == 0) {
                                                                                                   await deleteDataBase({
                                                                                                       id: row['update_id']
                                                                                                   }, 'OrderProduct');

                                                                                               }
                                                                                               getOrderSend().then();
                                                                                               calcultationSet();

                                                                                           }}
                                                                                                    img={require('../../assets/images/trash.png')}
                                                                                           />
                                                                                       </>
                                                                                   )
                                                                               }


                                                                           </div>)
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )

                                }
                                {/*Хоз-товары конец*/}

                                {
                                    is_servirov && (window.isRole("Order", 'edit') ? true : allInfoProduct?.price?.servsPrice > 0) && (
                                        <div style={{
                                            marginTop: "2rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                            <span onClick={async () => {

                                setPageOpen10(!pageOpen10);

                                let servingBox = (await getModal('OrderServing', '?order_id=' + params.id)).data;
                                setServing(servingBox);


                            }} style={{display: "flex", alignItems: "center"}}>
                             <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                  src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/>  Сервировка:
                            </span>

                                                {
                                                    window.isRole("Order", 'edit') && (
                                                        <div>
                                                            <Btn onClick={async () => {
                                                                setNameComplect('');
                                                                setPageModel(0);
                                                                setOpenModalControl(true);

                                                            }}
                                                                 className={'btn-def-table btn-def-table-w20 active text text-s14'}
                                                                 load={openProduct}
                                                                 label={'Добавить '}/>
                                                        </div>
                                                    )
                                                }

                                            </p>

                                            {
                                                pageOpen10 && (<div>

                                                    <div style={{width: "45rem"}}>
                                                        <CustomInput value={searchPage} onChange={(e) => {
                                                            setSearchPage(e.target.value);
                                                        }} name={'search'}
                                                                     img={require('../../assets/images/search-normal.png')}
                                                                     label={'Поиск'}/>
                                                    </div>
                                                    <br/>
                                                    <br/>
                                                    {
                                                        serving.map((servingItem) => {
                                                            return (
                                                                <div className={'serving_controls'}>
                                                                    <div className={'text serving_controls_menu text-s18'}
                                                                         style={{
                                                                             color: "#fff",
                                                                             padding: "0.75rem 1.5rem",
                                                                             width: "100%",
                                                                             backgroundColor: "#FFBD3E",
                                                                             display: "flex",
                                                                             alignItems: "center",
                                                                             justifyContent: "space-between"
                                                                         }}>
                                                                        <div>
                                                                            {
                                                                                updateIdMenu == servingItem.id && (
                                                                                    <>
                                                                                        <CustomInput onChange={async (e) => {
                                                                                            setUpdateIdMenuValue(e.target.value);
                                                                                            if (e.keyCode == 13) {
                                                                                                await (updateDB({
                                                                                                    'save': {
                                                                                                        "name": e.target.value,
                                                                                                    },
                                                                                                    'id': updateIdMenu,
                                                                                                    'model_name': 'OrderServing',
                                                                                                }, false));
                                                                                                let servingBox = (await getModal('OrderServing', '?order_id=' + params.id)).data;
                                                                                                setServing(servingBox);
                                                                                                setUpdateIdMenuValue(false);
                                                                                                setUpdateIdMenu(false);
                                                                                            }
                                                                                        }} value={updateIdMenuValue} name={'search'}
                                                                                                     label={'Изменить название / Enter жми'}/>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                updateIdMenu != servingItem.id && (
                                                                                    <>
                                                                                        {servingItem.name}
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>


                                                                        {
                                                                            window.isRole("Order", 'edit') && (
                                                                                <div
                                                                                    style={{display: "flex", alignItems: "center"}}>


                                                                                    <Btn onClick={async () => {


                                                                                        await deleteDataBase({
                                                                                            id: servingItem['id']
                                                                                        }, 'OrderServing');

                                                                                        let servingBox = (await getModal('OrderServing', '?order_id=' + params.id)).data;
                                                                                        setServing(servingBox);
                                                                                        getOrderSend().then();

                                                                                    }}
                                                                                         className={'ci-btn ci-btn-def btn-def-table  btn-def-table-w20  text text-s14'}
                                                                                         load={openProduct}
                                                                                         label={'Удалить '}/>


                                                                                    <Btn onClick={async () => {
                                                                                        setUpdateIdMenuValue(servingItem.name);
                                                                                        setUpdateIdMenu(servingItem.id);
                                                                                    }}
                                                                                         className={'ci-btn ci-btn-def btn-def-table  btn-def-table-w20  text text-s14'}
                                                                                         load={openProduct}
                                                                                         label={'Редактировать '}/>

                                                                                    <Btn onClick={async () => {
                                                                                        setSearch('');
                                                                                        setPage(0)
                                                                                        setPageModel(0);
                                                                                        setOpenProduct(true);
                                                                                        setSelectHouseProduct(false);

                                                                                        await getProductModal(page);

                                                                                        setServingId(servingItem.id);
                                                                                        setModalProductServ(true);

                                                                                        setOpenProduct(false);


                                                                                    }}
                                                                                         className={'ci-btn ci-btn-def btn-def-table  btn-def-table-w20  text text-s14'}
                                                                                         load={openProduct}
                                                                                         label={'Добавить '}/>
                                                                                </div>

                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div className="serving_controls_body">
                                                                        {
                                                                            servingItem.id in orderProductServ && Object.entries(orderProductServ[servingItem.id]).map(([storeName, productArray]) => {

                                                                                let rowTableSave = {...rowTable};
                                                                                delete (rowTableSave['catalog_id']);

                                                                                let serius = Object.values(productArray).sort((a, b) => {
                                                                                    let catalogIdA = a[0]?.product?.catalog_id ? a[0]?.product?.catalog_id : 0;
                                                                                    let catalogIdB = b[0]?.product?.catalog_id?.catalog_id ? b[0]?.product?.catalog_id : 0;
                                                                                    if (catalogIdA < catalogIdB) {
                                                                                        return -1;
                                                                                    }
                                                                                    if (catalogIdA > catalogIdB) {
                                                                                        return 1;
                                                                                    }
                                                                                    return 0;
                                                                                })

                                                                                if ((storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары')) {

                                                                                }
                                                                                delete (rowTableSave['store']);
                                                                                delete (rowTableSave['store_sklad']);
                                                                                delete (rowTableSave['option']);
                                                                                // console.log(rowTableSave);
                                                                                rowTableSave['count'] = {
                                                                                    name: 'Кол-во',
                                                                                    className: "ci-table-th-88",
                                                                                    value: "",
                                                                                };


                                                                                if (countSteps == 2 && window.isRole("Order", 'edit')) {
                                                                                    rowTableSave['count_dop'] = {
                                                                                        name: 'Доп. ед',
                                                                                        className: "ci-table-th-88",
                                                                                        value: "",
                                                                                    };
                                                                                    rowTableSave['count_dop_result'] = {
                                                                                        name: 'Общее кол-во',
                                                                                        className: "ci-table-th-88",
                                                                                        value: "",
                                                                                    };
                                                                                    rowTableSave['count_result'] = {
                                                                                        name: 'Сумма',
                                                                                        className: "ci-table-th-88",
                                                                                        value: "",
                                                                                    };
                                                                                } else if (!window.isRole("Order", 'edit')) {
                                                                                    rowTableSave['count'] = {
                                                                                        name: 'Кол-во',
                                                                                        className: "ci-table-th-88",
                                                                                        value: "",
                                                                                    };
                                                                                    delete rowTableSave['price_arenda'];
                                                                                } else if (window.isRole("Order", 'edit')) {

                                                                                    rowTableSave['count_result'] = {
                                                                                        name: 'Сумма',
                                                                                        className: "ci-table-th-88",
                                                                                        value: "",
                                                                                    };
                                                                                }


                                                                                rowTableSave['option'] = {
                                                                                    name: '',
                                                                                    className: "ci-table-th-88 text-right-table",
                                                                                    value: "",
                                                                                };


                                                                                return (
                                                                                    <div
                                                                                        style={{marginTop: 0}}
                                                                                        className={"edit-table " + ((storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары') && 'grenn-box')}>

                                                                                <span style={{
                                                                                    marginTop: "2rem",
                                                                                    display: "flex"
                                                                                }} className={'text text-s18'}>
                                                                                    {
                                                                                        storeName
                                                                                    }
                                                                                </span>
                                                                                        <div className="edit-table__body">
                                                                                            <Table th={{
                                                                                                ...(rowTableSave)
                                                                                            }}
                                                                                                   productOption={true}
                                                                                                   modalName={'Product'}
                                                                                                   tr={(Object.entries(serius).map(([productId, productArraySingle]) => {
                                                                                                       let productReturn = productArraySingle[0]['product'];
                                                                                                       if (productReturn == null) {
                                                                                                           return false;
                                                                                                       }


                                                                                                       productReturn['count'] = productArraySingle[0]['count'];
                                                                                                       productReturn['count_dop'] = productArraySingle[0]['count_dop'];
                                                                                                       productReturn['discont'] = productArraySingle[0]['discont'];
                                                                                                       productReturn['product_commit'] = productArraySingle[0]['product_commit'];
                                                                                                       productReturn['update_id'] = productArraySingle[0]['id'];
                                                                                                       productReturn['pre'] = productArraySingle[0];


                                                                                                       let countPlus = parseInt(productArraySingle[0]['count']);
                                                                                                       if (!(countPlus > 0)) {
                                                                                                           countPlus = 0;
                                                                                                       }

                                                                                                       let countDopPlus = parseInt(productArraySingle[0]['count_dop']);
                                                                                                       if (!(countDopPlus > 0)) {
                                                                                                           countDopPlus = 0;
                                                                                                       }

                                                                                                       productReturn['count_dop_result'] = countPlus + countDopPlus;


                                                                                                       productReturn['count_result'] = (productReturn['count'] * productReturn['price_arenda']);

                                                                                                       if (parseInt(productReturn['discont']) > 0) {
                                                                                                           productReturn['count_result'] = productReturn['count_result'] * (1 - (parseInt(productReturn['discont']) / 100));
                                                                                                       }

                                                                                                       if (!window.isRole("Order", 'edit')) {
                                                                                                           productReturn['count'] = productReturn['count_dop_result'];
                                                                                                       }

                                                                                                       return productReturn;
                                                                                                   }).filter((itsrx) => {

                                                                                                       let searchInfo = true;
                                                                                                       if (searchPage != '') {
                                                                                                           searchInfo = itsrx.name.toLowerCase().includes(searchPage.toLowerCase())
                                                                                                       }

                                                                                                       if (catalogSelect) {
                                                                                                           return searchInfo && itsrx;
                                                                                                       }


                                                                                                       return itsrx && searchInfo;
                                                                                                   }))
                                                                                                   }
                                                                                                   option={(id, row) => {

                                                                                                       return (<div
                                                                                                           className={'table-options table-options-sm-flex'}>

                                                                                                           {
                                                                                                               (storeName != 'Суб-аренда Decoretto' && storeName != 'Доп товары') && (
                                                                                                                   <div
                                                                                                                       className={'fileControlTextBOx'}
                                                                                                                       style={{alignItems: "flex-start"}}>
                                                                                                                       {
                                                                                                                           window.isRole("Order", 'edit') && (
                                                                                                                               <>
                                                                                                                                   <b>Остаток: {row['store']}</b>
                                                                                                                                   <br/>
                                                                                                                                   <b>В
                                                                                                                                       Аренде: {row['store_arenda']}</b><br/>
                                                                                                                               </>
                                                                                                                           )
                                                                                                                       }
                                                                                                                       <b>Кол-во: {row['count']}</b>
                                                                                                                   </div>
                                                                                                               )
                                                                                                           }
                                                                                                           {
                                                                                                               (storeName == 'Доп товары') && (
                                                                                                                   <div style={{
                                                                                                                       width: "100%",
                                                                                                                       alignItems: "flex-start"
                                                                                                                   }}>
                                                                                                                       <b>Кол-во: {row['count']}</b>
                                                                                                                   </div>
                                                                                                               )
                                                                                                           }

                                                                                                           {
                                                                                                               (storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары') && (
                                                                                                                   <div
                                                                                                                       style={{width: "7rem"}}>
                                                                                                                   </div>
                                                                                                               )
                                                                                                           }


                                                                                                           <div
                                                                                                               className="edit-box text text-s14"
                                                                                                               style={{
                                                                                                                   marginRight: "3rem",
                                                                                                                   width: "10rem"
                                                                                                               }}>
                                                                                                               <b>{row['product_commit']}</b>
                                                                                                           </div>


                                                                                                           {
                                                                                                               window.isRole("Order", 'post') && (
                                                                                                                   <>
                                                                                                                       <div
                                                                                                                           className={'row-option-flex'}>
                                                                                                                           <BtnIcon
                                                                                                                               onClick={async () => {
                                                                                                                                   if (window.isRole("Order", 'post')) {
                                                                                                                                       setStoreNameSave(storeName);
                                                                                                                                       setClickOrder(row);
                                                                                                                                       setTypeSelect('product_store');
                                                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                                                           'save': {
                                                                                                                                               "product_store_complit": (row['count'] == row['pre']['product_store_complit']) ? 0 : row['count'],
                                                                                                                                           },
                                                                                                                                           'id': row['update_id'],
                                                                                                                                           'model_name': 'OrderProduct',
                                                                                                                                       }, false));

                                                                                                                                       getOrderSend().then();
                                                                                                                                   }
                                                                                                                               }}
                                                                                                                               className={'icon-box-sas ' + ((row['count'] == row['pre']['product_store_complit']) && 'active')}
                                                                                                                               value={'Собран'}/>
                                                                                                                       </div>
                                                                                                                       <div
                                                                                                                           className={'row-option-flex'}>
                                                                                                                           <BtnIcon
                                                                                                                               onClick={async (e) => {
                                                                                                                                   if (window.isRole("Order", 'post')) {

                                                                                                                                       setStoreNameSave(storeName);
                                                                                                                                       setClickOrder(row);
                                                                                                                                       setTypeSelect('product_store');
                                                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                                                           'save': {
                                                                                                                                               "product_store_post": (row['count'] == row['pre']['product_store_post']) ? 0 : row['count'],
                                                                                                                                               "product_store_post_img": typeof e == 'string' ? e : '',
                                                                                                                                           },
                                                                                                                                           'id': row['update_id'],
                                                                                                                                           'model_name': 'OrderProduct',
                                                                                                                                       }, false));

                                                                                                                                       getOrderSend().then();
                                                                                                                                   }
                                                                                                                               }}
                                                                                                                               file={true}
                                                                                                                               fileImg={row['pre']['product_store_post_img']}
                                                                                                                               file_controls={(row['count'] == row['pre']['product_store_post'])}
                                                                                                                               className={'icon-box-sas ' + ((row['count'] == row['pre']['product_store_post']) && 'active')}
                                                                                                                               value={'Выдан'}/>

                                                                                                                           {window.isRole("Order", 'note') && (
                                                                                                                               <BtnIcon
                                                                                                                                   onClick={() => {
                                                                                                                                       setProductId(id);
                                                                                                                                       setStoreNameSave(storeName);
                                                                                                                                       setClickOrder(row);
                                                                                                                                       setModalNode(true);
                                                                                                                                   }}
                                                                                                                                   img={require('./message.png')}/>)}
                                                                                                                       </div>
                                                                                                                   </>
                                                                                                               )
                                                                                                           }


                                                                                                           {

                                                                                                               window.isRole("Order", 'get') && (
                                                                                                                   <>
                                                                                                                       <div
                                                                                                                           className={'row-option-flex'}>
                                                                                                                           <BtnIcon
                                                                                                                               onClick={async () => {
                                                                                                                                   setStoreNameSave(storeName);
                                                                                                                                   setClickOrder(row);
                                                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                                                       'save': {
                                                                                                                                           "product_manager_return": (row['count'] == row['pre']['product_manager_return']) ? 0 : row['count'],
                                                                                                                                       },
                                                                                                                                       'id': row['update_id'],
                                                                                                                                       'model_name': 'OrderProduct',
                                                                                                                                   }, false));

                                                                                                                                   getOrderSend().then();

                                                                                                                               }}
                                                                                                                               className={'icon-box-sas ' + ((row['count'] == row['pre']['product_manager_return']) && 'active')}
                                                                                                                               value={('Получен')}/>
                                                                                                                           <BtnIcon
                                                                                                                               onClick={() => {
                                                                                                                                   setClickOrder(row);
                                                                                                                                   setStoreNameSave(storeName);

                                                                                                                                   setTypeSelect('product_manager');
                                                                                                                                   setCountProductOpen(row['count']);
                                                                                                                                   setModelOpenId(id);

                                                                                                                               }}
                                                                                                                               img={require('./message.png')}/>
                                                                                                                       </div>


                                                                                                                       <div
                                                                                                                           className={'row-option-flex'}>
                                                                                                                           <BtnIcon
                                                                                                                               onClick={async () => {
                                                                                                                                   setClickOrder(row);
                                                                                                                                   setStoreNameSave(storeName);
                                                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                                                       'save': {
                                                                                                                                           "product_received_return": (row['count'] == row['pre']['product_received_return']) ? 0 : row['count'],
                                                                                                                                       },
                                                                                                                                       'id': row['update_id'],
                                                                                                                                       'model_name': 'OrderProduct',
                                                                                                                                   }, false));

                                                                                                                                   getOrderSend().then();

                                                                                                                               }}
                                                                                                                               className={'icon-box-sas ' + ((row['count'] == row['pre']['product_received_return']) && 'active')}
                                                                                                                               value={('Отправлен')}/>
                                                                                                                           <BtnIcon
                                                                                                                               onClick={() => {
                                                                                                                                   setClickOrder(row);
                                                                                                                                   setStoreNameSave(storeName);
                                                                                                                                   setTypeSelect('product_received');
                                                                                                                                   setCountProductOpen(row['count']);
                                                                                                                                   setModelOpenId(id);

                                                                                                                               }}
                                                                                                                               img={require('./message.png')}/>
                                                                                                                       </div>
                                                                                                                   </>
                                                                                                               )
                                                                                                           }

                                                                                                           {
                                                                                                               window.isRole("Order", 'post') && (
                                                                                                                   <div
                                                                                                                       className={'row-option-flex'}>
                                                                                                                       <BtnIcon
                                                                                                                           onClick={async () => {
                                                                                                                               setStoreNameSave(storeName);
                                                                                                                               setClickOrder(row);
                                                                                                                               const updateDataOrder = (await updateDB({
                                                                                                                                   'save': {
                                                                                                                                       "product_store_return": (row['count'] == row['pre']['product_store_return']) ? 0 : row['count'],
                                                                                                                                   },
                                                                                                                                   'id': row['update_id'],
                                                                                                                                   'model_name': 'OrderProduct',
                                                                                                                               }, false));

                                                                                                                               getOrderSend().then();

                                                                                                                           }}
                                                                                                                           className={'icon-box-sas ' + ((row['count'] == row['pre']['product_store_return']) && 'active')}
                                                                                                                           value={("Демонтаж:" + (row['pre']['product_store_return'] == null ? 0 : row['pre']['product_store_return']))}/>

                                                                                                                       <BtnIcon
                                                                                                                           onClick={() => {
                                                                                                                               // setProductId(id);
                                                                                                                               // setModalNode(true);
                                                                                                                               setStoreNameSave(storeName);
                                                                                                                               setTypeSelect('product_store');
                                                                                                                               setClickOrder(row);
                                                                                                                               if (window.isRole("Order", 'post')) {
                                                                                                                                   setCountProductOpen(row['count']);
                                                                                                                                   setModelOpenId(id);
                                                                                                                               }

                                                                                                                           }}
                                                                                                                           img={require('./message.png')}/>
                                                                                                                   </div>
                                                                                                               )
                                                                                                           }


                                                                                                           {
                                                                                                               window.isRole("Order", 'edit') && (
                                                                                                                   <>
                                                                                                                       <CustomInput
                                                                                                                           className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                                                           onChange={async (e) => {

                                                                                                                               if (countSteps == 0) {


                                                                                                                                   async function senUpdateInfo(e, row) {
                                                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                                                           'save': {
                                                                                                                                               "count": e.target.value,
                                                                                                                                           },
                                                                                                                                           'id': row['update_id'],
                                                                                                                                           'model_name': 'OrderProduct',
                                                                                                                                       }, false));
                                                                                                                                       // getOrderSend().then();
                                                                                                                                       calcultationSet();
                                                                                                                                   }

                                                                                                                                   clearTimeout(window.timeUpdateSend)

                                                                                                                                   window.timeUpdateSend = setTimeout(() => {
                                                                                                                                       senUpdateInfo(e, row);
                                                                                                                                   }, 1200);
                                                                                                                                   let preUpdate = {...orderProductServ}
                                                                                                                                   preUpdate[servingItem.id][storeName][row['id']][0]['count'] = e.target.value;
                                                                                                                                   setOrderProductServ(preUpdate);

                                                                                                                               } else {

                                                                                                                                   async function senUpdateInfo(e, row) {
                                                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                                                           'save': {
                                                                                                                                               "count_dop": e.target.value,
                                                                                                                                           },
                                                                                                                                           'id': row['update_id'],
                                                                                                                                           'model_name': 'OrderProduct',
                                                                                                                                       }, false));
                                                                                                                                       calcultationSet();
                                                                                                                                       // getOrderSend().then();
                                                                                                                                   }

                                                                                                                                   clearTimeout(window.timeUpdateSend)

                                                                                                                                   window.timeUpdateSend = setTimeout(() => {
                                                                                                                                       senUpdateInfo(e, row);
                                                                                                                                   }, 1200);
                                                                                                                                   let preUpdate = {...orderProductServ}
                                                                                                                                   preUpdate[servingItem.id][storeName][row['id']][0]['count_dop'] = e.target.value;
                                                                                                                                   setOrderProductServ(preUpdate);

                                                                                                                               }


                                                                                                                           }}
                                                                                                                           value={countSteps == 2 ? row['count_dop'] : row['count']}
                                                                                                                           type={'number'}
                                                                                                                           label={countSteps == 2 ? 'Доп. ед' : 'Кол-во'}/>


                                                                                                                       {
                                                                                                                           countSteps == 0 && (
                                                                                                                               <CustomInput
                                                                                                                                   className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                                                                   onChange={async (e) => {


                                                                                                                                       async function senUpdateInfo(e, row) {
                                                                                                                                           const updateDataOrder = (await updateDB({
                                                                                                                                               'save': {
                                                                                                                                                   "discont": e.target.value,
                                                                                                                                               },
                                                                                                                                               'id': row['update_id'],
                                                                                                                                               'model_name': 'OrderProduct',
                                                                                                                                           }, false));
                                                                                                                                       }

                                                                                                                                       clearTimeout(window.timeUpdateSend)

                                                                                                                                       window.timeUpdateSend = setTimeout(() => {
                                                                                                                                           senUpdateInfo(e, row);
                                                                                                                                       }, 1200);
                                                                                                                                       let preUpdate = {...orderProductServ}
                                                                                                                                       preUpdate[servingItem.id][storeName][row['id']][0]['discont'] = e.target.value;
                                                                                                                                       setOrderProductServ(preUpdate);


                                                                                                                                   }}
                                                                                                                                   value={row['discont']}
                                                                                                                                   type={'number'}
                                                                                                                                   label={'Скидка'}/>
                                                                                                                           )
                                                                                                                       }


                                                                                                                       <CustomInput
                                                                                                                           onBlur={async (e) => {

                                                                                                                               const updateDataOrder = (await updateDB({
                                                                                                                                   'save': {
                                                                                                                                       "product_commit": e.target.value,
                                                                                                                                   },
                                                                                                                                   'id': row['update_id'],
                                                                                                                                   'model_name': 'OrderProduct',
                                                                                                                               }, false));

                                                                                                                               if (window?.timeSend) {
                                                                                                                                   clearTimeout(window.timeSend);
                                                                                                                               }

                                                                                                                               window.timeSend = setTimeout(async () => {
                                                                                                                                   const orderProductSave = (await getOrderProduct(params.id)).data;
                                                                                                                                   setOrderProduct(orderProductSave);
                                                                                                                               }, 500);

                                                                                                                           }}
                                                                                                                           className={'edit-product-order edit-product-order-edit'}
                                                                                                                           onChange={async (e) => {

                                                                                                                               async function senUpdateInfo(e, row) {
                                                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                                                       'save': {
                                                                                                                                           "product_commit": e.target.value,
                                                                                                                                       },
                                                                                                                                       'id': row['update_id'],
                                                                                                                                       'model_name': 'OrderProduct',
                                                                                                                                   }, false));
                                                                                                                               }

                                                                                                                               clearTimeout(window.timeUpdateSend)

                                                                                                                               window.timeUpdateSend = setTimeout(() => {
                                                                                                                                   senUpdateInfo(e, row);
                                                                                                                               }, 1000);
                                                                                                                               let preUpdate = {...orderProductServ}
                                                                                                                               preUpdate[servingItem.id][storeName][row['id']][0]['product_commit'] = e.target.value;
                                                                                                                               setOrderProductServ(preUpdate);


                                                                                                                           }}
                                                                                                                           value={row['product_commit']}
                                                                                                                           type={'text'}
                                                                                                                           label={'Примечание'}/>


                                                                                                                       <BtnIcon
                                                                                                                           onClick={async () => {

                                                                                                                               let preUpdate = {...orderProductServ};

                                                                                                                               getOrderSend().then();
                                                                                                                           }}
                                                                                                                           productValue={row}
                                                                                                                           ahe={true}/>

                                                                                                                       <BtnIcon
                                                                                                                           onClick={async () => {


                                                                                                                               let preUpdate = {...orderProductServ}

                                                                                                                               if (countSteps == 0) {


                                                                                                                                   async function senUpdateInfo(row) {
                                                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                                                           'save': {
                                                                                                                                               "count": 0,
                                                                                                                                           },
                                                                                                                                           'id': row['update_id'],
                                                                                                                                           'model_name': 'OrderProduct',
                                                                                                                                       }, false));
                                                                                                                                       // getOrderSend().then();
                                                                                                                                   }

                                                                                                                                   await senUpdateInfo(row);
                                                                                                                                   preUpdate[servingItem.id][storeName][row['id']][0]['count'] = 0;
                                                                                                                                   setOrderProductServ(preUpdate);

                                                                                                                               } else {

                                                                                                                                   async function senUpdateInfo(row) {
                                                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                                                           'save': {
                                                                                                                                               "count_dop": 0,
                                                                                                                                           },
                                                                                                                                           'id': row['update_id'],
                                                                                                                                           'model_name': 'OrderProduct',
                                                                                                                                       }, false));
                                                                                                                                       // getOrderSend().then();
                                                                                                                                   }

                                                                                                                                   await senUpdateInfo(row);

                                                                                                                                   preUpdate[servingItem.id][storeName][row['id']][0]['count_dop'] = 0;
                                                                                                                                   setOrderProductServ(preUpdate);

                                                                                                                               }


                                                                                                                               let dopCountSave = parseInt(preUpdate[servingItem.id][storeName][row['id']][0]['count_dop']);
                                                                                                                               let countSave = parseInt(preUpdate[servingItem.id][storeName][row['id']][0]['count']);

                                                                                                                               if (!(dopCountSave > 0)) {
                                                                                                                                   dopCountSave = 0;
                                                                                                                               }
                                                                                                                               if (!(countSave > 0)) {
                                                                                                                                   countSave = 0;
                                                                                                                               }
                                                                                                                               if (dopCountSave == 0 && countSave == 0) {
                                                                                                                                   await deleteDataBase({
                                                                                                                                       id: row['update_id']
                                                                                                                                   }, 'OrderProduct')
                                                                                                                               }

                                                                                                                               getOrderSend().then();
                                                                                                                               calcultationSet();
                                                                                                                           }}
                                                                                                                           img={require('../../assets/images/trash.png')}
                                                                                                                       />
                                                                                                                   </>
                                                                                                               )
                                                                                                           }
                                                                                                       </div>)
                                                                                                   }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }


                                                </div>)
                                            }
                                        </div>
                                    )
                                }


                                {
                                    window.isRole("Order", 'edit') && type_val != 5 && (
                                        <div style={{
                                            marginTop: "2rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }} className={'ci-width-control'}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                            <span onClick={() => {
                                setPageOpen2(!pageOpen2);
                            }} style={{display: "flex", alignItems: "center"}}>
                               <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                    src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/>  Меню:
                            </span>

                                                <div style={{display: "flex", alignItems: "center"}}>

                                                    {
                                                        window.isRole("Order", 'edit') && (
                                                            <>

                                                                <Btn onClick={async () => {
                                                                    setModalMenuComplect(true);
                                                                }}
                                                                     className={'btn-def-table deactivation btn-def-table-w20  text text-s14'}
                                                                     load={openProduct}
                                                                     label={'Создать шаблон меню'}/>


                                                                <Btn onClick={async () => {
                                                                    let result_menu_count = {};
                                                                    setNameComplect('');

                                                                    Object.entries(orderMenuServ).forEach((item) => {

                                                                        let menu_id = item[0];
                                                                        let menu_child = Object.entries(item[1]);

                                                                        let menuTypeResult = (menuType).filter((menuTypeFilter) => {
                                                                            return menuTypeFilter.id == menu_id;
                                                                        });
                                                                        if (menuTypeResult.length > 0) {
                                                                            menuTypeResult = menuTypeResult[0]['name'];


                                                                            result_menu_count[menu_id] = {
                                                                                'id': menu_id,
                                                                                'name': menuTypeResult,
                                                                                'child': {}
                                                                            };


                                                                            menu_child.forEach((con_box) => {
                                                                                let menu_product_id = (con_box[0]);
                                                                                let menu_child_product = (con_box[1][con_box[1].length - 1]);

                                                                                let count_menu_product = parseInt(menu_child_product['count']) || 0;
                                                                                let count_dop_menu_product = parseInt(menu_child_product['count_dop']) || 0;

                                                                                result_menu_count[menu_id]['child'] = {...result_menu_count[menu_id]['child'], ...{[menu_product_id]: count_menu_product + count_dop_menu_product}};
                                                                            });

                                                                        }

                                                                    });

                                                                    setCreateModalMenuInfo(result_menu_count);
                                                                    setCreateModalMenu(true);
                                                                }}
                                                                     className={'btn-def-table deactivation btn-def-table-w20  text text-s14'}
                                                                     load={openProduct}
                                                                     label={'Добавить шаблон меню'}/>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        // typeMenu && (
                                                        <Btn onClick={async () => {
                                                            // setModalMenu(true);
                                                            // await getProductModal(page);
                                                            setPage(0);
                                                            setOpenMenuModalControl(true);

                                                        }} className={'btn-def-table btn-def-table-w20  active text text-s14'}
                                                             load={openProduct}
                                                             label={'Добавить подачу'}/>
                                                        // )
                                                    }
                                                </div>
                                            </p>


                                            {
                                                pageOpen2 && (
                                                    <div>
                                                        <br/>
                                                        <br/>
                                                        {
                                                            menuType.map((servingItem, indexSMenu) => {


                                                                let rowTableSave = {...productMenuRow};
                                                                delete (rowTableSave['option']);

                                                                rowTableSave['category_name']['name'] = 'Раздел';
                                                                rowTableSave['price']['name'] = 'Цена за ед';

                                                                rowTableSave['gram'] = {
                                                                    name: 'Вес порции',
                                                                    className: "ci-table-th-88",
                                                                    value: "",
                                                                };
                                                                rowTableSave['count'] = {
                                                                    name: 'Кол-во КП',
                                                                    className: "ci-table-th-88",
                                                                    value: "",
                                                                };


                                                                if (countSteps == 2 && window.isRole("Order", 'edit')) {
                                                                    rowTableSave['count_dop'] = {
                                                                        name: 'Доп. ед', className: "ci-table-th-88", value: "",
                                                                    };
                                                                    rowTableSave['count_dop_result'] = {
                                                                        name: 'Общее кол-во',
                                                                        className: "ci-table-th-88",
                                                                        value: "",
                                                                    };
                                                                    rowTableSave['count_result'] = {
                                                                        name: 'Сумма', className: "ci-table-th-88", value: "",
                                                                    };
                                                                } else if (!window.isRole("Order", 'edit')) {
                                                                    rowTableSave['count'] = {
                                                                        name: 'Кол-во', className: "ci-table-th-88", value: "",
                                                                    };
                                                                } else if (window.isRole("Order", 'edit')) {

                                                                    rowTableSave['count_result'] = {
                                                                        name: 'Сумма', className: "ci-table-th-88", value: "",
                                                                    };
                                                                }


                                                                rowTableSave['option'] = {
                                                                    name: '', className: "ci-table-th-88 ", value: "",
                                                                };

                                                                let productSortControl = [];
                                                                let serius = {};
                                                                if (servingItem.id in orderMenuServ) {

                                                                    serius = (orderMenuServ[servingItem.id])
                                                                    // delete (rowTableSave['']);


                                                                    productSortControl = (Object.entries(serius).map(([productId, productArraySingle]) => {

                                                                        let productReturn = productArraySingle[0]['menu'];

                                                                        if (productReturn == null) {
                                                                            return false;
                                                                        }
                                                                        productReturn['count'] = productArraySingle[0]['count'];
                                                                        productReturn['count_dop'] = productArraySingle[0]['count_dop'];
                                                                        productReturn['discont'] = productArraySingle[0]['discont'];
                                                                        productReturn['product_commit'] = productArraySingle[0]['product_commit'];
                                                                        productReturn['update_id'] = productArraySingle[0]['id'];
                                                                        productReturn['pre'] = productArraySingle[0];
                                                                        productReturn['sort'] = productArraySingle[0]['sort'];
                                                                        productReturn['count_result'] = productReturn['count'] * productReturn['price'];


                                                                        let countPlus = parseInt(productArraySingle[0]['count']);
                                                                        if (!(countPlus > 0)) {
                                                                            countPlus = 0;
                                                                        }

                                                                        let countDopPlus = parseInt(productArraySingle[0]['count_dop']);
                                                                        if (!(countDopPlus > 0)) {
                                                                            countDopPlus = 0;
                                                                        }

                                                                        productReturn['count_dop_result'] = countPlus + countDopPlus;

                                                                        if (!window.isRole("Order", 'edit')) {
                                                                            productReturn['count'] = productReturn['count_dop_result'];
                                                                        }

                                                                        return productReturn;
                                                                    }).filter((itsrx) => {

                                                                        let countBox = true;
                                                                        if (countSteps == 0) {
                                                                            countBox = itsrx?.count > 0
                                                                        }

                                                                        return itsrx && countBox;
                                                                    }));

                                                                    productSortControl.sort(function (a, b) {
                                                                        if (a.category_name < b.category_name) {
                                                                            return -1;
                                                                        } else if (a.category_name > b.category_name) {
                                                                            return 1;
                                                                        } else {
                                                                            return a.sort - b.sort;
                                                                        }
                                                                    });

                                                                }


                                                                let storeName = '';


                                                                const handleSortUp = (index) => {
                                                                    if (index > 0) {
                                                                        const updatedMenu = [...menuType];
                                                                        [updatedMenu[index - 1], updatedMenu[index]] = [updatedMenu[index], updatedMenu[index - 1]];

                                                                        updatedMenu.forEach((typeMenu, indexTypeIndex) => {

                                                                            updateDB({
                                                                                'save': {
                                                                                    "sort": updatedMenu.length - indexTypeIndex,
                                                                                },
                                                                                'id': typeMenu['id'],
                                                                                'model_name': 'OrderMenuType',
                                                                            }, false);

                                                                        });

                                                                        setMenuType(updatedMenu);
                                                                    }
                                                                };

                                                                const handleSortDown = (index, menuTypeId) => {

                                                                    if (index < menuType.length - 1) {
                                                                        const updatedMenu = [...menuType];
                                                                        [updatedMenu[index], updatedMenu[index + 1]] = [updatedMenu[index + 1], updatedMenu[index]];

                                                                        updatedMenu.forEach((typeMenu, indexTypeIndex) => {

                                                                            updateDB({
                                                                                'save': {
                                                                                    "sort": updatedMenu.length - indexTypeIndex,
                                                                                },
                                                                                'id': typeMenu['id'],
                                                                                'model_name': 'OrderMenuType',
                                                                            }, false);

                                                                        });
                                                                        setMenuType(updatedMenu);
                                                                    }

                                                                };

                                                                return (
                                                                    <div className={'serving_controls'}>
                                                                        <div className={'text serving_controls_menu text-s18'}
                                                                             style={{
                                                                                 color: "#fff",
                                                                                 padding: "0.75rem 1.5rem",
                                                                                 width: "100%",
                                                                                 backgroundColor: "#FFBD3E",
                                                                                 display: "flex",
                                                                                 alignItems: "center",
                                                                                 justifyContent: "space-between"
                                                                             }}>
                                                                            <div>

                                                                                {
                                                                                    updateIdMenu == servingItem.id && (
                                                                                        <>
                                                                                            <CustomInput onChange={async (e) => {
                                                                                                setUpdateIdMenuValue(e.target.value);
                                                                                                if (e.keyCode == 13) {
                                                                                                    await (updateDB({
                                                                                                        'save': {
                                                                                                            "name": e.target.value,
                                                                                                        },
                                                                                                        'id': updateIdMenu,
                                                                                                        'model_name': 'OrderMenuType',
                                                                                                    }, false));
                                                                                                    let servingBox = (await getModal('OrderMenuType', '?order_id=' + params.id)).data;
                                                                                                    setMenuType(servingBox);
                                                                                                    setUpdateIdMenuValue(false);
                                                                                                    setUpdateIdMenu(false);
                                                                                                }
                                                                                            }} value={updateIdMenuValue} name={'search'}
                                                                                                         label={'Изменить название / Enter жми'}/>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    updateIdMenu != servingItem.id && (
                                                                                        <>
                                                                                            {servingItem.name}
                                                                                        </>
                                                                                    )
                                                                                }


                                                                            </div>

                                                                            <div style={{display: "flex", alignItems: "center"}}>

                                                                                <div className="icon-sort">
                                                                                    <div
                                                                                        onClick={() => handleSortUp(indexSMenu, servingItem['id'])}
                                                                                        className="icon-sort-up"></div>
                                                                                    <div
                                                                                        onClick={() => handleSortDown(indexSMenu, servingItem['id'])}
                                                                                        className="icon-sort-down"></div>
                                                                                </div>

                                                                                <Btn onClick={async () => {

                                                                                    await deleteDataBase({
                                                                                        id: servingItem['id']
                                                                                    }, 'OrderMenuType');

                                                                                    let servingBox = (await getModal('OrderMenuType', '?order_id=' + params.id)).data;
                                                                                    setMenuType(servingBox);

                                                                                    getOrderSend().then();

                                                                                }}
                                                                                     className={'ci-btn ci-btn-def btn-def-table  btn-def-table-w20  text text-s14'}
                                                                                     load={openProduct}
                                                                                     label={'Удалить '}/>

                                                                                <Btn onClick={async () => {
                                                                                    setUpdateIdMenuValue(servingItem.name);
                                                                                    setUpdateIdMenu(servingItem.id);
                                                                                }}
                                                                                     className={'ci-btn ci-btn-def btn-def-table  btn-def-table-w20  text text-s14'}
                                                                                     load={openProduct}
                                                                                     label={'Редактировать '}/>

                                                                                <Btn onClick={async () => {
                                                                                    setPage(0);
                                                                                    setOpenProduct(true);
                                                                                    setSearch('');
                                                                                    await getProductSenPre(0, true);
                                                                                    setMenuId(servingItem.id);
                                                                                    setModalMenu(true);
                                                                                    setOpenProduct(false);
                                                                                }}
                                                                                     className={'ci-btn ci-btn-def btn-def-table  btn-def-table-w20  text text-s14'}
                                                                                     load={openProduct}
                                                                                     label={'Добавить '}/>
                                                                            </div>
                                                                        </div>

                                                                        <div className="serving_controls_body">
                                                                            <div
                                                                                style={{marginTop: 0}}
                                                                                className={"edit-table " + ((storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары') && 'grenn-box')}>

                                                                                <div className="edit-table__body">
                                                                                    <Table th={{
                                                                                        ...(rowTableSave)
                                                                                    }}
                                                                                           edit={openEditMenu}
                                                                                           openOrder={openOrder}
                                                                                           thSelect={thSelectInfo}
                                                                                           onCreate={async (boxInput) => {

                                                                                               let sortNumber = 0;


                                                                                               Object.entries(serius).map(async (item, index) => {

                                                                                                   if (!(parseInt(item[1][0]['sort']) >= 0)) {
                                                                                                       if (boxInput['pre']['id'] == item[1][0]['id']) {
                                                                                                           sortNumber = index * 1000;
                                                                                                       }
                                                                                                       const updateDataOrder = await (updateDB({
                                                                                                           'save': {
                                                                                                               "sort": index * 1000,
                                                                                                           },
                                                                                                           'id': item[1][0]['id'],
                                                                                                           'model_name': 'OrderProduct',
                                                                                                       }, false));
                                                                                                   } else {
                                                                                                       if (boxInput['pre']['id'] == item[1][0]['id']) {
                                                                                                           sortNumber = parseInt(item[1][0]['sort']);
                                                                                                       }
                                                                                                   }

                                                                                               });


                                                                                               let orderMenuSave = (await getOrderMenuPos(params.id)).data;


                                                                                               orderMenuSave[servingItem.id][0] = [
                                                                                                   {
                                                                                                       "id": 0,
                                                                                                       "order_id": params.id,
                                                                                                       "created_at": "2023-06-29T04:06:18.000000Z",
                                                                                                       "updated_at": "2023-06-29T04:15:10.000000Z",
                                                                                                       "count": "1",
                                                                                                       "menu_type_id": servingItem.id,
                                                                                                       "status": "new",
                                                                                                       "table": "order",
                                                                                                       "type": "rent",
                                                                                                       "sort": sortNumber + 1,
                                                                                                       "menu": {
                                                                                                           "id": 0,
                                                                                                           "created_at": "2023-06-14T08:57:25.000000Z",
                                                                                                           "updated_at": "2023-06-21T06:14:58.000000Z",
                                                                                                           "name": "Выбор товара",
                                                                                                           "photo": "",
                                                                                                           "category_name": boxInput['category_name'],
                                                                                                           "price": "0",
                                                                                                           "gram": "_"
                                                                                                       },
                                                                                                       "product": {
                                                                                                           "id": 0,
                                                                                                           "created_at": "2023-06-14T08:57:25.000000Z",
                                                                                                           "updated_at": "2023-06-21T06:14:58.000000Z",
                                                                                                           "name": "Выбор товара",
                                                                                                           "photo": "",
                                                                                                           "category_name": boxInput['category_name'],
                                                                                                           "price": "0",
                                                                                                           "gram": "_"
                                                                                                       }
                                                                                                   }
                                                                                               ];


                                                                                               setOrderMenuServ(orderMenuSave);


                                                                                           }}
                                                                                           productOption={true}
                                                                                           modalName={'Product'}
                                                                                           tr={productSortControl}
                                                                                           option={(id, row) => {

                                                                                               return (<div
                                                                                                   className={'table-options table-options-sm-flex'}>


                                                                                                   <div
                                                                                                       className="edit-box text text-s14"
                                                                                                       style={{
                                                                                                           marginRight: "3rem",
                                                                                                           width: "10rem"
                                                                                                       }}>
                                                                                                       <b>{row['product_commit']}</b>
                                                                                                   </div>


                                                                                                   {
                                                                                                       window.isRole("Order", 'edit') && (
                                                                                                           <>


                                                                                                               {
                                                                                                                   countSteps == 0 && (
                                                                                                                       <CustomInput
                                                                                                                           key={row['update_id'] + 1000000}
                                                                                                                           className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                                                           onChange={async (e) => {


                                                                                                                               let countValue = parseInt(e.target.value);

                                                                                                                               if (countValue <= 0 || e.target.value == '') {
                                                                                                                                   countValue = 0;
                                                                                                                               }

                                                                                                                               let preUpdate = {...orderMenuServ};
                                                                                                                               preUpdate[servingItem.id][row['id']][0]['discont'] = countValue;
                                                                                                                               setOrderMenuServ(preUpdate);

                                                                                                                               async function senUpdateInfo(e, row) {
                                                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                                                       'save': {
                                                                                                                                           "discont": countValue,
                                                                                                                                       },
                                                                                                                                       'id': row['update_id'],
                                                                                                                                       'model_name': 'OrderProduct',
                                                                                                                                   }, false));
                                                                                                                               }

                                                                                                                               clearTimeout(window.timeUpdateSend)

                                                                                                                               window.timeUpdateSend = setTimeout(() => {
                                                                                                                                   senUpdateInfo(e, row);
                                                                                                                               }, 1200);


                                                                                                                           }}
                                                                                                                           value={row['discont']}
                                                                                                                           type={'number'}
                                                                                                                           label={'Скидка'}/>
                                                                                                                   )
                                                                                                               }

                                                                                                               <CustomInput
                                                                                                                   className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                                                   onChange={async (e) => {


                                                                                                                       let countValue = parseInt(e.target.value);

                                                                                                                       if (countValue <= 0 || e.target.value == '') {
                                                                                                                           countValue = 1;
                                                                                                                       }

                                                                                                                       if (countSteps == 0) {


                                                                                                                           let preUpdate = {...orderMenuServ};
                                                                                                                           preUpdate[servingItem.id][row['id']][0]['count'] = countValue;
                                                                                                                           setOrderMenu(preUpdate);


                                                                                                                           async function senUpdateInfo(e, row) {
                                                                                                                               const updateDataOrder = (await updateDB({
                                                                                                                                   'save': {
                                                                                                                                       "count": countValue,
                                                                                                                                   },
                                                                                                                                   'id': row['update_id'],
                                                                                                                                   'model_name': 'OrderProduct',
                                                                                                                               }, false));
                                                                                                                               calcultationSet();
                                                                                                                           }

                                                                                                                           clearTimeout(window.timeUpdateSend)

                                                                                                                           window.timeUpdateSend = setTimeout(() => {
                                                                                                                               senUpdateInfo(e, row);
                                                                                                                           }, 1200);
                                                                                                                       } else {

                                                                                                                           let preUpdate = {...orderMenuServ};
                                                                                                                           preUpdate[servingItem.id][row['id']][0]['count_dop'] = countValue;
                                                                                                                           setOrderMenuServ(preUpdate);

                                                                                                                           async function senUpdateInfo(e, row) {
                                                                                                                               const updateDataOrder = (await updateDB({
                                                                                                                                   'save': {
                                                                                                                                       "count_dop": countValue,
                                                                                                                                   },
                                                                                                                                   'id': row['update_id'],
                                                                                                                                   'model_name': 'OrderProduct',
                                                                                                                               }, false));
                                                                                                                               calcultationSet();
                                                                                                                           }

                                                                                                                           clearTimeout(window.timeUpdateSend)

                                                                                                                           window.timeUpdateSend = setTimeout(() => {
                                                                                                                               senUpdateInfo(e, row);
                                                                                                                           }, 1200);

                                                                                                                       }

                                                                                                                   }}
                                                                                                                   value={countSteps == 2 ? row['count_dop'] : row['count']}
                                                                                                                   type={'number'}
                                                                                                                   label={countSteps == 2 ? 'Доп. ед' : 'Кол-во'}/>


                                                                                                               <CustomInput
                                                                                                                   onBlur={async (e) => {

                                                                                                                       const updateDataOrder = (await updateDB({
                                                                                                                           'save': {
                                                                                                                               "product_commit": e.target.value,
                                                                                                                           },
                                                                                                                           'id': row['update_id'],
                                                                                                                           'model_name': 'OrderProduct',
                                                                                                                       }, false));

                                                                                                                       if (window?.timeSend) {
                                                                                                                           clearTimeout(window.timeSend);
                                                                                                                       }

                                                                                                                       window.timeSend = setTimeout(async () => {
                                                                                                                           const orderProductSave = (await getOrderProduct(params.id)).data;
                                                                                                                           setOrderProduct(orderProductSave);
                                                                                                                       }, 500);

                                                                                                                   }}
                                                                                                                   className={'edit-product-order edit-product-order-edit'}
                                                                                                                   onChange={async (e) => {

                                                                                                                       async function senUpdateInfo(e, row) {
                                                                                                                           const updateDataOrder = (await updateDB({
                                                                                                                               'save': {
                                                                                                                                   "product_commit": e.target.value,
                                                                                                                               },
                                                                                                                               'id': row['update_id'],
                                                                                                                               'model_name': 'OrderProduct',
                                                                                                                           }, false));
                                                                                                                       }

                                                                                                                       clearTimeout(window.timeUpdateSend)

                                                                                                                       window.timeUpdateSend = setTimeout(() => {
                                                                                                                           senUpdateInfo(e, row);
                                                                                                                       }, 1000);
                                                                                                                       let preUpdate = {...orderMenuServ};
                                                                                                                       preUpdate[servingItem.id][row['id']][0]['product_commit'] = e.target.value;
                                                                                                                       setOrderMenuServ(preUpdate);


                                                                                                                   }}
                                                                                                                   value={row['product_commit']}
                                                                                                                   type={'text'}
                                                                                                                   label={'Примечание'}/>


                                                                                                               <BtnIcon
                                                                                                                   onClick={async () => {

                                                                                                                       let preUpdate = {...orderMenuServ};

                                                                                                                       getOrderSend().then();
                                                                                                                   }}
                                                                                                                   productValue={row}
                                                                                                                   ahe={true}/>

                                                                                                               <BtnIcon
                                                                                                                   onClick={async (e) => {

                                                                                                                       let countValue = 0;

                                                                                                                       if (countSteps == 0) {


                                                                                                                           let preUpdate = {...orderMenuServ};
                                                                                                                           preUpdate[servingItem.id][row['id']][0]['count'] = countValue;
                                                                                                                           setOrderMenu(preUpdate);

                                                                                                                           if (parseInt(preUpdate[servingItem.id][row['id']][0]['count_dop']) == 0 && parseInt(preUpdate[servingItem.id][row['id']][0]['count']) == 0) {
                                                                                                                               await deleteDataBase({
                                                                                                                                   id: row['update_id']
                                                                                                                               }, 'OrderProduct');
                                                                                                                               getOrderSend().then();
                                                                                                                           } else {
                                                                                                                               async function senUpdateInfo(e, row) {
                                                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                                                       'save': {
                                                                                                                                           "count": countValue,
                                                                                                                                       },
                                                                                                                                       'id': row['update_id'],
                                                                                                                                       'model_name': 'OrderProduct',
                                                                                                                                   }, false));
                                                                                                                                   getOrderSend().then();

                                                                                                                               }

                                                                                                                               senUpdateInfo(e, row);
                                                                                                                           }
                                                                                                                       } else {

                                                                                                                           let preUpdate = {...orderMenuServ};
                                                                                                                           preUpdate[servingItem.id][row['id']][0]['count_dop'] = countValue;
                                                                                                                           setOrderMenuServ(preUpdate);

                                                                                                                           if (parseInt(preUpdate[servingItem.id][row['id']][0]['count_dop']) == 0 && parseInt(preUpdate[servingItem.id][row['id']][0]['count']) == 0) {
                                                                                                                               await deleteDataBase({
                                                                                                                                   id: row['update_id']
                                                                                                                               }, 'OrderProduct');
                                                                                                                               calcultationSet();
                                                                                                                               getOrderSend().then();
                                                                                                                           } else {
                                                                                                                               async function senUpdateInfo(e, row) {
                                                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                                                       'save': {
                                                                                                                                           "count_dop": countValue,
                                                                                                                                       },
                                                                                                                                       'id': row['update_id'],
                                                                                                                                       'model_name': 'OrderProduct',
                                                                                                                                   }, false));
                                                                                                                                   calcultationSet();
                                                                                                                                   getOrderSend().then();

                                                                                                                               }

                                                                                                                               senUpdateInfo(e, row);
                                                                                                                           }

                                                                                                                       }


                                                                                                                   }}
                                                                                                                   img={require('../../assets/images/trash.png')}
                                                                                                               />
                                                                                                           </>
                                                                                                       )
                                                                                                   }
                                                                                               </div>)
                                                                                           }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }

                                {
                                    window.isRole("Order", 'edit') && menuType.length > 0 && (
                                        <div style={{
                                            marginTop: "2rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                            <span onClick={() => {
                                setPageOpen8(!pageOpen8);
                            }} style={{display: "flex", alignItems: "center"}}>
                             <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                  src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/>  Доп позиции:
                            </span>
                                            </p>

                                            {
                                                pageOpen8 && (<div>
                                                    <br/>
                                                    <br/>
                                                    <div style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                                                        {
                                                            Object.entries(dopMenu).map((item, index_control) => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            marginRight: "2rem",
                                                                            width: "100%",
                                                                            marginBottom: "2rem",
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }} className={'ci-element-contr'}>
                                                                        <div className="ci-element-contr_body">
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <CustomFile fileName={true}
                                                                                            onChange={(e) => {

                                                                                                setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{images: e.target.value}}}});

                                                                                            }} value={item[1]?.images}
                                                                                            name={''}
                                                                                            labelName={'Загрузить файл'}/>
                                                                            </div>


                                                                            <CustomSelect onChange={(e) => {
                                                                                setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{catalog_name: e.target.value}}}});

                                                                            }}
                                                                                          options={([...groupTableMenu].reduce((ass, val) => {
                                                                                              return {...ass, ...{[val.category_name]: val.category_name}};
                                                                                          }, {}))}
                                                                                          value={item[1]?.catalog_name}
                                                                                          label={'Все разделы'}/>


                                                                            <div
                                                                                className={`ci-input-custom-box `}>
                                                                                <div
                                                                                    className={'text  text-s10   ci-input-custom-box_label'}>Подача
                                                                                </div>
                                                                                <div
                                                                                    className="ci-input-custom ci-input-edit">
                                                                                    <select onChange={(e) => {

                                                                                        setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{catalog_box: e.target.value}}}});

                                                                                    }} required={true}
                                                                                            className={'text text-s14  ci-input-edit_in'}>
                                                                                        <option>Выбор подачи</option>

                                                                                        {
                                                                                            menuType.map((boxItem) => {
                                                                                                return (
                                                                                                    <option
                                                                                                        selected={item[1]?.catalog_box == boxItem.id}
                                                                                                        value={boxItem.id}>
                                                                                                        {boxItem.name}
                                                                                                    </option>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </div>


                                                                            <CustomInput onChange={(e) => {
                                                                                setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{name: e.target.value}}}});
                                                                            }}
                                                                                         value={item[1]?.name}
                                                                                         name={'decor_name'}
                                                                                         label={'Название'}/>

                                                                            <CustomInput onChange={(e) => {
                                                                                setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{price: e.target.value}}}});

                                                                                calcultationSet();
                                                                            }}
                                                                                         value={item[1]?.price}
                                                                                         name={'decor_name'}
                                                                                         label={'Цена за ед'}/>


                                                                            <div
                                                                                className="ci-input-custom-box undefined false">
                                                                                <div
                                                                                    className={'text  text-s10  ci-placeholder-edit '}>Вес
                                                                                    порции
                                                                                </div>
                                                                                <div
                                                                                    className="ci-input-custom  ci-input-edit  undefined">
                                                                                    <select onChange={(e) => {

                                                                                        setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{gram2: e.target.value}}}});
                                                                                        calcultationSet();
                                                                                    }}
                                                                                            className={'text text-s14  ci-input-edit_in'}>
                                                                                        <option>Вес порции</option>
                                                                                        <option
                                                                                            selected={item[1]?.gram2 == 'Гр'}
                                                                                            value={'Гр'}>Гр
                                                                                        </option>
                                                                                        <option
                                                                                            selected={item[1]?.gram2 == 'Мл'}
                                                                                            value={'Мл'}>Мл
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>

                                                                            <CustomInput onChange={(e) => {
                                                                                setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{gram: e.target.value}}}});

                                                                                calcultationSet();
                                                                            }}
                                                                                         value={item[1]?.gram}
                                                                                         name={'decor_name'}
                                                                                         label={'Вес порции'}/>


                                                                            <CustomInput onChange={(e) => {
                                                                                if (countSteps == 2) {
                                                                                    setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{count_dop: e.target.value}}}});
                                                                                } else {
                                                                                    setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{count: e.target.value}}}});
                                                                                }

                                                                                calcultationSet();
                                                                            }}
                                                                                         type={'number'}
                                                                                         value={countSteps == 2 ? item[1]?.count_dop : item[1]?.count}
                                                                                         name={'decor_count'}

                                                                                         label={countSteps == 2 ? 'Доп ед' : 'Количество'}/>


                                                                            <p className={'text text-s18'}>
                                                                                {'Сумма: ' + (parseInt(item[1]?.count) > 0 ? parseInt(item[1]?.count) : 0) * (parseInt(item[1]?.price) > 0 ? parseInt(item[1]?.price) : 0)}
                                                                            </p>


                                                                            <div style={{
                                                                                width: "10rem",
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <button title={''} type={'button'}
                                                                                        onClick={async (e) => {

                                                                                            let newValue = item[1]?.agn != '1' ? '1' : '0';

                                                                                            setDopMenu({...dopMenu, ...{[item[0]]: {...item[1], ...{agn: newValue}}}});


                                                                                            calcultationSet();


                                                                                        }}
                                                                                        className={` ci-btn-icon ci-btn-icon-age ` + (item[1]?.agn != '1' && ('active'))}>
                                                            <span className={'text text-s16'}>
                                                               %
                                                            </span>
                                                                                </button>
                                                                            </div>

                                                                            <p onClick={() => {

                                                                                let isd = {...dopMenu};
                                                                                delete isd[item[0]];
                                                                                setDopMenu(isd);

                                                                            }} style={{
                                                                                color: "red",
                                                                                cursor: 'pointer',
                                                                                marginLeft: "2rem",
                                                                                marginBottom: "0",
                                                                                alignItems: "center",
                                                                                display: "flex",
                                                                                width: "1rem"
                                                                            }}
                                                                               className={'text text-s18'}>
                                                                                <div
                                                                                    className="delete-button">&#10006;</div>
                                                                            </p>


                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>


                                                    {
                                                        window.isRole("Order", 'edit') && (
                                                            <Btn onClick={async () => {

                                                                function randomIntFromInterval(min, max) { // min and max included
                                                                    return Math.floor(Math.random() * (max - min + 1) + min)
                                                                }


                                                                let decorControls = {...dopMenu};

                                                                let keys = Object.keys(decorControls).reduce((ass, li) => {
                                                                    return ass + parseInt(li);
                                                                }, 0);
                                                                if (keys == 0) {
                                                                    keys = randomIntFromInterval(0, 40000);
                                                                } else {
                                                                    keys++;
                                                                }
                                                                setDopMenu({});


                                                                setTimeout(() => {
                                                                    setDopMenu({
                                                                        ...dopMenu, ...{
                                                                            [keys]: {
                                                                                'name': '',
                                                                                'price': '',
                                                                                'images': '',
                                                                                'gram': '',
                                                                                'count': 0,
                                                                                'count_dop': 0,
                                                                                'agn': 0,
                                                                                'comment': '',
                                                                                'catalog_name': '',
                                                                            }
                                                                        }
                                                                    });
                                                                }, 300);


                                                            }} className={'btn-def-table btn-def-table-w20  active text text-s14'}
                                                                 label={'Добавить позицию'}/>
                                                        )
                                                    }


                                                </div>)
                                            }
                                        </div>
                                    )
                                }

                                {
                                    window.isRole("Order", 'edit') && is_prsonal && (
                                        <div style={{
                                            marginTop: "2rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                            <span onClick={() => {
                                setPageOpen3(!pageOpen3);
                            }} style={{display: "flex", alignItems: "center"}}>
                             <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                  src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/>   Персонал:
                            </span>
                                                <Btn onClick={async () => {
                                                    setModalPersonal(true);
                                                    await getProductModal(page);
                                                }} className={'btn-def-table btn-def-table-w20 active text text-s14'}
                                                     load={openProduct}
                                                     label={'Добавить персонал'}/>
                                            </p>

                                            {
                                                pageOpen3 && Object.entries(orderPersonal).map(([storeName, productArray]) => {

                                                    let rowTableSave = {...rowPersonal};
                                                    delete (rowTableSave['option']);


                                                    rowTableSave['count'] = {
                                                        name: 'Кол-во КП', className: "ci-table-th-88", value: "",
                                                    };


                                                    if (countSteps == 2 && window.isRole("Order", 'edit')) {
                                                        rowTableSave['count_dop'] = {
                                                            name: 'Доп. ед', className: "ci-table-th-88", value: "",
                                                        };
                                                        rowTableSave['count_dop_result'] = {
                                                            name: 'Общее кол-во', className: "ci-table-th-88", value: "",
                                                        };
                                                        rowTableSave['count_result'] = {
                                                            name: 'Сумма', className: "ci-table-th-88", value: "",
                                                        };
                                                    } else if (!window.isRole("Order", 'edit')) {
                                                        rowTableSave['count'] = {
                                                            name: 'Кол-во', className: "ci-table-th-88", value: "",
                                                        };
                                                    } else if (window.isRole("Order", 'edit')) {

                                                        rowTableSave['count_result'] = {
                                                            name: 'Сумма', className: "ci-table-th-88", value: "",
                                                        };
                                                    }


                                                    rowTableSave['option'] = {
                                                        name: '', className: "ci-table-th-88 ", value: "",
                                                    };
                                                    let serius = (productArray)


                                                    // delete (rowTableSave['']);

                                                    return (
                                                        <div
                                                            style={{marginTop: 0}}
                                                            className={"edit-table " + ((storeName == 'Суб-аренда Decoretto' || storeName == 'Доп товары') && 'grenn-box')}>
                                                            <div className="edit-table__body">
                                                                <Table th={{
                                                                    ...(rowTableSave)
                                                                }}

                                                                       productOption={true}
                                                                       modalName={'Product'}
                                                                       tr={(Object.entries(serius).map(([productId, productArraySingle]) => {

                                                                               let productReturn = productArraySingle[0]['personal'];
                                                                               if (productReturn == null) {
                                                                                   return false;
                                                                               }
                                                                               productReturn['count'] = productArraySingle[0]['count'];
                                                                               productReturn['count_dop'] = productArraySingle[0]['count_dop'];
                                                                               productReturn['product_commit'] = productArraySingle[0]['product_commit'];
                                                                               productReturn['update_id'] = productArraySingle[0]['id'];
                                                                               productReturn['discont'] = productArraySingle[0]['discont'];
                                                                               productReturn['dop_time'] = productArraySingle[0]['dop_time'];
                                                                               productReturn['pre'] = productArraySingle[0];


                                                                               let countPlus = parseInt(productArraySingle[0]['count']);
                                                                               if (!(countPlus > 0)) {
                                                                                   countPlus = 0;
                                                                               }

                                                                               let countDopPlus = parseInt(productArraySingle[0]['count_dop']);
                                                                               if (!(countDopPlus > 0)) {
                                                                                   countDopPlus = 0;
                                                                               }
                                                                               productReturn['count_dop_result'] = countPlus + countDopPlus;

                                                                               let priceDop = 0;
                                                                               if (parseInt(productReturn['dop_time']) > 0) {
                                                                                   priceDop = parseInt(productReturn['dop_time']);
                                                                               }

                                                                               productReturn['count_result'] = productReturn['count'] * productReturn['price'] + ((4000 * priceDop) * productReturn['count']);

                                                                               if (!window.isRole("Order", 'edit')) {
                                                                                   productReturn['count'] = productReturn['count_dop_result'];
                                                                               }
                                                                               return productReturn;
                                                                           }).filter((itsrx) => {
                                                                               return itsrx;
                                                                           }).filter((itsrx) => {

                                                                               let countBox = true;
                                                                               if (countSteps == 0) {
                                                                                   countBox = itsrx?.count > 0
                                                                               }


                                                                               return countBox;
                                                                           })
                                                                       )
                                                                       }
                                                                       option={(id, row, index) => {
                                                                           return (
                                                                               <div
                                                                                   className={'table-options table-options-sm-flex'}>

                                                                                   {
                                                                                       window.isRole("Order", 'edit') && (
                                                                                           <>

                                                                                               <CustomInput
                                                                                                   className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                                   onChange={async (e) => {

                                                                                                       if (countSteps == 0) {
                                                                                                           let preUpdate = {...orderPersonal};
                                                                                                           preUpdate[''][row['id']][0]['count'] = e.target.value;
                                                                                                           setOrderPersonal(preUpdate);

                                                                                                           async function senUpdateInfo(e, row) {
                                                                                                               const updateDataOrder = (await updateDB({
                                                                                                                   'save': {
                                                                                                                       "count": e.target.value,
                                                                                                                   },
                                                                                                                   'id': row['update_id'],
                                                                                                                   'model_name': 'OrderProduct',
                                                                                                               }, false));
                                                                                                               calcultationSet();
                                                                                                           }

                                                                                                           clearTimeout(window.timeUpdateSend)

                                                                                                           window.timeUpdateSend = setTimeout(() => {
                                                                                                               senUpdateInfo(e, row);
                                                                                                           }, 1200);
                                                                                                       } else {

                                                                                                           let preUpdate = {...orderPersonal};
                                                                                                           preUpdate[''][row['id']][0]['count_dop'] = e.target.value;
                                                                                                           setOrderPersonal(preUpdate);

                                                                                                           async function senUpdateInfo(e, row) {
                                                                                                               const updateDataOrder = (await updateDB({
                                                                                                                   'save': {
                                                                                                                       "count_dop": e.target.value,
                                                                                                                   },
                                                                                                                   'id': row['update_id'],
                                                                                                                   'model_name': 'OrderProduct',
                                                                                                               }, false));
                                                                                                               calcultationSet();
                                                                                                           }

                                                                                                           clearTimeout(window.timeUpdateSend)

                                                                                                           window.timeUpdateSend = setTimeout(() => {
                                                                                                               senUpdateInfo(e, row);
                                                                                                           }, 1200);

                                                                                                       }

                                                                                                   }}
                                                                                                   value={countSteps == 2 ? row['count_dop'] : row['count']}
                                                                                                   type={'number'}
                                                                                                   label={countSteps == 2 ? 'Доп. ед' : 'Количество'}
                                                                                               />


                                                                                               {
                                                                                                   countSteps == 0 && (
                                                                                                       <CustomInput
                                                                                                           className={'edit-product-order edit-product-order-edit'}
                                                                                                           onChange={async (e) => {
                                                                                                               let preUpdate = {...orderPersonal};
                                                                                                               preUpdate[''][row['id']][0]['dop_time'] = e.target.value;
                                                                                                               setOrderPersonal(preUpdate);

                                                                                                               async function senUpdateInfo(e, row) {
                                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                                       'save': {
                                                                                                                           "dop_time": e.target.value,
                                                                                                                       },
                                                                                                                       'id': row['update_id'],
                                                                                                                       'model_name': 'OrderProduct',
                                                                                                                   }, false));
                                                                                                               }

                                                                                                               clearTimeout(window.timeUpdateSend)

                                                                                                               window.timeUpdateSend = setTimeout(() => {
                                                                                                                   senUpdateInfo(e, row);
                                                                                                               }, 1200);


                                                                                                           }} value={row['dop_time']}
                                                                                                           type={'number'}
                                                                                                           label={'Доп Время'}/>
                                                                                                   )
                                                                                               }

                                                                                               {
                                                                                                   countSteps == 0 && (
                                                                                                       <CustomInput
                                                                                                           key={row['update_id'] + 1000000}
                                                                                                           className={'edit-product-order mini-btn mini-btn-big edit-product-order-edit'}
                                                                                                           onChange={async (e) => {

                                                                                                               let preUpdate = {...orderPersonal};
                                                                                                               preUpdate[''][row['id']][0]['discont'] = e.target.value;
                                                                                                               setOrderPersonal(preUpdate);

                                                                                                               async function senUpdateInfo(e, row) {
                                                                                                                   const updateDataOrder = (await updateDB({
                                                                                                                       'save': {
                                                                                                                           "discont": e.target.value,
                                                                                                                       },
                                                                                                                       'id': row['update_id'],
                                                                                                                       'model_name': 'OrderProduct',
                                                                                                                   }, false));
                                                                                                               }

                                                                                                               clearTimeout(window.timeUpdateSend)

                                                                                                               window.timeUpdateSend = setTimeout(() => {
                                                                                                                   senUpdateInfo(e, row);
                                                                                                               }, 1200);


                                                                                                           }} value={row['discont']}
                                                                                                           type={'number'}
                                                                                                           label={'Скидка'}/>
                                                                                                   )
                                                                                               }


                                                                                               <BtnIcon onClick={async () => {

                                                                                                   let preUpdate = {...orderPersonal};

                                                                                                   getOrderSend().then();
                                                                                               }}
                                                                                                        productValue={row}
                                                                                                        ahe={true}/>

                                                                                               <BtnIcon onClick={async () => {


                                                                                                   let preUpdate = {...orderPersonal};


                                                                                                   if (countSteps == 0) {
                                                                                                       preUpdate[''][row['id']][0]['count'] = 0;
                                                                                                       setOrderPersonal(preUpdate);

                                                                                                       async function senUpdateInfo(row) {
                                                                                                           const updateDataOrder = (await updateDB({
                                                                                                               'save': {
                                                                                                                   "count": 0,
                                                                                                               },
                                                                                                               'id': row['update_id'],
                                                                                                               'model_name': 'OrderProduct',
                                                                                                           }, false));
                                                                                                       }


                                                                                                       await senUpdateInfo(row);
                                                                                                   } else {

                                                                                                       preUpdate[''][row['id']][0]['count_dop'] = 0;
                                                                                                       setOrderPersonal(preUpdate);

                                                                                                       async function senUpdateInfo(row) {
                                                                                                           const updateDataOrder = (await updateDB({
                                                                                                               'save': {
                                                                                                                   "count_dop": 0,
                                                                                                               },
                                                                                                               'id': row['update_id'],
                                                                                                               'model_name': 'OrderProduct',
                                                                                                           }, false));

                                                                                                       }


                                                                                                       await senUpdateInfo(row);

                                                                                                   }

                                                                                                   let dopCountSave = parseInt(preUpdate[''][row['id']][0]['count_dop']);
                                                                                                   if (!(dopCountSave > 0)) {
                                                                                                       dopCountSave = 0;
                                                                                                   }
                                                                                                   let countSave = parseInt(preUpdate[''][row['id']][0]['count']);
                                                                                                   if (!(countSave > 0)) {
                                                                                                       countSave = 0;
                                                                                                   }
                                                                                                   if ((dopCountSave == 0) && countSave == 0) {
                                                                                                       await deleteDataBase({
                                                                                                           id: row['update_id']
                                                                                                       }, 'OrderProduct')
                                                                                                   }
                                                                                                   calcultationSet();
                                                                                                   getOrderSend().then();


                                                                                               }}
                                                                                                        img={require('../../assets/images/trash.png')}
                                                                                               />
                                                                                           </>
                                                                                       )
                                                                                   }


                                                                                   {/*<div className={'text text-s16'}*/}
                                                                                   {/*     style={{*/}
                                                                                   {/*         display: "flex",*/}
                                                                                   {/*         position: "relative",*/}
                                                                                   {/*         alignItems: "center",*/}
                                                                                   {/*         top: "0.15rem",*/}
                                                                                   {/*         marginLeft: "1rem",*/}
                                                                                   {/*         paddingRight: "1rem"*/}
                                                                                   {/*     }}>*/}
                                                                                   {/*    {(([rowsDb[0], rowsDb[1], rowsDb[2]].map((typpp) => {*/}

                                                                                   {/*        let countDb = parseInt(((id in productIn && typpp[1] in productIn[id]) ? productIn[id][typpp[1]]['count'] : 0));*/}
                                                                                   {/*        if (countDb > 0) {*/}
                                                                                   {/*            return countDb;*/}
                                                                                   {/*        }*/}
                                                                                   {/*        return 0;*/}

                                                                                   {/*    })).reduce((ass, item) => {*/}
                                                                                   {/*        return ass + item;*/}
                                                                                   {/*    }, 0))}*/}
                                                                                   {/*</div>*/}

                                                                               </div>)
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                }


                                {
                                    window.isRole("Order", 'edit') && is_logistica && (
                                        <div style={{
                                            marginTop: "2rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                            <span onClick={() => {
                                setPageOpen4(!pageOpen4);
                            }} style={{display: "flex", alignItems: "center"}}>
                             <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                  src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/>   Логистика:
                            </span>

                                            </p>

                                            {
                                                pageOpen4 && (<div>

                                                    <br/>
                                                    <br/>
                                                    <div style={{width: "10rem"}}>
                                                        <button title={''} type={'button'} onClick={async (e) => {


                                                            let newValue = orderSingle?.logistic_ah != '1' ? '1' : '0';

                                                            setOrderSingle({...orderSingle, ...{[e.target.name]: newValue}});

                                                            await updateInfoInput(
                                                                {
                                                                    target: {
                                                                        value: newValue,
                                                                        name: 'logistic_ah'
                                                                    }
                                                                }
                                                            )

                                                            getOrderSend().then();


                                                        }}
                                                                className={` ci-btn-icon ci-btn-icon-age ` + (orderSingle?.logistic_ah != '1' && ('active'))}>
                                                            <span className={'text text-s16'}>
                                                               %
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <br/>
                                                    <br/>
                                                    <div className="ci-input-custom-box undefined false">
                                                        <div className="ci-input-custom  ci-input-edit  undefined">
                                                            <select className={'text text-s14  ci-input-edit_in'}>
                                                                <option>Тип логистики</option>
                                                                <option value={'в черте города'}>в черте города</option>
                                                                <option value={'за городам'}>за городам</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <br/>

                                                    <CustomInput onChange={updateInfoInput} value={orderSingle?.logistic_bas}
                                                                 name={'logistic_bas'}
                                                                 label={'Количество газелей'}/>
                                                    <br/>
                                                    <CustomInput onChange={updateInfoInput} value={orderSingle?.logistic_louder}
                                                                 name={'logistic_louder'}
                                                                 label={'Количество грузчиков'}/>
                                                    <br/>
                                                    <CustomInput onChange={updateInfoInput} type={'number'}
                                                                 value={orderSingle?.logistic_price}
                                                                 name={'logistic_price'}
                                                                 label={'Сумма'}/>
                                                    <br/>
                                                    <CustomInput onChange={updateInfoInput} value={orderSingle?.logistic_comment}
                                                                 name={'logistic_comment'}
                                                                 label={'Примечание'}/>

                                                </div>)
                                            }
                                        </div>
                                    )
                                }

                                {
                                    window.isRole("Order", 'edit') && is_decor_s && (
                                        <div style={{
                                            marginTop: "2rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                            <span onClick={() => {
                                setPageOpen5(!pageOpen5);
                            }} style={{display: "flex", alignItems: "center"}}>
                             <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                  src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/>   Декор:
                            </span>
                                                <p className={'text text-sm-big text-s18'} style={{flexDirection: "row"}}>
                                                    <span style={{color: "red"}}>(!)</span> сумма не умножается на количество
                                                </p>

                                            </p>


                                            {
                                                pageOpen5 && (<div>
                                                    <br/>
                                                    <br/>
                                                    <div style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>

                                                        {
                                                            countSteps != 0 && Object.entries(decorList).map((item, index_control) => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            marginRight: "2rem",
                                                                            width: "100%",
                                                                            marginBottom: "2rem",
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }} className={'ci-element-contr'}>
                                                                        <div className="ci-element-contr_body">

                                                                            <div style={{display: "flex", alignItems: "center"}}>
                                                                                <CustomFile disable={true} onChange={(e) => {

                                                                                }} value={item[1]?.images} name={''}
                                                                                            labelName={'Загрузить файл'}/>
                                                                            </div>
                                                                            <CustomInput disabled={true} onChange={(e) => {


                                                                            }}
                                                                                         value={item[1]?.name}
                                                                                         name={'decor_name'}
                                                                                         label={'Название'}/>

                                                                            <CustomInput disabled={true} onChange={(e) => {

                                                                            }}
                                                                                         type={'number'}
                                                                                         value={item[1]?.count}
                                                                                         name={'decor_count'}
                                                                                         label={'Количество'}/>

                                                                            <CustomInput disabled={true} onChange={(e) => {

                                                                            }}
                                                                                         type={'number'}
                                                                                         value={item[1]?.price}
                                                                                         name={'decor_price'}
                                                                                         label={'Сумма'}/>

                                                                            <CustomInput disabled={true} onChange={(e) => {

                                                                            }}
                                                                                         type={'text'}
                                                                                         value={item[1]?.comment}
                                                                                         name={'decor_price'}
                                                                                         label={'Примечание'}/>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            Object.entries(countSteps == 2 ? decorPlusList : decorList).map((item, index_control) => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            marginRight: "2rem",
                                                                            width: "100%",
                                                                            marginBottom: "2rem",
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }} className={'ci-element-contr'}>
                                                                        <div className="ci-element-contr_body">

                                                                            <div style={{display: "flex", alignItems: "center"}}>
                                                                                <CustomFile onChange={(e) => {

                                                                                    if (countSteps == 2) {
                                                                                        setDecorPlusList({...decorPlusList, ...{[item[0]]: {...item[1], ...{images: e.target.value}}}});
                                                                                    } else {
                                                                                        setDecorList({...decorList, ...{[item[0]]: {...item[1], ...{images: e.target.value}}}});
                                                                                    }

                                                                                }} value={item[1]?.images} name={''}
                                                                                            labelName={'Загрузить файл'}/>
                                                                            </div>
                                                                            <CustomInput onChange={(e) => {

                                                                                if (countSteps == 2) {
                                                                                    setDecorPlusList({...decorPlusList, ...{[item[0]]: {...item[1], ...{name: e.target.value}}}});
                                                                                } else {
                                                                                    setDecorList({...decorList, ...{[item[0]]: {...item[1], ...{name: e.target.value}}}});
                                                                                }
                                                                                // console.log(dopMenuList);

                                                                            }}
                                                                                         value={item[1]?.name}
                                                                                         name={'decor_name'}
                                                                                         label={'Название'}/>

                                                                            <CustomInput onChange={(e) => {

                                                                                if (countSteps == 2) {
                                                                                    setDecorPlusList({...decorPlusList, ...{[item[0]]: {...item[1], ...{count: e.target.value}}}});
                                                                                } else {
                                                                                    setDecorList({...decorList, ...{[item[0]]: {...item[1], ...{count: e.target.value}}}});
                                                                                }

                                                                                calcultationSet();
                                                                            }}
                                                                                         type={'number'}
                                                                                         value={item[1]?.count}
                                                                                         name={'decor_count'}
                                                                                         label={'Количество'}/>

                                                                            <CustomInput onChange={(e) => {


                                                                                if (countSteps == 2) {
                                                                                    setDecorPlusList({...decorPlusList, ...{[item[0]]: {...item[1], ...{price: e.target.value}}}});
                                                                                } else {
                                                                                    setDecorList({...decorList, ...{[item[0]]: {...item[1], ...{price: e.target.value}}}});
                                                                                }

                                                                                calcultationSet();
                                                                            }}
                                                                                         type={'number'}
                                                                                         value={item[1]?.price}
                                                                                         name={'decor_price'}
                                                                                         label={'Сумма'}/>

                                                                            <CustomInput onChange={(e) => {


                                                                                if (countSteps == 2) {
                                                                                    setDecorPlusList({...decorPlusList, ...{[item[0]]: {...item[1], ...{comment: e.target.value}}}});
                                                                                } else {
                                                                                    setDecorList({...decorList, ...{[item[0]]: {...item[1], ...{comment: e.target.value}}}});
                                                                                }

                                                                            }}
                                                                                         type={'text'}
                                                                                         value={item[1]?.comment}
                                                                                         name={'decor_price'}
                                                                                         label={'Примечание'}/>

                                                                            <p onClick={() => {


                                                                                if (countSteps == 2) {
                                                                                    let isd = {...decorPlusList};
                                                                                    delete isd[item[0]];
                                                                                    setDecorPlusList(isd);
                                                                                } else {
                                                                                    let isd = {...decorList};
                                                                                    delete isd[item[0]];
                                                                                    setDecorList(isd);
                                                                                }

                                                                            }} style={{
                                                                                color: "red",
                                                                                cursor: 'pointer',
                                                                                marginLeft: "2rem",
                                                                                marginBottom: "0",
                                                                                alignItems: "center",
                                                                                display: "flex",
                                                                                width: "1rem"
                                                                            }}
                                                                               className={'text text-s18'}>
                                                                                <div className="delete-button">&#10006;</div>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>


                                                    {
                                                        window.isRole("Order", 'edit') && (
                                                            <Btn onClick={async () => {

                                                                function randomIntFromInterval(min, max) { // min and max included
                                                                    return Math.floor(Math.random() * (max - min + 1) + min)
                                                                }


                                                                if (countSteps == 2) {


                                                                    let decorControls = {...decorPlusList};

                                                                    let keys = Object.keys(decorControls).reduce((ass, li) => {
                                                                        return ass + parseInt(li);
                                                                    }, 0);
                                                                    if (keys == 0) {
                                                                        keys = randomIntFromInterval(0, 40000);
                                                                    } else {
                                                                        keys++;
                                                                    }
                                                                    setDecorPlusList({});


                                                                    setTimeout(() => {
                                                                        setDecorPlusList({
                                                                            ...decorControls, ...{
                                                                                [keys]: {
                                                                                    'name': '',
                                                                                    'price': '',
                                                                                    'images': '',
                                                                                    'count': '',
                                                                                    'comment': '',
                                                                                }
                                                                            }
                                                                        });
                                                                    }, 300);
                                                                } else {


                                                                    let decorControls = {...decorList};

                                                                    let keys = Object.keys(decorControls).reduce((ass, li) => {
                                                                        return ass + parseInt(li);
                                                                    }, 0);
                                                                    if (keys == 0) {
                                                                        keys = randomIntFromInterval(0, 40000);
                                                                    } else {
                                                                        keys++;
                                                                    }
                                                                    setDecorList({});


                                                                    setTimeout(() => {
                                                                        setDecorList({
                                                                            ...decorControls, ...{
                                                                                [keys]: {
                                                                                    'name': '',
                                                                                    'price': '',
                                                                                    'images': '',
                                                                                    'count': '',
                                                                                    'comment': '',
                                                                                }
                                                                            }
                                                                        });
                                                                    }, 300);


                                                                }


                                                            }} className={'btn-def-table btn-def-table-w20  active text text-s14'}
                                                                 label={'Добавить позицию'}/>
                                                        )
                                                    }

                                                </div>)
                                            }
                                        </div>
                                    )
                                }


                                {
                                    window.isRole("Order", 'edit') && (
                                        <div style={{
                                            marginTop: "2rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                                                <span onClick={() => {
                                                    setPageOpen6(!pageOpen6);
                                                }} style={{display: "flex", alignItems: "center"}}>
                                                 <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                                      src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/>  Доп расходы:
                                                </span>

                                                <p className={'text text-sm-big text-s18'}>
                                                    <span style={{color: "red"}}>(!)</span> сумма не умножается на количество
                                                </p>
                                            </p>

                                            {
                                                pageOpen6 && (<div>
                                                    <br/>
                                                    <br/>
                                                    <div style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                                                        {
                                                            Object.entries(countSteps == 2 ? dopMenuPlusList : dopMenuList).map((item, index_control) => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            marginRight: "2rem",
                                                                            width: "100%",
                                                                            marginBottom: "2rem",
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }} className={'ci-element-contr'}>
                                                                        <div className="ci-element-contr_body">
                                                                            <div style={{display: "flex", alignItems: "center"}}>
                                                                                <CustomFile onChange={(e) => {

                                                                                    if (countSteps == 2) {
                                                                                        setDopMenuPlusList({...dopMenuPlusList, ...{[item[0]]: {...item[1], ...{images: e.target.value}}}});
                                                                                    } else {
                                                                                        setDopMenuList({...dopMenuList, ...{[item[0]]: {...item[1], ...{images: e.target.value}}}});
                                                                                    }

                                                                                }} value={item[1]?.images} name={''}
                                                                                            labelName={'Загрузить файл'}/>
                                                                            </div>

                                                                            <CustomInput onChange={(e) => {

                                                                                // console.log(dopMenuList);
                                                                                if (countSteps == 2) {
                                                                                    setDopMenuPlusList({...dopMenuPlusList, ...{[item[0]]: {...item[1], ...{name: e.target.value}}}});
                                                                                } else {
                                                                                    setDopMenuList({...dopMenuList, ...{[item[0]]: {...item[1], ...{name: e.target.value}}}});
                                                                                }

                                                                            }}
                                                                                         value={item[1]?.name}
                                                                                         name={'decor_name'}
                                                                                         label={'Название'}/>

                                                                            <CustomInput onChange={(e) => {

                                                                                if (countSteps == 2) {
                                                                                    setDopMenuPlusList({...dopMenuPlusList, ...{[item[0]]: {...item[1], ...{count: e.target.value}}}});
                                                                                } else {
                                                                                    setDopMenuList({...dopMenuList, ...{[item[0]]: {...item[1], ...{count: e.target.value}}}});
                                                                                }

                                                                                calcultationSet();
                                                                            }}
                                                                                         type={'number'}
                                                                                         value={item[1]?.count}
                                                                                         name={'decor_count'}
                                                                                         label={'Количество'}/>

                                                                            <CustomInput onChange={(e) => {

                                                                                if (countSteps == 2) {
                                                                                    setDopMenuPlusList({...dopMenuPlusList, ...{[item[0]]: {...item[1], ...{price: e.target.value}}}});
                                                                                } else {
                                                                                    setDopMenuList({...dopMenuList, ...{[item[0]]: {...item[1], ...{price: e.target.value}}}});
                                                                                }

                                                                                calcultationSet();
                                                                            }}
                                                                                         type={'number'}
                                                                                         value={item[1]?.price}
                                                                                         name={'decor_price'}
                                                                                         label={'Сумма'}/>

                                                                            <CustomInput onChange={(e) => {


                                                                                if (countSteps == 2) {
                                                                                    setDopMenuPlusList({...dopMenuPlusList, ...{[item[0]]: {...item[1], ...{comment: e.target.value}}}});
                                                                                } else {
                                                                                    setDopMenuList({...dopMenuList, ...{[item[0]]: {...item[1], ...{comment: e.target.value}}}});
                                                                                }

                                                                            }}
                                                                                         type={'text'}
                                                                                         value={item[1]?.comment}
                                                                                         name={'decor_price'}
                                                                                         label={'Примечание'}/>


                                                                            <div
                                                                                className={`ci-input-custom-box  `}>
                                                                                <div
                                                                                    className={'text  text-s10   ci-input-custom-box_label'}>Склад
                                                                                </div>
                                                                                <label
                                                                                    className={`ci-input-custom ci-input-edit  `}
                                                                                    style={{border: "none"}}>
                                                                                    <input checked={item[1]?.kp_} onChange={() => {
                                                                                        if (countSteps == 2) {
                                                                                            setDopMenuPlusList({...dopMenuPlusList, ...{[item[0]]: {...item[1], ...{kp_: item[1]?.kp_ ? false : true}}}});
                                                                                        } else {
                                                                                            setDopMenuList({...dopMenuList, ...{[item[0]]: {...item[1], ...{kp_: item[1]?.kp_ ? false : true}}}});
                                                                                        }
                                                                                    }} type={"checkbox"}/>
                                                                                </label>
                                                                            </div>


                                                                            <p onClick={() => {


                                                                                if (countSteps == 2) {
                                                                                    let isd = {...dopMenuPlusList};
                                                                                    delete isd[item[0]];
                                                                                    setDopMenuPlusList(isd);
                                                                                } else {
                                                                                    let isd = {...dopMenuList};
                                                                                    delete isd[item[0]];
                                                                                    setDopMenuList(isd);
                                                                                }

                                                                            }} style={{
                                                                                color: "red",
                                                                                cursor: 'pointer',
                                                                                marginLeft: "2rem",
                                                                                marginBottom: "0",
                                                                                alignItems: "center",
                                                                                display: "flex",
                                                                                width: "1rem"
                                                                            }}
                                                                               className={'text text-s18'}>
                                                                                <div className="delete-button">&#10006;</div>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>


                                                    {
                                                        window.isRole("Order", 'edit') && (
                                                            <Btn onClick={async () => {

                                                                function randomIntFromInterval(min, max) { // min and max included
                                                                    return Math.floor(Math.random() * (max - min + 1) + min)
                                                                }


                                                                if (countSteps == 2) {


                                                                    let decorControls = {...dopMenuPlusList};

                                                                    let keys = Object.keys(decorControls).reduce((ass, li) => {
                                                                        return ass + parseInt(li);
                                                                    }, 0);
                                                                    if (keys == 0) {
                                                                        keys = randomIntFromInterval(0, 40000);
                                                                    } else {
                                                                        keys++;
                                                                    }
                                                                    setDopMenuPlusList({});


                                                                    setTimeout(() => {
                                                                        setDopMenuPlusList({
                                                                            ...dopMenuPlusList, ...{
                                                                                [keys]: {
                                                                                    'name': '',
                                                                                    'price': '',
                                                                                    'images': '',
                                                                                    'count': '',
                                                                                    'comment': '',
                                                                                    'kp_': true,
                                                                                }
                                                                            }
                                                                        });
                                                                    }, 300);
                                                                } else {


                                                                    let decorControls = {...dopMenuList};

                                                                    let keys = Object.keys(decorControls).reduce((ass, li) => {
                                                                        return ass + parseInt(li);
                                                                    }, 0);
                                                                    if (keys == 0) {
                                                                        keys = randomIntFromInterval(0, 40000);
                                                                    } else {
                                                                        keys++;
                                                                    }
                                                                    setDopMenuList({});


                                                                    setTimeout(() => {
                                                                        setDopMenuList({
                                                                            ...dopMenuList, ...{
                                                                                [keys]: {
                                                                                    'name': '',
                                                                                    'price': '',
                                                                                    'images': '',
                                                                                    'count': '',
                                                                                    'comment': '',
                                                                                    'kp_': true,
                                                                                }
                                                                            }
                                                                        });
                                                                    }, 300);


                                                                }


                                                            }} className={'btn-def-table btn-def-table-w20  active text text-s14'}
                                                                 label={'Добавить позицию'}/>
                                                        )
                                                    }

                                                </div>)
                                            }
                                        </div>
                                    )
                                }


                                {
                                    !window.isRole("Order", 'edit') && Object.entries({...dopMenuList}).length > 0 && (
                                        <div style={{
                                            marginTop: "2rem",
                                            padding: "1.25rem",
                                            boxShadow: "0px 0px 4px 0px #ccc",
                                            borderRadius: "7px"
                                        }}>
                                            <p className={'text text-s20'}
                                               style={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   justifyContent: "space-between",
                                                   marginBottom: 0,
                                                   lineHeight: "100%"
                                               }}>
                            <span onClick={() => {
                                setPageOpen6(!pageOpen6);
                            }} style={{display: "flex", alignItems: "center"}}>
                             <img style={{width: "1.55rem", marginRight: "1.5rem"}}
                                  src={'https://cdn.onlinewebfonts.com/svg/download_342052.png'}/>  Доп расходы:
                            </span>

                                            </p>

                                            {
                                                (<div>
                                                    <br/>
                                                    <br/>
                                                    <div className={'ci-table_box ci-table_box-dip-coli ci-table_box-table'}>
                                                        {
                                                            Object.entries({...dopMenuList}).map((item, index_control) => {

                                                                if (typeof item[1]?.kp_ != "undefined" ? item[1]?.kp_ : true) {
                                                                    return (
                                                                        <ExtraExpen onChange={(e) => {


                                                                            setDopMenuList({...dopMenuList, ...{[item[0]]: {...item[1], ...e}}});

                                                                            setUpdateReset2(true);
                                                                            setUpdateReset(!updateReset);
                                                                        }} item={item[1]}/>
                                                                    )
                                                                }
                                                                return null;
                                                            })
                                                        }
                                                        {
                                                            Object.entries({...dopMenuPlusList}).map((item, index_control) => {


                                                                if (typeof item[1]?.kp_ != "undefined" ? item[1]?.kp_ : true) {
                                                                    return (
                                                                        <ExtraExpen onChange={(e) => {


                                                                            setDopMenuList({...dopMenuList, ...{[item[0]]: {...item[1], ...e}}});

                                                                            setUpdateReset2(true);
                                                                            setUpdateReset(!updateReset);
                                                                        }} item={item[1]}/>
                                                                    )
                                                                }
                                                                return null;
                                                            })
                                                        }
                                                    </div>


                                                    {
                                                        window.isRole("Order", 'edit') && (
                                                            <Btn onClick={async () => {

                                                                function randomIntFromInterval(min, max) { // min and max included
                                                                    return Math.floor(Math.random() * (max - min + 1) + min)
                                                                }


                                                                if (countSteps == 2) {


                                                                    let decorControls = {...dopMenuPlusList};

                                                                    let keys = Object.keys(decorControls).reduce((ass, li) => {
                                                                        return ass + parseInt(li);
                                                                    }, 0);
                                                                    if (keys == 0) {
                                                                        keys = randomIntFromInterval(0, 40000);
                                                                    } else {
                                                                        keys++;
                                                                    }
                                                                    setDopMenuPlusList({});


                                                                    setTimeout(() => {
                                                                        setDopMenuPlusList({
                                                                            ...dopMenuPlusList, ...{
                                                                                [keys]: {
                                                                                    'name': '',
                                                                                    'price': '',
                                                                                    'images': '',
                                                                                    'count': '',
                                                                                    'comment': '',
                                                                                    'is_complit': false,
                                                                                    'is_post': false,
                                                                                    'post_img': '',
                                                                                    'kp_': true,
                                                                                }
                                                                            }
                                                                        });
                                                                    }, 300);
                                                                } else {


                                                                    let decorControls = {...dopMenuList};

                                                                    let keys = Object.keys(decorControls).reduce((ass, li) => {
                                                                        return ass + parseInt(li);
                                                                    }, 0);
                                                                    if (keys == 0) {
                                                                        keys = randomIntFromInterval(0, 40000);
                                                                    } else {
                                                                        keys++;
                                                                    }
                                                                    setDopMenuList({});


                                                                    setTimeout(() => {
                                                                        setDopMenuList({
                                                                            ...dopMenuList, ...{
                                                                                [keys]: {
                                                                                    'name': '',
                                                                                    'price': '',
                                                                                    'images': '',
                                                                                    'count': '',
                                                                                    'comment': '',
                                                                                    'is_complit': false,
                                                                                    'is_post': false,
                                                                                    'post_img': '',
                                                                                    'kp_': true,
                                                                                }
                                                                            }
                                                                        });
                                                                    }, 300);


                                                                }


                                                            }} className={'btn-def-table btn-def-table-w20  active text text-s14'}
                                                                 label={'Добавить позицию'}/>
                                                        )
                                                    }

                                                </div>)
                                            }
                                        </div>
                                    )
                                }
                            </div>
                    )
                }
            </form>

            <Modal
                isOpen={modal}
                onRequestClose={() => {
                    setModal(false);
                }}
                contentLabel="Example Modal"
                className="model-add-box-comp-list"
            >
                <div className="pack-center">
                    <div className="pack-box">
                        <div className="pack-box__img">
                            <img
                                src={openRow?.photo ? REACT_APP_ASSETS_PATH + openRow?.photo : 'https://static.tildacdn.com/stor3066-6637-4635-b235-306432393236/28942791.jpg'}/>
                        </div>
                        <div className={'pack-box__list'}>
                            <div className="complect-list-head">
                                <span className={'text text-capitalize text-bold text-s26'}>{openRow?.name}</span>
                            </div>
                            <div className="complect-list-body">
                                {
                                    typeof openRow?.product_list_product != "undefined" ?
                                        Object.entries(openRow?.product_list_array).map((product_list_controls) => {


                                            let nameBox = '';


                                            let contBox = [...colSklad].filter((bControl) => {
                                                return bControl.id == product_list_controls[0];
                                            });

                                            if (contBox.length > 0) {
                                                nameBox = contBox[0].name;
                                            }


                                            return (
                                                <div style={{marginBottom: "4rem"}}>
                                                    <p className={'text text-s16'}>
                                                        <b>{nameBox}</b>
                                                    </p>
                                                    {
                                                        product_list_controls[1].map((li) => {


                                                            const string = li['photo'];
                                                            let type = 'text';


                                                            return (
                                                                <div className="item-complit"
                                                                     style={{display: "flex", alignItems: 'center'}}>

                                                                    {
                                                                        string ? (
                                                                            <img onClick={() => {

                                                                            }} style={{
                                                                                marginRight: "1.5rem",
                                                                                position: "relative",
                                                                                zIndex: 100000,
                                                                                pointerEvents: 'all',
                                                                                objectFit: 'contain',
                                                                                objectPosition: "center center",
                                                                                backgroundColor: '#ececec',
                                                                                width: '7.5rem',
                                                                                height: '7.5rem',
                                                                            }}
                                                                                 src={(string ? (string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') : '') + string}/>
                                                                        ) : (
                                                                            <img style={{
                                                                                marginRight: "1.5rem",
                                                                                position: "relative",
                                                                                zIndex: 100000,
                                                                                pointerEvents: 'all',
                                                                                objectPosition: "center center",
                                                                                width: '7.5rem',
                                                                                height: '7.5rem',
                                                                                backgroundColor: '#ececec'
                                                                            }}
                                                                            />
                                                                        )
                                                                    }
                                                                    <div className={'pack-detalis text text-s14'}>

                                                <span className={'pack-detalis__name text-capitalize'}>
                                                    <b>{li['name']}</b> <br/>
                                                    {li['num']}
                                                </span>

                                                                        <span
                                                                            className={'pack-detalis__count'}> {li['count']}</span>

                                                                        <span
                                                                            className={'pack-detalis__price'}> {parseInt(li['price_arenda']) > 0 ? parseInt(li['price_arenda']).toLocaleString() : '0'} тнг</span>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            );


                                        })

                                        : ''
                                }
                            </div>

                            <div className={'pack-box__footer'} style={{marginTop: "-4rem"}}>
                                <p className={'text text-s18'}>
                                    <b>Сумма: {
                                        typeof openRow?.product_list_product != "undefined" ? Object.entries(openRow?.product_list_product).reduce((ass, rowers) => {


                                            return ass + rowers[1].reduce((ass2, li) => {

                                                return ass2 + (parseInt(li['price_arenda']) > 0 ? parseInt(li['price_arenda']) : 0) * (parseInt(li['count']) > 0 ? parseInt(li['count']) : 0);


                                            }, 0);


                                        }, 0).toLocaleString() : '0'
                                    } {' '} тг.</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                isOpen={modalPackMenu}
                onRequestClose={() => {
                    setModalPackMenu(false);
                }}
                contentLabel="Example Modal"
                className="model-add-box-comp-list"
            >
                <div className={'complect-list'}>
                    <div className="complect-list-head">
                        <span className={'text text-bold text-s18'}>Комплект: {openRowMenu?.name}</span>
                    </div>
                    <div className="complect-list-body">
                        {
                            openRowMenu?.product_list_array && openRowMenu.product_list_array.map((packMenuList) => {

                                return (
                                    <div style={{marginBottom: "2rem"}}>
                                        <p className={'text text-s20 text-bold'}>
                                            {packMenuList.name}
                                        </p>

                                        {
                                            packMenuList.child && packMenuList.child.map((li) => {

                                                const string = li['photo'];
                                                let type = 'text';

                                                return (
                                                    <div className="item-complit" style={{display: "flex"}}>
                                                        {
                                                            string ? (
                                                                <img onClick={() => {

                                                                }} style={{
                                                                    marginRight: "1.5rem",
                                                                    position: "relative",
                                                                    zIndex: 100000,
                                                                    pointerEvents: 'all',
                                                                    objectFit: 'contain',
                                                                    backgroundColor: '#ececec'
                                                                }} width={75} height={75}
                                                                     src={(string ? (string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') : '') + string}/>
                                                            ) : (
                                                                <img style={{
                                                                    marginRight: "1.5rem",
                                                                    position: "relative",
                                                                    zIndex: 100000,
                                                                    pointerEvents: 'all',
                                                                    objectFit: 'contain',
                                                                    backgroundColor: '#ececec'
                                                                }} width={75} height={75}
                                                                />
                                                            )
                                                        }
                                                        <span className={'text text-s16'}>
                                                            Название: {li['name']}
                                                            <br/> кол-во: {li['count']}
                                                            <br/> цена: {parseInt(li['price']) > 0 ? parseInt(li['price']).toLocaleString() : '0'} тнг
                                                            <br/> цена за все: {((parseInt(li['price']) > 0 ? parseInt(li['price']) : 0) * (parseInt(li['count']) > 0 ? parseInt(li['count']) : 0)).toLocaleString()} тнг
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </Modal>


            <Modal
                isOpen={modalPdf}
                // isOpen={modal}
                onRequestClose={() => {
                    setModalPdf(false);
                }}
                contentLabel="Example Modal"
                className="model-add-box-comp-list"
            >
                <div className={'complect-list complect-list-export'}>
                    <div className="complect-list-head complect-list-head-fix">
                        <span className={'text text-bold text-s20'}>Выберите формат для экспорта </span>
                    </div>

                    <div className="complect-list-body complect-list-body-fix">


                        <div className="icoms-adv-box">


                        </div>


                        <div onClick={async () => {

                            function getRandomInt(max) {
                                return Math.floor(Math.random() * max);
                            }


                            let orderListBolx = {...orderSingle};


                            let fileNameSave = params.id + '_' + getRandomInt(10000);

                            let bodyPost = {
                                'filename': fileNameSave,
                                'order': orderSingle,
                                'dop': [
                                    ...(Object.entries({...orderProduct}).map(([storeName, productArray]) => {

                                            let rowTableSave = {...rowTable};
                                            delete (rowTableSave['catalog_id']);


                                            let serius = Object.values(productArray).sort((a, b) => {
                                                let catalogIdA = a[0]?.product?.catalog_id ? a[0]?.product?.catalog_id : 0;
                                                let catalogIdB = b[0]?.product?.catalog_id?.catalog_id ? b[0]?.product?.catalog_id : 0;
                                                if (catalogIdA < catalogIdB) {
                                                    return -1;
                                                }
                                                if (catalogIdA > catalogIdB) {
                                                    return 1;
                                                }
                                                return 0;
                                            })

                                            return (Object.entries(serius).map(([productId, productArraySingle]) => {
                                                let productReturn = productArraySingle[0]['product'];
                                                if (productReturn == null) {
                                                    return false;
                                                }
                                                productReturn['count'] = productArraySingle[0]['count'];
                                                productReturn['count_dop'] = productArraySingle[0]['count_dop'];
                                                productReturn['discont'] = productArraySingle[0]['discont'];
                                                productReturn['product_commit'] = productArraySingle[0]['product_commit'];
                                                productReturn['update_id'] = productArraySingle[0]['id'];
                                                productReturn['pre'] = productArraySingle[0];


                                                productReturn['count_result'] = (productReturn['count'] * productReturn['price_arenda']);

                                                if (parseInt(productReturn['discont']) > 0) {
                                                    productReturn['count_result'] = productReturn['count_result'] * (1 - (parseInt(productReturn['discont']) / 100));
                                                }
                                                return productReturn;
                                            }).filter((itsrx) => {
                                                if (catalogSelect) {
                                                    return itsrx.catalog_id == catalogSelect && itsrx;
                                                }

                                                return itsrx;
                                            }))
                                        }).reduce((ass, itemVal) => {
                                            return [...ass, ...itemVal];
                                        }, []).map((itemVal) => {
                                            let groupList = ([...groupTable].reduce((ass, val) => {
                                                return {...ass, ...{[val.id]: val.name}};
                                            }, {}));
                                            return {
                                                category_name: itemVal.catalog_id in groupList ? groupList[itemVal.catalog_id] : "Оборудование",
                                                category_name_big: "Оборудование",
                                                name: itemVal['name'],
                                                price_arenda: parseInt(itemVal['price_arenda']).toLocaleString(),
                                                photo: itemVal['photo'],
                                                photo_mini: itemVal['photo_mini'],
                                                pre: typeof itemVal['pre'] != "undefined" ? itemVal['pre'] : null,
                                                count: itemVal['count']
                                            }
                                        })
                                    ),
                                    ...(serving.map((servingItem) => {


                                        if (servingItem.id in orderProductServ) {
                                            let controlBoxRest = [...(Object.entries({...orderProductServ[servingItem.id]}))].map(([storeName, productArray]) => {

                                                let rowTableSave = {...rowTable};
                                                delete (rowTableSave['catalog_id']);

                                                let serius = Object.values(productArray).sort((a, b) => {
                                                    let catalogIdA = a[0]?.product?.catalog_id ? a[0]?.product?.catalog_id : 0;
                                                    let catalogIdB = b[0]?.product?.catalog_id?.catalog_id ? b[0]?.product?.catalog_id : 0;
                                                    if (catalogIdA < catalogIdB) {
                                                        return -1;
                                                    }
                                                    if (catalogIdA > catalogIdB) {
                                                        return 1;
                                                    }

                                                    return 0;
                                                })
                                                return (Object.entries(serius).map(([productId, productArraySingle]) => {
                                                    let productReturn = productArraySingle[0]['product'];
                                                    if (productReturn == null) {
                                                        return false;
                                                    }
                                                    productReturn['count'] = productArraySingle[0]['count'];
                                                    productReturn['count_dop'] = productArraySingle[0]['count_dop'];
                                                    productReturn['discont'] = productArraySingle[0]['discont'];
                                                    productReturn['product_commit'] = productArraySingle[0]['product_commit'];
                                                    productReturn['update_id'] = productArraySingle[0]['id'];
                                                    productReturn['pre'] = productArraySingle[0];
                                                    productReturn['count_result'] = (productReturn['count'] * productReturn['price_arenda']);

                                                    if (parseInt(productReturn['discont']) > 0) {
                                                        productReturn['count_result'] = productReturn['count_result'] * (1 - (parseInt(productReturn['discont']) / 100));
                                                    }
                                                    return productReturn;
                                                }).filter((itsrx) => {
                                                    if (catalogSelect) {
                                                        return itsrx.catalog_id == catalogSelect && itsrx;
                                                    }
                                                    return itsrx;
                                                }))
                                            }).reduce((ass, itemVal) => {
                                                return [...ass, ...itemVal];
                                            }, []).map((itemVal) => {
                                                let groupList = ([...groupTable].reduce((ass, val) => {
                                                    return {...ass, ...{[val.id]: val.name}};
                                                }, {}));

                                                return {
                                                    category_name: itemVal.catalog_id in groupList ? groupList[itemVal.catalog_id] : "Оборудование",
                                                    category_name_big: 'Сервировка: ' + servingItem.name,
                                                    name: itemVal['name'],
                                                    photo: itemVal['photo'],
                                                    pre: typeof itemVal['pre'] != "undefined" ? itemVal['pre'] : null,
                                                    photo_mini: itemVal['photo_mini'],
                                                    price_arenda: parseInt(itemVal['price_arenda']).toLocaleString(),
                                                    count: itemVal['count']
                                                }
                                            });
                                            return controlBoxRest;

                                        } else {
                                            return [];
                                        }

                                    }).reduce((ass, object) => {


                                        return [...ass, ...object];

                                    }, [])),
                                    ...Object.entries(orderPersonal['']).reduce((acc, item) => {
                                        if (!item[1][0]['personal']) {
                                            return [...acc];
                                        }

                                        // productReturn['discont'] = productArraySingle[0]['discont'];

                                        return [...acc, {
                                            ...item[1][0]['personal'], ...{
                                                'category_name_big': 'Персонал',
                                                'category_name': 'Персонал',
                                                'discont': item[1][0]['discont'],
                                                'photo': REACT_APP_ASSETS_PATH + item[1][0]['personal']['photo'],
                                                'photo_mini': item[1][0]['personal']['photo_mini'],
                                                'count': item[1][0]['count'],
                                                'pre': item[1][0],
                                                'price_arenda': item[1][0]['personal']['price'],
                                            }
                                        }]
                                    }, []),
                                    ...Object.entries(decorList).map((itemSl) => {

                                        return {
                                            'category_name_big': 'Декор',
                                            'category_name': 'Декор',
                                            'discont': 0,
                                            'price_arenda': parseInt(itemSl[1]['price']) / parseInt(itemSl[1]['count']),
                                            'name': itemSl[1]['name'],
                                            'photo': REACT_APP_ASSETS_PATH + itemSl[1]['images'],
                                            'count': itemSl[1]['count'],
                                        };
                                    }),
                                    ...Object.entries(dopMenuList).map((itemSl) => {
                                        return {
                                            'category_name_big': 'Доп расходы',
                                            'category_name': 'Доп расходы',
                                            'discont': 0,
                                            'price_arenda': parseInt(itemSl[1]['price']) / parseInt(itemSl[1]['count']),
                                            'name': itemSl[1]['name'],
                                            'photo': REACT_APP_ASSETS_PATH + itemSl[1]['images'],
                                            'count': itemSl[1]['count'],
                                        };
                                    })
                                ],
                                'allPrice': allInfoProduct?.price?.allPrice.toLocaleString(),
                                'allPriceNoDiscont': allInfoProduct?.price?.allPriceOrigin.toLocaleString(),
                                'players': countPrice,
                                'day': daySborVal,
                                'person': allInfoProduct?.price?.players.toLocaleString(),
                                'food': allInfoProduct?.price?.menuGramFood.toLocaleString(),
                                'drink': allInfoProduct?.price?.menuGramNoFood.toLocaleString(),
                                'menu': (menuType.map((serItems) => {
                                    if (serItems.id in orderMenuServ) {
                                        return {
                                            name: serItems.name,
                                            menu: Object.entries(orderMenuServ[serItems.id]).map((controlBMenu) => {
                                                return [...controlBMenu[1].map((menuControl) => {
                                                    return {
                                                        ...menuControl['menu'], ...{
                                                            'count': menuControl['count'],
                                                            'discont': menuControl['discont'],
                                                            'pre': menuControl
                                                        }
                                                    };
                                                })];
                                            }).map((controlBMenu) => {

                                                return controlBMenu[0];
                                            })
                                        }
                                    }
                                    return false;
                                }).filter((cofilter) => {
                                    return cofilter;
                                }))
                            };


                            const visited = new Set();

// Custom replacer function
                            const replacer = (key, value) => {
                                // Check if the value is an object and it has been visited before
                                if (typeof value === "object" && value !== null) {
                                    if (visited.has(value)) {
                                        // If it's a circular reference, return a placeholder value or omit the property
                                        return "[Circular Reference]";
                                    }

                                    // Mark the object as visited before recursively processing its properties
                                    visited.add(value);
                                }

                                return value;
                            };


                            await saveJson(
                                {
                                    body: JSON.stringify(bodyPost, replacer, 2)
                                }
                            );

                            // window.open(, '_blank');
                            setTimeout(function () {
                                if (window.innerWidth < 900) {
                                    document.location.href = REACT_APP_ASSETS_PATH + '/pdf/index.php?filename=' + fileNameSave;
                                } else {
                                    window.open(REACT_APP_ASSETS_PATH + '/pdf/index.php?filename=' + fileNameSave, '_blank');
                                }
                            }, 250);

                        }} style={{borderRadius: "0.75rem"}} className="btn-control-fix">
                            PDF
                        </div>
                        <a href={REACT_APP_ASSETS_PATH + '/api/storebox/' + orderSingle?.id + '/export'}

                           style={{
                               border: "1px solid #ccc",
                               marginLeft: "3rem",
                               color: "#000",
                               textDecoration: "none",
                               borderRadius: "0.75rem"
                           }}
                           className="btn-control-fix">
                            EXCEL
                        </a>
                        {/*<div className="btn-control-fix">*/}
                        {/*    EXCEL*/}
                        {/*</div>*/}

                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalShow}
                onRequestClose={() => {
                    setModalShow(false);
                }}
                contentLabel="Example Modal"
                className="model-add-box model-add-box-close model-add-box-center"
            >
                <div className="box_conteol box_conteol-img">
                    <img onClick={() => {
                        setModalShow(false);
                    }} className={'mole_img'}
                         src={(stringImg.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + stringImg}/>
                    <br/>
                    <div className="box_conteol_nav">
                        <p onClick={() => {
                            setModalShow(false);
                            setCatalogId('')
                            setSelectSklad('1');
                        }} className={'text text-s20'} style={{color: "#fff", marginTop: "2rem"}}>
                            закрыть
                        </p>
                    </div>
                </div>
            </Modal>


            <Modal
                isOpen={createModalMenu}
                onRequestClose={() => {
                    setCreateModalMenu(false);
                }}
                contentLabel="Example Modal"
                className="model-add-box  model-add-box-center"
            >
                <div className="edit-table edit-table-model edit-table-model-center">
                    <div className="edit-table__head edit-table__head-fix">
                        <div className="box_conteol box_conteol-img" style={{width: "100%"}}>
                            <div className="edit-table__head edit-table__head-fix-sm edit-table__head-fix">
                                <Btn className={'btn-def-table me-left btn-def-table-clear text text-s14'}
                                     onClick={async () => {
                                         setCreateModalMenu(false);
                                         setCatalogId('')
                                         setSelectSklad('1');
                                     }} label={'закрыть'}/>

                                <Btn className={'btn-def-table text text-s14'} onClick={async () => {
                                    const updateDataOrder = (await updateDB({
                                        'save': {
                                            name: nameComplect,
                                            product_list: JSON.stringify(createModalMenuInfo)
                                        }, 'id': 0, 'model_name': 'PackMenu',
                                    }));
                                    setCreateModalMenu(false);
                                }} label={'Создать'}/>
                            </div>
                            <br/>
                            <div className="box_conteol_nav" style={{width: "100%", flexDirection: "column"}}>
                                <CustomInput onChange={(e) => {
                                    setNameComplect(e.target.value);
                                }} value={nameComplect} name={'search'}

                                             label={'Название комплекта'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={createModal}
                onRequestClose={() => {
                    setCreateModal(false);
                }}
                contentLabel="Example Modal"
                className="model-add-box  model-add-box-center"
            >
                <div className="edit-table edit-table-model edit-table-model-center">
                    <div className="edit-table__head edit-table__head-fix">
                        <div className="box_conteol box_conteol-img" style={{width: "100%"}}>
                            <div className="edit-table__head edit-table__head-fix-sm edit-table__head-fix">
                                <Btn className={'btn-def-table me-left btn-def-table-clear text text-s14'}
                                     onClick={async () => {
                                         setCreateModal(false);
                                         setCatalogId('')
                                         setSelectSklad('1');
                                     }} label={'закрыть'}/>

                                <Btn className={'btn-def-table text text-s14'} onClick={async () => {
                                    // setCreateModal(false);

                                    let createProductName = {};
                                    Object.entries(orderProduct).map(([storeName, productArray]) => {

                                        let serius = Object.values(productArray);
                                        serius.forEach((productList) => {
                                            let count_save_b = parseInt(productList[0]['count']);
                                            let count_save_b_a = parseInt(productList[0]['count_dop']);
                                            if (!(count_save_b > 0)) {
                                                count_save_b = 0;
                                            }
                                            if (!(count_save_b_a > 0)) {
                                                count_save_b_a = 0;
                                            }
                                            createProductName[productList[0]['product_id']] = count_save_b_a + count_save_b;
                                        });
                                    });
                                    const updateDataOrder = (await updateDB({
                                        'save': {
                                            name: nameComplect,
                                            product_list: JSON.stringify(createProductName)
                                        }, 'id': 0, 'model_name': 'Pack',
                                    }));
                                    setCreateModal(false);
                                }} label={'Создать'}/>
                            </div>
                            <br/>
                            <div className="box_conteol_nav" style={{width: "100%", flexDirection: "column"}}>
                                <CustomInput onChange={(e) => {
                                    setNameComplect(e.target.value);
                                }} value={nameComplect} name={'search'}

                                             label={'Название шаблона'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                isOpen={openModalControl}
                onRequestClose={() => {
                    setOpenModalControl(false);
                }}
                contentLabel="Example Modal"
                className="model-add-box  model-add-box-center"
            >
                <div className="edit-table edit-table-model edit-table-model-center">
                    <div className="edit-table__head edit-table__head-fix">
                        <div className="box_conteol box_conteol-img" style={{width: "100%"}}>
                            <div className="edit-table__head edit-table__head-fix-sm edit-table__head-fix">
                                <Btn className={'btn-def-table me-left btn-def-table-clear text text-s14'}
                                     onClick={async () => {
                                         setOpenModalControl(false);
                                         setCatalogId('')
                                         setSelectSklad('1');
                                     }} label={'закрыть'}/>

                                <Btn className={'btn-def-table text text-s14'} onClick={async () => {
                                    // setCreateModal(false);

                                    const updateDataOrder = (await updateDB({
                                        'save': {
                                            name: nameComplect,
                                            order_id: params.id,
                                        }, 'id': 0, 'model_name': 'OrderServing',
                                    }));

                                    let servingBox = (await getModal('OrderServing', '?order_id=' + params.id)).data;
                                    setServing(servingBox);
                                    setOpenModalControl(false);
                                    setNameComplect('');

                                }} label={'Создать'}/>
                            </div>
                            <br/>
                            <div className="box_conteol_nav" style={{width: "100%", flexDirection: "column"}}>
                                <CustomInput onChange={(e) => {
                                    setNameComplect(e.target.value);
                                }} value={nameComplect} name={'search'}

                                             label={'Название cервировки'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                isOpen={openMenuModalControl}
                onRequestClose={() => {
                    setOpenMenuModalControl(false);
                }}
                contentLabel="Example Modal"
                className="model-add-box  model-add-box-center"
            >
                <div className="edit-table edit-table-model edit-table-model-center">
                    <div className="edit-table__head edit-table__head-fix">
                        <div className="box_conteol box_conteol-img" style={{width: "100%"}}>
                            <div className="edit-table__head edit-table__head-fix-sm edit-table__head-fix">
                                <Btn className={'btn-def-table me-left btn-def-table-clear text text-s14'}
                                     onClick={async () => {
                                         setOpenMenuModalControl(false);
                                         setCatalogId('')
                                         setSelectSklad('1');
                                     }} label={'закрыть'}/>

                                <Btn className={'btn-def-table text text-s14'} onClick={async () => {
                                    // setCreateModal(false);

                                    const updateDataOrder = (await updateDB({
                                        'save': {
                                            name: nameComplect,
                                            order_id: params.id,
                                        }, 'id': 0, 'model_name': 'OrderMenuType',
                                    }));
                                    let servingBox = (await getModal('OrderMenuType', '?order_id=' + params.id)).data;
                                    setMenuType(servingBox);

                                    setOpenMenuModalControl(false);
                                    setNameComplect('');

                                }} label={'Создать'}/>
                            </div>
                            <br/>
                            <div className="box_conteol_nav" style={{width: "100%", flexDirection: "column"}}>
                                <CustomInput onChange={(e) => {
                                    setNameComplect(e.target.value);
                                }} value={nameComplect} name={'search'}

                                             label={'Название меню'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            {
                window.isRole("Order", 'edit') && countSteps == 0 && params.id != 0 && (
                    <>
                        <div style={{display: "flex", justifyContent: "space-between", marginTop: "4rem"}}>

                            <div style={{display: "flex", alignItems: "center"}}>
                                <Btn onClick={() => {
                                    setModalPdf(true);
                                }} className={'btn-def-table btn-def-table-w20  active text text-s14'}
                                     label={'Скачать КП'}/>
                            </div>
                        </div>
                    </>
                )
            }
        </Layout>);
    })
;
export default MainScreen;
