import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {deleteDataBase, getModal, getModalRow, getOrder} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import {useNavigate, useParams} from "react-router-dom";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";


const MainScreen = (() => {

    const navigate = useNavigate();
    const lang = useSelector(LangSlices);

    const [order, setOrder] = useState([]);
    const [page, setPage] = useState(0);
    const [reset, setReset] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const [modalName, setModalName] = useState('User');

    const [searchOb, setSearchOb] = useState({});


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setSearchOb(searchObS);
        setReset(!reset);
    }

    function addFilter(e) {
        setSearchOb({...searchOb, ...{search: e.target.value}});
        setReset(!reset);
    }


    async function getOrderSend() {

        let row = (await getModalRow(modalName)).data;
        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });


        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }

        setRowTable(row);
        const col = (await getModal(modalName, '?' + serialize(searchOb) + "&browse=1&page=" + (page + 1) + "&catalog_id=")).data;
        setColTable(col);

        // setOrder(orderReq);
    }

    useEffect(() => {
        getOrderSend().then();
    }, [reset]);

    return (<Layout>

        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
                Пользователи
            </p>

            <div className="table-btn">

                <Btn className={'btn-def-table text text-s14'} onClick={() => {
                    navigate('/User/edit/0');
                }} label={'Добавить'}/>
            </div>
        </div>


        <div className="table-filter" style={{alignItems:"flex-start"}}>
            <CustomInput onChange={addFilter} showHolder={false} value={searchOb?.search}
                          label={'Поиск по пользователям'}/>


            <CustomSelect required={true} showHolder={false} onChange={(e) => {

            }}
                          options={{}} name={''} value={''}
                          label={'Фио'}/>

            <CustomSelect required={true} showHolder={false} onChange={(e) => {

            }}
                          options={{
                              2: 'Кладовщик',
                              14: 'Повар',
                              15:'Грузчик',
                              11:'Хозмастер'
                          }} name={''} value={''}
                          label={'Роль'}/>
        </div>



        {
            !calendar && (
                <Table th={
                    rowTable
                } tr={
                    colTable
                }
                       option={(id) => {
                           return (
                               <div className={'table-options'}>

                                   <BtnIcon onClick={() => {
                                       navigate('/User/edit/' + id);
                                   }} img={require('../../assets/images/edit.png')}/>
                                   <BtnIcon onClick={async () => {

                                       if (window.confirm("Точно удалить?")) {
                                          await deleteDataBase({
                                               id: id
                                           }, 'User')
                                       }
                                       setReset(!reset);

                                   }} img={require('../../assets/images/trash.png')}/>
                               </div>
                           )
                       }}
                />
            )
        }


        {/*{*/}
        {/*    colTable.length > 0 && (*/}
        {/*        <Modal*/}
        {/*            isOpen={true}*/}
        {/*            contentLabel="Example Modal"*/}
        {/*            className="model-add-box-comp-list"*/}
        {/*        >*/}
        {/*            <div className={'complect-list'}>*/}
        {/*                <div className="complect-list-head">*/}
        {/*                    <span className={'text text-bold text-s18'}>комплект: {colTable[3]['name']}</span>*/}
        {/*                </div>*/}
        {/*                <div className="complect-list-body">*/}


        {/*                    {*/}
        {/*                        colTable[3].product_list_product.map((li) => {*/}
        {/*                            return (*/}
        {/*                                <div className="item-complit">*/}
        {/*                                    <span className={'text text-s16'}>{li['name']} x{li['count']}</span>*/}
        {/*                                </div>*/}
        {/*                            )*/}
        {/*                        })*/}
        {/*                    }*/}

        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </Modal>*/}
        {/*    )*/}
        {/*}*/}


    </Layout>);
});
export default MainScreen;
