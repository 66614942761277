import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


const {REACT_APP_ASSETS_PATH} = process.env;

import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {deleteDataBase, getModal, getModalRow, getOrder} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import {useNavigate, useParams} from "react-router-dom";
import Modal from "react-modal";
import {object} from "prop-types";
import DateNewRange from "../../components/DateNewRange";
import moment from "moment/moment";


const MainScreen = (() => {


    const lang = useSelector(LangSlices);

    const [modal, setModal] = useState(false);
    const [openRow, setOpenRow] = useState({});

    const navigate = useNavigate();

    const [order, setOrder] = useState([]);

    const [wareHouse, setWareHouse] = useState([]);
    const [wareHouseSelect, setWareHouseSelect] = useState("");

    const [invSelect, setInvSelect] = useState("");
    const [invSelect2, setInvSelect2] = useState("");


    const [wareTime, setWareTime] = useState([]);
    const [wareTimeSelect, setWareTimeSelect] = useState("");

    const [reset, setReset] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const [modalName, setModalName] = useState('Inventory');

    const [searchOb, setSearchOb] = useState({});


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);

    const [timeStart, setTimeStart] = useState(false);
    const [timeEnd, setTimeEnd] = useState(false);


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setSearchOb(searchObS);
        setReset(!reset);
    }

    function addFilter(e) {
        setSearchOb({...searchOb, ...{search: e.target.value}});
        setReset(!reset);
    }


    async function getOrderSend() {

        let row = (await getModalRow(modalName)).data;

        row['option'] = 'Сумма';
        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });


        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }

        setRowTable(row);
        const col = (await getModal(modalName, '?' + serialize(searchOb) + '')).data;

        const save_pre = col.reduce((ass, item) => {
            return {...ass, ...{[item['sklad_id']]: item['sklad_id']}};
        }, {});
        setWareHouse(save_pre);

        const save_controls = Object.keys(save_pre);
        setWareHouseSelect(save_pre[save_controls[1]]);


        const save_time = col.reduce((ass, item) => {
            return {...ass, ...{[item['date_from_parse']]: item['date_from_parse'] + item['type']}};
        }, {});
        setWareTime(save_time);

        const save_time_ = Object.keys(save_pre).reverse();
        setWareTimeSelect(save_time[save_time_[0]]);


        setColTable(col);

        // setOrder(orderReq);
    }

    useEffect(() => {
        getOrderSend().then();
    }, [reset]);

    let list_row = [...[...colTable].map((ors) => {
        if (ors['type'] == '5' || ors['type'] == 'Приход') {
            ors['type'] = 'Приход'
        } else {
            ors['type'] = 'Инвентаризация'
            return false;
        }
        return ors;
    })].filter((item) => {
        if (!wareHouseSelect) {
            return true;
        }

        let controll = true;


        let moTime = moment(item.created_at).unix();
        let timeEndControl = true;

        if (timeStart && timeEnd) {
            let timeStartFix = moment(timeStart).unix();
            let timeEndFix = moment(timeEnd).unix();

            timeEndControl = timeEndFix >= moTime && timeStartFix <= moTime;


        }

        if (invSelect != '' && invSelect2 != '') {
            if (item.id > invSelect && item.id < invSelect2) {
                controll = true;
            } else {
                controll = false;
            }
        } else if (invSelect != '') {
            if (item.id > invSelect) {
                controll = true;
            } else {
                controll = false;
            }
        }

        return item['sklad_id'] == wareHouseSelect && controll && timeEndControl;
    });


    list_row.reverse();


    return (<Layout>

        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
                Приход
            </p>

            <div className="table-btn">


                <Btn className={'btn-def-table text text-s14'} onClick={() => {
                    navigate('/Prihod/edit/0');
                }} label={'Приход'}/>

            </div>
        </div>
        <div className="table-filter" style={{alignItems: "flex-start"}}>

            <CustomSelect showHolder={false} value={wareHouseSelect} onChange={(item) => {
                setInvSelect('');
                setInvSelect2('');
                setWareHouseSelect(item.target.value);
            }} options={wareHouse} label={'Все склады'}/>

            <p style={{display: "flex", marginRight: "2rem"}}>

            </p>

            <DateNewRange showHolder={false} finger={true}
                          onChangeVal1={(e) => {
                              setTimeStart(e);
                              setReset(!reset);

                          }}
                          onChangeVal2={(e) => {
                              setTimeEnd(e);
                              setReset(!reset);

                          }}
                          val1={timeStart}
                          val2={timeEnd}
            />

        </div>

        <div>


            <div>
                <p className={'text text-s22'}>
                    Суммарная сумма: {' '}
                    {
                        list_row.reduce((acc, item) => {


                            return acc + item.product_list_array.reduce((acc2, item2) => {
                                let count = parseInt(item2.count);
                                let price = parseInt(item2.price);
                                if (!(price > 0)) {
                                    price = 0;
                                }
                                if (!(count > 0)) {
                                    count = 0;
                                }
                                return acc2 + (count * price);
                            }, 0)

                        }, 0).toLocaleString()
                    } тнг

                </p>
            </div>

        </div>
        {
            !calendar && (
                <Table th={
                    rowTable
                } tr={
                    list_row
                }
                       option={(id, row) => {

                           return (
                               <div className={'table-options'}>

                                   <p className={'text text-s16'} style={{marginRight: "auto"}}>
                                       {
                                           row['product_list_array'].reduce((acc2, item2) => {
                                               let count = parseInt(item2.count);
                                               let price = parseInt(item2.price);
                                               if (!(price > 0)) {
                                                   price = 0;
                                               }
                                               if (!(count > 0)) {
                                                   count = 0;
                                               }
                                               return acc2 + (count * price);
                                           }, 0).toLocaleString()
                                       } тнг
                                   </p>

                                   {
                                       row['type'] == "Приход" ? (
                                           <BtnIcon onClick={() => {
                                               setOpenRow(row);
                                               setModal(true);
                                           }} value={'список'}/>
                                       ) : (
                                           <BtnIcon onClick={() => {
                                               if (row['type'] == "Приход") {

                                               } else {
                                                   navigate('/Prihod/detalis/' + id);
                                               }
                                           }} value={'детализация'}/>
                                       )
                                   }

                                   {/*<BtnIcon onClick={() => {*/}
                                   {/*    location.href = 'https://devapi.event-team.kz/api/excel/inventory/'+id;*/}
                                   {/*}} img={require('../../assets/images/document-download.png')} />*/}

                                   <BtnIcon onClick={() => {
                                       if (row['type'] == "Приход") {
                                           navigate('/Prihod/edit/' + id);
                                       } else {
                                           navigate('/Prihod/edit/' + id);
                                       }
                                   }} img={require('../../assets/images/edit.png')}/>


                                   <BtnIcon onClick={async () => {
                                       if (window.confirm("Точно удалить?")) {
                                           await deleteDataBase({
                                               id: id
                                           }, 'Inventory')
                                           setReset(!reset);
                                       }
                                   }} img={require('../../assets/images/trash.png')}/>
                               </div>
                           )
                       }}
                />
            )
        }


        <Modal
            isOpen={modal}
            onRequestClose={() => {
                setModal(false);
            }}
            contentLabel="Example Modal"
            className="model-add-box-comp-list"
        >
            <div className={'complect-list'}>
                <div className="complect-list-head">
                    <span className={'text text-bold text-s18'}>Товары на приход: {openRow?.name}</span>

                    <p className={'text text-bold text-s18'}>
                        стоимость прихода {
                        typeof openRow?.product_list_array != "undefined" ? openRow?.product_list_array.reduce((ass, li) => {

                            function isJson(str) {
                                try {
                                    JSON.parse(str);
                                } catch (e) {
                                    return false;
                                }
                                return true;
                            }

                            let countReturn = 0;
                            if (isJson(openRow.product_list) && openRow.product_list != null) {
                                let returnBox = JSON.parse(openRow.product_list);
                                if (li['id'] in returnBox) {
                                    countReturn = returnBox[li['id']];
                                }

                            }

                            return ass + (parseInt(li['price']) > 0 && parseInt(countReturn) > 0 ? parseInt(li['price']) * countReturn : 0)


                        }, 0).toLocaleString() : ''
                    } тнг
                    </p>
                </div>
                <div className="complect-list-body">


                    {
                        typeof openRow?.product_list_array != "undefined" ? openRow?.product_list_array.map((li) => {

                            function isJson(str) {
                                try {
                                    JSON.parse(str);
                                } catch (e) {
                                    return false;
                                }
                                return true;
                            }

                            let countReturn = 0;
                            if (isJson(openRow.product_list) && openRow.product_list != null) {
                                let returnBox = JSON.parse(openRow.product_list);
                                if (li['id'] in returnBox) {
                                    countReturn = returnBox[li['id']];
                                }

                            }


                            const string = li['photo'];
                            let type = 'text';


                            return (
                                <div className="item-complit" style={{display: "flex"}}>

                                    {
                                        string ? (
                                            <img onClick={() => {

                                            }} style={{
                                                marginRight: "1.5rem",
                                                position: "relative",
                                                zIndex: 100000,
                                                pointerEvents: 'all',
                                                objectFit: 'contain',
                                                backgroundColor: '#ececec'
                                            }} width={75} height={75}
                                                 src={(string ? (string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') : '') + string}/>
                                        ) : (
                                            <img style={{
                                                marginRight: "1.5rem",
                                                position: "relative",
                                                zIndex: 100000,
                                                pointerEvents: 'all',
                                                objectFit: 'contain',
                                                backgroundColor: '#ececec'
                                            }} width={75} height={75}
                                            />
                                        )
                                    }
                                    <span className={'text text-s16'}>Название: {li['name']}
                                        <br/> кол-во: {countReturn}
                                        <br/>Цена: {parseInt(li['price']) > 0 ? parseInt(li['price']).toLocaleString() + ' тнг' : 'Цены нет'}
                                        <br/>Цена за все: {parseInt(li['price']) > 0 && parseInt(countReturn) > 0 ? (parseInt(li['price']) * countReturn).toLocaleString() + ' тнг' : 'Цены нет или количества'}
                                         </span>

                                </div>
                            )
                        }) : ''
                    }

                </div>
            </div>
        </Modal>


    </Layout>);
});
export default MainScreen;
