import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

import Table from "../../components/Table";
import {getModal, getModalRow, getModalRowEdit, getOrder, getSingle, updateDB} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";


const MainScreen = (() => {


    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);

    const [modalName, setModalName] = useState('Inventory');
    const params = useParams();


    const [rowTable, setRowTable] = useState({});


    const [plusProduct, setPlusProduct] = useState(0);

    const [priceSave, setPriceSave] = useState(0);

    const [priceControl, setPriceControl] = useState(0);
    const [countPriceNo, setCountPriceNo] = useState(0);

    const [filterPlus, setFilterPlus] = useState(0);

    const [minusProduct, setMinusProduct] = useState(0);


    const [colTableProduct, setColTableProduct] = useState([]);

    const [orderSingle, setOrderSingle] = useState({});

    const [productList, setProductList] = useState({});
    const [productListPre, setProductListPre] = useState({});

    async function getProductModal(page) {
        setColTableProduct((await getModal('Product', '?page=' + (page + 1) + '&browse=1&catalog_id=')).data);
    }

    async function getOrderSend() {

        let row = (await getModalRowEdit(modalName, params.id + '?hidden=true')).data;
        let orderSingle = (await getSingle(modalName, params.id, '?detalis=true')).data;

        let save_price = 0;


        if (orderSingle?.product_list) {
            let productListSave = JSON.parse(orderSingle.product_list);
            setProductList(productListSave);

            if (orderSingle["pre"] != null) {
                if (orderSingle["pre"]) {

                    let productListPreSave = (orderSingle.pre.product_list);
                    setProductListPre(productListPreSave);


                    if (orderSingle && orderSingle?.product_list_array != null) {
                        let plust_product = 0;
                        let minus_product = 0;
                        orderSingle?.product_list_array.forEach((item) => {
                            if (productListSave && productListPreSave) {
                                if (typeof (productListSave[item['id']]) != "undefined" && typeof (productListPreSave[item['id']]) != "undefined") {
                                    let count_control = parseInt(productListSave[item['id']]) - parseInt(productListPreSave[item['id']]);

                                    if (item['id'] in orderSingle.product_down) {
                                        count_control -= orderSingle.product_down[item['id']];
                                    }

                                    if (count_control > 0) {
                                        plust_product = (plust_product + count_control);
                                    } else if (count_control < 0) {
                                        minus_product = (minus_product + count_control);
                                    }
                                }
                            }
                        });

                        setPlusProduct(plust_product);
                        setMinusProduct(minus_product);
                    }


                }
            }


            // const [priceControl, setPriceControl] = useState(0);
            // const [countPriceNo, setCountPriceNo] = useState(0);


            orderSingle.product_list_array = [...orderSingle.product_list_array].filter((produc) => {
                if (orderSingle['pre']) {
                    return typeof orderSingle['pre']['product_list'][produc['id']] != "undefined" ? (orderSingle['pre']['product_list'][produc['id']] - parseInt(productListSave[produc['id']])) != 0 : false;
                }
                return true;
            }).map((produc) => {
                let addBox = {};

                if (orderSingle['pre']) {
                    if (typeof orderSingle['pre']['product_list'][produc['id']] != "undefined") {

                        let priceCountSave = parseInt(orderSingle['pre']['product_list'][produc['id']]);
                        if (priceCountSave > 0) {

                        } else {
                            priceCountSave = 0;
                        }

                        let priceCountSaveTwo = parseInt(productListSave[produc['id']]);
                        if (priceCountSaveTwo > 0) {

                        } else {
                            priceCountSaveTwo = 0;
                        }


                        addBox["outPlus"] = "0";
                        addBox["outMinus"] = 0;
                        if (produc['id'] in orderSingle.product_down) {
                            addBox["outMinus"] = orderSingle.product_down[produc['id']];
                        }

                        let resultCount = (priceCountSaveTwo - priceCountSave) + addBox["outMinus"];

                        addBox["plusCount"] = "0";
                        addBox["minusCount"] = "0";

                        if (resultCount > 0) {
                            addBox["plusCount"] = resultCount;
                        }

                        if (resultCount < 0) {
                            addBox["minusCount"] = (resultCount * (-1));
                        }


                        addBox['faktCount'] = priceCountSave - addBox["outMinus"];

                        addBox['store_pre'] = priceCountSave;


                        addBox['result'] = (priceCountSaveTwo - priceCountSave) + addBox["outMinus"];

                        let priceSS = produc['price'];
                        if (priceSS == null || priceSS == "") {
                            priceSS = 0;
                        } else {
                            priceSS = parseInt(priceSS);
                        }

                        if (priceSS > 0 && (addBox['result'] * (-1)) > 0) {
                            save_price = (save_price + ((addBox['result'] * (-1)) * priceSS));
                        }

                    }
                }


                if (orderSingle['pre']) {
                    if (typeof orderSingle['pre']['product_list'][produc['id']] != "undefined") {
                        addBox['store_pre'] = orderSingle['pre']['product_list'][produc['id']];
                    }
                }


                if (productListSave) {

                }
                let countSas = parseInt((productListSave[produc['id']]));
                if (countSas > 0) {
                    addBox['count'] = countSas;
                } else {
                    addBox['count'] = 0;
                }

                return {...produc, ...addBox};
            });

        }


        setPriceSave(save_price);
        setOrderSingle(orderSingle);


        try {
            let modalBrowse = require('./components/Model/edit.json');
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        row[index] = {...row[index], ...modalBrowse[key]}
                    }
                });
            });

            let sortArray = [];
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        sortArray.push(row[index]);
                    }
                });
            });

            row.forEach((val, index) => {
                if (sortArray.filter((filItem) => {
                    return filItem['name'] == val['name']
                }).length == 0) {
                    sortArray.push(row[index]);
                }
            });


            row = sortArray;

        } catch (err) {

        }

        if (orderSingle) {
            row = row.map((pr) => {
                return {...pr, ...{value: orderSingle[pr.name]}}
            })
        }
        setInputs(row);


        tableRow(orderSingle).then();


    }

    async function tableRow(ord) {


        let time = ord.created_at.split(/[ T]+/);
        time = time[0].split("-");
        if (time.length > 2) {
            time = time[2] + "-" + time[1] + "-" + time[0];
        }
        let row = {
            "catalog_id": "Раздел",
            "photo": "Фото",
            "name": "Название",
            "price": "Цена за ед",
            "outPlus": "Приход",
            "outMinus": "Списание",
        };


        if (ord?.pre && ord?.pre?.created_at) {
            let timePrePre = ord.pre.created_at.split(/[ T]+/);
            timePrePre = timePrePre[0].split("-");
            if (timePrePre.length > 2) {
                row["store_pre"] = timePrePre[2] + "-" + timePrePre[1] + "-" + timePrePre[0];
            }
        }


        row["count"] = time;
        // row["result"] = "Разница";

        row["faktCount"] = "Фактический остаток";
        row["plusCount"] = "Излишек количество";
        row["minusCount"] = "Недосдача Количество";

        row["option"] = "Недосдача сумма";


        Object.keys(row).forEach((key) => {
            if (key == 'photo') {
                row[key] = {
                    name: row[key],
                    className: "ci-table-th-7",
                    value: "",
                }
            } else {
                row[key] = {
                    name: row[key],
                    className: "ci-table-th-88",
                    value: "",
                }
            }
        });


        setRowTable(row);

    }

    useEffect(() => {
        getOrderSend().then();
    }, [params.id]);

    return (<Layout>

        <div className="edit-model-head">
            <p className={'text text-bold text-s26'}>
                Инвентаризация
            </p>
        </div>

        <InputsEdit inputs={inputs} onChange={(e, name) => {
            setInputs(inputs.map((inp) => {
                if (inp["name"] == name) {
                    return {...inp, ...{value: e.target.value}};
                }
                return inp;
            }));
        }}/>

        {
            params.id == 0 ? (
                <div className={'info_table text text-s18'}>
                    Чтобы добавить товары, заполните информацию о заказе выше и нажмине кнопку сохранить
                </div>
            ) : <div className="edit-table">

                <div style={{display: 'flex', alignItems: "center"}}>
                    <label className={'text text-s16'} style={{display: 'flex', alignItems: "center"}}>
                        <input checked={filterPlus == 0} onChange={() => {
                            setFilterPlus(0);
                        }} type={'radio'} name={'filter'}/> &nbsp; Без фильтрации
                    </label>
                    <label className={'text text-s16'}
                           style={{display: 'flex', alignItems: "center", marginLeft: "2rem"}}>
                        <input checked={filterPlus == 1} onChange={() => {
                            setFilterPlus(1);
                        }} type={'radio'} name={'filter'}/> &nbsp;  показывать излишек
                    </label>
                    <label className={'text text-s16'}
                           style={{display: 'flex', alignItems: "center", marginLeft: "2rem"}}>
                        <input checked={filterPlus == 2} onChange={() => {
                            setFilterPlus(2);
                        }} type={'radio'} name={'filter'}/> &nbsp; показывать недостачу
                    </label>
                </div>
                <div className="edit-table__body">
                    <div style={{margin: "2rem 0", width: "100%", textAlign: "right"}}>
                        <p className={'text text-bold text-s16'}>
                            Суммарная сумма недосдачи {priceSave.toLocaleString()}
                        </p>
                    </div>
                    <Table th={
                        {...rowTable}
                    } tr={
                        orderSingle?.product_list_array ? [...orderSingle?.product_list_array].filter((isd) => {
                            if (filterPlus == 1 && isd.result < 0) {
                                return false;
                            }
                            if (filterPlus == 2 && isd.result > 0) {
                                return false;
                            }
                            if (isd["plusCount"] != 0 || isd["minusCount"] != 0) {
                                return true;
                            }
                            return false;
                        }) : []
                    }
                           trRender={(item, index, html) => {

                               return (
                                   <tr className={parseInt(productList[item['id']]) > 0 ? "  " : "el-error"}
                                       key={index}>
                                       {html}
                                   </tr>
                               );
                           }}
                           option={(id, row) => {
                               let price = row['price'];
                               if (price == null || price == "") {
                                   price = 0;
                               }
                               return (
                                   <div className={'text text-s14'}>
                                       {price != 0 ? ((price * parseInt(row['result'])) < 0 ? '+' + ((price * parseInt(row['result'])) * (-1)) : 0) + " тнг" : "Сумма не задана"}
                                   </div>
                               )
                           }}
                    />
                </div>
            </div>
        }


    </Layout>);
});
export default MainScreen;
