import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

import Table from "../../components/Table";
import {
    getModal,
    getModalRow,
    getModalRowEdit,
    getOrder,
    getSingle,
    sendImportFile,
    updateDB
} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";
import CustomFile from "../../components/CustomFile";

const MainScreen = (() => {


    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);
    const [excelFile, setExcelFile] = useState('');

    const [modalName, setModalName] = useState('AccountingUser');
    const params = useParams();


    const [search, setSearch] = useState("");

    const [reset, setReset] = useState(false);
    const [rowTable, setRowTable] = useState({});

    const [page, setPage] = useState(0);


    const [openProduct, setOpenProduct] = useState(false);

    const [colTable, setColTable] = useState([]);


    const [modalProduct, setModalProduct] = useState(false);

    const [colTableProduct, setColTableProduct] = useState([]);

    const [orderSingle, setOrderSingle] = useState({});

    const [productList, setProductList] = useState({});

    async function getProductModal(page) {

        setColTableProduct((await getModal('Product', '?page=' + (page + 1) + '&browse=1&filter_sklad_id=1&search=' + (search) + '&catalog_id=')).data);
    }

    async function getOrderSend() {

        let row = (await getModalRowEdit(modalName, params.id)).data;
        let orderSingle = (await getSingle(modalName, params.id)).data;

        if (orderSingle?.product_list) {
            setProductList(JSON.parse(orderSingle.product_list));
        }

        setOrderSingle(orderSingle);


        try {
            let modalBrowse = require('./components/Model/edit.json');
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        row[index] = {...row[index], ...modalBrowse[key]}
                    }
                });
            });


            let sortArray = [];
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        sortArray.push(row[index]);
                    }
                });
            });

            row.forEach((val, index) => {
                if (sortArray.filter((filItem) => {
                    return filItem['name'] == val['name']
                }).length == 0) {
                    sortArray.push(row[index]);
                }
            });


            row = sortArray;

        } catch (err) {

        }

        if (orderSingle) {
            row = row.map((pr) => {
                return {...pr, ...{value: orderSingle[pr.name]}}
            })
        }
        setInputs(row);


    }

    async function tableRow() {


        let row = (await getModalRow('Product',)).data;

        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88",
                value: "",
            }
        });
        try {
            let modalBrowse = require('../ProductScreen/components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }
        setRowTable(row);

    }

    useEffect(() => {
        getProductModal().then();
    }, [reset]);

    useEffect(() => {
        getOrderSend().then();
        tableRow().then();
    }, [params.id]);

    return (<Layout>

        <div className="edit-model-head">
            <p className={'text text-bold text-s26'}>
                Учет посещения
            </p>

            <Btn className={'btn-def-table text text-s14'} onClick={async () => {

                const updateDataOrder = (await updateDB({
                    'save': inputs.reduce((ass, val) => {
                        return {...ass, ...{[val.name]: val.value}};
                    }, {}), 'id': params.id, 'model_name': 'AccountingUser',
                })).data[0];

                if (params.id == 0) {
                    navigate('/AccountingAdmin/browse');
                }

                setProductList(JSON.parse(updateDataOrder.product_list));
                setOrderSingle(updateDataOrder);

            }} label={'Сохранить'}/>
        </div>

        <InputsEdit inputs={inputs} onChange={(e, name) => {
            setInputs(inputs.map((inp) => {
                if (inp["name"] == name) {
                    return {...inp, ...{value: e.target.value}};
                }
                return inp;
            }));

        }}/>



    </Layout>);
});
export default MainScreen;
