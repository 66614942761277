import React from 'react';

import './style.scss';


const Article = (({className, label, value, onChange}) => {

    return (
        <>
            <label className={`ci-input_login ${className}`}>
                <input type="text" value={value} onChange={onChange} required=""
                       className="text ci-input_login_in"/>
                <div className={'text ci-placeholder_login ' + (value && 'ci-placeholder_login__active')}>{label}</div>
            </label>
        </>
    )
});


export default Article;
