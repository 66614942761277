const INITIAL_STATE = {
    patients: [],
    user: {},
    city_select: "Алматы",
    city_open: false,
    modal_service: false,
    modal_service_call: false,
    auth: false,
    redirect: false,
    authUser: false,
    token_: false,
    politica: false,
    order_add_product: [],
    lang: 'en',
};


const stateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'STATE':
            return {...state, ...action.value};
        default:
            return state;
    }
};
export default stateReducer;
