import React, {useEffect, useState} from 'react';

import './style.scss';


const Article = (({className, img, name, type = 'text', label, value, onChange}) => {

    const [controlPost, setControlPost] = useState(false);

    return (
        <>
            {/*<div onClick={() => {*/}
            {/*    setControlPost(!controlPost);*/}
            {/*}} className="info-adv">*/}
            {/*    {*/}
            {/*        eventsToday.map((usbox) => {*/}
            {/*            return (*/}
            {/*                <div style={{color: "#000"}} className="btn-bolsa text text-bold text-s20">*/}
            {/*                    {usbox['title']}*/}
            {/*                </div>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}

            {/*    {*/}
            {/*        controlPost && (*/}
            {/*            <div className="box-list-box" style={{width: "80%", margin: "auto", marginTop: "2rem"}}>*/}
            {/*                <div onClick={() => {*/}
            {/*                    setOpenList(true);*/}
            {/*                }} style={{color: "#fff", backgroundColor: "#28C76F"}}*/}
            {/*                     className="btn-bolsa text text-bold text-s20">*/}
            {/*                    Вступить в смену*/}
            {/*                </div>*/}

            {/*                <div onClick={() => {*/}
            {/*                    setOpenList(true);*/}
            {/*                }} style={{color: "#fff", backgroundColor: "#28C76F"}}*/}
            {/*                     className="btn-bolsa text text-bold text-s20">*/}
            {/*                    Закрыть смену*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    }*/}


            {/*    {*/}
            {/*        openList && !window.isRole("Order", 'edit') && (*/}
            {/*            <div>*/}
            {/*                <div className="info-adv">*/}
            {/*                    <div className="info-adv-item">*/}
            {/*                        <div className="info-adv-item-icon">*/}
            {/*                            <img style={{width: "5rem"}} src={require('./components/img/info.png')}/>*/}
            {/*                        </div>*/}
            {/*                        <div className="info-adv-item-name">*/}
            {/*                        <span className={'text text-bold text-s20'}*/}
            {/*                              style={{color: "#A49999", fontWeight: "bold"}}>Тип смены</span>*/}
            {/*                            <span className={'text text-s20'}>Дневной</span>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}

            {/*                    <div className="info-adv-item">*/}
            {/*                        <div className="info-adv-item-icon">*/}
            {/*                            <img style={{width: "5rem"}} src={require('./components/img/edit.png')}/>*/}
            {/*                        </div>*/}
            {/*                        <div className="info-adv-item-name">*/}
            {/*                <span className={'text text-s20 text-bold'}*/}
            {/*                      style={{color: "#A49999", fontWeight: "bold"}}>Дата <br/> Время</span>*/}
            {/*                            <span className={'text text-s20'}>*/}
            {/*                <span>Понд, 31 июля 2023</span> <br/>*/}
            {/*                <span>с 10.00 до 22.00</span>*/}
            {/*                </span>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}

            {/*                    <div className="info-adv-item">*/}
            {/*                        <div className="info-adv-item-icon">*/}
            {/*                            <img style={{width: "5rem"}} src={require('./components/img/next.png')}/>*/}
            {/*                        </div>*/}
            {/*                        <div onClick={() => {*/}
            {/*                            if (!loading) {*/}
            {/*                                setOpenCamera(true);*/}
            {/*                            }*/}
            {/*                        }} className="info-adv-item-name">*/}
            {/*                            <div className="btn-bolsa text text-s20"*/}
            {/*                                 style={{backgroundColor: loading ? "#ccc" : ''}}>*/}
            {/*                                {loading ? 'проверка' : 'Пройти видеоидентификацию'}*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    }*/}
            {/*</div>*/}
        </>
    )
});


export default Article;
