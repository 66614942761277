import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

import Table from "../../components/Table";
import {
    getModal,
    getModalRow,
    getModalRowEdit,
    getOrder,
    getSingle,
    tableListGet,
    updateDB
} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";

const MainScreen = (() => {


    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);

    const [roleSave, setRoleSave] = useState({});

    const [modalName, setModalName] = useState('Role');
    const params = useParams();


    const [rowTable, setRowTable] = useState({});

    const [tableList, setTableList] = useState([]);


    const [colTableProduct, setColTableProduct] = useState([]);

    const [orderSingle, setOrderSingle] = useState({});

    const [productList, setProductList] = useState({});


    async function getOrderSend() {

        let row = ((await getModalRowEdit(modalName, params.id)).data);
        let orderSingle = (await getSingle(modalName, params.id)).data;


        setOrderSingle(orderSingle);


        if (orderSingle) {
            row = row.map((pr) => {
                if (typeof orderSingle[pr.name] != "undefined" && orderSingle[pr.name] != null) {
                    return {...pr, ...{value: orderSingle[pr.name]}};
                }
                return {...pr, ...{value: ""}};
            })
        }
        setInputs(row);

        const col = (await tableListGet()).data;
        setTableList(col);

        let array_json = {};

        try {
            array_json = JSON.parse(orderSingle.table_list);
        } catch (e) {
            array_json = {};
        }
        if (array_json == null) {
            array_json = {};
        }
        setRoleSave(array_json);
    }


    useEffect(() => {
        getOrderSend().then();
    }, [params.id]);

    return (<Layout>

        <div className="edit-model-head">
            <p className={'text text-bold text-s26'}>
                Добавить роли
            </p>

            <Btn className={'btn-def-table text text-s14'} onClick={async () => {

                let role_save = {...roleSave};
                const updateDataOrder = (await updateDB({
                    'save': {
                        ...inputs.reduce((ass, val) => {
                            return {...ass, ...{[val.name]: val.value}};
                        }, {}),
                        ...{table_list: JSON.stringify(role_save)}
                    }, 'id': params.id, 'model_name': 'Role',
                })).data[0];

                if (params.id == 0) {
                    navigate('/Role/edit/' + updateDataOrder.id);
                }

                setProductList(JSON.parse(updateDataOrder.product_list));
                setOrderSingle(updateDataOrder);

                NotificationManager.success('все хорошо, данные получили', 'Инвентаризация обновлена');

            }} label={'Сохранить'}/>
        </div>

        <InputsEdit inputs={inputs} onChange={(e, name) => {
            setInputs(inputs.map((inp) => {
                if (inp["name"] == name) {
                    return {...inp, ...{value: e.target.value}};
                }
                return inp;
            }));
        }}/>

        <div className="box-list-eid">
            {
                tableList ? tableList.map((name) => {

                    return (
                        <div className={'table_row text text-s16'}>
                            {
                                name.name
                            }


                            <div className="input-controle">
                                {
                                    name.name == "Order" && (
                                        <>
                                            <label>
                                                <input
                                                    checked={typeof roleSave[name.model] != "undefined" ? (roleSave[name.model]?.note) : false}
                                                    onChange={() => {

                                                        let roleSavePre = roleSave;

                                                        if (typeof roleSavePre[name.model] == "undefined") {
                                                            roleSavePre[name.model] = {};
                                                        }

                                                        if (typeof roleSavePre[name.model]['note'] != "undefined") {
                                                            roleSavePre[name.model]['note'] = !roleSavePre[name.model]['note'];
                                                        } else {
                                                            roleSavePre[name.model]['note'] = true;
                                                        }

                                                        setRoleSave({...roleSavePre});
                                                        // setRoleSave();

                                                    }} type={'checkbox'}/> Кнопка Примечание
                                            </label>
                                            <label>
                                                <input
                                                    checked={typeof roleSave[name.model] != "undefined" ? (roleSave[name.model]?.post) : false}
                                                    onChange={() => {

                                                        let roleSavePre = roleSave;

                                                        if (typeof roleSavePre[name.model] == "undefined") {
                                                            roleSavePre[name.model] = {};
                                                        }

                                                        if (typeof roleSavePre[name.model]['post'] != "undefined") {
                                                            roleSavePre[name.model]['post'] = !roleSavePre[name.model]['post'];
                                                        } else {
                                                            roleSavePre[name.model]['post'] = true;
                                                        }

                                                        setRoleSave({...roleSavePre});
                                                        // setRoleSave();

                                                    }} type={'checkbox'}/> Кнопка Выдан
                                            </label>
                                            <label>
                                                <input
                                                    checked={typeof roleSave[name.model] != "undefined" ? (roleSave[name.model]?.get) : false}
                                                    onChange={() => {

                                                        let roleSavePre = roleSave;

                                                        if (typeof roleSavePre[name.model] == "undefined") {
                                                            roleSavePre[name.model] = {};
                                                        }

                                                        if (typeof roleSavePre[name.model]['get'] != "undefined") {
                                                            roleSavePre[name.model]['get'] = !roleSavePre[name.model]['get'];
                                                        } else {
                                                            roleSavePre[name.model]['get'] = true;
                                                        }

                                                        setRoleSave({...roleSavePre});
                                                        // setRoleSave();

                                                    }} type={'checkbox'}/> Кнопка Получен
                                            </label>
                                        </>
                                    )
                                }
                                <label>
                                    <input
                                        checked={typeof roleSave[name.model] != "undefined" ? (roleSave[name.model]?.browse) : false}
                                        onChange={() => {

                                            let roleSavePre = roleSave;

                                            if (typeof roleSavePre[name.model] == "undefined") {
                                                roleSavePre[name.model] = {};
                                            }

                                            if (typeof roleSavePre[name.model]['browse'] != "undefined") {
                                                roleSavePre[name.model]['browse'] = !roleSavePre[name.model]['browse'];
                                            } else {
                                                roleSavePre[name.model]['browse'] = true;
                                            }

                                            setRoleSave({...roleSavePre});
                                            // setRoleSave();

                                        }} type={'checkbox'}/> просмотр
                                </label>


                                <label>
                                    <input
                                        checked={typeof roleSave[name.model] != "undefined" ? roleSave[name.model]?.delete : false}
                                        onChange={() => {

                                            let roleSavePre = roleSave;

                                            if (typeof roleSavePre[name.model] == "undefined") {
                                                roleSavePre[name.model] = {};
                                            }

                                            if (typeof roleSavePre[name.model]['delete'] != "undefined") {
                                                roleSavePre[name.model]['delete'] = !roleSavePre[name.model]['delete'];
                                            } else {
                                                roleSavePre[name.model]['delete'] = true;
                                            }

                                            setRoleSave({...roleSavePre});
                                        }} type={'checkbox'}/> удаление
                                </label>

                                <label>
                                    <input
                                        checked={typeof roleSave[name.model] != "undefined" ? roleSave[name.model]?.add : false}
                                        onChange={() => {

                                            let roleSavePre = roleSave;

                                            if (typeof roleSavePre[name.model] == "undefined") {
                                                roleSavePre[name.model] = {};
                                            }

                                            if (typeof roleSavePre[name.model]['add'] != "undefined") {
                                                roleSavePre[name.model]['add'] = !roleSavePre[name.model]['add'];
                                            } else {
                                                roleSavePre[name.model]['add'] = true;
                                            }


                                            setRoleSave({...roleSavePre});

                                        }} type={'checkbox'}/> добавление</label>

                                <label>
                                    <input
                                        checked={typeof roleSave[name.model] != "undefined" ? roleSave[name.model]?.edit : false}
                                        onChange={() => {

                                            let roleSavePre = roleSave;

                                            if (typeof roleSavePre[name.model] == "undefined") {
                                                roleSavePre[name.model] = {};
                                            }

                                            if (typeof roleSavePre[name.model]['edit'] != "undefined") {
                                                roleSavePre[name.model]['edit'] = !roleSavePre[name.model]['edit'];
                                            } else {
                                                roleSavePre[name.model]['edit'] = true;
                                            }

                                            setRoleSave({...roleSavePre});

                                        }} type={'checkbox'}/> редактирование
                                </label>
                            </div>
                        </div>
                    )
                }) : ''
            }
        </div>

    </Layout>);
});
export default MainScreen;
