import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

import Table from "../../components/Table";
import {
    deleteDataBase,
    getModal,
    getModalRow,
    getModalRowEdit,
    getOrder,
    getSingle,
    updateDB
} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";
import ProductEvent from "../../components/ProductEvent";
import CustomText from "../../components/CustomText";

const MainScreen = (() => {


    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);
    const [first, setFirst] = useState(false);

    const [saveEvent, setSaveEvent] = useState(false);
    const [save_reset, set_save_reset] = useState(false);

    const [addProduct, setAddProduct] = useState(false);

    const [modalName, setModalName] = useState('Inventory');
    const params = useParams();


    const [rowTable, setRowTable] = useState({});

    const [page, setPage] = useState(0);


    const [openProduct, setOpenProduct] = useState(false);

    const [colTable, setColTable] = useState([]);


    const [modalProduct, setModalProduct] = useState(false);

    const [updateForm, setUpdateForm] = useState(false);

    const [colTableProduct, setColTableProduct] = useState([]);

    const [productCount, setProductCount] = useState([]);

    const [orderSingle, setOrderSingle] = useState({});

    const [productSave, setProductSave] = useState([]);

    const [productList, setProductList] = useState({});
    const [productDrop, setProductDrop] = useState([]);

    const [productListRow, setProductListRow] = useState({});

    const [statusSend, setStatusSend] = useState(0);

    async function getProductModal(page) {

        setColTableProduct((await getModal('Product', '?page=' + (page + 1) + '&browse=1&catalog_id=')).data);
    }

    async function getOrderSend() {

        let row = (await getModalRowEdit(modalName, params.id + '?hidden=true')).data;
        let orderSingle = (await getSingle(modalName, params.id)).data;


        let rowProduct = (await getModalRowEdit('Product', 0)).data;

        if (orderSingle?.product_list) {
            setProductList(JSON.parse(orderSingle.product_list));
        }
        if (orderSingle?.product_input) {

            function isJson(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            }


            if (isJson(orderSingle.product_input)) {
                setProductSave(JSON.parse(orderSingle.product_input));
                setProductCount(JSON.parse(orderSingle.product_input));
            } else {
                setProductSave([]);
            }

        }

        setOrderSingle(orderSingle);

        try {
            let modalBrowse = require('./components/Model/prihod.json');
            Object.keys(modalBrowse).forEach((key) => {
                rowProduct.forEach((val, index) => {
                    if (val.name == key) {
                        rowProduct[index] = {...rowProduct[index], ...modalBrowse[key]}
                    }
                });
            });


            let sortArray = [];
            Object.keys(modalBrowse).forEach((key) => {
                rowProduct.forEach((val, index) => {
                    if (val.name == key) {
                        sortArray.push(rowProduct[index]);
                    }
                });
            });

            rowProduct.forEach((val, index) => {
                if (sortArray.filter((filItem) => {
                    return filItem['name'] == val['name']
                }).length == 0) {
                    sortArray.push(rowProduct[index]);
                }
            });


            rowProduct = sortArray;

        } catch (err) {

        }

        setProductListRow(rowProduct);


        if (orderSingle) {
            row = row.map((pr) => {
                return {...pr, ...{value: orderSingle[pr.name]}}
            })
        }
        if (params.id == 0) {


            let curr = new Date();
            row = row.map((pr) => {
                return {...pr, ...{value: curr.toJSON().slice(0, 16)}}
            })
        }

        setInputs(row);


    }

    async function tableRow() {


        let row = (await getModalRow('Product')).data;


        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key], className: "ci-table-th-88", value: "",
            }
        });
        try {
            let modalBrowse = require('../ProductScreen/components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }
        setRowTable(row);

    }


    async function save_form() {
        const updateDataOrder = (await updateDB({
            'save': {
                ...inputs.reduce((ass, val) => {
                    return {...ass, ...{[val.name]: val.value}};
                }, {}), ...{
                    type: "5",
                    status: statusSend,
                    product_list: JSON.stringify(productList),
                    product_input: JSON.stringify(productSave)
                }
            }, 'id': params.id, 'model_name': 'Inventory',
        })).data[0];

        if (params.id == 0) {
            navigate('/Inventory/prihod/' + updateDataOrder.id);
        }

        setProductList(JSON.parse(updateDataOrder.product_list));
        setOrderSingle(updateDataOrder);
    }

    async function getProductAll() {
        const colProduct = (await getModal('Product', '')).data;
        setProductDrop(colProduct);
    }


    useEffect(() => {

        if (addProduct) {
            // product_input
            if ([...productSave].filter(((inid) => {
                return inid == addProduct;
            })).length == 0) {
                setProductSave([...productSave, ...[addProduct]]);
            }

            if (window.save_reset) {
                clearTimeout(window.save_reset);
            }
            window.save_reset = setTimeout(() => {
                set_save_reset(!save_reset)
            }, 2000);
        }

    }, [addProduct]);

    useEffect(() => {
        if (first) {
            save_form().then();
        }
    }, [save_reset]);

    useEffect(() => {
        getProductAll();

        setTimeout(() => {
            setFirst(true);
        }, 1000)

    }, []);

    useEffect(() => {
        getOrderSend().then();
        tableRow().then();

    }, [params.id]);


    return (<Layout>

        <div className="edit-model-head">
            <p className={'text text-bold text-s26'}>
                Добавить приход
            </p>

            <div style={{marginLeft: "auto"}}>
                <Btn className={'btn-def-table text text-s14'} onClick={async () => {
                    setStatusSend(1);
                    setSaveEvent(true)
                    setTimeout(() => {
                        setSaveEvent(false)
                    }, 1000);
                }} label={'Завершить'}/>

                <Btn className={'btn-def-table text text-s14'} onClick={async () => {
                    setStatusSend(0);
                    setSaveEvent(true)
                    setTimeout(() => {
                        setSaveEvent(false)
                    }, 1000);
                }} label={'Сохранить'}/>
            </div>
        </div>


        <InputsEdit timeShow={false} inputs={inputs} onChange={(e, name) => {
            setInputs(inputs.map((inp) => {
                if (inp["name"] == name) {
                    return {...inp, ...{value: e.target.value}};
                }
                return inp;
            }));

        }}/>


        {
            productCount.map((id, index) => {
                return (
                    <ProductEvent
                        indexRemove={index}
                        productDrop={productDrop}
                        option={() => {
                            return (<div style={{marginBottom: "2rem"}}>

                                {
                                    id != 0 && (
                                        <CustomInput onChange={(e) => {
                                            let productListOld = {...productList};
                                            productListOld[id] = e.target.value;
                                            setProductList({...productListOld});
                                        }} value={typeof productList[id] != "undefined" ? '' + productList[id] : ""}
                                                     type={'text'}
                                                     label={'Количество'}/>
                                    )
                                }

                                {
                                    id == 0 && (
                                        <p className={'text text-s16'}>
                                            Сохраните товар и после укажите количество
                                        </p>
                                    )
                                }


                            </div>)
                        }}
                        remove={(id_remove, inx) => {
                            if (id_remove != 0) {
                                let pre_ar = [...productSave];

                                pre_ar = pre_ar.filter((es) => {
                                    if (es == id_remove) {
                                        return false;
                                    }
                                    return true;
                                });
                                setProductSave([...pre_ar]);
                                if (window.save_reset) {
                                    clearTimeout(window.save_reset);
                                }
                                window.save_reset = setTimeout(() => {
                                    set_save_reset(!save_reset)
                                }, 2000);
                            } else {

                                let pre_ar = [...productCount];
                                delete pre_ar[inx];
                                pre_ar.sort();
                                pre_ar = pre_ar.filter((es) => {
                                    if (!es && es != 0) {
                                        return false;
                                    }
                                    return true;
                                })

                                setProductCount([...pre_ar]);
                            }
                        }}
                        saveEvent={(idSave) => {
                            let productCountPre = [...productCount];
                            productCountPre[index] = idSave;
                            setProductCount(productCountPre);
                            setAddProduct(idSave);

                            if (window.save_reset) {
                                clearTimeout(window.save_reset);
                            }
                            window.save_reset = setTimeout(() => {
                                set_save_reset(!save_reset)
                            }, 2000);
                        }} save={saveEvent} id_save={id}/>
                )
            })
        }


        <div className="div_conreolw" style={{display: "flex", justifyContent: "flex-end"}}>
            <Btn className={'btn-def-table text text-s14'} onClick={async () => {
                setProductCount([...productCount, ...[0]]);
            }} label={'добавить товар'}/>
        </div>


        <div className="edit-table__body">

            <Table th={rowTable} tr={orderSingle ? orderSingle?.product_list_array : []}
                   option={(id, row) => {
                       return (<div className={'table-options'}>

                           {/*<input style={{marginRight: "2rem"}} type={'checkbox'}/>*/}
                           <BtnIcon onClick={() => {
                               navigate('/Product/edit/' + id);
                           }} img={require('../../assets/images/edit.png')}/>
                       </div>)
                   }}
            />
        </div>

    </Layout>);
});
export default MainScreen;
