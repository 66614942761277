import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {
    deleteDataBase, getModal, getModalRow, getOrder, copyOrder, updateDB, getOrderCalculation
} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import {useNavigate, useParams} from "react-router-dom";


import Modal from "react-modal";


import {NotificationManager} from "react-notifications";

const {REACT_APP_ASSETS_PATH} = process.env;

const MainScreen = (() => {


    const lang = useSelector(LangSlices);


    const [modal, setModal] = useState(false);
    const [openRow, setOpenRow] = useState({});
    const [openProduct, setOpenProduct] = useState([]);

    const [order, setOrder] = useState([]);

    const [status, setStatus] = useState('');

    const [date, setDate] = useState(0);
    const [newCreate, setNewCreate] = useState(false);

    const [reset, setReset] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const [modalName, setModalName] = useState('Order');

    const [searchOb, setSearchOb] = useState({});
    const [timeCode, setTimeCode] = useState(false);

    const navigate = useNavigate();


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);

    const [sendTime, setSendTime] = useState(false);


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setStatus('');
        // setSearchOb({});
        setNewCreate(false);
        setDate(-1);


        setReset(!reset);
    }

    function addFilter(e) {
        setSearchOb(prevSearch => ({ ...prevSearch, [e.target.name]: e.target.value }));
        if (e.target.name === 'search' && e.keyCode === 13) {
            setReset(prevReset => !prevReset);
        } else if (e.target.name !== 'search') {
            setReset(prevReset => !prevReset);
        }
    }


    async function getOrderSend() {

        let row = (await getModalRow(modalName)).data;
        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key], className: "ci-table-th-88"
            }
        });
        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }
        setRowTable(row);
        if (status != '') {
            searchOb['status'] = status;
        }
        let saveControl = {...searchOb};
        Object.keys(saveControl).forEach((key) => {
            if (!saveControl[key]) {
                delete saveControl[key];
            }
        })


        let currentDate = new Date();

// Вычесть 6 часов
        currentDate.setHours(currentDate.getHours() - 6);

// Форматировать дату в строку в нужном формате (гггг-ММ-дд чч:мм:сс)
        let year = currentDate.getFullYear();
        let month = String(currentDate.getMonth() + 1).padStart(2, '0');
        let day = String(currentDate.getDate()).padStart(2, '0');
        let hours = String(currentDate.getHours()).padStart(2, '0');
        let minutes = String(currentDate.getMinutes()).padStart(2, '0');
        let seconds = String(currentDate.getSeconds()).padStart(2, '0');

        let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;


        const col = (await getModal(modalName, '?' + serialize(saveControl) + '&hidden=true&status=1&demon_full_no=1&date_min_to='+formattedDate)).data;
        setColTable(col);
        setSendTime(false);

        // setOrder(orderReq);
    }

    let timerId;
    useEffect(() => {

        clearTimeout(timerId);
        timerId = (setTimeout(() => {
            getOrderSend().then();
        }, 1000))

    }, [reset]);


    let listOrder = colTable.map((orderIr) => {


        if (new Date(orderIr.origin_mon).toLocaleDateString() == new Date().toLocaleDateString()) {
            let explode = orderIr.mon.split("|");
            if (explode.length > 1) {
                explode = explode[1];
            } else {
                explode = explode[0];
            }
            orderIr.mon = '<b>Сегодня</b> |' + explode;
        }

        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);

        if (new Date(orderIr.origin_mon).toLocaleDateString() == currentDate.toLocaleDateString()) {
            let explode = orderIr.mon.split("|");
            if (explode.length > 1) {
                explode = explode[1];
            } else {
                explode = explode[0];
            }
            orderIr.mon = '<b>Завтра</b> |' + explode;
        }

        currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 2);

        if (new Date(orderIr.origin_mon).toLocaleDateString() == currentDate.toLocaleDateString()) {
            let explode = orderIr.mon.split("|");
            if (explode.length > 1) {
                explode = explode[1];
            } else {
                explode = explode[0];
            }
            orderIr.mon = '<b>Послезавтра</b> |' + explode;
        }


        if (new Date(orderIr.origin_demon).toLocaleDateString() == new Date().toLocaleDateString()) {
            let explode = orderIr.demon.split("|");
            if (explode.length > 1) {
                explode = explode[1];
            } else {
                explode = explode[0];
            }
            orderIr.demon = '<b>Сегодня</b> |' + explode;
        }

        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);

        if (new Date(orderIr.origin_demon).toLocaleDateString() == currentDate.toLocaleDateString()) {
            let explode = orderIr.demon.split("|");
            if (explode.length > 1) {
                explode = explode[1];
            } else {
                explode = explode[0];
            }
            orderIr.demon = '<b>Завтра</b> |' + explode;
        }

        currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 2);

        if (new Date(orderIr.origin_demon).toLocaleDateString() == currentDate.toLocaleDateString()) {
            let explode = orderIr.demon.split("|");
            if (explode.length > 1) {
                explode = explode[1];
            } else {
                explode = explode[0];
            }
            orderIr.demon = '<b>Послезавтра</b> |' + explode;
        }


        return orderIr;
    });


    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate = currentDate.getTime();




    return (<Layout>

        <p className={'text text-bold text-s26'}>
            Заказы
        </p>

        <div className="table-filter">
            <CustomInput onChange={addFilter} value={searchOb?.search} name={'search'}
                         img={require('../../assets/images/search-normal.png')} label={'Поиск'}/>
            <BtnCustom onChange={resetFilter} img={require('../../assets/images/refresh.png')}
                       label={'Сбросить фильтры'}/>

        </div>

        <div className="table-btn">

            {window.isRole("Order", 'edit') && (<>
                <Btn className={'btn-def-table text text-s14 ' + (!calendar && 'active')} onClick={() => {
                    setCalendar(false);
                }} label={'Перейти к таблице'}/>
                <Btn className={'btn-def-table text text-s14 ' + (calendar && 'active')} onClick={() => {
                    setCalendar(true);
                }} label={'Перейти к календарю'}/>
                <div className="btn-def-table" style={{background: "none"}}></div>
            </>)}



        </div>


        {calendar && (<div className="text text-s14">
            <FullCalendar
                eventTimeFormat={{
                    hour: 'numeric', minute: '2-digit', meridiem: false
                }}
                dayMaxEvents={6}
                dayMaxEventRows={1}
                plugins={[dayGridPlugin, timeGridPlugin]}
                locale={'ru'}
                locales={[ruLocale]}
                initialView="dayGridMonth"
                headerToolbar={{
                    right: 'dayGridMonth,timeGridWeek,timeGridDay', left: 'prev,next', center: 'title'
                }}
                eventClick={(item) => {
                    navigate('/Order/edit/' + item.event.extendedProps.order_id);
                }}
                events={[...colTable.map((ev) => {
                    return {
                        color: "#ad1457",
                        title: ev['name_ords'],
                        start: ev['origin_mon'],
                        end: ev['origin_demon'],
                        order_id: ev['id'],
                    };
                })]}
            />
        </div>)}

        <div className={'flex-box-flex'}>
            {!calendar && (<Table modalName={modalName} th={rowTable} tr={listOrder}
                                  loading={sendTime}
                                  productOption={() => {

                                  }}
                                  option={(id, row) => {
                                      return (<div className={'table-options'}>

                                          <div style={{width: "15rem", marginRight: "2rem"}}>
                                              <CustomSelect onChange={async (e) => {
                                                  let statysSas = [{
                                                      "id": 1,
                                                      "name": "\u0411\u0440\u043e\u043d\u044c",
                                                      "created_at": "2022-11-15T05:24:16.000000Z",
                                                      "updated_at": "2022-12-05T10:17:22.000000Z",
                                                      "deleted_at": null
                                                  }, {
                                                      "id": 2,
                                                      "name": "\u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043d\u044b\u0435",
                                                      "created_at": "2022-11-15T05:24:30.000000Z",
                                                      "updated_at": "2022-11-15T05:24:30.000000Z",
                                                      "deleted_at": null
                                                  }, {
                                                      "id": 3,
                                                      "name": "\u041e\u0442\u043c\u0435\u043d\u0435\u043d\u043d\u044b\u0435",
                                                      "created_at": "2022-11-15T05:24:40.000000Z",
                                                      "updated_at": "2022-11-15T05:25:00.000000Z",
                                                      "deleted_at": null
                                                  }].reduce((acc, item) => {
                                                      return {...acc, ...{[item['name']]: item["id"]}};
                                                  }, {});

                                                  const updateDataOrder = (await updateDB({
                                                      'save': {
                                                          status: statysSas[e.target.value],
                                                      }, 'id': row['id'], 'model_name': 'Order',
                                                  }));

                                                  setReset(!reset);
                                              }} options={[{
                                                  "id": 1,
                                                  "name": "\u0411\u0440\u043e\u043d\u044c",
                                                  "created_at": "2022-11-15T05:24:16.000000Z",
                                                  "updated_at": "2022-12-05T10:17:22.000000Z",
                                                  "deleted_at": null
                                              }, {
                                                  "id": 2,
                                                  "name": "\u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043d\u044b\u0435",
                                                  "created_at": "2022-11-15T05:24:30.000000Z",
                                                  "updated_at": "2022-11-15T05:24:30.000000Z",
                                                  "deleted_at": null
                                              }, {
                                                  "id": 3,
                                                  "name": "\u041e\u0442\u043c\u0435\u043d\u0435\u043d\u043d\u044b\u0435",
                                                  "created_at": "2022-11-15T05:24:40.000000Z",
                                                  "updated_at": "2022-11-15T05:25:00.000000Z",
                                                  "deleted_at": null
                                              }].reduce((acc, item) => {
                                                  return {...acc, ...{[item['name']]: item["name"]}};
                                              }, {})} value={row.status} label={'Статус'}/>
                                          </div>


                                          <BtnIcon onClick={async () => {
                                              NotificationManager.info('Начали копирование', 'Копирования заказа');
                                              getOrderSend();
                                              let idSa = (await copyOrder(id)).id;
                                              navigate('/Order/edit/' + idSa);

                                              NotificationManager.success('Все ок, скопировали', 'Копирования заказа');
                                          }} value={'копи'}/>


                                          <BtnIcon onClick={async () => {

                                              let allOrderInfo = (await getOrderCalculation(id));
                                              setOpenRow(row);

                                              setOpenProduct(allOrderInfo.data.product);

                                              setModal(true);

                                          }} value={'список'}/>

                                          <BtnIcon title={'download'} onClick={() => {

                                              location.href = REACT_APP_ASSETS_PATH+'/api/excel/order/' + id;
                                          }} img={require('../../assets/images/document-download.png')}/>
                                          <BtnIcon title={'edit'} onClick={() => {
                                              navigate('/Order/edit/' + id);
                                          }} img={require('../../assets/images/edit.png')}/>
                                          {/*<BtnIcon title={'delete'} onClick={async () => {*/}
                                          {/*    if (window.confirm("Точно удалить?")) {*/}
                                          {/*        await deleteDataBase({*/}
                                          {/*            id: id*/}
                                          {/*        }, 'Order')*/}
                                          {/*        setReset(!reset);*/}
                                          {/*    }*/}
                                          {/*}} img={require('../../assets/images/trash.png')}/>*/}
                                      </div>)
                                  }}
            />)}
        </div>


        <Modal
            isOpen={modal}
            onRequestClose={() => {
                setModal(false);
            }}
            contentLabel="Example Modal"
            className="model-add-box-comp-list"
        >
            <div className={'complect-list'}>
                <div className="complect-list-head">
                    <span className={'text text-bold text-s18'}>Заказ: {openRow?.name}</span>
                </div>
                <div className="complect-list-body">
                    {openProduct?.products && Object.entries(openProduct?.products).map((item) => {


                        return (<div>
                                <p className={'text text-s18'}>
                                    <b>{item[0]}</b>
                                </p>
                                {
                                    (Object.entries(item[1]).map((liBox) => {
                                        let li = liBox[1][0].product;

                                        let count = parseInt(li['count']);
                                        if (!(count > 0)) {
                                            count = 0;
                                        }
                                        let countDop = parseInt(li['count_dop']);
                                        if (!(countDop > 0)) {
                                            countDop = 0;
                                        }
                                        li['count'] = countDop + count;
                                        const string = li['photo'];
                                        let type = 'text';


                                        return (<div className="item-complit" style={{display: "flex"}}>

                                            {string ? (<img onClick={() => {

                                            }} style={{
                                                marginRight: "1.5rem",
                                                position: "relative",
                                                zIndex: 100000,
                                                pointerEvents: 'all',
                                                objectFit: 'contain',
                                                backgroundColor: '#ececec'
                                            }} width={75} height={75}
                                                            src={(string ? (string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') : '') + string}/>) : (
                                                <img style={{
                                                    marginRight: "1.5rem",
                                                    position: "relative",
                                                    zIndex: 100000,
                                                    pointerEvents: 'all',
                                                    objectFit: 'contain',
                                                    backgroundColor: '#ececec'
                                                }} width={75} height={75}
                                                />)}
                                            <span className={'text text-s16'}>Название: {li['name']}
                                                <br/> кол-во: {li['count']}
                                                <br/> цена: {parseInt(li['price']) > 0 ? parseInt(li['price']).toLocaleString() : '0'} тнг
                                        <br/> цена за все: {((parseInt(li['price']) > 0 ? parseInt(li['price']) : 0) * (parseInt(li['count']) > 0 ? parseInt(li['count']) : 0)).toLocaleString()} тнг</span>
                                        </div>);

                                    }))
                                }
                            </div>
                        );

                    })}
                </div>

            </div>
        </Modal>

    </Layout>);
});
export default MainScreen;
