import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import {useNavigate, useParams} from "react-router-dom";

import {DragDropContext, DropResult, Draggable, Droppable} from '@hello-pangea/dnd';
import {Card, Typography} from "@mui/material";
import {getModal, getModalRow, updateDB} from "../../services";
import moment from "moment/moment";

const MainScreen = (() => {

    const navigate = useNavigate();
    const lang = useSelector(LangSlices);
    const [repiteProduct, setRepiteProduct] = useState([]);

    async function getInfo() {

        const col = ((await getModal('Repair', '')).data).filter((liCOProduct) => {
            return liCOProduct.product_list != "{}";
        });


        let repType = ((await getModal('Repair_type', '?browse=1')).data)
        repType = repType.reduce((ass, item) => {
            return {
                ...ass, ...{
                    [item.id]: {
                        name: item.name,
                        child: [],
                    }
                }
            };
        }, {});

        col.forEach((item) => {

            if (item.repair_type in repType) {
                repType[item.repair_type].child = [
                    ...repType[item.repair_type].child,
                    item
                ]
            }
        });

        setRepiteProduct((
            Object.entries(repType).reduce((ass, item) => {
                return [
                    ...ass, {...item[1], ...{id: item[0]}}
                ];
            }, [])
        ));

    }

    useEffect(() => {

        getInfo();

    }, []);


    function getRandomArbitrary(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return '' + (Math.floor(Math.random() * (max - min + 1)) + min);
    }

    const onDragEnd = (DropResult) => {

        const fromColumn = DropResult.source.droppableId;
        const toColumn = DropResult.destination.droppableId;
        const productId = DropResult.draggableId;

        if (fromColumn != toColumn) {

            let saveControl = [...repiteProduct].reduce((ass, item) => {
                return {
                    ...ass, ...{[item['id']]: item}
                };
            }, {});


            saveControl[toColumn].child = [...saveControl[toColumn].child, ...saveControl[fromColumn].child.filter((pro) => {
                return pro.id == productId;
            })];

            saveControl[fromColumn].child = saveControl[fromColumn].child.filter((pro) => {
                return pro.id != productId;
            });


            //
            setRepiteProduct((
                Object.entries(saveControl).reduce((ass, item) => {
                    return [
                        ...ass, {...item[1], ...{id: item[0]}}
                    ];
                }, [])
            ));

            const updateDataOrder = ( updateDB({
                'save': {
                    repair_type: toColumn
                },
                'id': productId,
                'model_name': 'Repair',
            })).data[0];
        }
    }


    return (<Layout>
        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
                Ремонт Доска
            </p>
        </div>
        <div style={{display: "flex", alignItems: "flex-start"}}>
            <DragDropContext onDragEnd={onDragEnd}>
                {
                    repiteProduct.map((nameTitle, indexID) => {
                        return (
                            <Droppable droppableId={'' + nameTitle.id}>
                                {(
                                    provided,
                                    snapshot // the child of a Droppable element must be a function that returns an element
                                ) => (
                                    <div>
                                        <Typography variant="h4"
                                                    sx={{
                                                        textAlign: 'left',
                                                        color: "#000",
                                                        width: "100%",
                                                        padding: "1rem 0"
                                                    }}>
                                            {nameTitle.name}
                                        </Typography>
                                        <Card
                                            ref={provided.innerRef}
                                            square
                                            variant="outlined"
                                            sx={{
                                                minHeight: '600px',
                                                width: '39rem',
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                padding: '1.1rem',
                                                borderRadius: '0.6rem',
                                                marginRight: '16px',
                                                backgroundColor: snapshot.isDraggingOver
                                                    ? '#F6C86E'
                                                    : '#F6C86E',
                                            }}
                                            {...provided.droppableProps} // any droppable elements must have these properties
                                        >

                                            {
                                                nameTitle.child.map((taskName, taskId) => {
                                                    const product = taskName.product_list_product[0];


                                                    return (
                                                        <Draggable draggableId={'' + taskName.id}
                                                                   index={'' + taskName.id}>
                                                            {(
                                                                provided,
                                                                snapshot // the child of a Draggable element must be a function that returns an element
                                                            ) => (
                                                                <Card
                                                                    ref={provided.innerRef}
                                                                    square
                                                                    variant="outlined"
                                                                    sx={{
                                                                        padding: '4px',
                                                                        marginTop: '8px',
                                                                        width: '100%',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        borderRadius: '0.6rem',
                                                                        alignItems: 'center',
                                                                        backgroundColor: snapshot.isDragging ? 'lightgreen' : {}
                                                                    }}
                                                                    {...provided.draggableProps} //any draggable elements must have these properties
                                                                    {...provided.dragHandleProps} //comment out if you want to uncomment the drag handle code below
                                                                >
                                                                    <Typography>
                                                                        <div className="repire_box">
                                                                            <div
                                                                                className="repire_box_head text text-s14">
                                                                                <b>{product.name}</b><br/>
                                                                                {taskName.comment}
                                                                            </div>
                                                                            <div
                                                                                className="repire_box_body text text-s14">
                                                                                Дата начала
                                                                                ремонта: <b>{moment(taskName.created_at).format("DD-MM-YYYY HH:mm")}</b>
                                                                                <br/>
                                                                                Дата окончания
                                                                                ремонта: <b>{moment(taskName.repair_date).format("DD-MM-YYYY HH:mm")}</b>
                                                                            </div>
                                                                        </div>
                                                                    </Typography>
                                                                </Card>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })
                                            }
                                            {provided.placeholder}
                                        </Card>
                                    </div>
                                )}
                            </Droppable>
                        )
                    })
                }
            </DragDropContext>
        </div>

    </Layout>)
        ;
});
export default MainScreen;
