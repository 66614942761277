import React, {useEffect, useState} from 'react';

import './style.scss';
import Modal from "react-modal";

const {REACT_APP_ASSETS_PATH} = process.env;

import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import CustomFile from "../CustomFile";
import {updateDB} from "../../services";

import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const TableScree = (({
                         option = {},
                         thSelect = [],
                         tdEvent = () => {
                         },
                         onCreate = false,
                         inventory = false,
                         optionSm = false,
                         donwload = false,
                         productOption = false,
                         photoHidden = false,
                         edit = false,
                         modalName,
                         openArenda = false,
                         caralog_name = false,
                         openOrder = false,
                         show_product,
                         th,
                         trRender,
                         tr = [],
                         number = false,
                         loading = false
                     }) => {

    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [reset, setReset] = useState(false);
    const [modalShowEdit, setModalEditShow] = useState(false);
    const [winWidth, setWinWidth] = useState(window.innerWidth);

    const [string, setString] = useState('');
    const [loaderImage, setLoaderImage] = useState({});

    let catalog_name_global = "";


    const showModal = () => {
        setModalShow(false);
        setModalEditShow(false);
    }

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;


    useEffect(() => {
        const resizeListener = () => {
            // change width from the state object
            setWinWidth(getWidth())
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);
    }, [])


    function trBox(item, index) {
        return Object.entries({...th}).map((thName, indexTrBox) => {
            if (thName[0] == 'option') {
                return (
                    <td className={" " + (thName[1]?.className) + ' td-name-' + thName[0]}>
                        <div>
                            {option(item['id'], item, index)}
                        </div>
                    </td>
                )
            }


            let string = item[thName[0]];
            let type = 'text';
            if (typeof string == 'string' ? (string.indexOf('.jpg') > 0 || string.indexOf('.jfif') > 0 || string.indexOf('.JPG') > 0 || string.indexOf('.png') > 0 || string.indexOf('.jpeg') > 0) : false) {
                type = 'image';
            }

            if (thName[0] == 'photo' || thName[0] == 'image' || thName[0] == 'images') {
                type = 'image';
            }

            function urlify(text) {
                var urlRegex = /(https?:\/\/[^\s]+)/g;
                return text.replace(urlRegex, function (url) {
                    let domain = (new URL(url));
                    return '<a target="_blank" href="' + url + '">ссылка на ' + domain.hostname + '</a>';
                })

            }

            if (typeof string == 'string' && type != 'image') {
                string = urlify(string);
            }


            if (thName[0] == 'price' || thName[0] == 'price_arenda' || thName[0] == 'price_sebe' || thName[0] == 'store' || thName[0] == 'store_sklad') {
                if (parseInt(string) >= 1000) {
                    string = parseInt(string).toLocaleString();
                }
            }


            let string_photo = '';
            if (modalName == 'Product' && typeof item['photo_mini'] == 'string' && item['photo_mini'] != '') {
                string_photo = item['photo_mini'];
            } else if (typeof item['photo'] == 'string' && item['photo'] != '') {
                string_photo = item['photo'];
            } else if (typeof item['photo_start'] == 'string' && item['photo_start'] != '') {
                string_photo = item['photo_start'];
            } else if (typeof item['photo_end'] == 'string' && item['photo_end'] != '') {
                string_photo = item['photo_end'];
            }


            if (modalName == 'AccountingUser') {
                string_photo = string;
            }


            return (
                <td onClick={() => {
                    if (openArenda && thName[0] == 'store_arenda') {
                        if (parseInt(string) > 0) {
                            openArenda(item['id']);
                        }
                    }
                    if (openOrder) {
                        openOrder(item);
                    }

                }} className={" " + (thName[1]?.className) + ' td-name-' + thName[0]}>

                    {
                        edit !== false && thSelect.length > indexTrBox && edit == item['id'] && (
                            thSelect[indexTrBox]
                        )
                    }


                    {
                        indexTrBox == 0 && onCreate && (
                            <div onClick={(e) => {
                                e.stopPropagation();
                                if (onCreate) {
                                    onCreate(item);
                                }
                            }} className={'controlBox'}>
                                +
                            </div>
                        )
                    }

                    {
                        type == 'text' && (thSelect.length > indexTrBox ? (edit != item['id']) : true) && (
                            <div>
                                <span style={{
                                    display: "flex",
                                    color: thName[0] == 'count_dop' ? "#28BA3F" : "",
                                    fontWeight: thName[0] == 'count_dop' ? "700" : "",
                                    flexDirection: "column",
                                    whiteSpace: (thName[0] == 'demon' || thName[0] == 'mon') ? 'nowrap' : 'wrap'
                                }} className={'text text-s14'}
                                      dangerouslySetInnerHTML={{__html: string}}></span>
                            </div>
                        )
                    }

                    {
                        type == 'image' && (thSelect.length > indexTrBox ? edit != item['id'] : true) && (
                            <div style={{display: "flex", alignItems: "center"}}>
                                {
                                    !window.isRole("Order", 'edit') && string_photo != '' ? (
                                        <div>
                                            <div onClick={() => {
                                                setString(string);
                                                setModalShow(true);
                                            }}>
                                                <img width={50} height={50} style={{objectFit: "contain"}}
                                                     src={((string_photo.indexOf("storage/uploads") > 0 || string_photo.indexOf("storage/mini") > 0) ? REACT_APP_ASSETS_PATH : '') + string_photo}/>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    window.isRole("Order", 'edit') && (
                                        <>
                                            {
                                                string_photo != '' && (
                                                    <div onClick={() => {
                                                        setString(string);
                                                        setModalShow(true);
                                                    }}>
                                                        <img width={50} height={50} style={{objectFit: "contain"}}
                                                             src={((string_photo.indexOf("storage/uploads") > 0 || string_photo.indexOf("storage/mini") > 0) ? REACT_APP_ASSETS_PATH : '') + string_photo}/>
                                                    </div>
                                                )
                                            }

                                            {
                                                string_photo == '' && (
                                                    <div onClick={() => {
                                                        setString(string);
                                                        setModalShow(true);
                                                    }} style={{width: "50px", height: "50px"}}>


                                                    </div>
                                                )
                                            }


                                            {
                                                donwload && (
                                                    <CustomFile photoControl={inventory} onChange={(e) => {
                                                        updateDB({
                                                            'save': {
                                                                ...{[thName[0]]: e.target.value}
                                                            }, 'id': item['id'], 'model_name': modalName,
                                                        }, false);
                                                    }} value={''}
                                                                name={''}
                                                                labelName={'Загрузить файл'}/>
                                                )
                                            }
                                        </>

                                    )
                                }

                            </div>
                        )
                    }
                </td>
            )
        })
    }

    return (
        <>
            <Modal
                isOpen={modalShow}
                onRequestClose={showModal}
                contentLabel="Example Modal"
                className="model-add-box  model-add-box-center"
            >
                <div className="box_conteol box_conteol-img">
                    <img onClick={() => {
                        showModal();
                    }} className={'mole_img'}
                         src={(string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + string}/>
                    <br/>
                    <div className="box_conteol_nav">
                        <p onClick={() => {
                            showModal();
                        }} className={'text text-s20'} style={{color: "#fff", marginTop: "2rem"}}>
                            закрыть
                        </p>
                    </div>
                </div>
            </Modal>


            {
                (((modalName == 'Product' || modalName == 'ProductMenu' || modalName == 'Order') && winWidth < 1030) ? false : true) && (
                    <div className="ci-table_box">
                        <table className={'ci-table'}>
                            <thead>

                            <tr className={'no_fixed_tr'}>
                                {
                                    number && (<th>#</th>)
                                }
                                {
                                    Object.values(th).map((thName, index) => {
                                        return (
                                            <th key={index}
                                                title={thName?.name == 'Факт остаток' ? 'Фактический остаток с учетом мероприятий' : ''}
                                                className={" " + (thName?.className)}>
                                                <div>
                                                    <span style={{whiteSpace: 'nowrap'}}
                                                          className={'text text-s14 '}>{thName?.name}</span>
                                                </div>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                            <tr className={'fixed_tr'}>
                                {
                                    number && (<th>#</th>)
                                }
                                {
                                    Object.values(th).map((thName, index) => {
                                        return (
                                            <th key={index}
                                                title={thName?.name == 'Факт остаток' ? 'Фактический остаток с учетом мероприятий' : ''}
                                                className={" " + (thName?.className)}>
                                                <div>
                                                    <span style={{whiteSpace: 'nowrap'}}
                                                          className={'text text-s14 '}>{thName?.name}</span>
                                                </div>
                                            </th>
                                        )
                                    })
                                }
                            </tr>

                            </thead>
                            <tbody>

                            {
                                loading && (
                                    <>
                                        <tr>
                                            <td className={'row-col-l'} colSpan={Object.entries({...th}).length}>
                                                <div className="flex-center">
                                                    <div className="lds-dual-ring"></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            }
                            {
                                !loading && <>
                                    {
                                        tr.map((item, index) => {

                                            if (trRender) {
                                                return trRender(item, index, trBox(item, index));
                                            }

                                            // if(){
                                            //     openArenda
                                            // }
                                            // console.log(item['store_arenda']);

                                            let control_truen = false;
                                            if (caralog_name && catalog_name_global != item['category_name']) {
                                                catalog_name_global = item['category_name'];
                                                control_truen = true;
                                            } else {
                                                control_truen = false;
                                            }


                                            return (
                                                <>
                                                    {
                                                        control_truen && (
                                                            <tr>
                                                                <td colSpan={5} style={{textAlign: "center"}}>
                                                                    <b style={{color: "#6D6D6D"}} className={'text text-s22'}>
                                                                        {item['category_name']}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    <tr className={modalName == 'Product' ? (item['store'] < 0 ? 'error-class hover_controls ' : 'hover_controls') : '  '}
                                                        key={index}>
                                                        {
                                                            number && (<td>{index + 1}</td>)
                                                        }
                                                        {
                                                            trBox(item, index)
                                                        }
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }

                                    {
                                        tr.length == 0 && (
                                            <tr>
                                                <td className={'row-col-l'} colSpan={Object.entries({...th}).length}>
                                                    результатов нет...
                                                </td>
                                            </tr>
                                        )
                                    }
                                </>
                            }
                            </tbody>
                        </table>
                    </div>
                )
            }

            {
                modalName == 'Product' && winWidth < 1030 && (
                    <div className="ci-table_box ci-table_box-table">
                        {
                            tr.map((item, index) => {

                                if (trRender) {
                                    return trRender(item, index, trBox(item, index));
                                }


                                let string = item['id'] in loaderImage ? loaderImage[item['id']] : item['photo'];
                                if (!string) {
                                    string = "";
                                }


                                let imgPost = false;

                                if (item['pre'] != undefined) {
                                    if ('product_store_post_img' in item['pre']) {
                                        imgPost = item['pre']['product_store_post_img'];
                                    }
                                }


                                return (
                                    <>
                                        <div className={'product-sm' + (inventory ? ' product-sm-inv' : '')}>


                                            <div className={'img-box-control-fix'}>
                                                <CarouselProvider
                                                    naturalSlideWidth={200}
                                                    naturalSlideHeight={200}
                                                    totalSlides={imgPost ? 2 : 1}
                                                >
                                                    <Slider>
                                                        <Slide index={0}>
                                                            <div onClick={() => {
                                                                setString(string);
                                                                setModalShow(true);
                                                            }} className="product-sm-box">
                                                                <img
                                                                    src={(string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + (string ? string : 'https://rus-traktor.ru/upload/iblock/f74/f74f39dbc9b60954c926d72401adf1cc.jpg')}
                                                                    className="product-sm-img"/>
                                                            </div>
                                                        </Slide>

                                                        {
                                                            imgPost && (
                                                                <Slide index={1}>
                                                                    <div onClick={() => {
                                                                        setString(imgPost);
                                                                        setModalShow(true);
                                                                    }} className="product-sm-box">
                                                                        <img
                                                                            src={REACT_APP_ASSETS_PATH + imgPost}
                                                                            className="product-sm-img"/>
                                                                    </div>
                                                                </Slide>
                                                            )
                                                        }
                                                    </Slider>
                                                </CarouselProvider>


                                                {
                                                    window.isRole("Order", 'edit') && donwload && (
                                                        <CustomFile photoControl={inventory} noFixFox={false} onChange={async (e) => {

                                                            const returnControls = await updateDB({
                                                                'save': {
                                                                    ...{['photo']: e.target.value}
                                                                }, 'id': item['id'], 'model_name': modalName,
                                                            }, false);

                                                            setLoaderImage({...loaderImage, ...{[item['id']]: (returnControls['data'][0]['photo'])}});

                                                        }} value={''}
                                                                    name={''}
                                                                    labelName={''}/>
                                                    )
                                                }

                                            </div>

                                            <div className="product-sm-detalis">
                                                <div className="product-sm-name text text-s18">
                                                    {item['name']}
                                                    <br/>
                                                    <span style={{
                                                        color: "#C0C2D1",
                                                        fontWeight: "normal"
                                                    }}>{item['num']}</span>
                                                </div>
                                                <div className="product-sm-name text text-s18">
                                                    Упаковка: {item['package']}
                                                    <br/>
                                                    <span
                                                        style={{
                                                            color: "#C0C2D1",
                                                            fontWeight: "normal"
                                                        }}>Остаток: {item['store'] ? item['store'] : 0}</span>
                                                </div>

                                            </div>

                                            {
                                                !productOption && (
                                                    <div className="btn-box">
                                                        <div onClick={() => {
                                                            navigate('/' + modalName + '/edit/' + item['id']);
                                                        }} className="btn-info-open text text-s18">
                                                            Редактировать
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                productOption && (
                                                    option(item['id'], item, index)
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                )
            }

            {
                modalName == 'ProductMenu' && winWidth < 1030 && (
                    <div className="ci-table_box ci-table_box-table">
                        {
                            tr.map((item, index) => {

                                if (trRender) {
                                    return trRender(item, index, trBox(item, index));
                                }


                                let string = item['photo'];
                                if (!string) {
                                    string = "";
                                }

                                return (
                                    <>
                                        <div className={'product-sm'}>
                                            <div className="product-sm-box">
                                                <img
                                                    src={(string.indexOf("storage/uploads") > 0 ? REACT_APP_ASSETS_PATH : '') + (string ? string : 'https://rus-traktor.ru/upload/iblock/f74/f74f39dbc9b60954c926d72401adf1cc.jpg')}
                                                    className="product-sm-img"/>
                                            </div>
                                            <div className="product-sm-detalis">
                                                <div className="product-sm-name text text-s18">
                                                    {item['name']}

                                                </div>


                                            </div>

                                            {
                                                !productOption && (
                                                    <div className="btn-box">
                                                        <div onClick={() => {
                                                            navigate('/' + modalName + '/edit/' + item['id']);
                                                        }} className="btn-info-open text text-s18">
                                                            Редактировать
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                productOption && (
                                                    option(item['id'], item, index)
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                )
            }

            {
                modalName == 'Order' && winWidth < 1030 && (
                    <div className="ci-table_box ci-table_box-table-order">
                        {
                            tr.map((item, index) => {

                                if (trRender) {
                                    return trRender(item, index, trBox(item, index));
                                }

                                let string = item['photo'];
                                if (!string) {
                                    string = "";
                                }

                                return (
                                    <>
                                        <div className={'order-sm'}>
                                            <div className="product-sm-detalis">
                                                <div className="order-sm-head">
                                                    <div className="product-sm-name text text-bold text-s18">
                                                        {item['name'] ? item['name'] : "..."}
                                                        <br/>
                                                        {item['name_ords']}
                                                    </div>

                                                    <div
                                                        className="product-sm-name order-sm-head-right text text-bold text-s18">
                                                        Монтаж: &nbsp; <span
                                                        dangerouslySetInnerHTML={{__html: item['mon']}}></span>
                                                        <br/>
                                                        Демонтаж:&nbsp;  <span
                                                        dangerouslySetInnerHTML={{__html: item['demon']}}></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="order-sm-detalis">
                                                <div
                                                    className="product-sm-name text text-bold text-s18">
                                                    <p className={'order-adres-name'}>
                                                        Тип заказа: {item['status']}
                                                    </p>
                                                    <p className={'order-adres-name'}>
                                                        Заказчик: {item['name']}
                                                    </p>
                                                    <p className={'order-adres-name'}>
                                                        Адрес: {item['adress_full']}
                                                    </p>
                                                    <p className={'order-adres-name'}>
                                                        Телефон: {item['phone']}
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="btn-box btn-box-flex">
                                                <div onClick={() => {
                                                    navigate('/Order/edit/' + item['id']);
                                                }} className="btn-info-open text text-s18">

                                                    {
                                                        window.isRole("Order", 'edit') ? 'Редактировать' : 'Открыть'
                                                    }
                                                </div>

                                                <div onClick={() => {
                                                    location.href = REACT_APP_ASSETS_PATH + '/api/excel/order/' + item['id'];

                                                }} className="btn-info-open text text-s18">
                                                    Скачать чек-лист
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                )
            }
        </>
    )
});

export default TableScree;

