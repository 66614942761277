import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState, useRef} from "react";

import Table from "../../components/Table";
import {
    getModal,
    getModalRow,
    getModalRowEdit,
    getOrder,
    getSingle, sendFile,
    sendImportFile,
    getMessagePush,
    updateDB, getAllDistantionAdress
} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";
import CustomFile from "../../components/CustomFile";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import ruLocale from "@fullcalendar/core/locales/ru";

import Webcam from 'react-webcam';

import * as faceapi from 'face-api.js';
import CustomSelect from "../../components/CustomSelect";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";

const width = 500;
const height = 500;


const MainScreen = (() => {

    const state_in = useSelector(StateSlices);

    const [logs, setLogs] = useState([]);

    const [locationInfo, setLocationInfo] = useState([]);

    const [distantion, setDistantion] = useState(1000000);

    const [openList, setOpenList] = useState(false);
    const [look, setLook] = useState(false);

    const [openCamera, setOpenCamera] = useState(false);

    const [controlPost, setControlPost] = useState(false);

    const [userOpen, setUserOpen] = useState(false);

    const [isCameraReady, setCameraReady] = useState(false);

    const [reset, setReset] = useState(false);

    const [facesDetected, setFacesDetected] = useState(0);


    const [long, setLong] = useState('');
    const [lang, setLang] = useState('');
    const [openLocation, setOpenLocation] = useState(false);

    const webcamRef = useRef(null);


    const canvasRef = useRef(null);


    const handleLogEntry = (name, position, timeIn) => {
        const newLogEntry = {
            name,
            position,
            timeIn,
            timeOut: null,
        };
        setLogs([...logs, newLogEntry]);
    };

    const handleLogExit = (index, timeOut) => {
        const updatedLogs = [...logs];
        updatedLogs[index].timeOut = timeOut;
        setLogs(updatedLogs);
    };

    const calculateWorkingHours = (timeIn, timeOut) => {
        const millisecondsInHour = 3600000; // 1 hour = 3600000 milliseconds
        const workingHours = (timeOut - timeIn) / millisecondsInHour;
        return workingHours.toFixed(2);
    };
    const calculateInfo = (timeIn) => {
        const currentDate = new Date(timeIn);
        currentDate.setDate(currentDate.getDate() + 1);
        return currentDate;
    };

    const [loading, setLoading] = useState(false);

    const [calendarEvent, setCalendarEvent] = useState([]);
    const [allAdressLong, setAllAdressLong] = useState([]);

    const [modalShow, setModalShow] = useState(false);

    const [timer, setTimer] = useState(0);
    const [timerReset, setTimerReset] = useState(false);


    const today = new Date(); // Текущая дата и время

    const eventsToday = calendarEvent.filter(event => {
        const eventStart = new Date(event.start);
        const eventEnd = new Date(event.end);

        // Сравниваем дату события с текущей датой
        return (
            eventStart.getFullYear() === today.getFullYear() &&
            eventStart.getMonth() === today.getMonth() &&
            eventStart.getDate() === today.getDate()
        );
    });


    function formatDateToCustomString(dateString) {
        const months = [
            "января", "февраля", "марта", "апреля", "мая", "июня",
            "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ];

        const daysOfWeek = [
            "Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"
        ];

        const date = new Date(dateString);
        const dayOfWeek = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;
    }

    function formatTime(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes === 0 ? '00' : minutes;

        return `${formattedHours}.${formattedMinutes}`;
    }

    async function getCalendarInfo() {

        let calendar = (await getModal('AccountingUser', '')).data;

        calendar = calendar.map((eventPreInfo) => {
            return {
                ...eventPreInfo,
                ...{
                    title: eventPreInfo.user_name,
                    start: eventPreInfo.date_start,
                    end: eventPreInfo.date_start,
                }
            }
        })
        setCalendarEvent(calendar);


        let allLognLang = await getAllDistantionAdress();
        setLocationInfo(allLognLang);

    }


    function formatDateWithOffset(date) {
        const currentDate = date;

// Get the components of the date
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');

// Format the date components into the desired string format
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
        return formattedDate
    }

    async function analyzeFace(imageUrl) {
        const image = await faceapi.fetchImage(imageUrl,
            {
                referrerPolicy: "strict-origin-when-cross-origin"
            });
        const detections = await faceapi.detectAllFaces(image).withFaceLandmarks();

        // Вывести биометрические данные в консоль

        // console.log(detections);
    }


    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Радиус Земли в километрах
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((lat1 * Math.PI) / 180) *
            Math.cos((lat2 * Math.PI) / 180) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;

        return distance;
    }

    async function getLocationUser() {


        if ("geolocation" in navigator) {

            navigator.geolocation.getCurrentPosition(function (position) {


                let distantionList = [
                    [43.334483, 76.959223],
                    [43.220483, 76.950871],
                ];
                distantionList = [...distantionList, ...locationInfo];


                distantionList.forEach((corBoxList) => {


                    let long_save = '';
                    let lang_save = '';

                    if (long != '') {
                        let str = long;
                        let cleanStr = str.replace(/[()]/g, ""); // Убираем скобки
                        let parts = cleanStr.split(","); // Разделяем на части
                        let result = parts.map(part => parseFloat(part)); // Преобразуем строки в числа
                        lang_save = result[0];
                        long_save = result[1];
                    }


                    var latitude = openLocation ? parseFloat(lang_save) : position.coords.latitude;
                    var longitude = openLocation ? parseFloat(long_save) : position.coords.longitude;

                    const distance = calculateDistance(latitude, longitude, corBoxList[0], corBoxList[1]);
                    if ((distance * 1000) < 800) {
                        setDistantion(distance * 1000);
                    }
                });


            }, function (error) {
                NotificationManager.error('Нет доступа к вашим координатам', 'Не можем получить доступ к вашей геопозиции');
            });

            let user_push_message = await getMessagePush(state_in.authUser.id);
            if (user_push_message?.id) {


                let currentTime = new Date(user_push_message.date_to).getTime();
                let newTime = currentTime + 600000;
                let targetTime = new Date().getTime();
                let differenceInSeconds = Math.floor((newTime - targetTime) / 1000);
                setTimer(differenceInSeconds);
                setModalShow(true);
            }

        } else {
            NotificationManager.error('Нет доступа к вашим координатам', 'Не можем получить доступ к вашей геопозиции');
        }

    }


    useEffect(() => {


        getLocationUser();
        setTimeout(() => {
            if (location.pathname == '/Accounting/browse') {
                setReset(!reset);
            }
        }, 10000);


    }, [reset]);

    useEffect(() => {

        getCalendarInfo().then();


    }, []);


    async function sendEndTIme() {
        let user_push_message = await getMessagePush(state_in.authUser.id);
        if (user_push_message?.id && userOpenList.length > 0) {

            updateDB({
                'save': {
                    'status': 1,
                }, 'id': user_push_message.id, 'model_name': "Push",
            }, false);


            const updateDataOrder = await (updateDB({
                'save': {
                    "date_end": formatDateWithOffset(new Date()),
                },
                'id': userOpenList[0]['id'],
                'model_name': 'AccountingUser',
            }, false));

            showModal();

        }
    }

    useEffect(() => {
        if (timer && timer > 0) {
            setTimer(timer - 1)

            setTimeout(() => {
                setTimerReset(!timerReset)
            }, 1000);
        } else if (timer !== false && timer <= 0) {
            sendEndTIme();
        }
    }, [timerReset]);

    let userOpenList = calendarEvent.filter((userInfo) => {
        return userInfo.user_id == state_in.authUser.id && !(userInfo?.date_end);
    });


    const videoRef = useRef(null);
    const [isFaceDetected, setIsFaceDetected] = useState(false);

    function getRowControl() {

    }

    useEffect(() => {
        const loadFaceAPI = async () => {
            await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
            await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
            await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
            await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');

            analyzeFace('/_175412479.63411242.jpg');
        };
        loadFaceAPI().then();
    }, []);

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({video: true});
            videoRef.current.srcObject = stream;

            videoRef.current.addEventListener('play', () => {
                const canvas = faceapi.createCanvasFromMedia(videoRef.current);

                let box = document.getElementById('userCamera');
                box.append(canvas);

                const displaySize = {width: 400, height: 305};
                faceapi.matchDimensions(canvas, displaySize);

                setInterval(async () => {
                    const videoElement = videoRef.current;
                    const detections = await faceapi.detectAllFaces(videoElement,
                        new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptors();
                    const resizedDetections = faceapi.resizeResults(detections, displaySize);
                    canvas.getContext('2d').clearRect(0, 0, 400, 305);
                    faceapi.draw.drawDetections(canvas, resizedDetections);

                    setIsFaceDetected(detections.length > 0);
                }, 100);
            });
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };


    const handleFileUpload = async (event) => {


        var reader = new FileReader();
        reader.readAsBinaryString(event.target.files[0]);
        reader.onload = async () => {

            let file = (await sendFile({
                "files": btoa(reader.result),
            })).data


            const updateDataOrder = await (updateDB({
                'save': {
                    "photo_start": file,
                    "user_id": state_in.authUser.id,
                    "user_name": state_in.authUser.name,
                    "date_start": formatDateWithOffset(new Date()),
                },
                'id': 0,
                'model_name': 'AccountingUser',
            }, false));

            if ((updateDataOrder?.success)) {
                setLook(false);

                getCalendarInfo().then();
                setOpenList(false);

                alert('Вы открыли смену: ' + formatDateToCustomString(new Date()) + ' c ' + formatTime(new Date()));
            } else {
                NotificationManager.info('Сбой открытия смены, пытаюсь повторно отправить данные', ' ждите ');
                setLook(true);
                setTimeout(() => {
                    handleFileUpload(event);
                }, 5000);
            }
        };
        reader.onerror = function (error) {


        };
    };


    const handleFileUploadPush = async (event) => {


        var reader = new FileReader();
        reader.readAsBinaryString(event.target.files[0]);

        reader.onload = async () => {

            let file = (await sendFile({
                "files": btoa(reader.result),
            })).data


            if (distantion <= 300) {

                let user_push_message = await getMessagePush(state_in.authUser.id);
                if (user_push_message?.id && userOpenList.length > 0) {

                    updateDB({
                        'save': {
                            'status': 1,
                            'photo': file,
                            'accounting_id': userOpenList[0]['id'],
                        }, 'id': user_push_message.id, 'model_name': "Push",
                    }, false);

                    showModal();

                }

            } else {
                NotificationManager.error('До места вашей работы', 'Проверьте, пожалуйста, вашу геопозицию еще раз. ' + parseInt(distantion) + "");
            }

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };


    };

    const handleFileUploadClose = async (event) => {

        if (distantion <= 300) {
            var reader = new FileReader();
            reader.readAsBinaryString(event.target.files[0]);
            reader.onload = async () => {

                let file = (await sendFile({
                    "files": btoa(reader.result),
                })).data


                const updateDataOrder = await (updateDB({
                    'save': {
                        "photo_end": file,
                        "date_end": formatDateWithOffset(new Date()),
                    },
                    'id': userOpenList[0]['id'],
                    'model_name': 'AccountingUser',
                }, false));


                if ((updateDataOrder?.success)) {
                    setLook(false);
                    getCalendarInfo().then();
                    setOpenList(false);
                    alert('Вы закрыли смену: ' + formatDateToCustomString(new Date()) + ' c ' + formatTime(new Date()));

                } else {
                    NotificationManager.info('Сбой закрытия смены, пытаюсь повторно отправить данные', ' ждите ');
                    setLook(true);
                    setTimeout(() => {
                        handleFileUploadClose(event);
                    }, 5000);

                }

            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        } else {
            NotificationManager.error('Закрыть нельзя', 'Проверьте, пожалуйста, вашу геопозицию еще раз. \n');
        }

    };


    function showModal() {
        setModalShow(false);
    }

    return (<Layout>


        <Modal
            // isOpen={true}
            isOpen={look}
            onRequestClose={() => {

            }}
            contentLabel="Example Modal"
            className="model-add-box  model-add-box-center"
        >
            <div className="box_conteol box_conteol-img">

                <p className={'text text-s22'} style={{display: "flex", padding: "2rem", paddingTop: "2rem"}}>
                    Здравствуйте, у вас проблема с интернетом, подождите пока мы отправим ваши данные
                </p>
            </div>
        </Modal>

        <Modal
            // isOpen={true}
            isOpen={modalShow}
            onRequestClose={showModal}
            contentLabel="Example Modal"
            className="model-add-box  model-add-box-center"
        >
            <div className="box_conteol box_conteol-img">

                <p className={'text text-s22'} style={{display: "flex", padding: "2rem", paddingTop: "2rem"}}>
                    Здравствуйте, проводим проверку, что вы на работе
                </p>

                <div style={{display: "flex", justifyContent: "center", margin: "2rem 0"}}>
                    <p className={'text text-s18'}>
                        ({timer} секунд) до автоматической закрытие смены
                    </p>
                </div>

                <div className="box_conteol_nav" style={{width: "100%", display: "flex", marginTop: "0"}}>
                    <p onClick={() => {
                        showModal();
                    }} className={'text text-s20'} style={{color: "#fff", width: "50%"}}>
                        закрыть
                    </p>


                    <p className={'edit-box-control text text-s20'}
                       style={{color: "#fff", backgroundColor: "green", width: "50%"}}>
                        На работе

                        <input type="file" accept="image/*" capture="camera" onChange={handleFileUploadPush}/>

                    </p>
                </div>
            </div>
        </Modal>

        <canvas ref={canvasRef} style={{display: 'none'}}></canvas>


        {
            openCamera && (
                <div style={{
                    position: 'fixed',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    top: "50%",
                    border: "1px solid #ccc",
                    padding: "2rem 1rem"
                }} className={'position-user-pipe'}>


                    <div style={{marginTop: "1rem", display: "flex", flexDirection: "column", width: "420px"}}
                         className="info-adv-item-name">
                        <div id={'userCamera'}>
                            <video width={400} height={305} ref={videoRef}
                                   autoPlay
                                   playsInline
                                   webkit-playsinline="true"
                                   muted/>
                        </div>
                        <div onClick={async () => {

                            setOpenCamera(false);
                            setLoading(true);


                            function getRandomArbitrary(min, max) {
                                return Math.random() * (max - min) + min;
                            }


                            const video = videoRef.current;
                            const canvasSave = canvasRef.current;
                            canvasSave.width = video.videoWidth;
                            canvasSave.height = video.videoHeight;

                            const context = canvasSave.getContext('2d');
                            context.drawImage(video, 0, 0, canvasSave.width, canvasSave.height);

                            const photoDataUrl = canvasSave.toDataURL('image/png');


                            let infoDataSave = photoDataUrl.split(',');

                            let file = (await sendFile({
                                "files": infoDataSave[1],
                                "typeFile": 'jpg',
                                "saveName": '_' + getRandomArbitrary(0, 999999999) + '.jpg',
                            }));


                            if (userOpen?.date_start_current) {

                                const updateDataOrder = await (updateDB({
                                    'save': {
                                        "photo_end": file.data,
                                        "date_end_current": formatDateWithOffset(new Date()),
                                    },
                                    'id': userOpen.id,
                                    'model_name': 'AccountingUser',
                                }, false));
                                alert('Вы закрыли смену: ' + formatDateToCustomString(new Date()) + ' c ' + formatTime(new Date()));

                            } else {
                                const updateDataOrder = await (updateDB({
                                    'save': {
                                        "photo_start": file.data,
                                        "date_start_current": formatDateWithOffset(new Date()),
                                    },
                                    'id': userOpen.id,
                                    'model_name': 'AccountingUser',
                                }, false));
                                alert('Вы открыли смену: ' + formatDateToCustomString(new Date()) + ' c ' + formatTime(new Date()));
                            }

                            // date_start_current
                            //
                            getCalendarInfo().then();

                            setLoading(false);
                            setOpenList(false);

                        }} className="btn-bolsa text text-s14"
                             style={{backgroundColor: isFaceDetected ? "#F6C86E" : '#ccc'}}>
                            {' отправить на проверку'}
                        </div>
                    </div>
                </div>
            )
        }


        {
            window.isRole("Order", 'edit') && (
                <div className="controls" style={{display: "flex", alignItems: "center", marginTop: "4rem"}}>

                    <input type={'text'} value={long} style={{marginRight: "2rem"}} onChange={(e) => {
                        setLong(e.target.value);
                    }} placeholder={'long'}/>

                    <input type={'checkbox'} onChange={() => {
                        setOpenLocation(!openLocation);
                    }} checked={openLocation} placeholder={'lang'}/>

                </div>
            )
        }

        <div style={{marginTop: "4rem"}}>

            <h1 className={'text text-s26'}>Отчетность по посещаемости склада</h1>

            {
                !openList && true && (

                    userOpenList.length ? (
                        <div className="info-adv">

                            <div style={{color: "#fff", backgroundColor: "#28C76F"}}
                                 className="btn-bolsa btn-bolsa-file text text-bold text-s20">
                                Закрыть смену
                                <input type="file" accept="image/*" capture="camera" onChange={handleFileUploadClose}/>
                            </div>
                        </div>
                    ) : (
                        <div onClick={() => {
                            if (distantion <= 300) {
                                setUserOpen({start: (new Date())});
                                setOpenList(true);
                            } else {
                                NotificationManager.error('Вступить нельзя', 'Проверьте, пожалуйста, вашу геопозицию еще раз. ' + (parseInt(distantion)) + "");
                            }
                        }} className="info-adv">
                            <div style={{color: "#fff", backgroundColor: "#28C76F"}}
                                 className="btn-bolsa text text-bold text-s20">
                                Вступить в смену
                            </div>
                        </div>
                    )
                )
            }

            {
                openList && true && (
                    <div>
                        <div className="info-adv">
                            <div className="info-adv-item">
                                <div className="info-adv-item-icon">
                                    <img style={{width: "5rem"}} src={require('./components/img/info.png')}/>
                                </div>
                                <div className="info-adv-item-name">
                                    <span className={'text text-bold text-s20'}
                                          style={{
                                              color: "#A49999",
                                              fontWeight: "bold",
                                              display: "flex",
                                              alignItems: "center"
                                          }}>Тип смены </span>
                                    <span className={'text text-s20'}>
                                           <CustomSelect required={true} onChange={() => {

                                           }}
                                                         options={{
                                                             12: '12',
                                                             24: '24',
                                                         }} name={''} value={'24'}
                                                         label={''}/>
                                    </span>
                                </div>
                            </div>

                            <div className="info-adv-item">
                                <div className="info-adv-item-icon">
                                    <img style={{width: "5rem"}} src={require('./components/img/edit.png')}/>
                                </div>
                                <div className="info-adv-item-name">
                            <span className={'text text-s20 text-bold'}
                                  style={{color: "#A49999", fontWeight: "bold"}}>Дата <br/> Время</span>
                                    <span className={'text text-s20'}>
                            <span>{formatDateToCustomString(userOpen['start'])}</span> <br/>
                            <span>с {formatTime(today)} </span>
                            </span>
                                </div>
                            </div>

                            <div className="info-adv-item">
                                <div className="info-adv-item-icon">
                                    <img style={{width: "5rem"}} src={require('./components/img/next.png')}/>
                                </div>
                                <div className="info-adv-item-name">
                                    <div className="btn-bolsa btn-bolsa-file text text-s20"
                                         style={{backgroundColor: loading ? "#ccc" : ''}}>
                                        {loading ? 'проверка' : 'Пройти видеоидентификацию'}

                                        <input type="file" accept="image/*" capture="camera" onChange={handleFileUpload}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }


        </div>


    </Layout>);
});
export default MainScreen;
