import React, {useEffect, useState} from 'react';

import './style.scss';


const Article = (({
                      className,
                      img,
                      required = false,
                      name,
                      type = 'text',
                      disabled = false,
                      label,
                      value,
                      onChange
                  }) => {


    if (type == 'datetime-local') {
        type = 'Datetime-local';
    }


    return (
        <>
            <div
                className={`ci-input-custom-box ${className} ` + (type == 'Datetime-local' && 'ci-input-custom-box-date')}>

                <div
                    className={'text  text-s10  ci-placeholder-edit ' }>{label}</div>

                <label
                    className={`ci-input-custom ci-input-edit  ` + (img && 'ci-input-custom-img ')}>

                    {
                        value && value[0] == "#" && (
                            <p style={{width: 10, height: 10, marginTop: "3rem", backgroundColor: value}}>

                            </p>
                        )
                    }
                    {
                        img && (
                            <span className={'ci-input-img'} style={{backgroundImage: 'url("' + img + '")'}}></span>
                        )
                    }
                    <textarea required={required} value={value} disabled={disabled} onChange={(e) => onChange(e, name)}
                              name={name}
                              className="text text-s14  ci-input-edit_in"></textarea>


                </label>
            </div>
        </>
    )
});


export default Article;
