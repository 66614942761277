import React, {useEffect, useState} from 'react';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import StateSlices from "../../redux/slices/State";

import {NavLink} from 'react-router-dom'
import State from "../../redux/actions/StateActions";
import Btn from "../Btn";
import moment from "moment/moment";
import Modal from "react-modal";


const {REACT_APP_API_PATH} = process.env;

const Layout = (({logo, children, className}) => {

    const lang = useSelector(LangSlices);
    const state_in = useSelector(StateSlices);

    const [menuOpen, setMenuOpen] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);

    const [openBox, setOpenBox] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    useEffect(() => {


    }, []);


    let message_error = [...state_in.order_add_product].reverse();


    let urlLink = {
        'Order': 'Все заказы',
        'Tilda': 'Все заказы с сайта',
        'Accounting': 'Учет посещения',
        'AccountingAdmin': 'Всё посещение сотрудников',
        'Product': 'Все оборудование',
        'Sklad': 'Вся аналитика по складу',
        'ProductMenu': 'Все Меню',
        'Personal': 'Весь персонал',
        'Pack': 'Все готовые чек-листы',
        'ProductDown': 'Все списанные товаров групами',
        'Broken': 'Все утеря',
        'Inventory': 'Вся инвентаризация',
        'Role': 'Все роли',
        'Group': 'Все разделы',
        'Repair': 'Вся ремонт доска',
        'ProductTopDown': 'Вся аналитика списания',
        'SkladAn': 'Вся аналитика по складу',
        'ProductTop': 'Вся аналитика',
        'OrderDown': 'Весь просроченый демонтаж',
        'Prihod': 'Весь приход',
        'RepairBoard': 'Вся Ремонтная доска',
        'User': 'Все пользователи'
    };


    let urlPathSplit = location.pathname.split('/');
    let editBrowse = false;
    let modelNameSLink = '';
    if (urlPathSplit.length >= 2 && urlPathSplit[1] in urlLink) {
        modelNameSLink = urlPathSplit[1];
        editBrowse = urlPathSplit[2] == 'edit';
        urlPathSplit = urlLink[urlPathSplit[1]];
    } else {
        urlPathSplit = '';
    }


    return (
        <>
            <div>
                <div className={'main-box'}>

                    <div class="error-sender-list">
                        <div onClick={() => {
                            setOpenBox(!openBox);
                        }} class="error-sender-list_head">

                        </div>
                        {
                            openBox && (
                                <div className="error-sender-list_body">
                                    <div onClick={() => {
                                        // Преобразуем объект в JSON строку
                                        var jsonString = JSON.stringify(message_error);
                                        var blob = new Blob([jsonString], {type: "application/json"});
                                        var link = document.createElement("a");
                                        link.href = window.URL.createObjectURL(blob);
                                        link.download = "data.json"; // Имя файла для скачивания
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }} className="box_control-fixed text text-s20">
                                        скачать все
                                    </div>
                                    {
                                        (message_error).map((Item) => {
                                            let create_send = Item?.create_send;
                                            let message_send = Item?.message;
                                            let type_info = Item?.url;
                                            if (create_send) {
                                                create_send = moment(create_send).format("DD-MM-YYYY HH:mm:ss");
                                            }

                                            let product_list = false;

                                            if (type_info == REACT_APP_API_PATH + '/database/orderproduct?v=2') {
                                                type_info = 'Добавление товаров';
                                                product_list = true;
                                            }
                                            if (type_info == REACT_APP_API_PATH + '/database/update?v=2') {
                                                if (Item?.data.model_name == 'Order') {
                                                    type_info = 'Добавление или изменение заказа';
                                                }
                                                if (Item?.data.model_name == 'OrderProduct') {
                                                    type_info = 'Изменение товара: ' + Item?.data.save.count + " шт";
                                                }
                                            }

                                            return (
                                                <div className={'box_control-fixed text text-s22'}>
                                                    Время : <b>{create_send}</b> <br/>
                                                    Сообщение ошибки: <b>{message_send}</b> <br/>
                                                    Действие: <b>{type_info}</b>
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                            )
                        }
                    </div>

                    <NotificationContainer/>
                    <div className={'site-bar ' + (menuOpen && 'active')}>

                        <div className="site-bar__head">
                            {
                                REACT_APP_API_PATH == 'https://devapi.event-team.kz/api' && (<img
                                    src={'https://www.forumdaily.com/wp-content/uploads/2020/11/shutterstock_1293051877.jpg'}/>)
                            }
                            {
                                REACT_APP_API_PATH == 'https://api.event-team.kz/api' && (
                                    <img src={require('../../assets/images/logo.png')}/>)
                            }
                        </div>

                        <div className="site-bar__menu">
                            {/*['Списанные товары', '/ProductDown/browse', 'ProductDown'],*/}

                            {
                                [
                                    ['Календарь', '/', 'Order'],
                                    ['Заказы', '/Order/browse', 'Order'],
                                    ['Демонтаж просрочен', '/OrderDown/browse', 'Order'],
                                    ['Заказы с сайта', '/Tilda/browse', 'Tilda_order'],
                                    ['Учет посещения', '/Accounting/browse', 'AccountingUser'],
                                    ['Учет посещения Админ', '/AccountingAdmin/browse', 'User'],
                                    ['Оборудование', '/Product/browse', 'Product'],
                                    ['Аналитика', '/ProductTop/browse', 'User'],
                                    ['Аналитика списания', '/ProductTopDown/browse', 'User'],
                                    ['Аналитика по складу', '/SkladAn/browse', 'Sklad'],
                                    ['Меню', '/ProductMenu/browse', 'ProductMenu'],
                                    ['Персонал', '/Personal/browse', 'Personal'],
                                    ['Готовые чек-листы', '/Pack/browse', 'Pack'],
                                    ['Cклад', '/Sklad/browse', 'Sklad'],
                                    ['Списанные товары групами', '/ProductDownManage/browse', 'ProductDown'],
                                    // ['Утеря', '/Broken/browse', 'Broken'],
                                    ['Инвентаризация', '/Inventory/browse', 'Inventory'],
                                    ['Приход', '/Prihod/browse', 'Inventory'],
                                    ['Роли', '/Role/browse', 'Role'],
                                    ['Разделы', '/Group/browse', 'Group'],
                                    // ['Ремонт', '/Repair/browse', 'Repair'],
                                    // ['Ремонт Доска', '/RepairBoard/browse', 'Repair'],
                                    ['Пользователи', '/User/browse', 'User'],
                                ].map((text, index) => {

                                    if (text[2] == 'AccountingAdmin') {
                                        if (!window.isRole('Accounting', 'edit')) {
                                            return null;
                                        }
                                    } else {
                                        if (!window.isRole(text[2], 'browse')) {
                                            return null;
                                        }
                                    }
                                    return (
                                        <NavLink
                                            key={index} to={text[1]}
                                            className={'site-bat-link '}>
                                            <span className={'text text-s16'}>{text[0]}</span>
                                            <div className="site-bat-link__after"></div>
                                        </NavLink>
                                    )
                                })
                            }
                            <div
                                onClick={() => {
                                    setMenuOpen(false);
                                }}
                                className={'ci-btn active ci-btn-def btn-def-table text text-s14 site-bat-link-manu false '}>
                                <span className={'text text-s16'}>Закрыть</span>
                            </div>
                        </div>

                    </div>
                    <main style={{position: 'relative'}}>
                        <p onClick={() => {
                            setMenuOpen(!menuOpen);
                        }} className={'nav-container'}>
                            <div className="hamburger-lines">
                                <span className="line line1"></span>
                                <span className="line line2"></span>
                                <span className="line line3"></span>
                            </div>
                        </p>
                        <div className="nav-head-user">


                            <div onClick={() => {

                                setOpenSetting(!openSetting);
                            }} className="nav-head-user-src">
                                <img className={'nav-head-user__img'} src={require('../../assets/images/bottom.png')}/>
                                <p className={'text text-s16'}>
                                 ({state_in.authUser?.telegram_code})   {state_in.authUser.username} / {state_in.authUser.name}
                                </p>

                                {
                                    openSetting && (
                                        <>
                                            <div className="open-setting-user">
                                                <Btn type={'submit'} onClick={() => {
                                                    State({
                                                        token_: false,
                                                        auth: false,
                                                        authUser: false,
                                                    })
                                                }} className={'btn-def-table text text-s16'} label={'выйти'}/>

                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div className={'ci-while-bk'}>
                            <div className="heah-controls">
                                <a href={'/'} className={'text text-s18'}>Календарь </a>

                                <img style={{width: "2rem"}} src={('/right-arrow-svgrepo-com.svg')}/>
                                <a href={'/' + modelNameSLink + '/browse'}
                                   className={'text text-s18'}>{urlPathSplit} </a>

                                {
                                    editBrowse && window.isRole(modelNameSLink, 'edit') &&  (
                                        <>
                                            <img style={{width: "2rem"}} src={('/right-arrow-svgrepo-com.svg')}/>
                                            <a href={'/' + modelNameSLink + '/edit/0'} className={'text text-s18'}>Создать
                                                Новый</a>
                                        </>
                                    )
                                }


                            </div>
                            {children}
                        </div>
                    </main>

                </div>
            </div>
        </>
    )
});
export default Layout;
