import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {deleteDataBase, getModal, getModalRow, getOrder, getRowArenda} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";
import moment from 'moment';

import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import modalBrowse from "../OrderScreen/components/Model/browse.json";

const {REACT_APP_ASSETS_PATH, REACT_APP_API_PATH} = process.env;


import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import ruLocale from "@fullcalendar/core/locales/ru";
import DateNewRange from "../../components/DateNewRange";
import SelectSearch from "react-select-search"; // theme css file

const MainScreen = (() => {

    const navigate = useNavigate();

    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);

    const [calendarEvent, setCalendarEvent] = useState([]);
    const [users, setUsers] = useState([]);

    const [roleGroup, setRoleGroup] = useState([]);
    const [roleGroupSelect, setRoleGroupSelect] = useState(false);

    const [usersSelect, setUserSelect] = useState(false);


    const [calendar, setCalendar] = useState(true);

    const [roleSelect, setRoleSelect] = useState(false);
    const [page, setPage] = useState(0);


    const [roleId, setRoleId] = useState([]);

    const [modalName, setModalName] = useState('AccountingUser');


    const [timeStart, setTimeStart] = useState(false);
    const [timeEnd, setTimeEnd] = useState(false);

    async function getCalendarInfo() {

        let calendar = (await getModal('AccountingUser', "?browse=1"
            + (roleSelect ? '&role_id=' + roleSelect : '')
            + (timeStart ? '&date_start=' + moment(timeStart).format("YYYY-MM-DD HH:mm:ss") : '')
            + (usersSelect ? '&user_id=' + usersSelect : '')
            + (timeEnd ? '&date_end=' + moment(timeEnd).format("YYYY-MM-DD HH:mm:ss") : ''))).data;

        calendar = calendar.map((eventPreInfo) => {
            return {
                ...eventPreInfo,
                ...{
                    title: eventPreInfo.user_name,
                    start: eventPreInfo.date_start,
                    end: eventPreInfo?.date_end ? eventPreInfo.date_end : eventPreInfo.date_start,
                }
            }
        })
        setCalendarEvent(calendar);


    }

    async function getRoleRow() {

        let calendarRole = (await getModal('Role', "?browse=1")).data;
        setRoleId(calendarRole);

    }

    async function getOrderSend() {


        let row = (await getModalRow(modalName)).data;

        row['hours'] = 'Отработал в часах';
        row['option'] = 'Продление';


        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });


        setRowTable(row);

        const col = (await getModal(modalName, "?browse=1"
            + (roleSelect ? '&role_id=' + roleSelect : '')
            + (timeStart ? '&date_start=' + moment(timeStart).format("YYYY-MM-DD HH:mm:ss") : '')
            + (usersSelect ? '&user_id=' + usersSelect : '')
            + (timeEnd ? '&date_end=' + moment(timeEnd).format("YYYY-MM-DD HH:mm:ss") : '')
        )).data;


        setColTable(col);
    }

    async function getUsersList() {

        const roleGroup = (await getModal('RoleGroup', "")).data;
        setRoleGroup(roleGroup);

        const us = (await getModal('User', "")).data;
        setUsers(us);

    }

    useEffect(() => {

        getOrderSend().then();
        getRoleRow().then();
        getCalendarInfo().then();
        getUsersList().then();


    }, [roleSelect, timeEnd, timeStart, usersSelect]);


    function formatDateToCustomString(dateString) {
        const months = [
            "января", "февраля", "марта", "апреля", "мая", "июня",
            "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ];

        const daysOfWeek = [
            "Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"
        ];

        const date = new Date(dateString);
        const dayOfWeek = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;
    }

    function formatTime(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes === 0 ? '00' : minutes;

        return `${formattedHours}.${formattedMinutes}`;
    }


    let re_control = colTable.filter((userSmena) => {
        if (((userSmena?.date_start) || (userSmena?.date_end))) {
            return true;
        }
        return false;
    }).map((userControlsBox) => {


        let start_time = moment(userControlsBox.date_start).format("DD-MM-YYYY HH:mm");
        let end_time = moment(userControlsBox.date_end).format("DD-MM-YYYY HH:mm");
        let start = moment(userControlsBox.date_start).format("DD-MM-YYYY HH:mm");
        if (start_time == 'Invalid date') {
            start_time = '';
        }
        if (end_time == 'Invalid date') {
            end_time = '';
        }

        let start_time_pre = moment(userControlsBox.date_start, "YYYY-MM-DDTHH:mm");
        let end_time_pre = moment(userControlsBox.date_end, "YYYY-MM-DDTHH:mm");

        let hours = 'Не закрыл смену';
        let hours_prese = 'Не закрыл смену';


        if (start_time != 'Invalid date' && end_time != 'Invalid date') {

            let durationStart = start_time_pre.valueOf();
            let durationEnd = end_time_pre.valueOf();
            let restTime = durationEnd - durationStart;

            if (restTime >= 86400000) {
                restTime = 86400000;
            }

            if (restTime >= 0) {
                hours = (((restTime / 1000) / 60) / 60).toFixed(2);
                hours_prese = parseInt(((20000 / 24) * (hours + 1)).toFixed(2)) + ' тг';
                hours += ' часа';
            }

        }

        return {
            ...userControlsBox, ...{
                date_start: start_time,
                date_end: end_time,
                hours: hours,
                hours_priсe: hours_prese,
            }
        }
    });

    re_control.reverse();

    return (<Layout>

        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
                Учет посещения Админ
            </p>

            <a href={REACT_APP_API_PATH + '/user/donwload?' + (roleSelect ? '&role_id=' + roleSelect : '')
                + (timeStart ? '&date_start=' + moment(timeStart).format("YYYY-MM-DD HH:mm:ss") : '')
                + (usersSelect ? '&user_id=' + usersSelect : '')
                + (roleGroupSelect ? '&role_group=' + roleGroupSelect : '')
                + (timeEnd ? '&date_end=' + moment(timeEnd).format("YYYY-MM-DD HH:mm:ss") : '')} type={'submit'}
               className={'text text-s22'}
               style={{background: 'none', border: "none"}}>
                (скачать Excel)
            </a>

            <div className="table-btn">

                <Btn className={'btn-def-table text text-s14'} onClick={() => {
                    navigate('/AccountingAdmin/edit/0');
                }} label={'Добавить'}/>

            </div>
        </div>
        <div className="table-filter">
            <Btn showHolder={false} className={'btn-def-table text text-s14 ' + (calendar && 'active')} onClick={() => {
                setCalendar(true);
            }} label={'Перейти к календарю'}/>
            <Btn showHolder={false} className={'btn-def-table text text-s14 ' + (!calendar && 'active')}
                 onClick={() => {
                     setCalendar(false);
                 }} label={'Перейти к таблице'}/>

        </div>
        <div className="table-filter" style={{alignItems: "flex-start", marginTop: "4rem"}}>


            <CustomSelect required={true} showHolder={false} onChange={(e) => {
                setUserSelect(e.target.value);
            }}
                          options={users.filter((us_box) => {
                              return roleSelect ? us_box.role_id == roleSelect : true;
                          }).reduce((acc, item) => {
                              return {...acc, ...{[item['id']]: item["name"]}};
                          }, {})} name={''} value={usersSelect}
                          label={'Пользователи'}/>

            <CustomSelect required={true} showHolder={false} onChange={(e) => {
                setUserSelect(false);
                setRoleSelect(e.target.value);
            }}
                          options={roleId.filter((userMaps) => {
                              return parseInt(userMaps.price) > 0 && (roleGroupSelect ? roleGroupSelect == userMaps.parent_id : true);
                          }).reduce((ass, item) => {
                              return {...ass, ...{[item.id]: item.name}}
                          }, {})} name={''} value={roleSelect}
                          label={'Роль'}
            />

            <CustomSelect required={true} showHolder={false} onChange={(e) => {

                setUserSelect(false);
                setRoleGroupSelect(e.target.value);

            }}
                          options={roleGroup.reduce((ass, item) => {
                              return {...ass, ...{[item.id]: item.name}}
                          }, {})} name={''} value={roleGroupSelect}
                          label={'Отдел'}
            />


            <DateNewRange showHolder={false} finger={true}
                          onChangeVal1={(e) => {
                              setTimeStart(e);
                          }}
                          onChangeVal2={(e) => {
                              setTimeEnd(e);
                          }}
                          val1={timeStart}
                          val2={timeEnd}
            />
        </div>


        {
            calendar && (
                <div style={{fontSize: '1.8rem', marginTop: "4rem"}}>
                    <FullCalendar
                        eventTimeFormat={
                            {
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: false
                            }
                        }
                        dayMaxEvents={6}
                        dayMaxEventRows={1}
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        locale={'ru'}
                        locales={[ruLocale]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            right: 'dayGridMonth,timeGridWeek,timeGridDay',
                            left: 'prev,next',
                            center: 'title'
                        }}
                        eventClick={(e) => {

                        }}
                        events={calendarEvent}
                    />

                </div>
            )
        }


        <div className={'text text-s16'}>
            <ReactPaginate
                nextLabel=">"
                previousLabel="<"
                onPageChange={(e) => {
                    setPage(e.selected);
                }}
                forcePage={0}
                pageCount={re_control.length / 30}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </div>
        {
            !calendar && (
                <Table donwload={false} modalName={modalName} th={
                    rowTable
                } tr={
                    re_control.slice((page) * 30, ((page) * 30) + 30)
                }
                       option={(id) => {
                           return (
                               <div className={'table-options'}>

                                   <BtnIcon title={'edit'} onClick={() => {
                                       window.open('/AccountingAdmin/edit/' + id);
                                   }} img={require('../../assets/images/edit.png')}/>

                                   <BtnIcon title={'delete'} onClick={async () => {

                                       if (window.confirm("Точно удалить?")) {
                                           await deleteDataBase({
                                               id: id
                                           }, 'AccountingUser');

                                           getOrderSend().then();
                                           getCalendarInfo().then();
                                           getUsersList().then();
                                       }

                                   }} img={require('../../assets/images/trash.png')}/>
                               </div>
                           )
                       }}
                />
            )
        }
        <div className={'text text-s16'}>
            <ReactPaginate
                nextLabel=">"
                previousLabel="<"
                onPageChange={(e) => {
                    setPage(e.selected);
                }}
                forcePage={1}
                pageCount={re_control.length / 30}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </div>

    </Layout>);
});
export default MainScreen;
