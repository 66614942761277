import React, {useState} from 'react';
import './style.scss';
import Btn from "../Btn";
import Edit from "../Edit";


const More = (({label, items, onChange, checked}) => {

    const [show, setShow] = useState(false);

    return (
        <div className={'table_row text text-s16'} style={{width: "calc(33% - 4rem)"}}>
            <div className="input-controle">

                <div style={{cursor: "pointer", display: "flex", alignItems: "center"}}
                     className={'text btn-selec-style text-s16'}>
                    <div onClick={() => {
                        setShow(!show);
                    }} className={'btn-selec-style-style'}>
                        Раздел : {label}
                    </div>
                    <input
                        onChange={() => {

                            let filterCh = [...items].filter((name) => {
                                return !(name.id in checked);
                            });

                            onChange(filterCh.length == 0 ? items : filterCh);
                        }}
                        style={{marginLeft: "auto", marginRight: "0.5rem"}}
                        type={'checkbox'} checked={[...items].filter((name) => {
                        return !(name.id in checked);
                    }) == 0}/> все ({
                    [...items].filter((name) => {
                        return (name.id in checked);
                    }).length
                })
                </div>
                {
                    show && (
                        <div className={'drop_list_box'}>
                            {
                                items.map((name) => {
                                    return (
                                        <label>
                                            <input
                                                checked={name.id in checked}
                                                onChange={() => {
                                                    onChange([name]);
                                                }}
                                                type={'checkbox'}/> {name.name}
                                        </label>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
});


export default More;
