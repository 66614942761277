import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import ruLocale from "@fullcalendar/core/locales/ru";
import {getCalendar, getOrder, updateDB} from "../../services";
import Modal from "react-modal";
import {useNavigate} from "react-router-dom";
import listPlugin from "@fullcalendar/list";

const MainScreen = (() => {

    const lang = useSelector(LangSlices);

    const navigate = useNavigate();


    const [order, setOrder] = useState([])

    const [model, setModel] = useState(false);
    const [modelText, setModelText] = useState("");

    async function getOrderSend() {
        const orderReq = (await getCalendar(window.isRole("Pack", 'edit') ? true : false));
        // console.log(orderReq);
        setOrder(orderReq);
    }

    useEffect(() => {
        getOrderSend().then();
    }, []);

    return (<Layout>

        <p className={'text text-bold text-s26'}>
            Календарь
        </p>

        <div className="text text-s14">
            <FullCalendar
                eventTimeFormat={
                    {
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: false
                    }
                }
                dayMaxEvents={6}
                dayMaxEventRows={1}
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                locale={'ru'}
                locales={[ruLocale]}
                initialView={!window.isRole("Order", 'edit') ? 'listDay' : 'dayGridMonth'}
                headerToolbar={{
                    right: window.isRole("Order", 'edit') ? 'dayGridMonth,timeGridWeek,listDay' : 'listDay',
                    left: 'prev,next',
                    center: 'title'
                }}
                eventClick={(e) => {
                    setModelText(e.event._def.extendedProps.description);
                    setModel(true);
                    // if(){
                    //
                    // }
                    //
                    if (e.event.extendedProps.order_id) {
                        navigate('/Order/edit/' + e.event.extendedProps.order_id);
                    }
                }}
                events={[
                    ...order.map((ev) => {
                        return {
                            ...ev,
                            ...{
                                title: ev['name']
                            },
                        };
                    })
                ]}
            />
        </div>


        <Modal
            isOpen={model}
            onRequestClose={() => {
                setModel(false)
            }}
            contentLabel="Example Modal"
            className="model-add-box model-add-box-full fix-control model-add-box-full-flex"
        >
            <div className="edit-table edit-table-model edit-table-model-center">
                <div className="desc-calendar">
                    <p style={{whiteSpace: "break-spaces", lineHeight: "125%"}}
                       dangerouslySetInnerHTML={{__html: modelText}}
                       className={'text text-s14'}>
                    </p>
                </div>
            </div>
            <div className="center_box">

            </div>
        </Modal>


    </Layout>);
});
export default MainScreen;
