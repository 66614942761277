import React, {useState, useRef} from 'react';

import './style.scss';
import Loader from "react-js-loader";


const Article = (({
                      className,
                      onClick,
                      key,
                      type = 'button',
                      name = '',
                      disabled = false,
                      label = '',
                      value,
                      onChange,
                      load = false
                  }) => {


    const [valueUpdate, setValueUpdate] = useState(value);

    const timer = useRef(null);

    const handleInputChange = (e) => {

        setValueUpdate(parseInt(e.target.value));
        if (timer.current) clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            onChange(parseInt(e.target.value));
            timer.current = null;
        }, 500);
    }

    return (
        <div key={key} className={'ci-input-custom-box'}>
            <input  onChange={handleInputChange} type={'text'} name={name} value={valueUpdate}
                   className={`ci-input-custom ci-input-custom-price ci-input-edit text text-s18 ${className}`}/>
        </div>
    )
});


export default Article;
