import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {deleteDataBase, getModal, getModalRow, getOrder, updateDB} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import {useNavigate, useParams} from "react-router-dom";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import modalBrowse from "../ProductScreen/components/Model/browse.json";
import {NotificationManager} from "react-notifications";


const MainScreen = (() => {

    const navigate = useNavigate();
    const lang = useSelector(LangSlices);

    const [order, setOrder] = useState([]);
    const [page, setPage] = useState(0);
    const [reset, setReset] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const [modalName, setModalName] = useState('Repair');

    const [searchOb, setSearchOb] = useState({});


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);


    async function tableRow() {


        let row = (await getModalRow('Product')).data;
        row['count'] = "Кол-во";
        row['comment'] = "Примечание";
        row['status'] = "Статус";

        delete row['store'];
        delete row['store_sklad'];
        delete row['price'];
        delete row['price_arenda'];




        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key], className: "ci-table-th-88", value: "",
            }
        });
        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }
        setRowTable(row);

    }


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setSearchOb(searchObS);
        setReset(!reset);
    }

    function addFilter(e) {
        setSearchOb({...searchOb, ...{search: e.target.value}});
        setReset(!reset);
    }


    async function getOrderSend() {

        let row = (await getModalRow(modalName)).data;
        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });


        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }

        const col = (await getModal(modalName, '?' + serialize(searchOb) + "&browse=1&page=" + (page + 1) + "&catalog_id=")).data;
        setColTable(col);

        // setOrder(orderReq);
    }

    useEffect(() => {
        getOrderSend().then();
        tableRow().then();
    }, [reset]);

    return (<Layout>

        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
                Ремонт
            </p>

            <div className="table-btn">


                <Btn className={'btn-def-table text text-s14'} onClick={() => {
                    navigate('/Repair/edit/0');
                }} label={'Добавить'}/>
            </div>
        </div>
        <div className="table-filter">
            <CustomInput onChange={addFilter} name={'search'} value={''}
                         img={require('../../assets/images/search-normal.png')} label={'Поиск'}/>
            {/*<CustomSelect onChange={(e) => {*/}
            {/*    setStatus(e.target.value);*/}
            {/*    setReset(!reset);*/}
            {/*}} options={{*/}
            {/*    '1': 'В ремонте',*/}
            {/*    '2': 'Списано',*/}
            {/*    '3': 'Сделано',*/}
            {/*}} value={status} label={'статус'}/>*/}

            <BtnCustom onChange={resetFilter} img={require('../../assets/images/refresh.png')}
                       label={'Сбросить фильтры'}/>
        </div>

        <div className="edit-table__body">
            <Table th={{...rowTable}} tr={colTable ? (colTable.reduce((ass, items) => {

                let product_ch = {};
                if (items?.product_ch && items?.product_ch.length > 0) {
                    product_ch = (JSON.parse(items.product_ch));
                }

                return [...ass, ...items['product_list_product'].map((pro) => {

                    let product_ch_one = {};
                    if (pro['id'] in product_ch) {
                        product_ch_one = product_ch[pro['id']];
                    }

                    return {...pro, ...{'pre_id': items}, ...product_ch_one}
                })];

            }, [])) : []}
                   option={(id, row) => {
                       return (
                           <div className={'table-options'}>


                               <Btn className={'btn-def-table btn-def-table-mini text text-s12 '} onClick={async () => {

                                   if (window.confirm("Вы точно хотите вернуть " + row['name'] + " " + row['count'] + "шт?")) {
                                       let product_control = JSON.parse(row['pre_id']['product_list']);
                                       delete product_control[id];
                                       const updateDataOrder = (await updateDB({
                                           'save': {
                                               product_list: JSON.stringify(product_control)
                                           }, 'id': row['pre_id']['id'], 'model_name': 'Repair',
                                       })).data[0];

                                       setReset(!reset);
                                   }

                               }
                               }   label={<span style={{ fontSize: '13px' }}>Подтвердить</span>}
                               />


                               <Btn className={'btn-def-table btn-def-table-mini text text-s12'} onClick={async () => {

                                   if (window.confirm("Вы точно хотите вернуть " + row['name'] + " " + row['count'] + "шт?")) {
                                       let product_control = JSON.parse(row['pre_id']['product_list']);
                                       delete product_control[id];
                                       const updateDataOrder = (await updateDB({
                                           'save': {
                                               product_list: JSON.stringify(product_control)
                                           }, 'id': row['pre_id']['id'], 'model_name': 'Repair',
                                       })).data[0];

                                       setReset(!reset);
                                   }

                               }
                               } label={<span style={{ fontSize: '13px' }}>Доработка</span>}
                               />

                               {/*<CustomSelect onChange={(e) => {*/}
                               {/*    setStatus(e.target.value);*/}
                               {/*    setReset(!reset);*/}
                               {/*}} options={{*/}
                               {/*    '1': 'В ремонте',*/}
                               {/*    '2': 'Списано',*/}
                               {/*    '3': 'Сделано',*/}
                               {/*}} value={status} label={<span style={{ fontSize: '11px',width:'15rem',display:'flex' }}>Статус</span>}*/}
                               {/*/>*/}


                               <BtnIcon onClick={() => {
                                   navigate('/Repair/edit/' + row['pre_id']['id']);
                               }} img={require('../../assets/images/edit.png')}/>

                           </div>
                       )
                   }}
            />
        </div>


        {/*{*/
        }
        {/*    colTable.length > 0 && (*/
        }
        {/*        <Modal*/
        }
        {/*            isOpen={true}*/
        }
        {/*            contentLabel="Example Modal"*/
        }
        {/*            className="model-add-box-comp-list"*/
        }
        {/*        >*/
        }
        {/*            <div className={'complect-list'}>*/
        }
        {/*                <div className="complect-list-head">*/
        }
        {/*                    <span className={'text text-bold text-s18'}>комплект: {colTable[3]['name']}</span>*/
        }
        {/*                </div>*/
        }
        {/*                <div className="complect-list-body">*/
        }


        {/*                    {*/
        }
        {/*                        colTable[3].product_list_product.map((li) => {*/
        }
        {/*                            return (*/
        }
        {/*                                <div className="item-complit">*/
        }
        {/*                                    <span className={'text text-s16'}>{li['name']} x{li['count']}</span>*/
        }
        {/*                                </div>*/
        }
        {/*                            )*/
        }
        {/*                        })*/
        }
        {/*                    }*/
        }

        {/*                </div>*/
        }
        {/*            </div>*/
        }
        {/*        </Modal>*/
        }
        {/*    )*/
        }
        {/*}*/
        }


    </Layout>)
        ;
});
export default MainScreen;
