import React, {useEffect, useState, useRef} from 'react';

import './style.scss';

import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import {getModal, getModalRow, getText, setText, updateDB} from "../../services";
import LangActions from "../../redux/actions/LangActions";
import Btn from "../Btn";
import {NotificationManager} from "react-notifications";
import CustomInput from "../CustomInput";
import ReactPaginate from "react-paginate";
import Table from "../Table";
import Modal from "react-modal";
import DateNewRange from "../DateNewRange";

import CustomSelect from "../../components/CustomSelect";
import moment from "moment/moment";

const Article = (({
                      className,
                      name,
                      showHolder = true,
                      options = {},
                      label,
                      value,
                      onChange,
                      required = false,
                      openSelectCity = false,
                      sort = true
                  }) => {

    const [colTableProduct, setColTableProduct] = useState([]);

    const [rowTable, setRowTable] = useState([]);
    const [colTable, setColTable] = useState([]);


    const [timeStart, setTimeStart] = useState(false);
    const [timeEnd, setTimeEnd] = useState(false);

    const [modalProduct, setModalProduct] = useState(false);

    const timerIdRef = useRef(null);

    const [reset, setReset] = useState(false);


    const [user, setUser] = useState([]);
    const [userSelect, setUserSelect] = useState('');


    const [orderType, setOrderType] = useState([]);
    const [orderTypeSelect, setOrderTypeSelect] = useState('');


    const [search, setSearch] = useState('');

    let sortedObj = Object.entries(options);

    if (typeof options == "object" && options != null && sort) {
        try {
            sortedObj = sortedObj.sort(([, a], [, b]) => a.localeCompare(b));
        } catch (err) {

        }
    }

    async function getOrderList() {

        if (openSelectCity) {

            let searchGet = '';

            if (search != '') {
                searchGet += ((searchGet == '' ? '?' : '&') + 'search=' + search);
            }

            if (timeStart) {
                searchGet += ((searchGet == '' ? '?' : '&') + 'date_from=' + moment(timeStart).format("YYYY-MM-DD HH:mm:ss"));
            }
            if (timeEnd) {
                searchGet += ((searchGet == '' ? '?' : '&') + 'date_to_mon=' + moment(timeEnd).format("YYYY-MM-DD HH:mm:ss"));
            }
            if (userSelect) {
                searchGet += ((searchGet == '' ? '?' : '&') + 'menager_id_user=' + userSelect);
            }
            if (orderTypeSelect) {
                searchGet += ((searchGet == '' ? '?' : '&') + 'order_type_id=' + orderTypeSelect);
            }

            searchGet += ((searchGet == '' ? '?' : '&') + 'status_all=1');

            let row = (await getModalRow('Order',)).data;
            let col = ((await getModal('Order', searchGet)).data);
            row['option'] = '';
            Object.keys(row).forEach((key) => {
                row[key] = {
                    name: row[key],
                    className: "ci-table-th-88",
                    value: "",
                }
            });
            setRowTable(row);
            setColTable(col);
        }

    }

    async function getFirstBox() {
        if (openSelectCity) {
            setUser(((await getModal('User', '')).data).filter((box) => {
                return box.role_id == 3;
            }));
            setOrderType((await getModal('OrderType', '')).data);
        }
    }

    useEffect(() => {
        if (name == 'order_id') {
            getOrderList();
            getFirstBox();
        }
    }, []);


    useEffect(() => {


        clearTimeout(timerIdRef?.current);
        timerIdRef.current = setTimeout(() => {
            getOrderList();
        }, 500);


    }, [reset]);


    return (
        <>

            <Modal
                isOpen={modalProduct}
                onRequestClose={() => {
                    setModalProduct(false);
                }}
                contentLabel="Example Modal"
                className="model-add-box"
            >
                <div className="edit-table edit-table-model">
                    <div className="edit-table__head">
                        <span className="text text-s22">Выбрать Заказ</span>
                        <div className="modal-add-btns">
                            <Btn className={'btn-def-table btn-def-table-clear text text-s14'} onClick={() => {
                                setModalProduct(false);
                            }} label={'закрыть'}/>
                        </div>
                    </div>
                    <div className={'pagination-box text text-s18'}>
                        <div style={{marginRight: "auto", display: "flex"}}>
                            <CustomInput value={search} onChange={(e) => {
                                setSearch(e.target.value);
                                setReset(!reset);
                            }} name={'search'} img={require('../../assets/images/search-normal.png')} label={'Поиск'}/>

                            <div className="ci-input-custom-box bonx_control_linger" style={{marginLeft: "2rem"}}>
                                <DateNewRange finger={true}
                                              onChangeVal1={(e) => {
                                                  setTimeStart(e);
                                                  setReset(!reset);

                                              }}
                                              onChangeVal2={(e) => {
                                                  setTimeEnd(e);
                                                  setReset(!reset);
                                              }}
                                              val1={timeStart}
                                              val2={timeEnd}
                                />
                            </div>

                            <div className="ci-input-custom-box bonx_control_linger">
                                <CustomSelect onChange={(e) => {
                                    setUserSelect(e.target.value);
                                    setReset(!reset);
                                }} options={user.reduce((acc, item) => {
                                    return {...acc, ...{[item['id']]: item["name"]}};
                                }, {})} value={userSelect} label={'Ответсвенный менеджер'}/>
                            </div>
                            <div className="ci-input-custom-box bonx_control_linger" style={{marginLeft: "2rem"}}>
                                <CustomSelect onChange={(e) => {
                                    setOrderTypeSelect(e.target.value);
                                    setReset(!reset);
                                }} options={orderType.reduce((acc, item) => {
                                    return {...acc, ...{[item['id']]: item["name"]}};
                                }, {})} value={orderTypeSelect} label={'Тип мероприятия'}/>
                            </div>
                        </div>


                    </div>
                    <div className="edit-table__body">

                        <Table th={
                            rowTable
                        } tr={
                            colTable
                        }
                               productOption={true}
                               option={(id, row) => {
                                   return (
                                       <div className={'table-options'}>
                                           <Btn className={'btn-def-table text text-s14 ' + (value == id && 'active')}
                                                onClick={async () => {
                                                    setModalProduct(false);
                                                    onChange({
                                                        target: {
                                                            value: id,
                                                            name: name
                                                        }
                                                    }, name);

                                                }} label={'Выбрать'}/>
                                       </div>
                                   )
                               }}
                        />


                    </div>
                </div>
            </Modal>

            <div className="ci-input-custom-box">

                {
                    label && showHolder && (
                        <div
                            className={'text  text-s10  ci-placeholder-edit '}>{label}</div>
                    )
                }
                <label style={{position: "relative"}} className={`ci-input-custom ci-input-edit ${className}`}>

                    {
                        name == 'order_id' && (
                            <span onClick={() => {
                                if (name == 'order_id') {
                                    getOrderList();
                                    setModalProduct(true);
                                }
                            }} style={{position: 'absolute', left: 0, top: 0, width: "100%", height: "100%", zIndex: 1000}}
                                  className="controls">

</span>
                        )
                    }
                    <select required={required} value={value}
                            onChange={(e) => onChange(e, name)} name={name}
                            className="text text-s14 ci-input-edit_in">

                        <option value={''}>{label}</option>

                        {
                            sortedObj.map((key) => {
                                return (
                                    <option value={key[0]}>{key[1]}</option>
                                )
                            })
                        }
                    </select>
                </label>
            </div>
        </>
    )
});


export default Article;
