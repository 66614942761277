import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";

import Table from "../../components/Table";
import {getModal, getModalRow, getModalRowEdit, getOrder, getSingle, updateDB} from "../../services/index";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins

import {useNavigate, useParams} from "react-router-dom";
import InputsEdit from "../../components/InputsEdit";
import Btn from "../../components/Btn";
import Modal from "react-modal";
import CustomInput from "../../components/CustomInput";

import ReactPaginate from 'react-paginate';
import {NotificationManager} from "react-notifications";


import Loader from "react-js-loader";
import CustomSelect from "../../components/CustomSelect";

const MainScreen = (() => {


    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);


    const [openControl, setOpenControl] = useState([]);

    const [search, setSearch] = useState('');

    const [selectRazdel, setSelectRazdel] = useState('');

    const [modalName, setModalName] = useState('Inventory');
    const params = useParams();


    const [rowTable, setRowTable] = useState({});


    const [plusProduct, setPlusProduct] = useState(0);
    const [minusProduct, setMinusProduct] = useState(0);

    const [loadingSave, setLoadingSave] = useState(false);


    const [colTableProduct, setColTableProduct] = useState([]);

    const [orderSingle, setOrderSingle] = useState({});

    const [productList, setProductList] = useState({});
    const [productListPre, setProductListPre] = useState({});

    async function getProductModal(page) {

        setColTableProduct((await getModal('Product', '?page=' + (page + 1) + '&browse=1&catalog_id=')).data);
    }

    async function getOrderSend() {

        let row = (await getModalRowEdit(modalName, params.id + '?hidden=true')).data;
        let orderSingle = (await getSingle(modalName, params.id)).data;

        if (orderSingle?.product_list) {
            let productListSave = JSON.parse(orderSingle.product_list);
            setProductList(productListSave);

            if (orderSingle["pre"] != null) {
                if (orderSingle["pre"]) {

                    let productListPreSave = (orderSingle.pre.product_list);
                    setProductListPre(productListPreSave);

                    if (orderSingle && orderSingle?.product_list_array != null) {
                        let plust_product = 0;
                        let minus_product = 0;
                        orderSingle?.product_list_array.forEach((item) => {
                            if (productListSave && productListPreSave) {
                                if (typeof (productListSave[item['id']]) != "undefined" && typeof (productListPreSave[item['id']]) != "undefined") {
                                    const count_control = parseInt(productListSave[item['id']]) - parseInt(productListPreSave[item['id']]);

                                    if (count_control > 0) {
                                        plust_product = (plust_product + count_control);
                                    } else if (count_control < 0) {
                                        minus_product = (minus_product + count_control);
                                    }
                                }
                            }
                        });

                        setPlusProduct(plust_product);
                        setMinusProduct(minus_product);
                    }


                }
            }
        }


        setOrderSingle(orderSingle);


        try {
            let modalBrowse = require('./components/Model/edit.json');
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        row[index] = {...row[index], ...modalBrowse[key]}
                    }
                });
            });

            let sortArray = [];
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        sortArray.push(row[index]);
                    }
                });
            });

            row.forEach((val, index) => {
                if (sortArray.filter((filItem) => {
                    return filItem['name'] == val['name']
                }).length == 0) {
                    sortArray.push(row[index]);
                }
            });


            row = sortArray;

        } catch (err) {

        }

        if (orderSingle) {
            row = row.map((pr) => {
                return {...pr, ...{value: orderSingle[pr.name]}}
            })
        }
        setInputs(row);


    }

    async function tableRow() {


        let row = (await getModalRow('Product')).data;


        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88",
                value: "",
            }
        });
        try {
            let modalBrowse = require('../ProductScreen/components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }
        setRowTable(row);

    }

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    useEffect(() => {
        getOrderSend().then();
        tableRow().then();
    }, [params.id]);

    return (<Layout>

        <div className="edit-model-head">
            <p className={'text text-bold text-s26'}>
                Инвентаризация
            </p>


            <div style={{}}>
                <Btn className={'btn-def-table loading-mini text text-s14'} onClick={async () => {

                    if (!loadingSave) {
                        setLoadingSave(true);

                        const updateDataOrder = (await updateDB({
                            'save': {
                                ...inputs.reduce((ass, val) => {
                                    return {...ass, ...{[val.name]: val.value}};
                                }, {}),
                                ...{
                                    product_list: JSON.stringify(productList),
                                    'status': '1'
                                }
                            }, 'id': params.id, 'model_name': 'Inventory',
                        })).data[0];

                        if (params.id == 0) {
                            navigate('/Inventory/edit/' + updateDataOrder.id);
                        }

                        // setProductList(JSON.parse(updateDataOrder.product_list));
                        // setOrderSingle(updateDataOrder);
                        NotificationManager.success('все хорошо, данные получили', 'Инвентаризация обновлена');
                        setLoadingSave(false);
                    }
                }} label={loadingSave ? ('Ожидайте') : 'Завершить'}/>

                <Btn className={'btn-def-table loading-mini text text-s14'} onClick={async () => {

                    if (!loadingSave) {
                        setLoadingSave(true);

                        const updateDataOrder = (await updateDB({
                            'save': {
                                ...inputs.reduce((ass, val) => {
                                    return {...ass, ...{[val.name]: val.value}};
                                }, {}),
                                ...{product_list: JSON.stringify(productList)}
                            }, 'id': params.id, 'model_name': 'Inventory',
                        })).data[0];


                        if (params.id == 0) {
                            navigate('/Inventory/edit/' + updateDataOrder.id);
                        }

                        getOrderSend().then();

                        NotificationManager.success('все хорошо, данные получили', 'Инвентаризация обновлена');

                        setLoadingSave(false);
                    }
                }} label={loadingSave ? ('Ожидайте') : 'Сохранить'}/>


            </div>
        </div>

        <InputsEdit timeShow={false} inputs={inputs} onChange={(e, name) => {
            setInputs(inputs.map((inp) => {
                if (inp["name"] == name) {
                    return {...inp, ...{value: e.target.value}};
                }
                return inp;
            }));

        }}/>

        {
            params.id == 0 ? (
                <div className={'info_table text text-s18'}>
                    Чтобы добавить товары, заполните информацию о заказе выше и нажмине кнопку сохранить
                </div>
            ) : <div className="edit-table">
                <div className="info_box">
                    <p className={'text text-s18'}>
                        Итоги инвентаризации
                    </p>
                    <div className="info_box_plus text text-s18">
                        +{plusProduct} позиций
                    </div>
                    <div className="info_box_minus text text-s18">
                        {minusProduct} позиций
                    </div>
                </div>
                <div className="edit-table__head">
                    <span className="text text-s22">Заполните товары из каталога</span>
                </div>

                <div style={{marginRight: "auto"}}>
                    <CustomInput value={search} onChange={(e) => {
                        setSearch(e.target.value);
                    }} name={'search'}
                                 img={require('../../assets/images/search-normal.png')}
                                 label={'Поиск'}/>

                    <div className="table-filter" style={{marginTop: "1rem"}}>
                        <CustomSelect value={selectRazdel} onChange={(item) => {
                            setSelectRazdel(item.target.value);
                        }} options={
                            orderSingle?.product_list_array ? [...orderSingle?.product_list_array].reduce((ass, pro) => {
                                return {...ass, ...{[pro['catalog_id']]: pro['catalog_id']}}
                            }, {}) : {}
                        } label={'Все разделы'}/>
                    </div>

                </div>

                <div className="edit-table__body">
                    <Table modalName={'Product'} productOption={true} inventory={true} th={
                        {...rowTable}
                    } donwload={true} tr={
                        orderSingle?.product_list_array ? orderSingle?.product_list_array.filter((pro) => {
                            return ((pro['name'] ? pro['name'].toLowerCase().includes(search.toLowerCase()) : false) || (pro['catalog_id'] ? pro['catalog_id'].toLowerCase().includes(search.toLowerCase()) : false)) && (selectRazdel ? pro['catalog_id'].includes(selectRazdel) : true)
                        }) : []
                    }
                           trRender={getWidth() > 1030 ? ((item, index, html) => {


                               return (
                                   <tr className={parseInt(productList[item['id']]) > 0 ? (productList[item['id']] == item['store_sklad'] ? 'el-green' : 'el-error') : "el-error"}
                                       key={index}>
                                       {html}
                                   </tr>
                               );
                           }) : false}
                           option={(id, row) => {
                               return (
                                   <div className={'table-options'}>
                                       <CustomInput onBlur={() => {
                                           let consaSave = {...openControl};
                                           delete consaSave[id];
                                           setOpenControl(consaSave);
                                       }} className={'count-input'} onChange={(e) => {
                                           setOpenControl({...openControl, ...{[id]: 1}});
                                           let productListOld = {...productList};
                                           productListOld[id] = e.target.value;
                                           setProductList({...productListOld});
                                       }}
                                                    disabled={typeof productList[id] != "undefined" ? (id in openControl ? false : true) : false}
                                                    value={typeof productList[id] != "undefined" ? '' + productList[id] : ""}
                                                    type={'text'}
                                                    label={'количество'}/>

                                       <p onClick={() => {
                                           setOpenControl({...openControl, ...{[id]: 1}});
                                       }} style={{
                                           backgroundColor: typeof productList[id] != "undefined" ? (id in openControl ? "green" : "red") : "green",
                                           borderRadius: "100%",
                                           overflow: "hidden",
                                           cursor: 'pointer',
                                           marginLeft: "1rem"
                                       }} className={'top-margin2'}>
                                           <img style={{filter: 'invert(1)'}} width={25}
                                                src={'https://cdn.onlinewebfonts.com/svg/download_520019.png'}/>
                                       </p>
                                   </div>
                               )
                           }}
                    />
                </div>
            </div>
        }


    </Layout>);
});
export default MainScreen;
