import React, {useState} from 'react';

import './style.scss';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";

import ru from 'date-fns/locale/ru';
import moment from "moment/moment";

registerLocale('ru', ru);

const Article = (({
                      className,
                      val1 = false,
                      val2 = false,
                      onClick,
                      type = 'button',
                      name = '',
                      disabled = false,
                      finger = false,
                      label = '',
                      value,
                      onChange,
                      load = false,
                  }) => {



    const [stateStart, setStateStart] = useState(val1 == 'Invalid Date' ? false : val1);

    const [stateEnd, setStateEnd] = useState(val2 == 'Invalid Date' ? false : val2);

    const handleDateChangeStart = (dates) => {
        const dateSa = new Date(dates);
        const hours = dateSa.getHours();
        const minutes = dateSa.getMinutes();
        dateSa.setHours(hours);
        dateSa.setMinutes(minutes);
        setStateStart(dateSa);

        let saveDateBox = moment(dates).format("YYYY-MM-DD HH:mm:ss");
        onChange({
            target: {
                name: 'mon',
                value: saveDateBox,
            }
        }, 'mon');

    };


    const handleDateChangeEnd = (dates) => {
        const dateSa = new Date(dates);
        const hours = dateSa.getHours();
        const minutes = dateSa.getMinutes();
        dateSa.setHours(hours);
        dateSa.setMinutes(minutes);
        setStateEnd(dateSa);

        let saveDateBox = moment(dates).format("YYYY-MM-DD HH:mm:ss");

        onChange({
            target: {
                name: 'demon',
                value: saveDateBox,
            }
        }, 'demon');
    };


    let currentTime = false;
    let currentTimeEnd = false;


    let maxTimeStartDate = false;

    let minTimeEndDate = false;


    let isSameDay = false;

    if (stateStart != false && stateEnd != false) {
        isSameDay = (
            stateStart.getFullYear() === stateEnd.getFullYear() &&
            stateStart.getMonth() === stateEnd.getMonth() &&
            stateStart.getDate() === stateEnd.getDate()
        );
    }

    if (isSameDay && stateStart != false && stateEnd != false) {

        currentTime = new Date(stateStart);
        currentTime.setHours(0);
        currentTime.setMinutes(0);

        maxTimeStartDate = new Date();
        maxTimeStartDate.setHours(stateEnd.getHours());
        maxTimeStartDate.setMinutes(stateEnd.getMinutes());
        maxTimeStartDate.setSeconds(0);


        minTimeEndDate = new Date();
        minTimeEndDate.setHours(stateStart.getHours());
        minTimeEndDate.setMinutes(stateStart.getMinutes());
        minTimeEndDate.setSeconds(0);

        currentTimeEnd = new Date(stateEnd);
        currentTimeEnd.setHours(23);
        currentTimeEnd.setMinutes(30);
    }

    return (
        <div className={'box-input-controls'}>
            <div
                className={`ci-input-custom-box `}>
                <div
                    className={'text  text-s10  ci-placeholder-edit '}> {finger ? 'Начало' : 'Монтаж'}
                </div>
                <DatePicker
                    selected={stateStart}
                    onChange={handleDateChangeStart}
                    showTimeSelect
                    startDate={stateStart}
                    endDate={stateEnd}
                    locale="ru"
                    timeIntervals={30}
                    dateFormat="dd.MM.yyyy HH:mm"
                    maxDate={stateEnd}
                    minDate={new Date()}
                    minTime={currentTime}
                    maxTime={maxTimeStartDate}
                />
            </div>

            <div
                className={`ci-input-custom-box `}>
                <div
                    className={'text  text-s10  ci-placeholder-edit '}>{finger ? 'Конец' : 'Демонтаж'}
                </div>
                <DatePicker
                    selected={stateEnd}
                    onChange={handleDateChangeEnd}
                    showTimeSelect
                    startDate={stateStart}
                    endDate={stateEnd}
                    locale="ru"
                    timeIntervals={30}
                    dateFormat="dd.MM.yyyy HH:mm"
                    minDate={stateStart}
                    minTime={minTimeEndDate}
                    maxTime={currentTimeEnd}
                />
            </div>
        </div>
    );
});


export default Article;
