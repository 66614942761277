import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {getModal, getModalRow, getOrder} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import {useNavigate, useParams} from "react-router-dom";
import Modal from "react-modal";


const MainScreen = (() => {

    const navigate = useNavigate();
    const lang = useSelector(LangSlices);

    const [order, setOrder] = useState([]);
    const [reset, setReset] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const [modalName, setModalName] = useState('ProductDown');

    const [searchOb, setSearchOb] = useState({});

    const [colSklad, setSklad] = useState([]);


    const [groupTable, setGroupTable] = useState([]);


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setSearchOb(searchObS);
        setReset(!reset);
    }

    function addFilter(e) {
        setSearchOb({...searchOb, ...{search: e.target.value}});
        setReset(!reset);
    }


    async function getOrderSend() {

        let row = (await getModalRow(modalName)).data;

        const coSklad = (await getModal('Sklad', '?hidden_info=true')).data;

        setSklad(coSklad);


        const colGroup = (await getModal('Group', '')).data;


        setGroupTable(colGroup);

        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });


        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }

        setRowTable(row);
        const col = (await getModal(modalName, '?hidden_info=true')).data;
        setColTable(col);

        // setOrder(orderReq);
    }

    useEffect(() => {
        getOrderSend().then();
    }, [reset]);

    return (<Layout>

        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
               Списание товаров из заказа
            </p>

            <div className="table-btn">


            </div>
        </div>

        <div className="table-filter">
            <CustomSelect onChange={(e) => {
            }} options={{}} value={''} label={'Пользователи'}/>


            <CustomSelect onChange={(e) => {
            }} options={{}} value={''} label={'Заказы'}/>


        </div>
        <div>

            <Table  modalName={modalName} th={
                []
            } tr={
                []
            }
                   option={(id) => {
                       return (
                           <div className={'table-options'}>


                           </div>
                       )
                   }}
            />

        </div>

    </Layout>);
});
export default MainScreen;
