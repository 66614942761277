import React, {useEffect, useState} from 'react';

import './style.scss';
import Loader from "react-js-loader";
import {useNavigate, useParams} from "react-router-dom";
import {getModal, getModalRowEdit, getSingle, updateDB} from "../../services";
import InputsEdit from "../InputsEdit";
import modalBrowse from "../../views/InventoryScreen/components/Model/prihod.json";
import {NotificationManager} from "react-notifications";


const Article = (({
                      id_save,
                      saveEvent,
                      remove,
                      indexRemove,
                      save,
                      option,
                      productDrop,
                      onClick,
                      type = 'button',
                      label = '',
                      value,
                      onChange,
                      load = false
                  }) => {


    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);

    const [modalName, setModalName] = useState('Product');
    const params = useParams();


    const [rowTable, setRowTable] = useState({});

    const [hidden, setHidden] = useState(false);


    const [colTableProduct, setColTableProduct] = useState([]);

    const [orderSingle, setOrderSingle] = useState({});

    const [productList, setProductList] = useState({});
    const [id, setId] = useState(id_save);


    async function getOrderSend() {

        let row = ((await getModalRowEdit(modalName, id)).data);
        let orderSingle = (await getSingle(modalName, id)).data;




        setOrderSingle(orderSingle);

        if (orderSingle) {
            row = row.map((pr) => {
                if (typeof orderSingle[pr.name] != "undefined" && orderSingle[pr.name] != null) {
                    return {...pr, ...{value: orderSingle[pr.name]}};
                }
                return {...pr, ...{value: ""}};
            })
        }


        try {
            let modalBrowse = require('../../views/InventoryScreen/components/Model/prihod.json');
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        row[index] = {...row[index], ...modalBrowse[key]}
                    }
                });
            });


            let sortArray = [];
            Object.keys(modalBrowse).forEach((key) => {
                row.forEach((val, index) => {
                    if (val.name == key) {
                        sortArray.push(row[index]);
                    }
                });
            });

            rowProduct.forEach((val, index) => {
                if (sortArray.filter((filItem) => {
                    return filItem['name'] == val['name']
                }).length == 0) {
                    sortArray.push(row[index]);
                }
            });


            row = sortArray;

        } catch (err) {

        }

        setInputs(row);


    }


    async function save_send() {

        const updateDataOrder = (await updateDB({
            'save':
                inputs.reduce((ass, val) => {
                    return {...ass, ...{[val.name]: val.value}};
                }, {})
            , 'id': id, 'model_name': 'Product',
        })).data[0];
        if (id == 0) {
            if (saveEvent) {
                saveEvent(updateDataOrder['id']);
            }
            setId(updateDataOrder['id']);
        } else {
            if (saveEvent) {
                saveEvent(id);
            }
        }
        setOrderSingle(updateDataOrder);

    }


    useEffect(() => {
        console.log(save, hidden)
        if (save) {
            if (!hidden) {
                save_send();
            }
        }
    }, [save]);

    useEffect(() => {
        getOrderSend().then();
    }, [id]);

    if (hidden) {
        return false;
    }
    return (
        <>
            <InputsEdit nameDrop={productDrop} nameDropClick={(product) => {
                setId(product['id']);
                setOrderSingle(product);
            }} inputs={inputs} onChange={(e, name) => {
                setInputs(inputs.map((inp) => {
                    if (inp["name"] == name) {
                        return {...inp, ...{value: e.target.value}};
                    }
                    return inp;
                }));

            }}/>

            {
                option()
            }
            <div style={{display: "flex", justifyContent: "flex-start"}}>
                <p onClick={() => {
                    if (remove) {
                        remove(id, indexRemove);
                        setHidden(true);
                    }
                }} className={'text text-s18'} style={{color: "red", marginBottom: "3rem", cursor: 'pointer'}}>
                    Удалить
                </p>
            </div>
        </>
    )
});


export default Article;
