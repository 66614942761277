import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {deleteDataBase, getCatalogMenu, getModal, getModalRow, getOrder, getRowArenda} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";


import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import modalBrowse from "../OrderScreen/components/Model/browse.json";


const MainScreen = (() => {

    const navigate = useNavigate();
    const lang = useSelector(LangSlices);

    const [searchParams, setSearchParams] = useState();

    const [order, setOrder] = useState([]);
    const [page, setPage] = useState(0);

    const [reset, setReset] = useState(false);


    const [catalogIdMenu, setCatalogIdMenu] = useState(false);
    const [catalogMenuList, setCatalogMenuList] = useState([]);


    const [calendar, setCalendar] = useState(false);


    const [openArendaModel, setOpenArendaModel] = useState(false);

    const [openArendaModelRow, setOpenArendaModelRow] = useState([]);
    const [openArendaModelRowCol, setOpenArendaModelRowCol] = useState([]);

    const [catalogId, setCatalogId] = useState(false);

    const [modalName, setModalName] = useState('ProductMenu');

    const [selectSklad, setSelectSklad] = useState('');

    // const [searchOb, setSearchOb] = useState({});
    const [searchOb, setSearchOb] = useState(location.search.length > 0 ? JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {filter_sklad_id: 1});


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);

    const [colSklad, setSklad] = useState([]);

    const [sendTime, setSendTime] = useState(false);


    const [groupTable, setGroupTable] = useState([]);


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setSearchOb(searchObS);
        setReset(!reset);
    }


    function addFilter(e) {
        setPage(0);
        setSearchOb({...searchOb, ...{[e.target.name]: e.target.value, page: 1}});


        if (e.target.name == 'search' && e.keyCode == 13) {
            setReset(!reset);

        } else if (e.target.name != 'search') {
            setReset(!reset);

        }
    }


    async function getOrderSend() {

        let catalogMenu = (await getCatalogMenu('', ''));
        setCatalogMenuList(catalogMenu);

        const coSklad = (await getModal('Sklad', '?hidden_info=true')).data;

        setSklad(coSklad);
        let select_id = '';
        if (selectSklad == '') {
            setSelectSklad(coSklad[0]['id']);
            select_id = coSklad[0]['id'];
        } else {
            select_id = selectSklad;
        }


        let row = (await getModalRow(modalName)).data;
        // row['store_arenda'] = 'Дубл';
        // row['store_arenda2'] = 'Видимость';
        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });


        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }

        setRowTable(row);


        const colGroup = (await getModal('Group', '')).data;


        const col = (await getModal(modalName, '?' + serialize(searchOb) + "&browse=1" + (catalogIdMenu ? '&catalog_name=' + catalogIdMenu : ''))).data;

        setColTable(col);
        setGroupTable(colGroup);
        setSendTime(false);

        // setOrder(orderReq);
    }

    useEffect(() => {

        clearTimeout(sendTime);
        setSendTime(
            setTimeout(() => {

                getOrderSend().then();
            }, 500)
        )

    }, [reset]);


    useEffect(() => {
        navigate('/ProductMenu/browse?' + querystring(searchOb));
    }, [searchOb]);


    function querystring(obj) {

        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    async function setSearch(e) {
        setSelectSklad(e.target.value);
        const col = (await getModal(modalName, '?' + serialize(searchOb) + "&browse=1&filter_sklad_id=" + e.target.value)).data;
        setColTable(col);
    }

    async function arendaOpen(id) {


        let info_arenda = await getRowArenda(id, searchOb?.day);
        // const coSklad = (await getModal('Order', '?hidden_info=true')).data;

        let row = (await getModalRow('Order')).data;

        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });
        try {
            let modalBrowse = require('../OrderScreen/components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }


        setOpenArendaModel(true);
        setOpenArendaModelRowCol(row);
        setOpenArendaModelRow(info_arenda);

    }


    return (<Layout>

        <div className={'edit-model-head'}>
            <p className={'text text-bold text-s26'}>
                Меню
            </p>

            <div className="table-btn">

                <Btn className={'btn-def-table text text-s14'} onClick={() => {
                    navigate('/ProductMenu/edit/0');
                }} label={'Добавить'}/>

            </div>
        </div>


        <div className="table-filter">
            <CustomInput onChange={addFilter} name={'search'} value={searchOb?.search}
                         img={require('../../assets/images/search-normal.png')} label={'Поиск по каталогу'}/>

            <CustomSelect onChange={async (e) => {
                setSearchOb({...searchOb, ...{page: 1}});
                setPage(0);
                setCatalogIdMenu(e.target.value)
                setReset(!reset);
            }} options={catalogMenuList.reduce((acc, item) => {
                return {...acc, ...{[item]: item}};
            }, {})} value={catalogIdMenu} label={'Все разделы'}/>
            {/*<CustomSelect onChange={(e) => {*/}
            {/*    setCatalogId(e.target.value)*/}

            {/*    setPage(0);*/}

            {/*    setSearchOb({...searchOb, ...{catalog_id: e.target.value, page: 1}});*/}
            {/*    setReset(!reset);*/}

            {/*}} options={groupTable.reduce((acc, item) => {*/}
            {/*    return {...acc, ...{[item['id']]: item["name"]}};*/}
            {/*}, {})} value={searchOb ? searchOb?.catalog_id : ''} label={'Все разделы'}/>*/}


            {/*<CustomSelect onChange={(e) => {*/}
            {/*    // setSearch(e);*/}
            {/*    setSearchOb({...searchOb, ...{filter_sklad_id: e.target.value, page: 1}});*/}
            {/*    setReset(!reset);*/}
            {/*}} options={colSklad.reduce((acc, item) => {*/}
            {/*    return {...acc, ...{[item['id']]: item["name"]}};*/}
            {/*}, {})} value={searchOb?.filter_sklad_id} label={'Склады'}/>*/}

            {/*<CustomInput timeShow={false} onChange={addFilter} value={searchOb?.day} name={'day'}*/}
            {/*             type={'datetime-local'}*/}
            {/*             label={'Поиск по дате'}/>*/}
            <BtnCustom onChange={resetFilter} img={require('../../assets/images/refresh.png')}
                       label={'Сбросить фильтры'}/>
        </div>


        <div className={'pagination-box text text-s18'}>
            {colTable.last_page > 1 && (
                <ReactPaginate
                    nextLabel=">"
                    previousLabel="<"
                    initialPage={searchOb?.page ? parseInt(searchOb?.page - 1) : 0}
                    onPageChange={(e) => {
                        // setPage(1);
                        setSearchOb({...searchOb, ...{page: e.selected + 1}});
                        setPage(e.selected);
                        setReset(!reset);
                    }}
                    forcePage={page}
                    pageCount={colTable.last_page}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            )}

        </div>
        {
            !calendar && (
                <Table modalName={modalName} donwload={true} th={
                    rowTable
                } tr={
                    colTable.data
                }
                       loading={sendTime}
                       openArenda={arendaOpen}
                       option={(id) => {
                           return (
                               <div className={'table-options'}>

                                   {/*<input style={{marginRight: "2rem"}} type={'checkbox'}/>*/}
                                   <BtnIcon title={'edit'} onClick={() => {
                                       navigate('/ProductMenu/edit/' + id);
                                   }} img={require('../../assets/images/edit.png')}/>
                                   <BtnIcon title={'delete'} onClick={async () => {
                                       if (window.confirm("Точно удалить?")) {
                                           await deleteDataBase({
                                               id: id
                                           }, 'ProductMenu')
                                       }
                                       setReset(!reset);
                                   }} img={require('../../assets/images/trash.png')}/>

                               </div>
                           )
                       }}
                />
            )
        }

        <div className={'pagination-box text text-s18'}>
            {colTable.last_page > 1 && (
                <ReactPaginate
                    nextLabel=">"
                    previousLabel="<"
                    initialPage={searchOb?.page ? parseInt(searchOb?.page - 1) : 0}
                    onPageChange={(e) => {
                        // setPage(1);
                        setSearchOb({...searchOb, ...{page: e.selected + 1}});
                        setPage(e.selected);
                        setReset(!reset);
                    }}
                    forcePage={page}
                    pageCount={colTable.last_page}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            )}

        </div>


        <Modal
            isOpen={openArendaModel}
            contentLabel="Example Modal"
            className="modelOpenOrder-row"
            onRequestClose={() => {
                setOpenArendaModel(false);
            }}
        >
            <div className={'complect-list'}>

                <div className="complect-list-body">

                    <Table modalName={modalName} th={
                        openArendaModelRowCol
                    } tr={
                        openArendaModelRow
                    }
                           option={(id) => {
                               return (
                                   <div className={'table-options'}>

                                       {
                                           window.isRole("ProductMenu", 'edit') && (
                                               <>
                                                   <BtnIcon title={'download'} onClick={() => {
                                                       location.href = REACT_APP_ASSETS_PATH + '/api/excel/order/' + id;
                                                   }} img={require('../../assets/images/document-download.png')}/>
                                                   <BtnIcon title={'edit'} onClick={() => {
                                                       navigate('/Order/edit/' + id);
                                                   }} img={require('../../assets/images/edit.png')}/>
                                               </>
                                           )
                                       }
                                   </div>
                               )
                           }}
                    />


                </div>
            </div>
        </Modal>


    </Layout>);
});
export default MainScreen;
