import './style.scss';

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";


import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import Table from "../../components/Table";
import {getModal, getModalRow, getOrder} from "../../services/index";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import Btn from "../../components/Btn";
import BtnCustom from "../../components/BtnCustom";
import BtnIcon from "../../components/BtnIcon";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import {useNavigate, useParams} from "react-router-dom";
import Modal from "react-modal";


const MainScreen = (() => {


    const navigate = useNavigate();
    const lang = useSelector(LangSlices);

    const [order, setOrder] = useState([]);
    const [reset, setReset] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const [modalName, setModalName] = useState('Tilda_order');

    const [searchOb, setSearchOb] = useState({});


    const [rowTable, setRowTable] = useState({});
    const [colTable, setColTable] = useState([]);


    function resetFilter() {
        let searchObS = searchOb;
        Object.keys(searchObS).forEach((key) => {
            searchObS[key] = '';
        });
        setSearchOb(searchObS);
        setReset(!reset);
    }

    function addFilter(e) {
        setSearchOb({...searchOb, ...{search: e.target.value}});
        setReset(!reset);
    }


    async function getOrderSend() {

        let row = (await getModalRow(modalName)).data;
        Object.keys(row).forEach((key) => {
            row[key] = {
                name: row[key],
                className: "ci-table-th-88"
            }
        });


        try {
            let modalBrowse = require('./components/Model/browse.json');
            Object.keys(modalBrowse).forEach((key) => {
                if (typeof row[key] != "undefined") {
                    row[key] = {...row[key], ...modalBrowse[key]};
                } else {
                    row[key] = modalBrowse[key]
                }
            });
        } catch (err) {

        }

        setRowTable(row);
        const col = (await getModal(modalName, '?' + serialize(searchOb) )).data;
        setColTable(col);

        // setOrder(orderReq);
    }

    useEffect(() => {
        getOrderSend().then();
    }, [reset]);

    return (<Layout>

        <p className={'text text-bold text-s26'}>
            Заказы c сайта
        </p>


        {
            !calendar && (
                <Table th={
                    rowTable
                } tr={
                    colTable
                }
                       option={(id) => {
                           return (
                               <div className={'table-options'}>

                                   <BtnIcon title={'edit'} onClick={() => {
                                       navigate('/Tilda/edit/' + id);
                                   }} img={require('../../assets/images/edit.png')}/>
                                   <BtnIcon title={'delete'} img={require('../../assets/images/trash.png')}/>
                               </div>
                           )
                       }}
                />
            )
        }


        {/*{*/}
        {/*    colTable.length > 0 && (*/}
        {/*        <Modal*/}
        {/*            isOpen={true}*/}
        {/*            contentLabel="Example Modal"*/}
        {/*            className="model-add-box-comp-list"*/}
        {/*        >*/}
        {/*            <div className={'complect-list'}>*/}
        {/*                <div className="complect-list-head">*/}
        {/*                    <span className={'text text-bold text-s18'}>комплект: {colTable[3]['name']}</span>*/}
        {/*                </div>*/}
        {/*                <div className="complect-list-body">*/}


        {/*                    {*/}
        {/*                        colTable[3].product_list_product.map((li) => {*/}
        {/*                            return (*/}
        {/*                                <div className="item-complit">*/}
        {/*                                    <span className={'text text-s16'}>{li['name']} x{li['count']}</span>*/}
        {/*                                </div>*/}
        {/*                            )*/}
        {/*                        })*/}
        {/*                    }*/}

        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </Modal>*/}
        {/*    )*/}
        {/*}*/}


    </Layout>);
});
export default MainScreen;
