const INITIAL_STATE = {
    "patients": [],
    "user": {},
    "city_select": "Алматы",
    "city_open": false,
    "token_": false,
    "main.service.name": "Service",
    "main.work.title": "Stand out",
    "main.work.name": "Work",
    "main.service.ul.list1": {
        "ul": [{"id": 34786, "name": "Digital complex", "href": "", "sort": 0}, {
            "id": 17338,
            "name": " Brand building",
            "href": "",
            "sort": 0
        }, {"id": 19246, "name": "Branding and Rebranding", "href": "", "sort": 0}, {
            "id": 51018,
            "name": "Performance strategy",
            "href": "",
            "sort": 0
        }, {"id": 95537, "name": " Sales Analysis", "href": "", "sort": 0}]
    },
    "main.service.ul.list4": {
        "name": "PR and Events",
        "ul": [{"id": 89467, "name": "Online and Offline PR", "href": "", "sort": 0}, {
            "id": 61965,
            "name": "Social and ECO PR",
            "href": "",
            "sort": 0
        }, {"id": 52028, "name": " Product presentation", "href": "", "sort": 0}, {
            "id": 31307,
            "name": "Company`s and Brand`s",
            "href": "",
            "sort": 0
        }, {"id": 72041, "name": "Opening Teambuilding", "href": "", "sort": 0}]
    },
    "main.service.ul.list2": {
        "name": "Marketing",
        "ul": [{"id": 54035, "name": "Digital-marketing", "href": "", "sort": 0}, {
            "id": 70093,
            "name": " Social Media Marketing",
            "href": "",
            "sort": 0
        }, {"id": 20046, "name": " Web and App development", "href": "", "sort": 0}, {
            "id": 86194,
            "name": " Targeting",
            "href": "",
            "sort": 0
        }, {"id": 10701, "name": "", "href": "", "sort": 0}]
    },
    "main.service.ul.list3": {
        "name": "Design",
        "ul": [{"id": 87728, "name": "Logo design and branding", "href": "", "sort": 0}, {
            "id": 16134,
            "name": " Web and App design",
            "href": "",
            "sort": 0
        }, {"id": 91366, "name": " Social Media and advertising design", "href": "", "sort": 0}, {
            "id": 611,
            "name": " Motion design",
            "href": "",
            "sort": 0
        }, {"id": 48335, "name": " Character design", "href": "", "sort": 0}]
    },
    "main.service.ul.list5": {
        "name": "Production",
        "ul": [{"id": 65483, "name": "Video and photo production", "href": "", "sort": 0}, {
            "id": 14103,
            "name": "2D and 3D animation",
            "href": "",
            "sort": 0
        }, {"id": 78136, "name": "Sound production", "href": "", "sort": 0}]
    },
    "main.banner.text.last": "WORKS",
    "main.banner.text.main": {
        "name": "WE KNOW HOW",
        "ul": [{"id": 74479, "name": "Marketing", "href": "", "sort": 0}, {
            "id": 81799,
            "name": "Design",
            "href": "",
            "sort": 0
        }, {"id": 2304, "name": "Sales", "href": "", "sort": 0}, {
            "id": 20617,
            "name": "PR",
            "href": "",
            "sort": 0
        }, {"id": 10267, "name": "Production", "href": "", "sort": 0}, {
            "id": 95753,
            "name": "It",
            "href": "",
            "sort": 0
        }]
    },
    "main.banner.text.after": "Every project is a chance to try something new. <br/>                 Look at something with a fresh perspective. <br/> Do something for the first time.",
    "main.banner.text.btn": "Okey, Let’s Go!",
    "main.banner.text.color3": "#0A0A2B",
    "main.banner.text.color4": "#F48811",
    "main.banner.text.color2": "#F48811",
    "main.banner.text.color1": "#E61E13",
    "main.team.list": {
        "ul": [{
            "id": 18859,
            "name": "Dinara Kravchenko",
            "image": "/storage/uploads/ZiwV4dqLRU.jpg",
            "skill": "ыф\nфы",
            "super_skill": "",
            "href": "",
            "position": "SEO / Director / Marketing",
            "sort": 0
        }, {
            "id": 53569,
            "name": "Mikhail Krasnov",
            "image": "/storage/uploads/ab1ptnyUyh.jpg",
            "skill": "",
            "super_skill": "",
            "href": "",
            "position": "Designer",
            "sort": 0
        }, {
            "id": 51501,
            "name": "Mikhail Krasnov",
            "image": "/storage/uploads/mwCGL3Jol3.jpg",
            "skill": "",
            "super_skill": "",
            "href": "",
            "position": "SEO / Director / Marketing",
            "sort": 0
        }, {
            "id": 46699,
            "name": "Mikhail Krasnov",
            "image": "/storage/uploads/wJzx4bSOxA.jpg",
            "skill": "",
            "super_skill": "",
            "href": "",
            "position": "Designer",
            "sort": 0
        }, {
            "id": 10709,
            "name": "Mikhail Krasnov",
            "image": "/storage/uploads/VyAnFrZBfk.jpg",
            "skill": "",
            "super_skill": "",
            "href": "",
            "position": "SEO / Director / Marketing",
            "sort": 0
        }]
    },
    "main.about_up.text.color2": null,
    "main.about_up.text.btn": null,
    "main.about_up.text.color1": null,
    "main.about_up.text.color4": null,
    "main.about_up.text.color3": null,
    "main.about.up.text.btn": null,
    "main.about.up.text.color1": "#000",
    "main.about.up.text.color2": "#000",
    "main.about.up.text.color3": "#000",
    "main.about.up.text.color4": "#000",
    "main.aboutus.text.color2": "#12A9EA",
    "main.aboutus.text.color1": "#5BF8D2",
    "main.aboutus.text.color3": "#07071C",
    "main.aboutus.text.btn": "We look at the world through your eyes, and you look into our soul",
    "main.aboutus.text.color4": "#044897",
    "main.cases.text.btn": "test",
    "main.cases.text.color2": "#606085",
    "main.cases.text.color4": "#90C3C3",
    "main.cases.text.color3": "#0A0A2B",
    "main.cases.text.color1": "#B8F1F1",
    "main.cases.banner.text": "We look at the world through your eyes, and you look into our soul",
    "main.aboutus.text.text1": "We live in the era of multitasking, so every day each of us loads himself with dozens of unnecessary things. To make sure of this, you just need to go to any street in your city at 8 am and understand that many of us are running without knowing where and without knowing why. How many contented and happy faces do you see every day? So we want to double or even triple the number of smiles on the faces of passers-by who meet you throughout the day!",
    "main.aboutus.text.text6": {
        "name": "We speak different languages",
        "ul": [{"id": 33305, "name": "English", "href": "", "sort": 0}, {
            "id": 24498,
            "name": "Kazakh",
            "href": "",
            "sort": 0
        }, {"id": 84235, "name": "German", "href": "", "sort": 0}, {
            "id": 25781,
            "name": "Ukrainian  ",
            "href": "",
            "sort": 0
        }, {"id": 7976, "name": "Russian", "href": "", "sort": 0}]
    },
    "main.aboutus.text.text3": "BEE creative agency is a young community, with no less young specialists. The agency was created with the idea of implementing real and live projects for people. Every day hundreds of entrepreneurs try to create or refine their incredible and useful ideas in order to make life easier for all of us. We help the world to hear you, entrepreneurs!",
    "main.aboutus.text.text2": "BEE creative agency",
    "main.aboutus.text.text4": "The desire to be creative, come up with non–standard solutions, and challenge conventional things - all of this is in every member of our team. The most successful projects once, were just a crazy idea. We consider ourselves lucky because we are working with projects just at the stage of a \"crazy idea\"!",
    "main.aboutus.text.text5": {
        "name": "We are from different countries",
        "ul": [{"id": 98155, "name": "Kazakhstan", "href": "", "sort": 0}, {
            "id": 82034,
            "name": "Austria",
            "href": "",
            "sort": 0
        }, {"id": 99357, "name": "Ukraine", "href": "", "sort": 0}, {
            "id": 17611,
            "name": "Spain",
            "href": "",
            "sort": 0
        }]
    },
    "main.aboutus.text.text7": "But we are all united by one thing - love for our work and the belief that everything can be done better than it has been done so far!",
    "main.work.ul.cases": {},
};


const langReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LANG':
            return {...state, ...action.value};
        default:
            return state;
    }
};
export default langReducer;
