import {
    Routes,
    Route
} from "react-router-dom";


import OrderScreen from '../views/OrderScreen';
import OrderAddScreen from '../views/OrderScreen/add';

import OrderDownScreen from '../views/OrderDownScreen';


import TildaOrderScreen from '../views/TildaOrderScreen';
import AddTildaOrderScreen from '../views/TildaOrderScreen/add';


import ProductScreen from '../views/ProductScreen';
import ProductAddScreen from '../views/ProductScreen/add';

import ProductMenuScreen from '../views/ProductMenuScreen';
import ProductMenuAddScreen from '../views/ProductMenuScreen/add';

import PersonalScreen from '../views/PersonalScreen';
import PersonalAddScreen from '../views/PersonalScreen/add';


import PackScreen from '../views/PackScreen';
import PackAddScreen from '../views/PackScreen/add';


import GroupScreen from '../views/GroupScreen';
import GroupAddScreen from '../views/GroupScreen/add';


import ProductMoveScreen from '../views/ProductMoveScreen';
import ProductMoveAddScreen from '../views/ProductMoveScreen/add';


import SkladScreen from '../views/SkladScreen';
import SkladAddScreen from '../views/SkladScreen/add';

import SkladAnaScreen from '../views/SkladAnaScreen';
import SkladAnaScreenAdd from '../views/SkladAnaScreen/add';


import ProductTopScreen from '../views/ProductTopScreen';


import ProductTopDownScreen from '../views/ProductTopDownScreen';

import ProductDownManageScreen from '../views/ProductDownManageScreen';
import AddProductDownManageScreen from '../views/ProductDownManageScreen/add';

import ProductDownScreen from '../views/ProductDownScreen';
import AddProductDownScreen from '../views/ProductDownScreen/add';


import ProductDownOrderScreen from '../views/ProductDownOrderScreen';
import ProductDownOrderScreenAdd from '../views/ProductDownOrderScreen/add';

import BrokenScreenScreen from '../views/BrokenScreen';
import AddBrokenScreenScreen from '../views/BrokenScreen/add';

import InventoryScreen from '../views/InventoryScreen';
import AddInventoryScreen from '../views/InventoryScreen/add';
import PrihodInventoryScreen from '../views/InventoryScreen/prihod';
import DetalisInventoryScreen from '../views/InventoryScreen/detalis';

import PrihodScreen from '../views/PrihodScreen';
import AddPrihodScreen from '../views/PrihodScreen/add';
import PrihodPrihodScreen from '../views/PrihodScreen/prihod';
import DetalisPrihodScreen from '../views/PrihodScreen/detalis';


import RoleScreen from '../views/RoleScreen';
import AddRoleScreen from '../views/RoleScreen/add';

import AccountingScreen from '../views/AccountingScreen/index';
import AddAccountingScreen from '../views/AccountingScreen/add';


import AccountingAdminScreen from '../views/AccountingAdminScreen/index';
import AddAccountingAdminScreen from '../views/AccountingAdminScreen/add';


import RepairBoardScreen from '../views/RepairBoardScreen/index';
import RepairBoardAddScreen from '../views/RepairBoardScreen/add';

import UserScreen from '../views/UserScreen';
import AddUserScreen from '../views/UserScreen/add';

import AuthScreen from '../views/AuthScreen';

import CalendarScreen from '../views/CalendarScreen';
import {useSelector} from "react-redux";
import {getText, getUserInfo} from "../services";
import LangSlices from "../redux/slices/Lang";
import LangActions from "../redux/actions/LangActions";
import {useEffect} from "react";
import State_in from "../redux/slices/State";
import State from "../redux/actions/StateActions";
import RepairScreen from "../views/RepairScreen";
import AddRepairScreen from "../views/RepairScreen/add";

const Router = (() => {

    const lang = useSelector(LangSlices);
    const state_in = useSelector(State_in);
    const getFun = async () => {
        LangActions(await getText());

    }


    window.isRole = function (column, type) {
        if (state_in.authUser.role_id_array != null) {
            if (typeof state_in.authUser.role_id_array != "undefined") {
                if (typeof state_in.authUser.role_id_array[column] != "undefined") {
                    if (typeof state_in.authUser.role_id_array[column][type] != "undefined") {
                        if (state_in.authUser.role_id_array[column][type] == true) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    if (!state_in.auth && window.location.pathname != '/login') {
        window.location.href = '/login';
    }


    async function userInfo() {

        const userInfoAuth = await getUserInfo(state_in.token_);
        if (userInfoAuth.success) {
            State({
                authUser: userInfoAuth.message,
            })
        } else if (location.pathname != '/login') {
            window.location.href = '/login';
        }

    }

    useEffect(() => {
        const path = location.pathname.split('/');
        const lang = state_in.lang;

        if (path.length >= 1 && (path[1] == 'en' || path[1] == 'de')) {
            State({lang: path[1]});
        }

        userInfo();

        getFun();
    }, []);
    return (
        <Routes>

            <Route path="/" element={<CalendarScreen/>}/>
            <Route path="/login" element={<AuthScreen/>}/>

            <Route path="/Order/browse" element={<OrderScreen/>}/>
            <Route path="/OrderDown/browse" element={<OrderDownScreen/>}/>
            <Route path="/Order/edit/:id" element={<OrderAddScreen/>}/>

            <Route path="/Tilda/browse" element={<TildaOrderScreen/>}/>
            <Route path="/Tilda/edit/:id" element={<AddTildaOrderScreen/>}/>

            <Route path="/Product/browse" element={<ProductScreen/>}/>
            <Route path="/Product/edit/:id" element={<ProductAddScreen/>}/>

            <Route path="/Personal/browse" element={<PersonalScreen/>}/>
            <Route path="/Personal/edit/:id" element={<PersonalAddScreen/>}/>


            <Route path="/ProductMenu/browse" element={<ProductMenuScreen/>}/>
            <Route path="/ProductMenu/edit/:id" element={<ProductMenuAddScreen/>}/>


            <Route path="/Pack/browse" element={<PackScreen/>}/>
            <Route path="/Pack/edit/:id" element={<PackAddScreen/>}/>

            <Route path="/ProductMove/browse" element={<ProductMoveScreen/>}/>
            <Route path="/ProductMove/edit/:id" element={<ProductMoveAddScreen/>}/>


            <Route path="/Repair/browse" element={<RepairScreen/>}/>
            <Route path="/Repair/edit/:id" element={<AddRepairScreen/>}/>

            <Route path="/Sklad/browse" element={<SkladScreen/>}/>
            <Route path="/Sklad/edit/:id" element={<SkladAddScreen/>}/>


            <Route path="/SkladAn/browse" element={<SkladAnaScreen/>}/>
            <Route path="/SkladAn/edit/:id" element={<SkladAnaScreenAdd/>}/>


            <Route path="/ProductDown/browse" element={<ProductDownScreen/>}/>
            <Route path="/ProductDown/edit/:id" element={<AddProductDownScreen/>}/>

            <Route path="/ProductDownManage/browse" element={<ProductDownManageScreen/>}/>
            <Route path="/ProductDownManage/edit/:id" element={<AddProductDownManageScreen/>}/>


            <Route path="/ProductDownManageOrder/browse" element={<ProductDownOrderScreen/>}/>
            <Route path="/ProductDownManageOrder/edit/:id" element={<ProductDownOrderScreenAdd/>}/>


            <Route path="/Broken/browse" element={<BrokenScreenScreen/>}/>
            <Route path="/Broken/edit/:id" element={<AddBrokenScreenScreen/>}/>

            <Route path="/RepairBoard/browse" element={<RepairBoardScreen/>}/>
            <Route path="/RepairBoard/edit/:id" element={<RepairBoardAddScreen/>}/>


            <Route path="/ProductTop/browse" element={<ProductTopScreen/>}/>


            <Route path="/ProductTopDown/browse" element={<ProductTopDownScreen/>}/>


            <Route path="/Inventory/browse" element={<InventoryScreen/>}/>
            <Route path="/Inventory/edit/:id" element={<AddInventoryScreen/>}/>
            <Route path="/Inventory/prihod/:id" element={<PrihodInventoryScreen/>}/>
            <Route path="/Inventory/detalis/:id" element={<DetalisInventoryScreen/>}/>


            <Route path="/Prihod/browse" element={<PrihodScreen/>}/>
            <Route path="/Prihod/edit/:id" element={<AddPrihodScreen/>}/>
            <Route path="/Prihod/prihod/:id" element={<PrihodPrihodScreen/>}/>
            <Route path="/Prihod/detalis/:id" element={<DetalisPrihodScreen/>}/>


            <Route path="/Role/browse" element={<RoleScreen/>}/>
            <Route path="/Role/edit/:id" element={<AddRoleScreen/>}/>


            <Route path="/Group/browse" element={<GroupScreen/>}/>
            <Route path="/Group/edit/:id" element={<GroupAddScreen/>}/>


            <Route path="/Accounting/browse" element={<AccountingScreen/>}/>
            <Route path="/Accounting/edit/:id" element={<AddAccountingScreen/>}/>


            <Route path="/AccountingAdmin/browse" element={<AccountingAdminScreen/>}/>
            <Route path="/AccountingAdmin/edit/:id" element={<AddAccountingAdminScreen/>}/>


            <Route path="/User/browse" element={<UserScreen/>}/>
            <Route path="/User/edit/:id" element={<AddUserScreen/>}/>

        </Routes>
    )
})
export default Router;
