import {useEffect, useState} from "react";

import Input from "../../components/Input";
import Btn from "../../components/Btn";
import './style.scss'
import {getAuth, getUserInfo} from "../../services";
import State from "../../redux/actions/StateActions";


const MainScreen = (() => {

    const [name, setName] = useState('');
    const [pass, setPass] = useState('');


    return (
        <div className={'ci-login'}>
            <div className={'ci-page'}>
                <div className={'ci-form'}>
                    <form onSubmit={async (e) => {
                        e.preventDefault()
                        let reg = await getAuth({login: name, password: pass});
                        if (reg.success) {
                            const userInfoAuth = await getUserInfo(reg.data.token);

                            State({
                                token_: reg.data.token,
                                auth: true,
                                authUser: userInfoAuth.message,
                            })

                             location.href = "/"
                        }
                        return false;
                    }} className="ci-form__block">
                        <div className="logo-box">
                            <span className="material-symbols-outlined logo">
                              verified_user
                            </span>
                        </div>
                        <Input value={name} onChange={(e) => {
                            setName(e.target.value);
                        }} label={'Логин / Эл. почта'}/>
                        <Input value={pass} onChange={(e) => {
                            setPass(e.target.value);
                        }} label={'Пароль'}/>
                        <Btn type={'submit'} label={'Войти'}/>
                    </form>
                </div>
            </div>
        </div>
    );
});
export default MainScreen;
